import React from "react";
import './tableOrder.css';
// import { db } from '././../../firebase.js';
// import { collection, onSnapshot, query, addDoc, getDocs, serverTimestamp, documentId } from "firebase/firestore";
import { TabContent, TabPane, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, Tooltip } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactToPrint from 'react-to-print';
import TableBill from "./tableBill";
import { var_api } from "../constant";
import Select from 'react-select';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchableDropdown from "../creditCustomers/search.js";
import Bill from "../Foods/bill.js";
import pics from '../kitchen/kitImag/tray.png';
import { Card} from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import Offcanvas from 'react-bootstrap/Offcanvas';
import qz from 'qz-tray';
import sha256 from 'js-sha256';
import forge from 'node-forge';
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

toast.configure()

  class TableOrders extends React.Component {
    constructor(props) {
      
      super(props);
      this.routeChange = this.routeChange.bind(this);
      this.routeChance=this.routeChance.bind(this);
      this.routeDay=this.routeDay.bind(this);
      this.routeShop=this.routeShop.bind(this);
      this.routeDaily=this.routeDaily.bind(this);
      this.routeTable =  this.routeTable.bind(this);
      this.routeCredit =  this.routeCredit.bind(this);
      this.toggle = this.toggle.bind(this);
      this.toggleNav = this.toggleNav.bind(this);
      // this.toggleMastersMenu = this.toggleMastersMenu.bind(this);
      this.toggleChange = this.toggleChange.bind(this);
      this.handleOnclick = this.handleOnclick.bind(this);
      this.routeReport = this.routeReport.bind(this);
      this.routeGroupOrders =  this.routeGroupOrders.bind(this);
      this.routeEmployee = this.routeEmployee.bind(this);
      this.routeRawMaterial = this.routeRawMaterial.bind(this);
      this.routeTablemaster = this.routeTablemaster.bind(this);
      this.routeSupplier =  this.routeSupplier.bind(this);
      this.routeOutstandingpayment = this.routeOutstandingpayment.bind(this);
      this.routeItemSummary = this.routeItemSummary.bind(this);
      this.routeOrderhistory= this.routeOrderhistory.bind(this);
      this.routeMysubscription =  this.routeMysubscription.bind(this);
      this.routeUom  = this.routeUom.bind(this);
      this.fetchData = this.fetchData.bind(this);
      this.handleCardClick = this.handleCardClick.bind(this);
      this.handleCloseConfirmationModal = this.handleCloseConfirmationModal.bind(this);
      this.routeSettlements = this.routeSettlements.bind(this);
      this.handleOnclick = this.handleOnclick.bind(this);
      this.handleModLogout = this.handleModLogout.bind(this);
      this.handleCloseConfirmation = this.handleCloseConfirmation.bind(this);
      this.routenNewSettlements = this.routenNewSettlements.bind(this);
      this.handleSwitchToggle = this.handleSwitchToggle.bind(this);
      this.handleInstandFoodChange = this.handleInstandFoodChange.bind(this);
      this.handleInstandQuantityChange = this.handleInstandQuantityChange.bind(this);
      this.handleInstandPriceChange =  this.handleInstandPriceChange.bind(this);
      this.routeSubKitchenmaster =  this.routeSubKitchenmaster.bind(this);
      this.routeSubChance = this.routeSubChance.bind(this);
      this.routeMyProfile = this.routeMyProfile.bind(this);
      this.handleOpenNotifications = this.handleOpenNotifications.bind(this);
      this.routePaymodeStatusMaster = this.routePaymodeStatusMaster.bind(this);
      this.handleShowServices = this.handleShowServices.bind(this);
      this.handleCloseServices = this.handleCloseServices.bind(this);
      this.handleOpenPremiumAlert = this.handleOpenPremiumAlert.bind(this);
      this.routeDayClosing =this.routeDayClosing.bind(this);
      this.routeStockTransaction =this.routeStockTransaction.bind(this);
      this.routeStockTake =this.routeStockTake.bind(this);
      this.routeGLAccounts =this.routeGLAccounts.bind(this);
      this.routeGLFinanceSummary =this.routeGLFinanceSummary.bind(this);
      this.routeStockBalanceReport =this.routeStockBalanceReport.bind(this);
      this.routeStockAdjustments =this.routeStockAdjustments.bind(this);
      this.routePurchaseOrders =this.routePurchaseOrders.bind(this);
      this.routeStoreGrn =this.routeStoreGrn.bind(this);
      this.routeVoid=this.routeVoid.bind(this);
      this.routepandLReport = this.routepandLReport.bind(this);
    //  this.calculateTotal = this.calculateTotal.bind(this);
      
      
      this.state = {
        dropdownOpen: false
      };
      this.state = {
        shadropdownOpen: false,
        tooltipOpen: false // State to manage tooltip visibility
      };
      
      this.state = {
        activeTab: '1'
      };
      // Get today's date
      const today = new Date();

     // Format the date as dd-mm-yyyy
      const formattedDate = `${String(today.getDate()).padStart(2, '0')}-${String(today.getMonth() + 1).padStart(2, '0')}-${today.getFullYear()}`;
  const hisyear = today.getFullYear();
  const hismonth = String(today.getMonth() + 1).padStart(2, '0'); // Add leading zero if month is a single digit
  const hisday = String(today.getDate()).padStart(2, '0'); // Add leading zero if day is a single digit
  const historyformattedDate = `${hisyear}-${hismonth}-${hisday}`;
  
      this.state = {
        isChecked:true,
        activeTab: 2,
        selectedList: 'Breakfast',
        customers:[], // Set the default selected list to 'Breakfast'
        orderNo: 100,
        showTable: false,
        shopName: '',
        users:[],
        categories: [],
        foods: [],
        tables: [],
        cartItems: [],
        orders: [],
        searchQuery:'',
        checkedCategories:false,
        loading:false,
        modalOpen: false,
        selectedOrder: null,
        firstCategoryId:0,
        shopDetails:null,
        ItemCodeOptions:[],
        selectedItemCode:null,
      selectedTable: null,
      showItems:false,
      isOpenConfirmationModal: false, // Add state variable for new modal
      tableOrder:[],
      currentOrder:{},
      isUpdate:false,
      isPlaceOrder:false,
      loadTable:false,
      deliTableOrders:[],
      searchDate:historyformattedDate,
      searchOrders:"",
      orderHistoryItems:[],
      selectedOrderLabel:   { value: '7', label: 'Pending'},
        labelCodeOptions: [
            { value: '7', label: 'Pending' },
            { value: '1', label: 'Normal Order' },
            { value: '2', label: 'Credit Order' },
            { value: '3', label: 'Group Location Order' },
            { value: '4', label: 'Normal Table Order' },
            { value: '5', label: 'Credit Table Order' },
            { value: '0', label: 'All' },
        ], 
        startDate: formattedDate,
        endDate: formattedDate,
        isOpenSelectDate:false,
        orderSummary:{},
        isOpenOrderSummary:false,
        order_no:null,
        orderLoading: false,
        moveLoading:false,
        addLoading:false,
        normalLoading:false,
        creditLoading:false, 
        currentOrderLoading:false,
        HistoryOrderLoading:false,
        putLoader:[],
        deleteLoader:[],
        isdeletemodalopen:false,
        Dfood_item_id:null,
        Dkitchen_order_no:null,
        Dfood_id:null,
        Dindex:null,
        DorderLength:null,
        DorderId:null,
        Dfood_name: "",
        amountDetail:{},
        selectedPayMode: null,
        payModeOptions: [
         
      ],
      payModes:[],
      isNormalModal: false,
      isNormalCreditModal:false,
      isCreditModal:false,
      orderResponse:{},
      isOrderConfirmation:false,
      show:false,
      imageStatus: false,
      isPayModeEdit:false,
      editPaymodeOrder:null,
      isPayModeEditLoading:false,
      editpaymode:null,
      updateRemark:" ",
      selectedAmounts: [],
      enteredPaymodes:null,
      editSelectedAmounts:[],
      isOpenAddInstant: false,
      instantFoodName:null,
      instantQuantity:null,
      instantPrice:null,
      isMastersMenuOpen: false,
      showNotify:false,
      isNotify:false,
      notifications:[],
      currentPage:1,
      totalItems:0,
      startIndex:0,
      notifySearchQuery:'',
      itemsPerPage : 25,
      foodSearchQuery: '',
      notifytooltipOpen:false,
      printTooltipOpen:[],
      puttooltipOpen:[],
      showService:false,
      selectedCategory: 0,
      addedItems: [], // Track items that have been added to the cart
      subscriptions : null, 
      isPremium: false,
      connected: false,
      connectionAttempts: 0,
      disconnecting: false,
      isVoid: false,
      voidOrder: null,
      printers: [],
      isShowPrinters: false,
      printLoader:false, 
      printingType: localStorage.getItem('printingType')
      };
    }

   
    componentDidMount() {

      const userId = localStorage.getItem('userId');
      // this.getShopName(userId);
      if (userId) {
       
        const shopName = localStorage.getItem('shopName');
        const shopID = localStorage.getItem('shopId');
        console.log("shop",shopName,shopID)
        // Retrieve the shop name from localStorage
        if (shopName && shopID) {
          console.log("yes shop id is there")

          this.setState({ shopName:shopName })
          this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);
          this.fetchAllOrdersSummary(this.state.selectedOrderLabel.value);
          this.getExitingOrderNo();

          const sub_status = localStorage.getItem("sub_status");
            const verification_status = localStorage.getItem("verification_status");
            console.log("s,v",sub_status,verification_status, shopID, shopName);
            if(verification_status == false){
              Swal.fire({
                icon:"error",
                title:"error",
                text:"The Subscription was rejected', 'Please Contact SG BILLER Admin.",
                confirmButtonColor: '#007500',
              })
              
            }
            if(sub_status == false){
              Swal.fire({
                icon:"error",
                title:"error",
                text:"The Shop did not get Subscription', 'Please contact SG BILLER Admin",
                confirmButtonColor: '#007500',
              })
              
            }
         
         
          // localStorage.removeItem('shopName');
        } else {
          console.log("yes shop id is not there")
          console.log("ssshop",shopName)
          // If shop name is not available in localStorage, fetch it from the server
          this.getShopName(userId);
          if (localStorage.getItem('image_status') === null || '0') {
            localStorage.setItem('image_status', '1');
            console.log("b imagestatus",localStorage.getItem('image_status'));
      
          }
           
        }
      } else {
        console.error('User ID not found in local storage.');
        // Handle the case where the user ID is not available in local storage
      }
      

      this.fetchData();
      // this.fetchOrderHistory();
     // Call fetchCategories every second using setInterval
    this.fetchCategories(); // Call immediately
    setInterval(() => {
        this.fetchCategories();
    }, 100000);

      // this.getShopName(userId);
      // this.handleListClick(this.state.firstCategoryId);
      this.handleListClick()
      this.fetchItemCode();
      this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);
      this.fetchAllOrdersSummary(this.state.selectedOrderLabel.value);
      this.getExitingOrderNo();
      this.openModal();
      // this.handleListClick(this.defaultCategoryId)


      // Simulate a 3-second delay before checking categories (replace with your data fetching logic)
  //   setTimeout(() => {
  //     this.fetchData();
  //     this.fetchCategories();
  // this.setState({ checkedCategories: true})
  //   }, 9000); // Delay for 3 seconds
  this.subscriptionDetail();
  // this.initializeQZTray();
    this.handleListPayModes();
    this.setState({imageStatus: localStorage.getItem('image_status') === '1' ? true : false})
    console.log("imagestatus",localStorage.getItem('image_status'),this.state.imageStatus);
    }

    

    componentWillUnmount() {
      if (this.unsubscribe) {
        this.unsubscribe();
      }
      this.disconnectFromQZTray();
    }


    signData = (toSign, privateKeyPem) => {
      const privateKey = forge.pki.privateKeyFromPem(privateKeyPem);
      const md = forge.md.sha512.create();
      md.update(toSign, 'utf8');
      const signature = privateKey.sign(md);
      return forge.util.encode64(signature);
    };


    initializeQZTray = async () => {
      try {

        qz.security.setCertificatePromise(function(resolve, reject) {
          //Preferred method - from server
    //        fetch("assets/signing/digital-certificate.txt", {cache: 'no-store', headers: {'Content-Type': 'text/plain'}})
    //          .then(function(data) { data.ok ? resolve(data.text()) : reject(data.text()); });
    
          //Alternate method 1 - anonymous
    //        resolve();  // remove this line in live environment
    
          //Alternate method 2 - direct
          resolve("-----BEGIN CERTIFICATE-----\n" +
    "MIIECzCCAvOgAwIBAgIGAZBtKKX2MA0GCSqGSIb3DQEBCwUAMIGiMQswCQYDVQQG\n" +
    "EwJVUzELMAkGA1UECAwCTlkxEjAQBgNVBAcMCUNhbmFzdG90YTEbMBkGA1UECgwS\n" +
    "UVogSW5kdXN0cmllcywgTExDMRswGQYDVQQLDBJRWiBJbmR1c3RyaWVzLCBMTEMx\n" +
    "HDAaBgkqhkiG9w0BCQEWDXN1cHBvcnRAcXouaW8xGjAYBgNVBAMMEVFaIFRyYXkg\n" +
    "RGVtbyBDZXJ0MB4XDTI0MDYzMDA3MTgxOFoXDTQ0MDYzMDA3MTgxOFowgaIxCzAJ\n" +
    "BgNVBAYTAlVTMQswCQYDVQQIDAJOWTESMBAGA1UEBwwJQ2FuYXN0b3RhMRswGQYD\n" +
    "VQQKDBJRWiBJbmR1c3RyaWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMs\n" +
    "IExMQzEcMBoGCSqGSIb3DQEJARYNc3VwcG9ydEBxei5pbzEaMBgGA1UEAwwRUVog\n" +
    "VHJheSBEZW1vIENlcnQwggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQDd\n" +
    "snZmXeIgZFbb/TgULomwgto15aNCLZJBy86Jsx9DF05IhEQLOYJ8x8QgNc7bTFfc\n" +
    "ywnZvtsv2VdYip197R+g2LVL4f0FUTY3zTkV4RMWjgHt8ohuxww/MLKhy5R3Fknp\n" +
    "Xn5ovDUZOyIbs9gVCZepR7MvoCOcE/KP9R7gGF2l3zIpCI1EEntr13CIrnAZrhs+\n" +
    "JA5DHzlDEV0rZpCEQnysA4exgGpmNGpstDRy2Kdj3RtKXyTaH3dD/I2NQ5BUOBeJ\n" +
    "wVOWHgLxP9kE0s1i0XgDFSjt9Di8gk1SEK9BRHGComonX2vFW+c0a39W6zkhpPhv\n" +
    "wCQyUEJ4X359VrjCQU4TAgMBAAGjRTBDMBIGA1UdEwEB/wQIMAYBAf8CAQEwDgYD\n" +
    "VR0PAQH/BAQDAgEGMB0GA1UdDgQWBBTJ4yKVPVX6NPawD8kSkOSlpg1PcjANBgkq\n" +
    "hkiG9w0BAQsFAAOCAQEAzbyjcQ90HaxQKyazZUuB128q4dsVDXyIam6NMnT5Lkv9\n" +
    "AROiVnef2XityBucBJnC9Pf4HW98msTSeyPjSDPDLX6k3Ib8/f76gU6uu6WgApHI\n" +
    "EYBkIVpoeKq4ch35yg9SBZZNwv/xJeW6EjA9zLQ1Poje75AYOouXI2j6I0eqk6RD\n" +
    "MCtThm8CS6JlDcArMBUZwFk7otsWS90PQTIfaFkXbXoyFcSZmna1gOW0ZZ4AHgKY\n" +
    "rXVmWlaSLstc5jUFCKDDfTfIay9bqLjbc4EaTmayU8qdmJrhcvULFMmGCvu1FGgA\n" +
    "T+/fhNSt4qhYlDrqkTIAV1ICXEIOWnh/vHx3fb/5SA==\n" +
    "-----END CERTIFICATE-----");
      });
    
      qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
      qz.security.setSignaturePromise((toSign) => {
        return (resolve, reject) => {
          try {
            const privateKey = "-----BEGIN PRIVATE KEY-----\n" +
    "MIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQDdsnZmXeIgZFbb\n" +
    "/TgULomwgto15aNCLZJBy86Jsx9DF05IhEQLOYJ8x8QgNc7bTFfcywnZvtsv2VdY\n" +
    "ip197R+g2LVL4f0FUTY3zTkV4RMWjgHt8ohuxww/MLKhy5R3FknpXn5ovDUZOyIb\n" +
    "s9gVCZepR7MvoCOcE/KP9R7gGF2l3zIpCI1EEntr13CIrnAZrhs+JA5DHzlDEV0r\n" +
    "ZpCEQnysA4exgGpmNGpstDRy2Kdj3RtKXyTaH3dD/I2NQ5BUOBeJwVOWHgLxP9kE\n" +
    "0s1i0XgDFSjt9Di8gk1SEK9BRHGComonX2vFW+c0a39W6zkhpPhvwCQyUEJ4X359\n" +
    "VrjCQU4TAgMBAAECggEADFngx8jg4WNwWrbg9MWM9+T/tDNUJPU1/0K+rHmVZcml\n" +
    "cQZ8EKYGaN5A78x09vm/XAvXGNGjE3zdJWUqQLWWZqx8GTtWEIGa7taY9hOWhSdU\n" +
    "izBREomLW0qNa9R+3nhC649PT+rLCISnXKS92++EtV0gaf2sI4oRoEc3OPk9NRGU\n" +
    "zj3IEFLyx3E2saYI129w29evDMplo6C3NRtaAFEp+cWSG5DI+sCc+Juzbu1CblSl\n" +
    "9+8W3vV4TPK4lwbe1XmUauUFam0n+2j/X9Ipr+yZy92O1eP5isVRW9/wBnLunSz3\n" +
    "kNYD50DNgEkvWDrd6061JEzhSpnx4q+c48n4kdYcGQKBgQD9YHjrVAH1mMl6EZWH\n" +
    "kNy/QWnZ+OkB5okPDLLh4SzmDAiJ8DKqhO5nALtMkv3mBYceVGKMySmfTBMAuwh5\n" +
    "ogWMoyipoS3YHslzVMpJXzZtSTNXBY4SAoCtyYz9yKjcAg/Ykv7jMaiXelwfTkMq\n" +
    "wWQTTEe0ghDIFLOl2tpFK1iVOQKBgQDf/gds4IKLkd8yzvhLSAAd533I40mMp3LH\n" +
    "pBQ76vOt3FiIU7Xg0cuH6T5H/hhuW7N3BkvEXRSzABo98sRKDd3aCd5HEFkQqivh\n" +
    "auLRHFL+0hfyLQH8UzprJ+YtGk6AEx41UpeddsaBR6ezmsuj+gGA3MseSWiK81kM\n" +
    "Gw7TWyupqwKBgQCWuPGVQ2ba+uU3CQjDGOhHmRjnggrRh5qWaj5q4bD8s8HMlCb/\n" +
    "io3qlePU/EeusjEbWKU7DFsHGJDhSWEUQc49R71jkq6btkytn42pscrFndq9dF97\n" +
    "1SNIvfFx8hCIt21HrsGS0rJwUWhFcx+/akUmuaSZ+xkSfEYVbieTJ+LM+QKBgQCg\n" +
    "XrZeQ5fz6arOK9UlwRUkU/uA+wpLxA+v1FAQXjW9gwd4A8cygcKhOmO3E7vWN52U\n" +
    "WFOi8anCRw6Cz++tvaPbWjlk09WQ0uW77NUcNpVNkIpIf+TuDYDD+7sXC8CsfgOg\n" +
    "1dNrkACryuvM8cVfT74Hzw02UASjh3t36V+B/8KRJQKBgQCEq+FX7l6VrZIdvyAK\n" +
    "f+A4Vxr+S+cRvqXphilnjcuPEMTdRzTD8ROHVeQ+GajptVyC+ODOq1roBqp9VXRI\n" +
    "+zxhjo2vBbWjXKWD0fFssCPam9C5OzavJ6ZAI/FtujwjPnxoDOhrGzBOhF0nzAA3\n" +
    "5/7Etv6n5dzVZ9Px1dM0Vn1KSQ==\n" +
    "-----END PRIVATE KEY-----";
            const signedData = this.signData(toSign, privateKey);
            resolve(signedData);
          } catch (error) {
            reject(error);
          }
        };
      });
    
        qz.api.setSha256Type((data) => sha256(data));
        qz.api.setPromiseType((resolver) => new Promise(resolver));
    
        await this.connectToQZTray();
    } catch (error) {
        console.error('Failed to connect to QZ Tray', error);
        alert('Failed to connect to QZ Tray. Please ensure QZ Tray is running.');
    }
    };


    


    connectToQZTray = async () => {
      try {
        await qz.websocket.connect({ retries: 5, delay: 1000 });
        this.setState({ connected: true });
        console.log('Connected to QZ Tray');
    } catch (error) {
        this.setState(prevState => ({ connectionAttempts: prevState.connectionAttempts + 1 }));
        console.error('Failed to connect to QZ Tray:', error);
        if (this.state.connectionAttempts < 5) {
            setTimeout(this.connectToQZTray, 2000); // Retry connection after 2 seconds
        } else {
            alert('Failed to connect to QZ Tray after multiple attempts. Please ensure QZ Tray is running.');
        }
    }
    };
    
    
    disconnectFromQZTray = async () => {
      if (this.state.connected && !this.state.disconnecting) {
          this.setState({ disconnecting: true });
          try {
              await qz.websocket.disconnect();
              console.log('Disconnected from QZ Tray');
          } catch (error) {
              console.error('Error disconnecting from QZ Tray:', error);
          } finally {
              this.setState({ connected: false, disconnecting: false });
          }
      }
    };
    
    
    handlePrintss = async(index,id) => {
      console.log('Print button clicked');
      if (!this.state.connected) {
        console.error('Not connected to QZ Tray');
        alert('Not connected to QZ Tray. Please ensure QZ Tray is running.');
        return;
    }
    const billContent = document.getElementById(`billContent${index}`).innerHTML;
    const selectedPrinter = localStorage.getItem('selectedPrinter');
    console.log("local storage printer", selectedPrinter);
// if(selectedPrinter){
    try {
        const config = qz.configs.create(selectedPrinter); // Replace 'Printer_Name' with your printer name
        const data = [
            { type: 'html', format: 'plain', data: billContent },
        ];
    
        await qz.print(config, data);
        console.log('Print command executed successfully');
        this.handlePrint(id);
    } catch (error) {
        console.error('Error printing', error);
    }
  // }
  // else{
  //   alert("printer is not selected")
  //   this.setState({isShowPrinters:true});
  // }
    };




     // Method to handle the print button click
//   handlePrints= async (index, orderId) =>{
//     try {
//     const element = document.getElementById(`billContent${index}`);
//     if (!element) {
//         console.error('Element to print does not exist.');
//         return;
//     }
//     this.setState({printLoader:true});

//     // Convert the div to a PDF
//     const options = {
//         margin: [0, 0, 0, 0],
//         filename: `bill_${orderId}.pdf`,
//         image: { type: 'jpeg', quality: 0.98 },
//         html2canvas: { scale: 2, useCORS: true },
//         jsPDF: { unit: 'in', format:  [2.28, 1.90], orientation: 'portrait' }  // Adjust size as needed
//     };

//     const pdf = html2pdf().from(element).set(options);
//     const pdfData = await pdf.outputPdf('datauristring');

//     // Convert PDF to base64
//     const base64Data = pdfData.split(',')[1];

//     // Send the base64 PDF data to the backend
//     await axios.post('http://localhost:3001/print', {
//         data: base64Data,
//     });
//     toast('Print successfully');
//     this.setState({printLoader:false});
//     this.handlePrint(orderId)
//     //need to change print status 0 to 1 so call the update api
// } catch (err) {
//     console.error('Error printing:', err);
//     this.setState({printLoader:false});
//     toast('Please run the print server!')
// } 
// };



handlePrints = async (index, orderId) => {
  try {
      const element = document.getElementById(`billContent${index}`);

      if (!element) {
          console.error('Element to print does not exist.');
          return;
      }
      this.setState({ printLoader: true });

      // Capture the element as an image
      const canvas = await html2canvas(element, {
          scale: 2, // Adjust the scale for better quality
          useCORS: true
      });

      // Convert the canvas to an image
      const imgData = canvas.toDataURL('image/png');

      // Get natural dimensions of the canvas to maintain aspect ratio
      const imgWidth = 1.98; // Width in inches
      const imgHeight = (canvas.height / canvas.width) * imgWidth; // Height maintaining aspect ratio
      
      // Create a PDF and add the image to it
      const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'in',
          format: [imgWidth, imgHeight] // Set the dimensions of the PDF
      });

      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

      // Convert the PDF to base64
      const pdfData = pdf.output('datauristring');
      const printerName = localStorage.getItem("selectedPrinter");

      // Send the base64 PDF data to the backend
      await axios.post('http://localhost:3001/print', {
          data: pdfData.split(',')[1],
          printerName: printerName
      });

      toast('Print successfully');
      this.setState({ printLoader: false });
      this.handlePrint(orderId);
  } catch (err) {
      console.error('Error printing:', err);
      this.setState({ printLoader: false });
      toast('Please run the print server!');
  }
};

    


    // fetchCategoriesAndFirstCategoryFoodItems() {
    //   const { shopName } = this.state;
    //   const categoriesRef = collection(db, `shops/${shopName}/categories`);
    
    //   getDocs(categoriesRef)
    //     .then((querySnapshot) => {
    //       const categories = querySnapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }));
    //       console.log('Categories:', categories);
    
    //       if (categories.length > 0) {
    //         const firstCategoryId = categories[0].id;
    //         console.log(firstCategoryId )
    //         // this.handleListClick(firstCategoryId);
    //       }
    //     })
    //     .catch((error) => {
    //       console.error('Error fetching categories:', error);
    //     });
    // }

    
  //show the shop name in center
    getShopName(userId) {
      axios.get(`${var_api}/api/shop/shopData/${userId}`)
        .then((response) => {
          const data = response.data;
          this.setState({ shopName:data.shopName });
          localStorage.setItem('shopName', data.shopName);
          localStorage.setItem('shopId', data.shopID);
          this.fetchCategories();
        })
        .catch((error) => {
          console.error('Error fetching shop name:', error);
          // Handle the error if needed
        });
}


subscriptionDetail = async () => {
  const userid = localStorage.getItem('userId');
  try {
    const response = await axios.get(`${var_api}/api/user/user-subscription-detail/${userid}`);
    const subData = response.data;
    console.log("sub data", subData);
    this.setState({subscriptions: subData});
  } catch (error) {
    console.log('Error fetching data:', error);
    this.setState({subscriptions: []});
  }
  
};


routeDayClosing() {
  let path = `/day-closing`;
  this.props.history.push(path);
}


 //show the shop name in center
 getTableorderHistory(shop_id,tableNo,date) {
  this.setState({HistoryOrderLoading:true});
 // Split the date string into year, month, and day
 const [year, month, day] = date.split('-');
 // Create a new date string with the desired format
 const hformattedDate = `${day}-${month}-${year}`;
 console.log("Formatted date:", hformattedDate);
  axios.get(`${var_api}/api/food/getAll/table-orders/${shop_id}/${tableNo}/${hformattedDate}`)
    .then((response) => {
      const data = response.data.results;
      this.setState({ deliTableOrders:data });
      this.setState({HistoryOrderLoading:false});
    })
    .catch((error) => {
      console.error('Error fetching shop name:', error);
      this.setState({HistoryOrderLoading:false});
      // Handle the error if needed
    });
}


//list the pay modes
handleListPayModes =async() => {
  const shop_id = localStorage.getItem('shopId');
  const user_id = localStorage.getItem('userId');
  try {
    const response = await axios.get(`${var_api}/api/shop-paymode/active-paymode-list/${shop_id}/${user_id}` );
    const data = response.data
    const options = data.map(modes => ({
      value: modes.pay_mode_name,
      label:  modes.pay_mode_name
    }));
    const filteredPaymode = data.filter(mode => mode.pay_mode_name !== "CREDIT ORDER" && mode.pay_mode_name !== "GROUP LOCATION CREDIT ORDER");
    this.setState({payModeOptions: options, payModes:filteredPaymode})
    const newSelectedAmounts = Array(this.state.payModes.length).fill(0);
    this.setState({ selectedAmounts: newSelectedAmounts});
   console.log("payyyyy",data);
  }catch (error) {
    console.log('Error fetching data:', error);
    }
  }



//find the big order number
getExitingOrderNo() {
  const shop_id = localStorage.getItem('shopId');
  axios.get(`${var_api}/api/food/bigorderno/${shop_id}`)
    .then((response) => {
      const data = response.data.results;
      this.setState({ order_no:data[0] });
    })
    .catch((error) => {
      console.error('Error fetching order no:', error);
      // Handle the error if needed
    });
}


     //show the shop name in center
     getTableOrderItems(shop, table_no) {
      this.setState({currentOrderLoading:true});
      axios.get(`${var_api}/api/table-orders/get/table-orders/${shop}/${table_no}`)
        .then((response) => {
          const data = response.data.results;
          this.setState({ tableOrder:data });
          this.setState({currentOrderLoading:false});
        })
        .catch((error) => {
          console.error('Error fetching shop name:', error);
          this.setState({ tableOrder:[] });
          this.setState({currentOrderLoading:false});
          // Handle the error if needed
        });
      }

    //fetch categories
      fetchCategories = async () => {
       this.setState({loadTable:true});
        const shopId = localStorage.getItem('shopId')
        try {
         const response = await axios.get(`${var_api}/api/table-details/get-table/${shopId}` );
         const data = response.data;
         if (data.length > 0) {
           // If there are categories in the response, set the ID of the first category in the state
           this.setState({ tables: data});
           this.setState({loadTable:false});
         } else {
          this.setState({ tables: []});
           // Handle the case where there are no categories
           console.log('No tables found.');
           this.setState({loadTable:false});
         }
       }catch (error) {
         console.log('Error fetching data:', error);
         this.setState({loadTable:false});
         
         }
      // const shopName= localStorage.getItem('shopName');
      // // console.log("shop category:",shopName)
      // const colRef = collection(db, `shops/${shopName}/categories`);
      // const q = query(colRef);
      // const unsub = onSnapshot(q, (snapshot) => {
      //   const categoryNames = snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }));
      //   this.setState({ categories: categoryNames });
        
      //   });
      }


//fetch orders data to listing
    fetchOrderHistory = async () => {
      
      const shopName= localStorage.getItem('shopName');
      try {
        const response = await axios.get(`${var_api}/api/food/related-data/${shopName}`);
        console.log("get api:",response.data)
        this.setState({ orders: response.data });
      } catch (error) {
        console.log('API response is not an array:',error);
      }
    };




   //show the food details
    handleListClick = async() => {
      
      this.setState({loadTable:true});
      const id = localStorage.getItem('shopId')
      try {
        const response = await axios.get(`${var_api}/api/categories/cate/subfood/${id}` );
        const data = response.data
        console.log("dddddd",data,data.categories[0].sub_food,data.categories[0].sub_food[0].food_name);
        this.setState({ foods: data.categories});
        this.setState({ loading : false });
        this.fetchOrderHistory();
        
       this.setState({loadTable:false});
      }catch (error) {
        console.log('Error fetching data:', error);
        
        }
      // this.setState({ loading : true });
      // const shopName = localStorage.getItem('shopName');
      // console.log("shop category:",shopName)
      // const colRef = collection(db, `shops/${shopName}/categories/${id}/foodMaster`);
      // const q = query(colRef);
      //   const unsub = onSnapshot(q, (snapshot) => {
      //     const foodNames = snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }));
      //     this.setState({ foods: foodNames, defaultCategoryId: id });
      //     this.setState({ loading : false });
      //   });
      };


  
    toggle(tab) {
     
      const { activeTab } = this.state;
      this.fetchCategories();
  
      if (activeTab === tab) {
    
        // Close the active tab if it's clicked again
        this.setState({ activeTab: null });
      } else {
      
        this.setState({ activeTab: tab });
        
      }
    }


    openModal = (
      // order
    ) => {
      // this.setState({
      //   selectedOrder: order,
      //   modalOpen: true,
      // });
      const shopID = localStorage.getItem('shopId');
      // Make the API request
      axios
        .get(`${var_api}/api/shop/shopDetails/${shopID}`)
        .then((response) => {
          this.setState({
            shopDetails: response.data
          });
          console.log("shop details:",this.state.shopDetails)
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    };
  
    closeModal = () => {
      this.setState({
        modalOpen: false,
      });
    };

    routeChange() {
      let path = `/cate`;
      this.props.history.push(path);
    }

    routeChance() {
      let path = `/kitchen`;
      this.props.history.push(path);
    }

    routeMyProfile() {
      let path = `/my-profile`;
      this.props.history.push(path);
    }

    routeSubChance() {
      let path = `/sub-kitchen`;
      this.props.history.push(path);
    }

    routeShop() {
      let path = `/shopmas`;
      this.props.history.push(path);
    }

    routeDay() {
      let path = `/deliver`;
      this.props.history.push(path);
    }

    routeDaily() {
      let path = `/daily`;
      this.props.history.push(path);
    }

    routeTable() {
      let path = `/home`;
      this.props.history.push(path);
    }


    routeCredit() {
      let path = `/creditUser`;
      this.props.history.push(path);
    }


    routeReport() {
      let path = `/report`;
      this.props.history.push(path);
    }


    routeGroupOrders() {
      let path = `/groupOrders`;
      this.props.history.push(path);
    }

    routeEmployee() {
      let path = `/employee-master`;
      this.props.history.push(path);
    }

    routeRawMaterial() {
      let path = `/RawMaterial-master`;
      this.props.history.push(path);
    }

    routeSupplier() {
      let path = `/supplier-master`;
      this.props.history.push(path);
    }

    routeTablemaster() {
      let path = `/table-master`;
      this.props.history.push(path);
    }

    routeSubKitchenmaster() {
      let path = `/sub-kitchen-master`;
      this.props.history.push(path);
    }


    routeUom() {
      let path = `/uom-master`;
      this.props.history.push(path);
    }


    routeMysubscription() {
      let path = `/Mysubscriptionhistory`;
      this.props.history.push(path);
    }

     routeOutstandingpayment() {
        let path = `/Outstandingpayment`;
        this.props.history.push(path);
    }


    routeItemSummary() {
      let path = `/item-summary`;
      this.props.history.push(path);
  }


  routenNewSettlements() {
    let path = `/new-settlement`;
    this.props.history.push(path);
  }

  routeOrderhistory() {
    let path = `/order-history`;
    this.props.history.push(path);
}

handleCloseConfirmation(){
  this.setState({show: false})
}



routeSettlements() {
  let path = `/expense-log`;
  this.props.history.push(path);
}


routePaymodeStatusMaster() {
  let path = `/paymode-status-master`;
  this.props.history.push(path);
}


routeVoid() {
  let path = `/void-transaction`;
  this.props.history.push(path);
}



    toggleNav() {
      
      this.setState(prevState => ({
        dropdownOpen: !prevState.dropdownOpen

      }));
     
    }

    

    toggleMastersMenu = () => {
      this.setState(prevState => ({
        isMastersMenuOpen: !prevState.isMastersMenuOpen,
        dropdownOpen: !prevState.dropdownOpen
      }));
    }

      toggleChange() {
        this.setState(prevState => ({
          shadropdownOpen: !prevState.shadropdownOpen
        }));

    }

    toggleTooltip = () => {
      this.setState(prevState => ({
        tooltipOpen: !prevState.tooltipOpen
      }));
    }


    toggleNotifyTooltip = () => {
      this.setState({
        notifytooltipOpen: !this.state.notifytooltipOpen
      });
    };

    togglePrintTooltip = (index) => {
      const printTooltipOpen = [...this.state.printTooltipOpen];
      printTooltipOpen[index] = !printTooltipOpen[index];
      this.setState({ printTooltipOpen });
    };



    togglePutTooltip = (index) => {
      const puttooltipOpen = [...this.state.puttooltipOpen];
      puttooltipOpen[index] = !puttooltipOpen[index];
      this.setState({ puttooltipOpen });
    };

    routeStockAdjustments() {
      let path = `/stock-adjustments`;
      this.props.history.push(path);
    }


    routePurchaseOrders() {
      let path = `/purchase-orders`;
      this.props.history.push(path);
    }

    routeStoreGrn() {
      let path = `/store-grn`;
      this.props.history.push(path);
    }

    routeStockTake() {
      let path = `/stock-take`;
      this.props.history.push(path);
    }
    
    routeGLAccounts() {
      let path = `/glaccounts-Master`;
      this.props.history.push(path);
    }
    
    routeGLFinanceSummary() {
      let path = `/glfinance-Summary`;
      this.props.history.push(path);
    }
    
    routeStockBalanceReport() {
      let path = `/stock-balance-report`;
      this.props.history.push(path);
    }

    routeStockTransaction() {
      let path = `/stock-transaction`;
      this.props.history.push(path);
    }


    routepandLReport() {
      let path = `/profit-and-loss-report`;
      this.props.history.push(path);
    }


    handleOnclick(){
      this.setState({show:true})
    }

     //fetch notifications
     fetchNotifications = async () => {
      const { currentPage, itemsPerPage } = this.state;
      const startIndexCalc = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndexCalc + itemsPerPage;
      this.setState({isNotify:true});
       const shopId = localStorage.getItem('shopId')
       try {
        const response = await axios.get(`${var_api}/api/orderNotification/getall/ordernotification/${shopId}` );
        const data = response.data.results;
        if (data.length > 0) {
          const slicedData = data.slice(startIndexCalc, endIndex);
          // If there are categories in the response, set the ID of the first category in the state
          this.setState({ notifications:slicedData, isNotify:false, startIndex:startIndexCalc, totalItems:data.length });
          
        } else {
          // Handle the case where there are no categories
          console.log('No notifications found.');
          this.setState({isNotify:false,notifications:[]});
          this.setState({totalItems:0,startIndex:0})
        }
      }catch (error) {
        console.log('Error fetching data:', error);
        this.setState({isNotify:false,notifications:[]});
        }
    }


    handleOpenNotifications(){
      this.setState({showNotify:true})
      this.fetchNotifications();
    }

     
   


      //function for logout
  handleModLogout(){
    const logout= localStorage.removeItem('userId');
    const shop= localStorage.removeItem('shopId');
    const printera= localStorage.removeItem('selectedPrinter');
       const place = localStorage.getItem('userId');
       localStorage.removeItem('e_role');
       console.log("id",place)
       if(place==null){
         this.props.history.push('/signin')
       }
       localStorage.removeItem('shopName');
       }



    handleNameSelection = (name) => {
      if(name.shop_type === 2){
        localStorage.removeItem('shopName');
        const shopName=localStorage.setItem('shopName',name.shop_name);
        localStorage.setItem('shopId',name.id);
        this.props.history.push('/sb-home');
        console.log("type",name.shop_type,localStorage.getItem("shopType"));
        localStorage.setItem('shopType',name.shop_type);
        console.log("localStorage type",localStorage.getItem("shopType"));
      }else{
        this.setState({
          shopName: name.shop_name,
        });
        const shopName=localStorage.setItem('shopName',name.shop_name);
        const role = localStorage.getItem('e_role')
        if(role === "Chef" || role === "Captain" || role === "waiter"){
          localStorage.setItem('shopId',name.shop_id);
        }else{
          localStorage.setItem('shopId',name.id);
        }
        console.log(localStorage.getItem('shopId'), shopName);
        this.fetchCategories();
        this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);
        this.fetchAllOrdersSummary(this.state.selectedOrderLabel.value);
        this.getExitingOrderNo();
        this.setState({foods:[],cartItems:[]});
        this.setState({orders:[]});
      }
    };


    handlePaymentUpdate = (tableNo) => {
      const shopName = localStorage.getItem('shopName');
  
      // Send a PUT request to update the paid_status
      axios.put(`${var_api}/api/food/put/${shopName}/${tableNo}`)
        .then((response) => {
          if (response.status === 200) {
            // Payment update was successful
            toast('paid status updated',{autoClose:2000})
           this.fetchOrderHistory();
            // You can perform any additional actions here
          } else {
            Swal.fire({
              icon:"success",
              title:"success",
              text:"error updating payment",
              confirmButtonColor: '#007500',
            })
            // Handle the error appropriately
          }
        })
        .catch((error) => {
          console.error('Error updating payment:', error);
          // Handle the error appropriately
        });
    };
  

    fetchData = async () => {
      try {
        const role = localStorage.getItem('e_role');
        if( role === "Chef" || role === "Captain" || role === "Waiter" ){
           // const response = await axios.get('https://api.spbiller.com/api/shop/data');
         const create = localStorage.getItem('empId')
         const response = await axios.get(`${var_api}/api/shop/detailed-shop/${create}`);
        const user =response.data;

         if (Array.isArray(user)) {
          this.setState({ 
            users: user
           });
  
        } else {
          // Handle the case when responseData is not an array
          console.error('API response is not an array:', user);
          this.setState({ 
            users: [user]
           });; // Set users to an empty array or handle the error appropriately
        }

        }else{
           // const response = await axios.get('https://api.spbiller.com/api/shop/data');
         const create = localStorage.getItem('userId')
         const response = await axios.get(`${var_api}/api/shop/api/shops/${create}`);
        const user =response.data;

         if (Array.isArray(user)) {
          this.setState({ 
            users: user
           });
  
        } else {
          // Handle the case when responseData is not an array
          console.error('API response is not an array:', user);
          this.setState({ 
            users: [user]
           });; // Set users to an empty array or handle the error appropriately
        }

        }
        
      } catch (error) {
        console.log(error);
      }
    };

    // handleOnclick(){
    //   const logout= localStorage.removeItem('userId');
    //   const place = localStorage.getItem('userId');
    //   console.log("id",place)
    //   if(place==null){
    //     this.props.history.push('/')
    //   }
    //   localStorage.removeItem('shopName');
    // }

    handleSearchInput = (e) => {
     this.setState({
      searchQuery : e.target.value,
     });
    };


    //fetch all the item code
    fetchItemCode= async()=> {
      const shopId = localStorage.getItem('shopId')
        try {
          const response = await axios.get(`${var_api}/api/foodMaster/api/allFood/${shopId}`);
          const data = response.data.result;
    
          // Transform the data from the API into the format expected by react-select
          const formattedOptions = data.map(item => ({
            label: item.item_code, // Change this to the actual property name from your API response
          }));
    
          this.setState({ItemCodeOptions:formattedOptions});
        } catch (error) {
          console.log('Error fetching data:', error);
        }
      }


    

   //fetch the item details filter by item code
   handleAddByItemCode = async () => {
    const { selectedItemCode } = this.state
    if(selectedItemCode && selectedItemCode.label){
      const item_code = selectedItemCode.label
    const shopId = localStorage.getItem('shopId')
    try {
      const response = await axios.get(`${var_api}/api/foodMaster/api/filterItem/${shopId}/${item_code}`);
      const data = response.data.result;

      const food = data[0];
     // Add the selected food to the cart
      this.addToCart(food);
      console.log("selected food details",food)

     
    } catch (error) {
      console.log('Error fetching data:', error);
      
    }
      
    }else{
      Swal.fire({
        text:"Select Item Code Before Add",
        confirmButtonColor: '#007500',
      })
    }
  }


  incrementQuantity = (itemId) => {
    const { cartItems } = this.state;
    const updatedCartItems = cartItems.map((item) => {
      if (item.id === itemId) {
        return { ...item, quantity: item.quantity + 1 };
      }
      return item;
    });
    this.setState({ cartItems: updatedCartItems });
  };
  
  
  // decrementQuantity = (itemId) => {
  //   const { cartItems } = this.state;
  //   const updatedCartItems = cartItems.map((item) => {
  //     if (item.id === itemId && item.quantity > 0) {
  //       return { ...item, quantity: item.quantity - 1 };
  //     }
  //     return item;
  //   });
  //   this.setState({ cartItems: updatedCartItems });
  // };


  decrementQuantity = (itemId) => {
    const { cartItems, addedItems } = this.state;
    const updatedCartItems = cartItems
      .map((item) => {
        if (item.id === itemId && item.quantity > 0) {
          return { ...item, quantity: item.quantity - 1 };
        }
        return item;
      })
      .filter(item => item.quantity > 0); // Remove items with quantity 0

    // Remove the item from addedItems if its quantity is 0
    const updatedAddedItems = updatedCartItems.length === cartItems.length
      ? addedItems
      : addedItems.filter(item => item !== cartItems.find(ci => ci.id === itemId).food.id);

    this.setState({ cartItems: updatedCartItems, addedItems: updatedAddedItems });
  };

  




    addToCart = (food) => {
      const { cartItems } = this.state;
  const existingItemIndex = cartItems.findIndex((item) => item.food.id === food.id);

  if (existingItemIndex !== -1) {
    const updatedCartItems = [...cartItems];
    updatedCartItems[existingItemIndex].quantity += 1;
    this.setState({ cartItems: updatedCartItems });
  } else {
    const cartItemId = Date.now();
    this.setState((prevState) => ({
      cartItems: [...prevState.cartItems, {  id: cartItemId,food: food, quantity: 1 }],
      addedItems: [...prevState.addedItems, food.id],
    }));
  }
  this.setState({ selectedItemCode:null })
    };

    removeFromCart = (cartItemId) => {
      const { cartItems } = this.state;
      const updatedCartItems = cartItems.filter((item) => item.id !== cartItemId);
      this.setState({ cartItems: updatedCartItems });
    };


    generateOrder = async () => {
      console.log("it s")
      const { cartItems ,orders, selectedTable} = this.state;

  
      //check cart is empty
      if(cartItems.length === 0){
        console.log("it ss")
        Swal.fire({
          icon:"error",
          title:"Oops...",
          text:"Your cart is empty. Please add items to your cart before placing an order.",
          confirmButtonColor:"#007500"
        })
      }
      else{
        this.setState({moveLoading:true})
        console.log("it sss",cartItems)
        const role = localStorage.getItem('e_role');
        const shop = parseInt(localStorage.getItem('shopId'));
        const user = parseInt(localStorage.getItem('userId'));
        const empl =  role === "Chef" || role === "Captain" || role === "Waiter" ? parseInt(localStorage.getItem('empId')) : parseInt(localStorage.getItem('admin_employee'));
        const currentCaptain = role === "Chef" || role === "Captain" || role === "Waiter" ? localStorage.getItem('empName') : "Admin";
      // console.log("shop category:",shopName)
    //   const orderRef = collection(db, `shops/${shopName}/orders`);
    
      const orderItems = this.state.cartItems.map((cartItem) => {
        return { 
          food_id:cartItem.food.id,
          food_name: cartItem.food.food_name,
          quantity: cartItem.quantity,
          unit_price: cartItem.food.price,
          item_code: cartItem.food.item_code,
          category_code:cartItem.food.category_code,
          // Add more properties as needed
        };
      });

      const total = this.state.cartItems.reduce((accumulator, cartItem) => {
        return accumulator + cartItem.quantity * cartItem.food.price;
      }, 0);

      const totalItems = this.state.cartItems.length;
    
      // const totalItems = this.state.cartItems.reduce((accumulator, cartItem) => {
      //   return accumulator + cartItem.quantity;
      // }, 0);
    
      try {
        // const timestamp = serverTimestamp();
        // const querySnapshot = await getDocs(orderRef);
        // const orderNumber = querySnapshot.size + 1;

        // const customerName = document.querySelector('#name').value;
        // const customerPhone = document.querySelector('#mobile').value;

        // Validate name (only strings)
    // if (!/^[A-Za-z\s]+$/.test(attender_name)) {
    //   alert('Invalid name. Please enter a valid name.');
    //   return;
    // }

        // await addDoc(orderRef, {
        //   customer_name: customerName,
        //   customer_phone: customerPhone,
        //   order_items: orderItems,
        //   total: total,
        //   total_items: totalItems,
        //   order_no:orderNumber,
        //   ordered_at: timestamp,
        //   process_code:0,
        //   // Add more properties as needed
        // });


       //  calculate order number 
       let maxOrderNo = 0; // Initialize with a value that ensures the first order is greater
  
       for (const order of orders) {
         if (order.order_no > maxOrderNo) {
          console.log("orderno",order.order_no)
           maxOrderNo = order.order_no;
          }
        }
        maxOrderNo = maxOrderNo+1
        const currDate = new Date();
        const dd = String(currDate.getDate()).padStart(2, '0');
        const mm = String(currDate.getMonth() + 1).padStart(2, '0'); // January is 0!
        const yyyy = currDate.getFullYear();
        
        const formattedDate = `${dd}-${mm}-${yyyy}`;
      
        // Prepare the data to be sent to the Node.js API
        const orderData = {
          shop_id : shop,
          captain: currentCaptain, 
          table_no: selectedTable.table_no , 
          is_order_completed: 0,
          attender_name: "-",
          ordered_at:formattedDate ,
          user_id:user ,
          emp_id: empl || 0,
          is_table: 0,
          kitchen_status: 0,
          items: orderItems
        };

         // Send the order data to the Node.js API using axios
    const response = await axios.post(`${var_api}/api/table-orders/post`, orderData);
    if (response.status === 200) {
      
      // Handle successful order submission to both databases
      console.log('Order submitted successfully to Firestore and Node.js');
    }

    
       // Remove the items from the cartItems array
       this.setState({ cartItems: [], addedItems:[] });
       this.setState({ selectedItemCode: null});
       this.setState({ isOpenConfirmationModal: true})
       this.getTableOrderItems(shop,selectedTable.table_no)
       
         console.log('Order posted to Firestore successfully!');
         this.setState({moveLoading:false})
        console.log('Cart items removed from state!');
      } catch (error) {
        console.error('Error posting order:', error);
        this.setState({moveLoading:false})
      }
      this.setState({showItems:false})
      this.fetchCategories();
       
      }
    };




    AgaingenerateOrder = async () => {
      const { cartItems , currentOrder , selectedTable} = this.state;
  
      //check cart is empty
      if(cartItems.length === 0){
        Swal.fire({
          icon:"error",
          title:"Oops...",
          text:"Your cart is empty. Please add items to your cart before placing an order.",
          confirmButtonColor:"#007500"
        })
      }
      else{
        this.setState({addLoading:true})
        const role = localStorage.getItem('e_role');
        const shop = parseInt(localStorage.getItem('shopId'));
        const user = parseInt(localStorage.getItem('userId'));
        const empl =  role === "Chef" || role === "Captain" || role === "Waiter" ? parseInt(localStorage.getItem('empId')) : parseInt(localStorage.getItem('admin_employee'));
      // console.log("shop category:",shopName)
    //   const orderRef = collection(db, `shops/${shopName}/orders`);
    
      const orderItems = this.state.cartItems.map((cartItem) => {
        return {
          ordered_by: currentOrder.id,
          food_id:cartItem.food.id,
          food_name: cartItem.food.food_name,
          quantity: cartItem.quantity,
          unit_price: cartItem.food.price,
          item_code: cartItem.food.item_code,
          category_code:cartItem.food.category_code,
          // Add more properties as needed
        };
      });

      const total = this.state.cartItems.reduce((accumulator, cartItem) => {
        return accumulator + cartItem.quantity * cartItem.food.price;
      }, 0);

      const totalItems = this.state.cartItems.length;
    
    
      try {
      
        const currDate = new Date();
        const dd = String(currDate.getDate()).padStart(2, '0');
        const mm = String(currDate.getMonth() + 1).padStart(2, '0'); // January is 0!
        const yyyy = currDate.getFullYear();
        
        const formattedDate = `${dd}-${mm}-${yyyy}`;
      
        // Prepare the data to be sent to the Node.js API
        const orderData = {
          shop_id : shop,
          table_no: currentOrder.table_no ,
          ordered_at:formattedDate ,
          user_id:user ,
          emp_id: empl ,
          is_table: 0,
          data: orderItems,
          kitchen_order_no: currentOrder.kitchen_order_no, 
          kitchen_status: 0, 
          //  kitchen_status: currentOrder.kitchen_status, 
        };

         // Send the order data to the Node.js API using axios
    const response = await axios.post(`${var_api}/api/table-orders/add/order-items`, orderData);
    if (response.status === 200) {
      
      // Handle successful order submission to both databases
      console.log('Order submitted successfully to Firestore and Node.js');
    }

    
       // Remove the items from the cartItems array
       this.setState({ cartItems: [] , addedItems:[]});
       this.setState({ selectedItemCode: null});
       this.setState({ isOpenConfirmationModal: true})
       this.getTableOrderItems(shop,currentOrder.table_no)
       
         console.log('Order posted to Firestore successfully!');
         this.setState({addLoading:false})
        console.log('Cart items removed from state!');
      } catch (error) {
        console.error('Error posting order:', error);
        this.setState({addLoading:false})
      }
       
      }
      this.setState({showItems:false})
      this.fetchCategories();
    };



    handleAddCard = () => {
      // Navigate to a specific route using history.push
      this.props.history.push('/cate');
    };


    // Function to update customer name and phone number
  updateCustomerInfo = (table_no,total) => {

    const shopName = localStorage.getItem('shopName');
    const customer_name = document.querySelector('#customer_name').value;
    const phoneNo = document.querySelector('#phone_no').value;

    const updatedData = {
      cust_name:customer_name,
      phone_no:phoneNo,
      total:total,
    }

    axios
    .put(`${var_api}/api/food/printput/${table_no}/${shopName}`, updatedData)
    .then((response) => {
      toast('Order updated successfully',{autoClose:2000})
      console.log('Data updated successfully:', response.data.message);
      // Handle success, e.g., show a success message or update the UI
      this.fetchOrderHistory();
     
       // Clear the input fields
       document.querySelector('#customer_name').value = '';
       document.querySelector('#phone_no').value = '';
      
    })
    .catch((error) => {
      console.error('Error updating data:', error);
      // Handle error, e.g., show an error message or update the UI accordingly
    });
};


//fill the attender name and table no when reorder
handleChangeInput= (tableNo,attName) =>{
  // Clear the input fields
  document.querySelector('#attender_name').value = attName;
  document.querySelector('#table_no').value = tableNo;


}

//set the selected item code
handleCodeSelectChange = (data) => {
  this.fetchItemCode();

  this.setState({selectedItemCode:data}, () => {
    console.log('selected item code:', this.state.selectedItemCode);
  });
 }

handleCardClick = (table) => {
  const today = new Date();
  const hyear = today.getFullYear();
  const hmonth = String(today.getMonth() + 1).padStart(2, '0'); // Add leading zero if month is a single digit
  const hday = String(today.getDate()).padStart(2, '0'); // Add leading zero if day is a single digit
  const hformattedDate = `${hyear}-${hmonth}-${hday}`;
  // Determine the value of isOpenConfirmationModal based on the condition
console.log("open",this.state.tableOrder, table)
  this.setState({
    isOpenConfirmationModal: table.is_active == 0 ? false: true,
    selectedTable: table,
    isUpdate:false,
    isPlaceOrder:false,
    searchDate: hformattedDate,
    showItems: table.is_active == 1 ? false: true
  });
  
  this.getTableOrderItems(localStorage.getItem('shopId'),table.table_no)
  this.getTableorderHistory(table.shop_id, table.table_no,this.state.searchDate)
  document.querySelector('#name').value = '';
  document.querySelector('#mobile').value = '';
 
   
}

//fetch the customer balance
fetchCustomerBalance= async(id)=> {
  const shopId = localStorage.getItem('shopId')
    try {
      const response = await axios.get(`${var_api}/api/payment/balancedata/${id}/${shopId}`);
      const data = response.data;
     console.log("de",data)
      this.setState({amountDetail:data});
    } catch (error) {
      console.log('Error fetching data:', error);
      
    }
}


handleDropdownChange = (selectedOption) => {
  this.setState({ selectedData: selectedOption });
  console.log("credit user",selectedOption)
  console.log("credit user",this.state.selectedData)
  // Perform validation checks here
  this.fetchCustomerBalance(selectedOption.iden)
};



handleCloseConfirmationModal = () => {
  this.setState({
    isOpenConfirmationModal: false, isPlaceOrder: false
  });
  this.fetchCategories();
}


handleShowItems = () => {
  this.setState({isOpenConfirmationModal:false, showItems: true});
}


handleOpenDeleteConfirmation = (id, kitchen_order_no, food_master_id, index, length, orderID, food_name) => {
  this.setState ({isdeletemodalopen:true, Dfood_item_id: id, Dkitchen_order_no: kitchen_order_no, Dfood_id: food_master_id, Dindex:index, DorderLength:length, DorderId: orderID, Dfood_name:food_name });
}

handleDeletecloseConfirmation = () => {
  this.setState ({isdeletemodalopen:false});
}



handleAfterAddItems = (data) => {
  this.setState({isOpenConfirmationModal:false, showItems: true, currentOrder:data, isUpdate:true, isPlaceOrder:false});
}


 //Fetch the order history
 fetchAllOrdersHistory = async (order_label) => {
  const shopId = localStorage.getItem('shopId')
  this.setState({orderLoading: true});
  try {
   const response = await axios.get(`${var_api}/api/food/normaldate/related-data/${shopId}/${order_label}/${this.state.startDate}/${this.state.endDate}` );
   const data = response.data;
   if (data.length > 0) {
     // If there are categories in the response, set the ID of the first category in the state
     this.setState({ orderHistoryItems: data });
     
     this.setState({orderLoading: false});
     // this.handleListClick(this.state.firstCategoryId)
   } else {
     // Handle the case where there are no categories
     console.log('No categories found.');
     this.setState({ orderHistoryItems: [] });
     this.setState({orderLoading: false});
   }
 }catch (error) {
   console.log('Error fetching data:', error);
   
   }
 };

calculateTotal(items) {
  let total = 0;
  items.forEach(item => {
    total += item.unit_price * item.quantity;
  });
  return total;
}

 // Function to update customer name and phone number
 handleCloseOrder = (order_id,table_no) => {
   this.setState({isOpenConfirmationModal:false, isPlaceOrder : true})
  
};



handleGenerateOrderModal = () => {
 
   this.setState({ isNormalModal: true, selectedAmounts:[]});
  
}


handleGenerateNormalOrderModal = () => {

  if( this.state.selectedData){
   this.setState({ isNormalCreditModal: true,selectedAmounts:[]});
 }  else{
    Swal.fire({
      icon:"error",
      title:"Oops...",
      text:"Select Credit User",
      confirmButtonColor:"#007500"
    })
  }
 

}


//close the summary modal
handleCloseNormalConfirmation = () => {
  this.setState({isNormalModal:false});
}

//close the summary modal
handleCloseOrderConfirmation = () => {
  this.setState({isOrderConfirmation:false});
}


//close the summary modal
handleCloseNotifyConfirmation = () => {
  this.setState({showNotify:false});
}



//close the summary modal
handleCloseNormalCreditConfirmation = () => {
  this.setState({isNormalCreditModal:false});
}




//generate normal table order
generateNormalOrder = async () => {
  // Calculate the total amount from selectedAmounts
  const selectedTotal = this.state.selectedAmounts.reduce((accumulator, amount) => {
    return accumulator + amount;
  }, 0);

  const total = this.state.tableOrder[0].ordered_by.reduce((accumulator, cartItem) => {
    return accumulator + cartItem.quantity * cartItem.unit_price;
  }, 0);

   console.log("amounts",total,selectedTotal)
      if(selectedTotal > 0){

        if(selectedTotal === total){

  this.setState({ normalLoading:true});

    const shopName = localStorage.getItem('shopName');
  // console.log("shop category:",shopName)
  // const orderRef = collection(db, `shops/${shopName}/orders`);

  const orderItems = this.state.tableOrder[0].ordered_by.map((cartItem) => {
    return {
      food_id:cartItem.food_master_id,
      food_name: cartItem.food_name,
      quantity: cartItem.quantity,
      unit_price: cartItem.unit_price,
      item_code: cartItem.item_code,
      category_code:cartItem.category_code,
      // Add more properties as needed
    };
  });

 

  const totalWithServiceCharge = total.toFixed(2); // Add a service charge of 5 to the total

  // const totalItems = this.state.cartItems.reduce((accumulator, cartItem) => {
  //   return accumulator + cartItem.quantity;
  // }, 0);

  const totalItems = this.state.tableOrder[0].ordered_by.length;
  try {
    // const timestamp = serverTimestamp();
    // const querySnapshot = await getDocs(orderRef);
    // const orderNumber = querySnapshot.size + 1;
    const currDate = new Date();
    const dd = String(currDate.getDate()).padStart(2, '0');
    const mm = String(currDate.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = currDate.getFullYear();
    
    const formattedDate = `${dd}-${mm}-${yyyy}`;
    const currTime = new Date().toLocaleTimeString();
    
    var customerName = document.querySelector('#name') ? document.querySelector('#name').value : this.state.selectedData.label;
    var customerPhone = document.querySelector('#mobile') ? document.querySelector('#mobile').value : this.state.selectedData.value;
    var remark = document.querySelector('#remark') ? document.querySelector('#remark').value : "-";


    let maxOrderNo = 0;
        // if (order_no > maxOrderNo) {
        //   console.log("orderno",order_no)
        //    maxOrderNo = order_no.max_order_no;
        //   }
          console.log("m",this.state.order_no)
          maxOrderNo = this.state.order_no.max_order_no + 1
          console.log("m",maxOrderNo)
          const userEmail = localStorage.getItem("emailId");
        const userID = localStorage.getItem('userId');

    // Prepare the data to be sent to the Node.js API
    const orderData = {
      admin_id: parseInt(userID),
    admin_email: userEmail,
      shop_name:shopName,
      cust_name: customerName,
      phone_no: customerPhone,
      total_items:totalItems,
      order_no:maxOrderNo,
      total: totalWithServiceCharge,
      order_type:1,
      table_no:this.state.selectedTable.table_no,
      paid_status: 0,
      order_label:0,
      is_group_location_order:0,
      group_location_order_id:0,
      ordered_at:formattedDate,
      ordered_time:currTime,
      shop_id:localStorage.getItem('shopId'),
      credit_user_id:0,
      items: orderItems,
      captain: this.state.selectedTable ? this.state.selectedTable.captain : "-",
      pay_mode: "-",
      remarks:remark,
    };
    

     // Send the order data to the Node.js API using axios
const response = await axios.post(`${var_api}/api/table-orders/table-normal/post`, orderData);
if (response.status === 200) {

  console.log("customer id:",response.data.idUser)
  const customerid = response.data.idUser;

  const data = response.data.insertedTransIds; 
  this.setState({ selectedPayMode: null});

       // Create a new array with amounts inserted into matched pay modes
       const paymodesArray = this.state.payModes.map((mode, index) => {
        const amount = this.state.selectedAmounts[index] || 0; // Get amount from selectedAmounts array
        return { ...mode, amount, shop_id:localStorage.getItem('shopId'), order_id: customerid, ordered_at:formattedDate  }; // Create a new object with amount inserted
      });
      
  
      console.log("amountwitharray",paymodesArray);

      try {
        const response = await axios.post(`${var_api}/api/order-paymode/post`, paymodesArray);
        console.log(response.data); // Log response from server
        console.log( "Succes", response.data.message );
        const entered_Paymodes = response.data.results;
        const enteredPaymodesString = entered_Paymodes.map(paymode => paymode.pay_mode_name).join(', ');
        this.setState({selectedAmounts:[], enteredPaymodes: enteredPaymodesString});
      } catch (error) {
        console.error('Error posting pay modes:', error);
      }
  

  // Extract the insertedTransIds array from the response
 console.log("transaction id",data);

 const orderFireItems = this.state.tableOrder[0].ordered_by.map((cartItem,index) => {
  const data = response.data.insertedTransIds[index]; 
  return {
    food_name: cartItem.food_name,
    quantity: cartItem.quantity,
    unit_price: cartItem.unit_price,
    item_code: cartItem.item_code,
    category_code:cartItem.category_code,
    trans_id: data,
    // Add more properties as needed
  };
});

  // await addDoc(orderRef, {
  //   customer_name: customerName,
  //   customer_phone: customerPhone,
  //   order_items: orderFireItems,
  //   total: totalWithServiceCharge,
  //   total_items: totalItems,
  //   order_no:orderNumber,
  //   ordered_at: timestamp,
  //   sub_total:total,
  //   process_code:0,
  //   print_status:0,
  //   paid_status:0,
  //   parcel_status:0,
  //   customer_id : customerid,
  //   // Add more properties as needed
  // });
  
  // Handle successful order submission to both databases
  console.log('Order submitted successfully to Firestore and Node.js');
  this.setState({orderResponse:response.data})
    // Clear the text fields by setting their values to an empty string
document.querySelector('#name').value = '';
document.querySelector('#mobile').value = '';
document.querySelector('#remark').value = '';
this.setState({isNormalModal:false});
this.setState({isOrderConfirmation:true});

await this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);

const audio = new Audio('https://spbiller.com/audio/place-order.mp3');
    audio.play();

    const reversedOrders = this.state.orderHistoryItems.slice().reverse();
 // Find the index of the new order in orderHistoryItems array
 const newOrderIndex = reversedOrders.findIndex(order => order.id === customerid );

 // After order completion, check if the checkbox is checked
 if (this.state.isPrintNowChecked && newOrderIndex !== -1) {
  this.setState({ showService: true });
   await this.handlePrints(newOrderIndex, customerid);
   console.log("print check box checked",reversedOrders[newOrderIndex])
   this.setState({ showService: false });
 }
}



    console.log('Order posted to Firestore successfully!');

     // Remove the items from the cartItems array
this.setState({ cartItems: [],isPlaceOrder: false, addedItems:[] });
this.setState({ selectedItemCode: null});
this.fetchCategories();
const shop_id = localStorage.getItem('shopId');
const order_id = this.state.tableOrder[0].id
  axios
  .put(`${var_api}/api/table-orders/change/activetatus/${this.state.selectedTable.id}/${shop_id}/${order_id}`)
  .then((response) => {
    toast('Order updated successfully',{autoClose:2000})
    console.log('Data updated successfully:', response.data.message);
    // Handle success, e.g., show a success message or update the UI
   this.setState({isOpenConfirmationModal:false, isPlaceOrder : true, tableOrder: []})
    
  })
  .catch((error) => {
    console.error('Error updating data:', error);
    // Handle error, e.g., show an error message or update the UI accordingly
  });
  this.setState({ normalLoading:false});
  this.fetchCategories();
  this.getExitingOrderNo();

console.log('Cart items removed from state!');
  } catch (error) {
    console.error('Error posting order:', error);
    this.setState({ normalLoading:false});
  }
    // Fetch and display order history
    this.fetchOrderHistory();
  }
  else{
    Swal.fire({
      icon:"error",
      title:"Oops...",
      text:"pay mode amount is not equal to total amount",
      confirmButtonColor:"#007500"
    })
  }

}else{
  Swal.fire({
    icon:"error",
    title:"Oops...",
    text:"Enter the pay mode amount",
    confirmButtonColor:"#007500"
  })
}

}



generateCreditNormalOrder = async () => {
  // Calculate the total amount from selectedAmounts
  const selectedTotal = this.state.selectedAmounts.reduce((accumulator, amount) => {
    return accumulator + amount;
  }, 0);

  const total = this.state.tableOrder[0].ordered_by.reduce((accumulator, cartItem) => {
    return accumulator + cartItem.quantity * cartItem.unit_price;
  }, 0);

   console.log("amounts",total,selectedTotal)
  if(selectedTotal === total){

  if( this.state.selectedData){
    this.setState({ normalLoading:true});

  const shopName = localStorage.getItem('shopName');
// console.log("shop category:",shopName)
// const orderRef = collection(db, `shops/${shopName}/orders`);

const orderItems = this.state.tableOrder[0].ordered_by.map((cartItem) => {
  return {
    food_id:cartItem.food_master_id,
    food_name: cartItem.food_name,
    quantity: cartItem.quantity,
    unit_price: cartItem.unit_price,
    item_code: cartItem.item_code,
    category_code:cartItem.category_code,
    // Add more properties as needed
  };
});

const total = this.state.tableOrder[0].ordered_by.reduce((accumulator, cartItem) => {
  return accumulator + cartItem.quantity * cartItem.unit_price;
}, 0);

const totalWithServiceCharge = total.toFixed(2); // Add a service charge of 5 to the total

// const totalItems = this.state.cartItems.reduce((accumulator, cartItem) => {
//   return accumulator + cartItem.quantity;
// }, 0);

const totalItems = this.state.tableOrder[0].ordered_by.length;
try {
  // const timestamp = serverTimestamp();
  // const querySnapshot = await getDocs(orderRef);
  // const orderNumber = querySnapshot.size + 1;
  const currDate = new Date();
  const dd = String(currDate.getDate()).padStart(2, '0');
  const mm = String(currDate.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = currDate.getFullYear();
  
  const formattedDate = `${dd}-${mm}-${yyyy}`;
  const currTime = new Date().toLocaleTimeString();
  
 


  let maxOrderNo = 0;
      // if (order_no > maxOrderNo) {
      //   console.log("orderno",order_no)
      //    maxOrderNo = order_no.max_order_no;
      //   }
        console.log("m",this.state.order_no)
        maxOrderNo = this.state.order_no.max_order_no + 1
        console.log("m",maxOrderNo)

        var remark = document.querySelector('#remark') ? document.querySelector('#remark').value : "-";
        const userEmail = localStorage.getItem("emailId");
        const userID = localStorage.getItem('userId');

  // Prepare the data to be sent to the Node.js API
  const orderData = {
    admin_id: parseInt(userID),
    admin_email: userEmail,
    shop_name:shopName,
    cust_name: this.state.selectedData.label,
    phone_no: this.state.selectedData.value,
    total_items:totalItems,
    order_no:maxOrderNo,
    total: totalWithServiceCharge,
    order_type:1,
    table_no:this.state.selectedTable.table_no,
    paid_status: 0,
    order_label:0,
    is_group_location_order:0,
    group_location_order_id:0,
    ordered_at:formattedDate,
    ordered_time:currTime,
    shop_id:localStorage.getItem('shopId'),
    credit_user_id:this.state.selectedData.iden,
    items: orderItems,
    captain: this.state.selectedTable ? this.state.selectedTable.captain : "-",
    pay_mode: "-",
    remarks:remark
  };
  

   // Send the order data to the Node.js API using axios
const response = await axios.post(`${var_api}/api/table-orders/table-normal/post`, orderData);
if (response.status === 200) {

console.log("customer id:",response.data.idUser)
const customerid = response.data.idUser;

const data = response.data.insertedTransIds; 

// Extract the insertedTransIds array from the response
console.log("transaction id",data);

 // Create a new array with amounts inserted into matched pay modes
 const paymodesArray = this.state.payModes.map((mode, index) => {
  const amount = this.state.selectedAmounts[index] || 0; // Get amount from selectedAmounts array
  return { ...mode, amount, shop_id:localStorage.getItem('shopId'), order_id: customerid, ordered_at:formattedDate }; // Create a new object with amount inserted
});


console.log("amountwitharray",paymodesArray);

try {
  const response = await axios.post(`${var_api}/api/order-paymode/post`, paymodesArray);
  console.log(response.data); // Log response from server
  console.log( "Succes", response.data.message );
  const entered_Paymodes = response.data.results;
  const enteredPaymodesString = entered_Paymodes.map(paymode => paymode.pay_mode_name).join(', ');
  this.setState({selectedAmounts:[], enteredPaymodes: enteredPaymodesString});
} catch (error) {
  console.error('Error posting pay modes:', error);
}

const orderFireItems = this.state.tableOrder[0].ordered_by.map((cartItem,index) => {
const data = response.data.insertedTransIds[index]; 
return {
  food_name: cartItem.food_name,
  quantity: cartItem.quantity,
  unit_price: cartItem.unit_price,
  item_code: cartItem.item_code,
  category_code:cartItem.category_code,
  trans_id: data,
  // Add more properties as needed
};
});
this.setState({orderResponse:response.data})

document.querySelector('#remark').value = '';
this.setState({ selectedPayMode: null});
this.setState({isNormalCreditModal:false});
this.setState({isOrderConfirmation:true});
await this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);

// await addDoc(orderRef, {
//   customer_name: customerName,
//   customer_phone: customerPhone,
//   order_items: orderFireItems,
//   total: totalWithServiceCharge,
//   total_items: totalItems,
//   order_no:orderNumber,
//   ordered_at: timestamp,
//   sub_total:total,
//   process_code:0,
//   print_status:0,
//   paid_status:0,
//   parcel_status:0,
//   customer_id : customerid,
//   // Add more properties as needed
// });

// Handle successful order submission to both databases
console.log('Order submitted successfully to Firestore and Node.js');
console.log('yes coming');
const audio = new Audio('https://spbiller.com/audio/place-order.mp3');
    audio.play();
    const reversedOrders = this.state.orderHistoryItems.slice().reverse();
    // Find the index of the new order in orderHistoryItems array
    const newOrderIndex = reversedOrders.findIndex(order => order.id === customerid );
    // After order completion, check if the checkbox is checked
    if (this.state.isPrintNowChecked && newOrderIndex !== -1) {
     this.setState({ showService: true });
      await this.handlePrints(newOrderIndex, customerid);
      console.log("print check box checked",reversedOrders[newOrderIndex])
      this.setState({ showService: false });
    }

}

 
  this.clearSelectedValue();


  console.log('Order posted to Firestore successfully!');

   // Remove the items from the cartItems array
this.setState({ cartItems: [],isPlaceOrder: false, addedItems:[] });
this.setState({ selectedItemCode: null});
this.fetchCategories();
const shop_id = localStorage.getItem('shopId');
const order_id = this.state.tableOrder[0].id
axios
.put(`${var_api}/api/table-orders/change/activetatus/${this.state.selectedTable.id}/${shop_id}/${order_id}`)
.then((response) => {
  toast('Order updated successfully',{autoClose:2000})
  console.log('Data updated successfully:', response.data.message);
  // Handle success, e.g., show a success message or update the UI
 this.setState({isOpenConfirmationModal:false, isPlaceOrder : true, tableOrder: []})
  
})
.catch((error) => {
  console.error('Error updating data:', error);
  // Handle error, e.g., show an error message or update the UI accordingly
});
this.fetchCategories();
this.getExitingOrderNo();
this.setState({ normalLoading:false});
console.log('Cart items removed from state!');
} catch (error) {
  console.error('Error posting order:', error);
  this.setState({ normalLoading:false});
}
  // Fetch and display order history
  this.fetchOrderHistory();

}
else{
  Swal.fire({
    icon:"error",
    title:"Oops...",
    text:"Select Credit User",
    confirmButtonColor:"#007500"
  })
}
  }else{
    Swal.fire({
      icon:"error",
      title:"Oops...",
      text:"pay mode amount is not equal to total amount!",
      confirmButtonColor:"#007500"
    })
  }
}


handleGeneratecreditOrderModal = () => {
  

  if(this.state.selectedData){
    const user_balance = this.state.amountDetail.balance;
     const maxLimit = this.state.selectedData.maxCredit;
     const itemtotal = this.state.tableOrder[0].ordered_by.reduce((accumulator, cartItem) => {
      return accumulator + cartItem.quantity * cartItem.unit_price;
    }, 0);
    const totalAmount = user_balance + itemtotal;
          console.log("uuu",user_balance,maxLimit,itemtotal,totalAmount)
  if(maxLimit>=totalAmount){
   this.setState({ isCreditModal: true,selectedAmounts:[]});
    }else{

      Swal.fire({
        icon:"error",
        title:"Oops...",
        text:"The order reached the credit limit",
        confirmButtonColor:"#007500"
      })
      }


 }  else{
    Swal.fire({
      icon:"error",
      title:"Oops...",
      text:"Select Credit User",
      confirmButtonColor:"#007500"
    })
  }
 

}

//close the summary modal
handleCloseCreditConfirmation = () => {
  this.setState({isCreditModal:false});
}



generateCreditOrder = async () => {
  if( this.state.selectedData){
     const user_balance = this.state.amountDetail.balance;
     const maxLimit = this.state.selectedData.maxCredit;
     const itemtotal = this.state.tableOrder[0].ordered_by.reduce((accumulator, cartItem) => {
      return accumulator + cartItem.quantity * cartItem.unit_price;
    }, 0);
    const totalAmount = user_balance + itemtotal;
          console.log("uuu",user_balance,maxLimit,itemtotal,totalAmount)
  if(maxLimit>=totalAmount){
    this.setState({ creditLoading:true});
    const shopName = localStorage.getItem('shopName');
    const shop = parseInt(localStorage.getItem('shopId'));
        const user = parseInt(localStorage.getItem('userId'));
        const empl = parseInt(localStorage.getItem('admin_employee'));
  // console.log("shop category:",shopName)
  // const orderRef = collection(db, `shops/${shopName}/orders`);

  const orderItems = this.state.tableOrder[0].ordered_by.map((cartItem) => {
    return {
      food_id:cartItem.food_master_id,
      food_name: cartItem.food_name,
      quantity: cartItem.quantity,
      unit_price: cartItem.unit_price,
      item_code: cartItem.item_code,
      category_code:cartItem.category_code,
      // Add more properties as needed
    };
  });

  const total = this.state.tableOrder[0].ordered_by.reduce((accumulator, cartItem) => {
    return accumulator + cartItem.quantity * cartItem.unit_price;
  }, 0);

  const totalWithServiceCharge = total.toFixed(2); // Add a service charge of 5 to the total

console.log('Total with Service Charge:', totalWithServiceCharge);

  // const totalItems = this.state.cartItems.reduce((accumulator, cartItem) => {
  //   return accumulator + cartItem.quantity;
  // }, 0);

  const totalItems = this.state.tableOrder[0].ordered_by.length;

  try {
    // const timestamp = serverTimestamp();
    // const querySnapshot = await getDocs(orderRef);
    // const orderNumber = querySnapshot.size + 1;
    const currDate = new Date();
    const dd = String(currDate.getDate()).padStart(2, '0');
    const mm = String(currDate.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = currDate.getFullYear();
    
    const formattedDate = `${dd}-${mm}-${yyyy}`;
    const currTime = new Date().toLocaleTimeString();


    let maxOrderNo = 0;
    // if (order_no > maxOrderNo) {
    //   console.log("orderno",order_no)
    //    maxOrderNo = order_no.max_order_no;
    //   }
      console.log("m",this.state.order_no)
      maxOrderNo = this.state.order_no.max_order_no + 1
      console.log("m",maxOrderNo)

      var remark = document.querySelector('#remark') ? document.querySelector('#remark').value : "-";
      const userEmail = localStorage.getItem("emailId");
        const userID = localStorage.getItem('userId');
   

    // Prepare the data to be sent to the Node.js API
    const orderData = {
      admin_id: parseInt(userID),
    admin_email: userEmail,
      creditUser_id:this.state.selectedData.iden,
      shop_name:shopName,
      cust_name: this.state.selectedData.label,
      phone_no: this.state.selectedData.value,
      total_items:totalItems,
      order_no: maxOrderNo,
      total: totalWithServiceCharge,
      order_type:1,
      table_no:this.state.selectedTable.table_no,
      paid_status: 0,
      attender_name: "-",
      captain:this.state.selectedTable ? this.state.selectedTable.captain : "-",
      parcel_status:0,
      print_status:0,
      order_label:1,
      items: orderItems,
      ordered_at:formattedDate,
      ordered_time:currTime,
      is_group_location_order:0,
      group_location_order_id:0,
      shop_id:shop,
      user_id: user,
      emp_id: empl,
      is_table:1,
      kitchen_status:this.state.selectedTable.kitchen_status,
      pay_mode: "CREDIT ORDER",
      remarks:remark,
    };

     // Send the order data to the Node.js API using axios
const response = await axios.post(`${var_api}/api/table-orders/table-credit/post`, orderData);
if (response.status === 200) {

  console.log("customer id:",response.data.idCust)
  const customerID = response.data.idCust;
  const creditUserId =  response.data.creditId;
  const traData = response.data.insertedTransItesIds;
 
  console.log("credit user ids",creditUserId,traData);
  this.setState({orderResponse:response.data})

  const paymodesArray = [
    {
      order_id:customerID, 
      shop_id:shop, 
      id:10, 
      amount:total,
      pay_mode_name:"CREDIT ORDER",
      ordered_at:formattedDate
    }
  ]

  try {
    const response = await axios.post(`${var_api}/api/order-paymode/post`, paymodesArray);
    console.log(response.data); // Log response from server
    console.log( "Succes", response.data.message );
    const entered_Paymodes = response.data.results;
    const enteredPaymodesString = entered_Paymodes.map(paymode => paymode.pay_mode_name).join(', ');
    this.setState({enteredPaymodes: enteredPaymodesString});
  } catch (error) {
    console.error('Error posting pay modes:', error);
  }

  document.querySelector('#remark').value = '';
this.setState({ selectedPayMode: null});
this.setState({isCreditModal:false});
 this.setState({isOrderConfirmation:true});

  const creditorderItems = this.state.tableOrder[0].ordered_by.map((cartItem,index) => {
    const tradata = response.data.insertedTransItesIds[index]; 
    return {
      food_name: cartItem.food_name,
      quantity: cartItem.quantity,
      unit_price: cartItem.unit_price,
      item_code: cartItem.item_code,
      category_code:cartItem.category_code,
      trans_id: tradata,
      // Add more properties as needed
    };
  });
  console.log("cccc",creditorderItems)
  await this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);
  // await addDoc(orderRef, {
  //   // customer_name: selectedCreditCustomer.name,
  //   // customer_phone: selectedCreditCustomer.phone_no,
  //   customer_name: this.state.selectedData.label,
  //   customer_phone: this.state.selectedData.value,
  //   order_items: creditorderItems,
  //   total: totalWithServiceCharge,
  //   total_items: totalItems,
  //   order_no:orderNumber,
  //   ordered_at: timestamp,
  //   sub_total:total,
  //   process_code:0,
  //   print_status:0,
  //   paid_status:0,
  //   parcel_status:0,
  //   customer_id :customerID,
  //   creditUser_id:creditUserId
  //   // Add more properties as needed
  // });
  
  // Handle successful order submission to both databases
  console.log('Order submitted successfully to Firestore and Node.js');
  const audio = new Audio('https://spbiller.com/audio/place-order.mp3');
    audio.play();

    const reversedOrders = this.state.orderHistoryItems.slice().reverse();
    // Find the index of the new order in orderHistoryItems array
    const newOrderIndex = reversedOrders.findIndex(order => order.id === customerID );
   
    // After order completion, check if the checkbox is checked
    if (this.state.isPrintNowChecked && newOrderIndex !== -1) {
     this.setState({ showService: true });
      await this.handlePrints(newOrderIndex, customerID);
      console.log("print check box checked",reversedOrders[newOrderIndex])
      this.setState({ showService: false });
    }
}

 

    console.log('Order posted to Firestore successfully!');
    const email = localStorage.getItem('emailId');
    console.log('shop admin:',email);
    const orderItemsString = JSON.stringify(orderItems);
    

    // const billHtml = ReactDOMServer.renderToString(<Bill order={order} />);
    const emailData = {
      to:[this.state.selectedData.mail,email],
      subject: `${this.state.selectedData.label} ordered new order from ${shopName}..!`,
    }
    console.log('this.state.selectedData:',emailData,this.state.selectedData);

    const username = this.state.selectedData.label
    const shopID = localStorage.getItem('shopId');
    try {
      const response = await axios.post(`${var_api}/api/mailnotify/send-orderemail/${this.state.selectedData.mail}/${shopID}`, emailData);
      console.log('Email sent:', response.data);
        
    this.clearSelectedValue();
    } catch (error) {
      console.error('Error sending email:', error);
    }
  

     // Remove the items from the cartItems array
this.setState({ cartItems: [],isPlaceOrder: false, addedItems:[]  });
this.fetchCategories();

const shop_id = localStorage.getItem('shopId');

const order_id = this.state.tableOrder[0].id
  axios
  .put(`${var_api}/api/table-orders/change/activetatus/${this.state.selectedTable.id}/${shop_id}/${order_id}`)
  .then((response) => {
    toast('Order updated successfully',{autoClose:2000})
    console.log('Data updated successfully:', response.data.message);
    // Handle success, e.g., show a success message or update the UI
   this.setState({isOpenConfirmationModal:false, isPlaceOrder : true, tableOrder: []})
    
  })
  .catch((error) => {
    console.error('Error updating data:', error);
    // Handle error, e.g., show an error message or update the UI accordingly
  });
  this.setState({ creditLoading:false});
  this.fetchCategories();
 
  
  this.getExitingOrderNo();

console.log('Cart items removed from state!');
  } catch (error) {
    console.error('Error posting order:', error);
    this.setState({ creditLoading:false});
  }
}else{

  Swal.fire({
    icon:"error",
    title:"Oops...",
    text:"The order reached the credit limit",
    confirmButtonColor:"#007500"
  })
  }
}
else{
  Swal.fire({
    icon:"error",
    title:"Oops...",
    text:"Select Credit User",
    confirmButtonColor:"#007500"
  })
}
    
};


clearSelectedValue = () => {
  this.setState({ selectedData: ""}); // Clear the selected value
};



//onchange function for select date in order history
handleChange = (event) => {
  const selectedDate = event.target.value;
 // Split the date string into year, month, and day
 const [year, month, day] = selectedDate.split('-');
 // Create a new date string with the desired format
 const formattedDate = `${year}-${month}-${day}`;
 // Do whatever you want with the formatted date
 console.log('Formatted date:', formattedDate);
 this.setState({searchDate: formattedDate});
 this.getTableorderHistory(this.state.selectedTable.shop_id,this.state.selectedTable.table_no,formattedDate)
}


//get the value in search and set the setstate
handleSearchOrderHistoryInput = (e) => {
  this.setState({searchOrders:e.target.value})
  };

  fetchAllOrdersSummary = async (order_label) => {
    const shopId = localStorage.getItem('shopId')
    try {
     const response = await axios.get(`${var_api}/api/food/summary/related-data/${shopId}/${order_label}/${this.state.startDate}/${this.state.endDate}` );
     const data = response.data;
     this.setState({ orderSummary: data });
   }catch (error) {
     console.log('Error fetching data:', error);
      this.setState({ orderSummary: [] });
     }
   };


  handleOrderLabelSelectChange = (selectedOption) => {
    this.setState({ selectedOrderLabel: selectedOption });
    this.fetchAllOrdersHistory(selectedOption.value);
    this.fetchAllOrdersSummary(selectedOption.value)
  }
  
  handleStartDateChange = (event) => {
    const startDate = event.target.value;
    // Split the date string into year, month, and day
   const [year, month, day] = startDate.split('-');
   // Create a new date string with the desired format
   const formattedDate = `${day}-${month}-${year}`;
   // Do whatever you want with the formatted date
   console.log('Formatted date:', formattedDate);
    // Do something with the start date, e.g., store it in state
    this.setState({ startDate:formattedDate });
    
  }
  
  handleEndDateChange = (event) => {
    const endDate = event.target.value;
    // Split the date string into year, month, and day
   const [year, month, day] = endDate.split('-');
   // Create a new date string with the desired format
   const formattedDate = `${day}-${month}-${year}`;
   // Do whatever you want with the formatted date
   console.log('Formatted date:', formattedDate);
    // Do something with the end date, e.g., store it in state
    this.setState({ endDate:formattedDate });
    
  }
  
  
  handleOpenSelectDate = () => {
    this.setState ({modalOpen:true});
  }
  
  
  handleCloseDateConfirmation = () => {
    this.setState ({isOpenSelectDate:false});
  }
  
  handleSelectDateRange = () => {
    this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);
    this.setState ({modalOpen:false});
    this.fetchAllOrdersSummary(this.state.selectedOrderLabel.value)
    
  }
  
  formatDate(dateString) {
    const [day, month, year] = dateString.split('-');
    return `${year}-${month}-${day}`;
  }
  
  //open the summary modal
  handleOpenOrderSumModal = () => {
    this.setState({isOpenOrderSummary:true});
  }
  
  
  //close the summary modal
  handleCloseSummaryConfirmation = () => {
    this.setState({isOpenOrderSummary:false});
  }


//update the put loader state
  updateLoadingPutState = (index, isLoading) => {
    this.setState(prevState => {
      const newLoadingStates = [...prevState.putLoader]; // Use prevState.putLoader here
      newLoadingStates[index] = isLoading;
      return { putLoader: newLoadingStates };
    });
  };


//put method
handleEditQuantity = (id, kitchen_order_no, food_id, index, table_order_id) => {
  this.updateLoadingPutState(index, true);
  const shopName = localStorage.getItem('shopId');

  axios
      .put(`${var_api}/api/table-orders/edit-quantity/${id}/${kitchen_order_no}/${shopName}/${food_id}/${table_order_id}`)
      .then((response) => {
          toast('Order updated successfully', { autoClose: 2000 });
          console.log('Data updated successfully:', response.data.message, table_order_id);
          this.getTableOrderItems(shopName, this.state.selectedTable.table_no);
      })
      .catch((error) => {
          console.error('Error updating data:', error);
          // Handle error, e.g., show an error message or update the UI accordingly
      })
      .finally(() => {
          this.updateLoadingPutState(index, false);
      });
};


//udate the deleting loader state
updateLoadingDeleteState = (index, isLoading) => {
  this.setState(prevState => {
    const newLoadingStates = [...prevState.deleteLoader]; // Use prevState.deleteLoader here
    newLoadingStates[index] = isLoading;
    return { deleteLoader: newLoadingStates };
  });
};


//delete method
 handleDeleteItem = () => {
  const { Dfood_item_id , Dkitchen_order_no , Dfood_id, Dindex, DorderLength, DorderId} = this.state;
  console.log("l", Dfood_item_id , Dkitchen_order_no , Dfood_id, Dindex, DorderLength, DorderId)
  // this.setState({deleteLoader:true});
  this.updateLoadingDeleteState(Dindex,true);
  const shopName = localStorage.getItem('shopId');
  axios
  .delete(`${var_api}/api/table-orders/food-delete/${Dfood_item_id}/${Dkitchen_order_no}/${shopName}/${Dfood_id}/${DorderId}`)
  .then((response) => {
    if (response.status === 200) {
      this.getTableOrderItems(shopName, this.state.selectedTable.table_no)
      this.updateLoadingDeleteState(Dindex,false);

      if(DorderLength === 1){
           console.log("this is the last one")
           axios
  .put(`${var_api}/api/table-orders/change/activetatus/${this.state.selectedTable.id}/${shopName}/${DorderId}`)
  .then((response) => {
    toast('Order Cancelled',{autoClose:2000})
    console.log('Data updated successfully:', response.data.message);
    this.getTableOrderItems(shopName, this.state.selectedTable.table_no)
    this.fetchCategories()
  })
  .catch((error) => {
    console.error('Error updating data:', error);
  });
      }
      this.setState({isdeletemodalopen: false})
      // this.setState({deleteLoader:false});
      // Perform any other actions you need on success
    } else if (response.status === 404) {
      console.log('Slot not found');
      Swal.fire("error","Food not found!","error")
      // this.setState({deleteLoader:false});
      this.updateLoadingDeleteState(Dindex,false);
      this.setState({isdeletemodalopen: false})
    }
  })
  .catch((error) => {
    console.error('Error executing the request:', error);
    Swal.fire("error","Failed to Remove Item!","error")
    this.updateLoadingDeleteState(Dindex,false);
    this.setState({isdeletemodalopen: false})
    // Handle the error as needed
  });

 }


 //change the active status
 handledChangeActiveStats = (order_id) => {
  console.log('Sss');
  const shopName = localStorage.getItem('shopId');

  axios
  .put(`${var_api}api/table-orders/change/activetatus/${this.state.selectedTable.id}/${shopName}/${order_id}`)
  .then((response) => {
    toast('Order Cancelled',{autoClose:2000})
    console.log('Data updated successfully:', response.data.message);
    this.getTableOrderItems(shopName, this.state.selectedTable.table_no)
    this.fetchCategories()
  })
  .catch((error) => {
    console.error('Error updating data:', error);
  });
};


//go to kitchen
handleGoToKitchen = () => {
  let path = `/kitchen`;
    this.props.history.push(path);
}


 //onchange function for payment mode
handlePayModeSelectChange = (selectedOption) => {
  this.setState({ selectedPayMode: selectedOption });
  console.log(selectedOption)
} 


//switch for image status onchange
handleSwitchToggle() {
  // Toggle the switch state
  const newStatus = !this.state.imageStatus;
  this.setState({ imageStatus: newStatus });
  // Update localStorage
  localStorage.setItem('image_status', newStatus ? '1' : '0');
  console.log("imagestatus",localStorage.getItem('image_status'));
}



//to open the  edit modal
handleOpenEditPayModal = (order) => {
   // Sort pay_modes in ascending order based on pay_mode_id
   const sortedPayModes = order.pay_modes.sort((a, b) => a.paymode_id - b.paymode_id);
   const filteredPaymode = sortedPayModes.filter(mode => mode.pay_mode_name !== "CREDIT ORDER" && mode.pay_mode_name !== "GROUP LOCATION CREDIT ORDER");
  this.setState({isPayModeEdit:true, editPaymodeOrder:order, editSelectedAmounts:filteredPaymode, editpaymode: {label:order.pay_mode, value: order.pay_mode}, updateRemark:order.remarks});
  console.log("paymodd order",order);
  console.log("editSelectedAmounts", sortedPayModes);
}


//onchange the edit modal
handlePayModeEditChange = (selectedOption) => {
  this.setState({ editpaymode: selectedOption });
  console.log(selectedOption)
}

// Update the remark value in state when input changes
handleRemarkChange = (event) => {
  this.setState({ updateRemark: event.target.value });
};


//update method for paymode
handleUpdatePaymode = () => {
  const ModeTotal = this.state.editSelectedAmounts && this.state.editSelectedAmounts.reduce((sum, currentItem) => sum + currentItem.amount, 0)
  if(this.state.editPaymodeOrder.total !== ModeTotal){
    Swal.fire({
            icon:"error",
            title:"Oops...",
            text:"Paymode Total is not Equal to Total Amount",
            confirmButtonColor:"#007500"
          })

  }
  else{
  this.setState({isPayModeEditLoading:true});
  const shopID = localStorage.getItem('shopId');

   console.log("update",this.state.editSelectedAmounts)
   const paymodesArray = this.state.editSelectedAmounts.map((mode, index) => ({
    amount:  mode.amount ? mode.amount : null,
    id: mode.id,
    shop_id: parseInt(shopID),
    remarks: this.state.updateRemark,
    order_id: mode.order_id
}));


   axios
   .put(`${var_api}/api/order-paymode/edit-order-paymodes`, paymodesArray)
   .then((response) => {
  
     toast('Updated Successfully',{autoClose:2000});
     this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);
     this.setState({isPayModeEdit:false,isPayModeEditLoading:false});
     console.log('Data updated successfully:', response.data.message);
   })
   .catch((error) => {
     console.error('Error updating data:', error);
     this.setState({isPayModeEditLoading:false});
     // Handle error, e.g., show an error message or update the UI accordingly
   });
  }
};

//close the summary modal
handleCloseaPayModeEdit = () => {
  this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);
  this.setState({isPayModeEdit:false});
}


handleEditCardClick = (id, total) => {
  const { editSelectedAmounts } = this.state;
  // Find the index of the pay mode with the given ID
  const index = this.state.editSelectedAmounts.findIndex(mode => mode.id === id);
  console.log("inddx", index,this.state.editSelectedAmounts);
  
  const updatedEditSelectedAmounts = editSelectedAmounts.map(item => ({
    ...item,
    amount: 0
}));

if (index !== -1) {
  updatedEditSelectedAmounts[index].amount = total;
}

// Update the component state with the new array
this.setState({ editSelectedAmounts: updatedEditSelectedAmounts });
 
 
  console.log("after setting", updatedEditSelectedAmounts);
}


handleEditAmountChange = (index, value) => {
  const newSelectedAmounts = [...this.state.editSelectedAmounts];
  // Convert the value to a number
  const newValue = value !== '' ? parseFloat(value) : 0;
  // Ensure that the value is non-negative
  const nonNegativeValue = newValue >= 0 ? newValue : 0;
  // Update the amount at the specified index
  newSelectedAmounts[index].amount = nonNegativeValue;
  // Log the updated state
  console.log("after setting textfield", newSelectedAmounts);
  // Set the state with the updated amounts
  this.setState({ editSelectedAmounts: newSelectedAmounts });
}



//onclick method paymode card
handlemodeCardClick = (id) => {
  const { cartItems } = this.state;
  // Find the index of the pay mode with the given ID
  const index = this.state.payModes.findIndex(mode => mode.id === id);
  console.log("inddx", index,this.state.payModes);
  

  const total= this.state.tableOrder[0] && this.state.tableOrder[0].ordered_by && this.state.tableOrder[0].ordered_by.reduce((accumulator, cartItem) => {
    return accumulator + cartItem.quantity * cartItem.unit_price;
  }, 0)

    // Create an array to hold the new amounts, initialized with zeros
    const newSelectedAmounts = Array(this.state.payModes.length).fill(0);
 
  // Set the amount for the clicked pay mode
  newSelectedAmounts[index] = total; // Set the initial value here
 
  // Update the state
  this.setState({ selectedAmounts: newSelectedAmounts, isAmountSetByCardClick: true });

  console.log("after setting", newSelectedAmounts, this.state.isAmountSetByCardClick);
}


showalert = () => {
  Swal.fire({
    icon:"success",
    title:"success",
    text:"error updating payment",
    confirmButtonColor: '#007500',
  })
  return;
}





handleAmountChange = (index, value) => {

  // Check if the amount is being set due to card clicking
  // if (this.state.isAmountSetByCardClick) {
  //    // Create an array to hold the new amounts, initialized with zeros
  //    const newSelectedAmounts = Array(this.state.payModes.length).fill(0);
  //    console.log("if", newSelectedAmounts);
  //   // Reset the flag
  //   this.setState({ selectedAmounts: newSelectedAmounts, isAmountSetByCardClick: false });
  // }
 // Create a copy of the current selectedAmounts array
 const newSelectedAmounts = [...this.state.selectedAmounts];

 // Convert the value to a number
const newValue = value !== '' ? parseFloat(value) : 0;

// Check if the value is 0 and the user wants to clear it
if (newValue === 0) {
  newSelectedAmounts[index] = null; // Set the value to an empty string
} else {
  // Ensure that the value is non-negative
  const nonNegativeValue = newValue >= 0 ? newValue : 0;
  // Update the quantity at the specified index
  newSelectedAmounts[index] = nonNegativeValue;
}
  // Set the amount for the corresponding index
  // newSelectedAmounts[index] = parseFloat(value) || 0;
  console.log("after setting textfield", newSelectedAmounts);
  // Clear the card clicked index
  this.setState({ selectedAmounts: newSelectedAmounts});
}



//to open the instant food modal
handletoOpenInstantFood = () => {
  this.setState({ isOpenAddInstant: true});
}


//close the summary modal
handleCloseInstantAdding = () => {
  this.setState({isOpenAddInstant:false});
}


//onchange function for instant food name
handleInstandFoodChange(event) {
  this.setState({
    instantFoodName : event.target.value
  });
}


//onchange function for instant quantity
handleInstandQuantityChange(event) {
  this.setState({
    instantQuantity : event.target.value
  });
}


//onchange function for instant price
handleInstandPriceChange(event) {
  this.setState({
    instantPrice : event.target.value
  });
}


//to adding instant food in cart items
handleToInstantAddingFood = () => {
  const { cartItems, instantFoodName, instantQuantity, instantPrice } = this.state;
  if(instantFoodName && instantQuantity && instantPrice && instantPrice > 0 && instantQuantity > 0){
const existingItemIndex = cartItems.findIndex((item) => item.food.food_name === instantFoodName);

if (existingItemIndex !== -1) {
const updatedCartItems = [...cartItems];
updatedCartItems[existingItemIndex].quantity += instantQuantity;
this.setState({ cartItems: updatedCartItems });
this.setState({ isOpenAddInstant: false, instantFoodName:null, instantQuantity:null, instantPrice:null});
} else {

const food = {
   food_name: instantFoodName,
   price: instantPrice || 1,
   category_code:" ",
   category_id:0,
   cost:0,
   currency:" ",
   id:0,
   image:" ",
   item_code:`instant+${instantFoodName}`,
   uom:" "
}
const cartItemId = Date.now();
this.setState((prevState) => ({
  cartItems: [...prevState.cartItems, {  id: cartItemId,food: food, quantity: instantQuantity }],
}));
this.setState({ isOpenAddInstant: false, instantFoodName:null, instantQuantity:null, instantPrice:null});
 }
console.log("cc",cartItems);
  }else{
    console.log("in",instantFoodName, instantQuantity,);
    toast('Please enter all the fields',{autoClose:2000})
  }
};


//handle to change the pagination page
handlePageChange = (pageNumber) => {
  this.setState({ currentPage: pageNumber }, () => {
    this.fetchNotifications(); // Fetch data for the new page
  });
};
    

//get the value in search and set the setstate
 handleSearchNotifyInput = (e) => {
  this.setState({notifySearchQuery:e.target.value});
  };


   //onchange for food search text field
   handleFoodSearchInput = (e) => {
    this.setState({
      foodSearchQuery : e.target.value,
    });
   };

   handleShowServices() {
    this.openModal();
    this.setState({ showService: true });
  }

  handleCloseServices() {
    this.setState({ showService: false });
  }

  onCategoryClick = (idx) => {
    this.setState({ selectedCategory: idx });
  };


  //update the print status
handlePrint = (orderId) => {
  const shopID = localStorage.getItem('shopId');
  console.log("ids", orderId);
   
  const updatedStatus = {
    print_status:1,
   }

   axios
   .put(`${var_api}/api/food/put/print/${shopID}/${orderId}`, updatedStatus)
   .then((response) => {
    //  Swal.fire({
    //    text:"print status updated",
    //    confirmButtonColor:"#007500"
    //  })
    this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);
    toast('print status updated',{autoClose:2000})
     console.log('Data updated successfully:', response.data.message);
   })
   .catch((error) => {
     console.error('Error updating data:', error);
     // Handle error, e.g., show an error message or update the UI accordingly
   });
  // this.setState({ isPrinted: true }); // Set the state to indicate that the button has been clicked
  // // Call the ReactToPrint logic here if needed
};

handleOpenPremiumAlert = () => {
  this.setState({ isPremium: true});
}

handleClosePremiumAlert = () => {
  this.setState({ isPremium: false});
}


toggleModalPrinter = () => {
  this.setState(prevState => ({ isShowPrinters: !prevState.isShowPrinters }));
};

selectPrinter = (printer) => {
  console.log(printer );
  localStorage.setItem('selectedPrinter', printer);
  this.toggleModalPrinter();
};


removePrinter = () => {
  localStorage.removeItem('selectedPrinter');
  console.log( "remove printer",localStorage.removeItem('selectedPrinter'))
  this.toggleModalPrinter();
};



//close the summary modal
handleCloseVoidConfirmation = () => {
  this.setState({isVoid:false});
}


//update the print status
handleVoidPut = () => {
  const shopID = localStorage.getItem('shopId');
  const orderId = this.state.voidOrder.id;
   
  const updatedStatus = {
    is_void:1,
   }

   axios
   .put(`${var_api}/api/food/put/is-void/${shopID}/${orderId}`, updatedStatus)
   .then((response) => {
    //  Swal.fire({
    //    text:"print status updated",
    //    confirmButtonColor:"#007500"
    //  })
    this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);
    toast('void status updated',{autoClose:2000})
     console.log('Data updated successfully:', response.data.message);
     this.setState({isVoid:false});
   })
   .catch((error) => {
     console.error('Error updating data:', error);
     // Handle error, e.g., show an error message or update the UI accordingly
   });
  // this.setState({ isPrinted: true }); // Set the state to indicate that the button has been clicked
  // // Call the ReactToPrint logic here if needed
};


handleVoidStatus = (order) => {
  this.setState({ isVoid:true, voidOrder: order});
}


handlePrintCheckboxChange = () => {
  this.setState(prevState => ({
    isPrintNowChecked: !prevState.isPrintNowChecked,
  }));
  console.log("checkbox", this.state.isPrintNowChecked);
};
    








// // Add this function to your component
// handlePrint = () => {
//   const { selectedOrder } = this.props;
//   this.updateCustomerInfo(selectedOrder.table_no, selectedOrder.total_amount);
// };

    render() {
      const { shopName, shopDetails, editPaymodeOrder } = this.state;
      const { users, putLoader, deleteLoader } = this.state;
      const { categories } = this.state;
      const { foods, Dfood_name } = this.state;
      const { tables } = this.state;
      const { orders } = this.state;
      const { searchQuery } = this.state;
      const { checkedCategories, showItems,orderHistoryItems } = this.state;
      const { loading } = this.state;
      const { modalOpen, selectedOrder,tableOrder, isOpenSelectDate, isdeletemodalopen } = this.state;
      const { ItemCodeOptions, selectedItemCode,deliTableOrders, searchOrders } = this.state;
      const { selectedTable, isOpenConfirmationModal, isUpdate, isPlaceOrder, selectedCategory } = this.state;
      const currentDate = new Date();
      const currentDateFormatted = currentDate.toLocaleDateString();
      const currentTime = currentDate.toLocaleTimeString();
      
   // Filter orders based on the search query
const filteredOrders = orders.filter((order) => {
  const searchLower = searchQuery.toLowerCase();
  return (
    (order.cust_name && order.cust_name.toLowerCase().includes(searchLower)) ||
    (order.phone_no && order.phone_no.includes(searchQuery)) ||
    (order.table_no && order.table_no.toString().includes(searchQuery)) ||
    (order.items && order.items.some((item) =>
      item.item_name && item.item_name.toLowerCase().includes(searchLower)
    ))
  );
});

//search the deliorder data
const filteredOrderHistory = deliTableOrders && deliTableOrders.filter((order) => {
  const searchLower = searchOrders.toLowerCase();
  return (
    (order.captain && order.captain.toString().toLowerCase().includes(searchLower)) ||
    (order.attender_name && order.attender_name.toString().toLowerCase().includes(searchLower)) ||
    (order.cust_name && order.cust_name.toString().toLowerCase().includes(searchLower)) ||
    (order.total && order.total.toString().toLowerCase().includes(searchLower)) 
    
  );
});



    // Check if there are no matching results
const noResults = orderHistoryItems.length === 0;

const CartTotal = this.state.tableOrder && this.state.tableOrder.length > 0
? this.state.tableOrder[0] && this.state.tableOrder[0].ordered_by && this.state.tableOrder[0].ordered_by.reduce((accumulator, cartItem) => {
  return accumulator + cartItem.quantity * cartItem.unit_price;
}, 0)  : 0;

const CartTrueTotal = this.state.cartItems.reduce((accumulator, cartItem) => {
  return accumulator + cartItem.quantity * cartItem.food.price;
}, 0);


// Calculate the total amount from selectedAmounts
const modeTotal = this.state.selectedAmounts.reduce((accumulator, amount) => {
  return accumulator + amount;
}, 0);

const CartTotalOrdr = this.state.tableOrder && this.state.tableOrder.length > 0
? this.state.tableOrder[0] && this.state.tableOrder[0].ordered_by && this.state.tableOrder[0].ordered_by.reduce((accumulator, cartItem) => {
  return accumulator + cartItem.quantity * cartItem.unit_price;
}, 0)  : 0;


const editModeTotal = this.state.editSelectedAmounts && this.state.editSelectedAmounts.reduce((sum, currentItem) => sum + currentItem.amount, 0)

const role =localStorage.getItem("e_role");
// console.log("table role", role);


// console.log("this.state.notifications",this.state.notifications);

const filteredNotifys = Array.isArray(this.state.notifications) && this.state.notifications.length > 0
? this.state.notifications.filter((order)=> {
  const searchLower = this.state.notifySearchQuery.toLowerCase();
  return(
      (order.triggered_by_username.name && order.triggered_by_username.name.toString().toLowerCase().includes(searchLower)) ||
      (order.triggered_by_username.phone_no && order.triggered_by_username.phone_no.toString().toLowerCase().includes(searchLower)) || 
      (order.triggered_by_username.email && order.triggered_by_username.email.toString().toLowerCase().includes(searchLower)) ||
      (order.ordered_at && order.ordered_at.toString().toLowerCase().includes(searchLower)) ||
      (order.ordered_time && order.ordered_time.toString().toLowerCase().includes(searchLower))
  )
})
 : [];
//  console.log("notifi",filteredNotifys);


const filteredFoods = foods && foods.map((food) => {
  const searchLower = this.state.foodSearchQuery.toLowerCase();
  const matchedFoods = food.sub_food.filter((item) => {
    // Check if food name or category name matches the search query
    const foodNameMatch = item.food_name.toLowerCase().includes(searchLower);
    const categoryNameMatch = food.category_name.toLowerCase().includes(searchLower);
    return foodNameMatch || categoryNameMatch;
  });
  return { ...food, sub_food: matchedFoods };
});

const hasMatchedItems = filteredFoods.some(food => food.sub_food.length > 0); // Check if any category has matched items




  return (
    <>
    
   <div id="fix" style={{position:"fixed",left:"650px"}}>
    <h1 class="banner" style={{textTransform:"uppercase", whiteSpace: "nowrap"}}><div class="glow">&nbsp;</div>{ shopName }</h1>
   </div>
  <div style={{display: 'flex', alignItems: 'center', padding: '5px', marginLeft: '10px', marginTop: '5px', borderRadius: '5px', position: 'fixed', zIndex: '2'}}>
  {
    this.state.subscriptions  && this.state.subscriptions.is_tableorder === 0 ? (
  <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleNav} className="custom-dropdown">
  <DropdownToggle className='wine'> <i class="bi bi-list-stars" style={{fontSize:"23px"}}></i> </DropdownToggle>
 {
   role === "Chef" || role === "Captain" || role === "Waiter" ? (
    <DropdownMenu className="scrollable-menu">
    <DropdownItem className="dropItem" onClick={this.routeMyProfile}>
     MY PROFILE
    </DropdownItem>
    
    <DropdownItem className="dropItem" onClick={this.routeChance}>
    KITCHEN
    </DropdownItem>
    <DropdownItem className="dropItem" onClick={this.routeSubChance}>
    SUB KITCHEN
    </DropdownItem>
      <DropdownItem className="dropItem" onClick={this.routeOrderhistory}>
          ORDER HISTORY
      </DropdownItem>
  </DropdownMenu>
   
  ) : (
   <DropdownMenu>
   <DropdownItem className="dropItem" onClick={this.routeMysubscription}>
    MY SUBSCRIPTION
   </DropdownItem>
   <DropdownItem className="dropItem" onClick={this.toggleMastersMenu}>MASTERS
   {this.state.isMastersMenuOpen ? <i class="bi bi-caret-up-fill" style={{marginLeft:"7px"}}></i> : <i class="bi bi-caret-down-fill" style={{marginLeft:"7px"}}></i>} </DropdownItem> 
   
   {this.state.isMastersMenuOpen && (
    
    <>
   {/* <DropdownItem className="dropItem"  onClick={this.routeShop}> 
      SHOP MASTER
   </DropdownItem>  */}
   <DropdownItem className="dropItem"  onClick={this.routeUom}>
      UOM MASTER
   </DropdownItem>
   <DropdownItem className="dropItem"  onClick={this.routeChange}> 
      CATEGORY MASTER
   </DropdownItem>
  
   <DropdownItem className="dropItem"  onClick={this.routeTablemaster}> 
      TABLE MASTER
   </DropdownItem>
   <DropdownItem className="dropItem"  onClick={this.routeSubKitchenmaster}> 
      SUB KITCHEN MASTER
   </DropdownItem>
   <DropdownItem className="dropItem"  onClick={this.routeEmployee}> 
      EMPLOYEE MASTER
   </DropdownItem>
   <DropdownItem className="dropItem"  onClick={this.routeSupplier}>
      SUPPLIER MASTER
   </DropdownItem>
   <DropdownItem className="dropItem"  onClick={this.routeRawMaterial}> 
      RAW MATERIAL MASTER
   </DropdownItem>
   <DropdownItem className="dropItem" onClick={this.routePaymodeStatusMaster}>
      PAYMODE STATUS MASTER
    </DropdownItem>
   </>
   )}

   <DropdownItem divider style={{ borderColor: 'white' }}/>
   {/* <DropdownItem className="dropItem" onClick={this.routeTable}>
   NORMAL ORDERS
   </DropdownItem> */}
   <DropdownItem className="dropItem" onClick={this.routeChance}>
   KITCHEN
   </DropdownItem>
   <DropdownItem className="dropItem" onClick={this.routeSubChance}>
   SUB KITCHEN
   </DropdownItem>
     <DropdownItem className="dropItem" onClick={this.routeOrderhistory}>
         ORDER HISTORY
     </DropdownItem>
   <DropdownItem className="dropItem" onClick={this.routeCredit}>
   CREDIT CUSTOMERS
   </DropdownItem>
   <DropdownItem className="dropItem" onClick={this.routeOutstandingpayment}>
         OUTSTANDING PAYMENT
     </DropdownItem>
    
     <DropdownItem className="dropItem" onClick={this.routeItemSummary}>
         ITEM & PAYMODE SUMMARY
     </DropdownItem>
     <DropdownItem className="dropItem" onClick={this.routeSettlements}>
     EXPENSE LOG
     </DropdownItem>
     <DropdownItem className="dropItem" onClick={this.routenNewSettlements}>
         SETTLEMENTS
     </DropdownItem>
     <DropdownItem className="dropItem" onClick={this.routeGroupOrders}>
   GROUP LOCATION ORDERS
   </DropdownItem>
   <DropdownItem className="dropItem" onClick={this.routeDaily}>
   DAILY INVENTORY
   </DropdownItem>
   <DropdownItem className="dropItem" onClick={this.routeVoid}>
      VOID TRANSACTION
   </DropdownItem>
   <DropdownItem className="dropItem" onClick={this.routePurchaseOrders}>
      PURCHASE ORDERS
    </DropdownItem>
    <DropdownItem className="dropItem" onClick={this.routeStoreGrn}>
      STORE GRN
    </DropdownItem>
    <DropdownItem className="dropItem" onClick={this.routeStockTake}>
      STOCK TAKE
    </DropdownItem>
    <DropdownItem className="dropItem" onClick={this.routeStockAdjustments}>
      STOCK ADJUSTMENTS
    </DropdownItem>
     <DropdownItem className="dropItem" onClick={this.routeStockTransaction}>
       STOCK TRANSACTION
      </DropdownItem>
     <DropdownItem className="dropItem" onClick={this.routeDayClosing}>
       DAY CLOSING
      </DropdownItem>
      <DropdownItem className="dropItem" onClick={this.routeGLFinanceSummary}>
       GL FINANCE SUMMARY
      </DropdownItem>
      <DropdownItem className="dropItem" onClick={this.routeStockBalanceReport}>
       STOCK BALANCE REPORT
      </DropdownItem>
      <DropdownItem className="dropItem" onClick={this.routepandLReport}>
            P & L REPORT
          </DropdownItem>
   
 </DropdownMenu>
  )
 }
      </Dropdown>
    ) : (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleNav} className="custom-dropdown">
  <DropdownToggle className='wine'> <i class="bi bi-list-stars" style={{fontSize:"23px"}}></i> </DropdownToggle>
 {
   role === "Chef" || role === "Captain" || role === "Waiter" ? (
    <DropdownMenu className="scrollable-menu">
    <DropdownItem className="dropItem" onClick={this.routeMyProfile}>
     MY PROFILE
    </DropdownItem>
      <DropdownItem className="dropItem" onClick={this.routeOrderhistory}>
          ORDER HISTORY
      </DropdownItem>
  </DropdownMenu>
   
  ) : (
   <DropdownMenu>
   <DropdownItem className="dropItem" onClick={this.routeMysubscription}>
    MY SUBSCRIPTION
   </DropdownItem>
   <DropdownItem className="dropItem" onClick={this.toggleMastersMenu}>MASTERS
   {this.state.isMastersMenuOpen ? <i class="bi bi-caret-up-fill" style={{marginLeft:"7px"}}></i> : <i class="bi bi-caret-down-fill" style={{marginLeft:"7px"}}></i>} </DropdownItem> 
   
   {this.state.isMastersMenuOpen && (
    
    <>
   {/* <DropdownItem className="dropItem"  onClick={this.routeShop}> 
      SHOP MASTER
   </DropdownItem>  */}
   <DropdownItem className="dropItem"  onClick={this.routeUom}>
      UOM MASTER
   </DropdownItem>
   <DropdownItem className="dropItem"  onClick={this.routeChange}> 
      CATEGORY MASTER
   </DropdownItem>
  
   <DropdownItem className="dropItem"  onClick={this.routeTablemaster}> 
      TABLE MASTER
   </DropdownItem>
   <DropdownItem className="dropItem"  onClick={this.routeEmployee}> 
      EMPLOYEE MASTER
   </DropdownItem>
   <DropdownItem className="dropItem" onClick={this.routePaymodeStatusMaster}>
      PAYMODE STATUS MASTER
    </DropdownItem>
    
   </>
   )}
   <DropdownItem className="dropItem" onClick={this.routeOrderhistory}>
         ORDER HISTORY
     </DropdownItem>
      
     <DropdownItem className="dropItem" onClick={this.routeItemSummary}>
         ITEM & PAYMODE SUMMARY
     </DropdownItem>

   <DropdownItem divider style={{ borderColor: 'white' }}/>
   <DropdownItem header style={{ backgroundColor:"white", color: 'red', fontSize: '16px', fontWeight: 'bold', paddingLeft: '15px' }}>
          <i class="bi bi-lightning-fill" style={{color:"yellow"}}></i>Premium Features
    </DropdownItem>
   <DropdownItem style={{ color: 'green', backgroundColor:"white", fontSize: '15px', paddingLeft: '15px' }} onClick={this.handleOpenPremiumAlert} >
   KITCHEN
   </DropdownItem>
   <DropdownItem style={{ color: 'green', backgroundColor:"white", fontSize: '15px', paddingLeft: '15px' }} onClick={this.handleOpenPremiumAlert} >
   SUB KITCHEN
   </DropdownItem>
    
   <DropdownItem style={{ color: 'green', backgroundColor:"white", fontSize: '15px', paddingLeft: '15px' }} onClick={this.handleOpenPremiumAlert}>
   CREDIT CUSTOMERS
   </DropdownItem>
   <DropdownItem style={{ color: 'green', backgroundColor:"white", fontSize: '15px', paddingLeft: '15px' }} onClick={this.handleOpenPremiumAlert}>
         OUTSTANDING PAYMENT
     </DropdownItem>
   
     <DropdownItem style={{ color: 'green', backgroundColor:"white", fontSize: '15px', paddingLeft: '15px' }} onClick={this.handleOpenPremiumAlert}>
     EXPENSE LOG
     </DropdownItem>
     <DropdownItem style={{ color: 'green', backgroundColor:"white", fontSize: '15px', paddingLeft: '15px' }} onClick={this.handleOpenPremiumAlert} >
         SETTLEMENTS
     </DropdownItem>
     <DropdownItem style={{ color: 'green', backgroundColor:"white", fontSize: '15px', paddingLeft: '15px' }} onClick={this.handleOpenPremiumAlert} >
   GROUP LOCATION ORDERS
   </DropdownItem>
   <DropdownItem style={{ color: 'green', backgroundColor:"white", fontSize: '15px', paddingLeft: '15px' }} onClick={this.handleOpenPremiumAlert} >
   DAILY INVENTORY
   </DropdownItem>
   
 </DropdownMenu>
  )
 }
      </Dropdown>
    )
  }
      {/* <div className="form-check form-switch" style={{ marginLeft: "30px" }}>
  <label className="form-check-label" htmlFor="flexSwitchCheckDefault" style={{ float: 'left', marginRight: '40px', textTransform:"uppercase",fontWeight:"bolder",color:"green" }}>Image Status</label>
  <input 
   className="form-check-input"
   type="checkbox"
   role="switch"
   id="flexSwitchCheckDefault"
   style={{ backgroundColor: 'green', borderColor: 'green', float: 'right' }}
   checked={this.state.imageStatus}
   onChange={this.handleSwitchToggle} />
</div> */}
  </div>

  {/* <Tooltip
          isOpen={this.state.tooltipOpen}
          target="dropdownToggle"
          toggle={this.toggleTooltip}
        >
           Shop List
        </Tooltip>

  <Dropdown isOpen={this.state.shadropdownOpen} toggle={this.toggleChange} className="custom-dropdown">
  <DropdownToggle className='wine'  id="dropdownToggle" style={{marginLeft:"1280px",backgroundColor:"white",border:"none",position:"fixed"}} onClick={this.toggleTooltip}><i class="bi bi-shop-window" style={{fontSize:"35px",color:"green"}}></i></DropdownToggle>
  
  <DropdownMenu >
  {users.map(user=>(
    <DropdownItem key={user.id} className="dropItem" onClick={() => this.handleNameSelection(user)}>{user.shop_name}</DropdownItem>
    ))}
  </DropdownMenu>
 
  </Dropdown> */}
  {role === "Chef" || role === "Captain" || role === "waiter" ? null : 
  <button  class="btn btn-outline-success"  style={{position:"fixed",marginLeft:"1200px",marginTop:"20px"}} onClick={this.routeTable}>
  <i class="bi bi-house-fill"></i>
</button>}
  <button className="btn btn-outline-success" style={{position:"fixed",marginLeft:"1400px",marginTop:"20px"}} onClick={this.handleOnclick}>Logout</button>
  {/* {
    role === "Chef" || role === "Captain" || role === "waiter" ? ( */}
     <Tooltip
          isOpen={this.state.notifytooltipOpen}
          target="notificationButton" // Specify the target ID of the button
          toggle={this.toggleNotifyTooltip}
        >
          View Notifications
        </Tooltip>
    <button  id="notificationButton" class="btn btn-outline-success"  style={{position:"fixed",marginLeft:"1350px",marginTop:"20px"}} onClick={() => { this.handleOpenNotifications(); this.toggleNotifyTooltip(); }}>
    <i class="bi bi-bell-fill"></i>
</button>
      
    {/* ) : null
  } */}

  {/* <button className="btn btn-outline-success" style={{position:"fixed",marginLeft:"1400px",marginTop:"20px"}} onClick={this.handleOnclick}>Logout</button> */}

  <div id="container">
  <div id='leftt-sside' style={{zIndex:"1"}}>

<br/>

<h4 id="hefo"><b>TABLE ORDERS CART</b></h4>
<button className={!this.state.isChecked ? "btn btn-secondary" : "btn btn-success"} onClick={() => this.setState({ isChecked: true})}>Normal Order</button> 
     <button className={this.state.isChecked ? "btn btn-secondary" : "btn btn-success" } onClick={() => this.setState({ isChecked: false})}>Credit Order</button>
   <div id="leftcont">
     <div style={{backgroundColor:"green",padding:"10px 10px 2px 10px"}}>
     {this.state.isChecked ? (
<div style={{ display: 'flex', alignItems: 'center', gap:"4px" }}>
<Input hidden={!this.state.isChecked} type="text" id="name" placeholder="Name" required />
<Input hidden={!this.state.isChecked} type="number" id="mobile" placeholder="Mobile No" maxLength="10" style={{marginTop:"2px"}} required/>
</div>

) : (
<SearchableDropdown onSelect={this.handleDropdownChange} selectedValue={this.state.selectedData} />

)}
  <Select
        options={ItemCodeOptions}
        placeholder="Select Item Code"
        value={selectedItemCode}
        onChange={this.handleCodeSelectChange}
        isSearchable={true}
        className="customSelect"
    />  <span><button className="btn btn-primary" onClick={this.handleAddByItemCode} style={{float:"right",marginTop:"-38px"}}>Add</button></span> 
<div class="d-flex justify-content-center">
<h6 style={{fontFamily: "cursive", letterSpacing: "1px", fontWeight: "bolder", marginTop:"4px"}}>
    <span class="badge rounded-pill bg-danger p-2" style={{fontWeight:"bolder"}}>Table No: 
        <span id="tableNumber">
            {selectedTable && selectedTable.table_no ? selectedTable.table_no : "-"}
        </span>
    </span>
</h6>
</div>
{
isPlaceOrder ? 
<>
{this.state.selectedData && this.state.selectedData.iden  && !this.state.isChecked &&
<h6 style={{paddingTop:"5px", color:"white"}}>maximum Credit limit: {" "}{this.state.selectedData && this.state.selectedData.maxCredit && this.state.selectedData.maxCredit.toFixed(3)} <br/><span>Outstanding Payment : {this.state.amountDetail && this.state.amountDetail.balance && this.state.amountDetail.balance.toFixed(3)} 
</span></h6>}
<h6 style={{color:"white"}}>No.of Items: {" "}{this.state.tableOrder[0] && this.state.tableOrder[0].ordered_by.length} <br/><span>Total : {CartTotal && CartTotal.toFixed(1)} 
</span></h6> 

</>
:
<h6 style={{color:"white"}}>No.of Items: {" "}{this.state.cartItems.length} <br/>
<span>Total : {CartTrueTotal.toFixed(1)}
{
selectedTable && selectedTable.table_no && <span style={{marginLeft:"5px"}}><button className="btn btn-primary" style={{borderRadius:"15px",padding:"1px 5px 1px 5px",fontSize:"15px"}} onClick={this.handletoOpenInstantFood}><i class="bi bi-plus-circle-fill"></i>{" "}Instant Food</button></span>
}

</span></h6>

}

  
     </div>


     
     <div id="tkbox">
       
     { isPlaceOrder ? 
     (
      tableOrder[0] && tableOrder[0].ordered_by && tableOrder[0].ordered_by.map((cartItem,index) => (
        <div id="box1" >
        
          <table>
          
           <tbody key={index}>

            <tr>
              <td style={{color:"green",textShadow:"1px 1px 1px black",fontSize:"17px"}}>
               { cartItem.food_name}</td>
               {/* <i class="bi bi-x-circle-fill" onClick={() => this.removeFromCart(cartItem.id)} style={{marginLeft:"100px",float:"right"}}></i> */}
              <td style={{fontSize:"15px",textAlign:"right",width:"70%",marginLeft:"80px",float:"right",marginTop:"15px"}}>
              {/* <span onClick={() => this.incrementQuantity(cartItem.id)} style={{color:"green",fontSize:"18px",padding:"5px"}}><i class="bi bi-plus-circle-fill"></i></span> */}
               {cartItem.quantity}
               {/* <span onClick={() => this.decrementQuantity(cartItem.id)} style={{color:"green",fontSize:"18px",padding:"5px"}}><i class="bi bi-dash-circle-fill"></i></span> */}
               </td>
             </tr>
            <tr>
              <td colSpan='2'>unit price: <span style={{color:"green",fontSize:"15px",marginRight:"20px"}}>
              {cartItem.unit_price} 
               </span><span>{" "}Total Price: <b>{cartItem.quantity * cartItem.unit_price} </b> </span></td>
            </tr>
            </tbody>
         
          </table>
            
        </div>
         ))
     )
     : 
     (
      this.state.cartItems.map((cartItem) => (
        <div id="box1" >
        
          <table>
          
           <tbody key={cartItem.id}>

            <tr>
              <td style={{color:"green",textShadow:"1px 1px 1px black",fontSize:"17px"}}>
               { cartItem.food && cartItem.food.food_name ? cartItem.food.food_name : '' }</td>
               {/* <i class="bi bi-x-circle-fill" onClick={() => this.removeFromCart(cartItem.id)} style={{marginLeft:"100px",float:"right"}}></i> */}
              <td style={{fontSize:"15px",textAlign:"right",width:"70%",marginLeft:"80px",float:"right",marginTop:"15px"}}>
              {/* {
              cartItem.quantity === 1 ? <span style={{color:"grey",fontSize:"18px",padding:"5px"}}><i class="bi bi-dash-circle-fill"></i></span> :
              <span onClick={() => this.decrementQuantity(cartItem.id)} style={{color:"red",fontSize:"18px",padding:"5px"}}><i class="bi bi-dash-circle-fill"></i></span>} */}
               <span onClick={() => this.decrementQuantity(cartItem.id)} style={{color:"red",fontSize:"18px",padding:"5px"}}><i class="bi bi-dash-circle-fill"></i></span>
               {cartItem.quantity}
              
               <span onClick={() => this.incrementQuantity(cartItem.id)} style={{color:"green",fontSize:"18px",padding:"5px"}}><i class="bi bi-plus-circle-fill"></i></span>
               </td>
             </tr>
            <tr>
              <td colSpan='2'> price: <span style={{color:"green",fontSize:"15px"}}>
              {cartItem.food ? ` ${cartItem.food.price}  ${cartItem.food.currency}` : ''} 
               </span><span>{" "}Total Price: <b>{cartItem.quantity * cartItem.food.price} {cartItem.food.currency}</b> </span></td>
            </tr>
            </tbody>
         
          </table>
            
        </div>
         ))
     )
     
     
    }
       <br/>
       
     </div>

     {
isPlaceOrder ? (
<>
 <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: !this.state.isChecked ? '10px' : '0'  }}>
  {
    this.state.normalLoading ? (
      <div class="text-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    ): this.state.isChecked ? <button onClick={this.handleGenerateOrderModal} className="btn btn-success">Place  Order</button> : <button onClick={this.handleGenerateNormalOrderModal} className="btn btn-success">Normal  Order</button>
    }
    {
      this.state.creditLoading ? (
        <div class="text-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
      ):
    !this.state.isChecked && (
  <button disabled={this.state.isChecked} className="btn btn-danger" onClick={this.handleGeneratecreditOrderModal}> Credit Order</button>
  )}
  </div>
</>
) : isUpdate ? (

this.state.addLoading ? (
  <div class="text-center">
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
) :
<button className="btn btn-success" onClick={this.AgaingenerateOrder}>Add to Kitchen</button>
) : (

  this.state.moveLoading ? (
    <div class="text-center">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  ):<button id="press" onClick={this.generateOrder}>Move to Kitchen</button>
)
}
   </div>
 </div>

<div id='mainn-sside' style={role === "Chef" || role === "Captain" || role === "waiter" ? { width: '80%' } : {}}>
{/* <TabContent activeTab={this.state.activeTab}>
<TabPane tabId="2">
        <Row>
          <Col md="12">
            <div style={{width:"50%",margin:"auto"}}>
            <ul style={{textAlign:"center"}}>
            {categories.map((category) => (
              <li key={category.id} className="listing" style={{border:"1px solid green",padding:"5px",marginTop:"5px",fontWeight:"bold",listStyleType:"none",textTransform:"capitalize"}}>
                <a href='#' className="linking" onClick={() => this.handleListClick(category.id)}>{category.category_name}
                <span style={{fontSize:"8px",letterSpacing:"1px"}}>({category.category_code})</span>
                </a></li>
              ))}
            </ul>
            </div>
          </Col>
        </Row>
      </TabPane>
  </TabContent> */}
  
<section className="food-area my-0">

  {
    showItems ? (
      <div className="container" style={{width:"1100px",padding:"10px"}}>
         {/* <input class="form-control" type="Search" placeholder="Quick Food Search" id="foodtablesearch" onChange={this.handleFoodSearchInput}/> */}
  {
  // this.state.loadTable ? (
  //   <div className="fooder-container">
  // 	  <div className="fooddinner"></div>
  //   </div>
  // ) : (
    hasMatchedItems ? (
     
        <>
     <div className="d-flex justify-content-center">
          <Row xs={1} md={2} lg={3} xl={4} xxl={5} className="g-3">
            {filteredFoods.map((category, idx) => (
              <Col key={category.id}>
                <Card
                  style={{
                    maxWidth: "12rem",
                    height: "auto",
                    border: "2px solid black",
                    backgroundColor:
                      selectedCategory === idx ? "green" : "white", // Check if the category is selected
                    padding: "0px",
                    borderRadius: "0px",
                    cursor: "pointer"
                  }}
                  onClick={() => this.onCategoryClick(idx)} // Set selected category index
                >
                  <Card.Body>
                    <Card.Title
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                        padding: "0px",
                        margin: "0px",
                        color: selectedCategory === idx ? "white" : "black" // Check if the category is selected
                      }}
                    >
                      {category.category_name.toUpperCase()}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
        <br/>
        <div className="row" id="mafix" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', gap: '25px' }}>
        {/* {food.sub_food.map((item, index) => ( */}
        {(this.state.selectedCategory !== null &&
    filteredFoods[this.state.selectedCategory] &&
    filteredFoods[this.state.selectedCategory].sub_food &&
    filteredFoods[this.state.selectedCategory].sub_food.map((item, index) => (
        
        <div className="col mb-1 mt-1 ftco-animate" key={index} style={{ flex: '0 0 auto', maxWidth: '12rem' }}>
            {/* <div className="card" id="effe" style={{ width: '12rem', height:"auto", padding:"10px", marginTop:"6px",
             backgroundColor: this.state.addedItems.includes(item.id) ? 'rgba(10, 241, 33, 0.133)' : 'white'
            }} onClick={() => !this.state.addedItems.includes(item.id) && this.addToCart(item)}> */}
             <div className="card" id="effe" style={{ width: '12rem', height:"auto", padding:"10px", marginTop:"6px",
             backgroundColor: this.state.addedItems.includes(item.id) ? 'rgba(10, 241, 33, 0.133)' : 'white'
            }} onClick={() =>this.addToCart(item)}>
              {/* {this.state.imageStatus ? <img src={`${var_api}/${item.image}`} alt='image' height="8rem" style={{ height:"8rem", marginTop:"1px" }} /> : null} */}
                <div style={{textAlign:"center", padding:"10px"}}>
                    <h6 className="card-title" style={{color:"green", textTransform:"capitalize", fontSize:"18px"}}>{item.food_name}</h6>
                    {/* <p style={{textAlign:"center", fontSize:"13px", margin:"3px 8px"}}>UOM: <b> {item.uom}</b> </p> */}
                    <h6  style={{color:"green",textAlign:"center",fontSize:"20px",fontWeight:"bolder"}}> {item.price} {" "} {item.currency} </h6>
                    {/* {
                      item.is_active === 1 ?  <button className="btn" style={{backgroundColor:"green", color:"white",padding:"5px"}} onClick={() => this.addToCart(item)}><i className="bi bi-cart3"></i> Add to Cart</button> : 
                      <span className="badge rounded-pill bg-danger p-2" style={{fontWeight:"normal",fontSize:"15px"}}><i class="bi bi-exclamation-triangle"></i>{" "}Not Available</span>
                    } */}
                   
                </div>
            </div>
        </div>
           )))}
         {/* ))} */}
   
</div>
    </>
  
  
  ) : (
    <div className="text-center" style={{color:"red",fontWeight:"bolder",marginTop:"20px"}}>
    <i class="bi bi-exclamation-triangle"></i> No Foods Available
  </div>
   )}
   </div>

    ) : (
<div className="container" style={role === "Chef" || role === "Captain" || role === "waiter" ? {padding:"10px"} : { width: "1100px", padding: "10px" }}>
  {tables.length === 0 && !this.state.loadTable ? (
   
    <div className="text-center">
      <h6 style={{textAlign:"center"}}>No Tables Found</h6>
      <br/>
      {
        role === "Chef" || role === "Captain" || role === "waiter" ? null : <button className="btn btn-success" style={{margin:"auto"}} onClick={this.routeTablemaster}>Add Tables</button>
      }
      
    </div>
    
 
  ) : (
  <div className={"row row-cols-1 row-cols-md-0 row-cols-lg-5 g-1"} id="mafix" style={role === "Chef" || role === "Captain" || role === "waiter" ? {marginTop:"35px"} : {}} >
  {tables.map((table,index) => (
      <div className="col mb-1  ftco-animate" key={index} >
        <div className="card" id="effe" style={{ width: '12rem',height:"auto",padding:"10px" }} onClick={() => this.handleCardClick(table)}>
          <div style={{ position: "relative" }}>
<i className="bi bi-circle-fill" style={{ color: table.is_active === 0 ? "green" : "red", position: "absolute", left: "145px", top: "5px"}}></i>
<img src={`${var_api}/${table.table_image}`} alt='image' style={{height:"9rem",marginTop:"1px",width:"170px"}} />
</div>
          <div style={{textAlign:"center",padding:"10px"}}>
         
            <h6 className="card-title" style={{color:"green",fontSize:"20px",fontWeight:"bolder"}}>Table No: <span style={{color:"black"}}>{table.table_no}</span></h6>
            <p className="card-title p-0 m-0" style={{color:"rgb(79, 78, 78)",fontSize:"15px",textAlign:"left",fontWeight:"bold",fontFamily:"serif"}}>No.Of.Seats: <span style={{color:"black", fontSize:"17px",fontFamily:"monospace"}}>{table.no_of_seats}</span></p>
            <p className="card-title p-0 m-0" style={{color:"rgb(79, 78, 78)",fontSize:"15px",textAlign:"left",fontWeight:"bold",fontFamily:"serif"}}>Occupied by: <span style={{color:"black", fontSize:"17px",fontFamily:"monospace"}}>{table.captain ? table.captain : "-"}</span></p>
            <p className="card-title" style={{color:"rgb(79, 78, 78)",fontSize:"15px",textAlign:"left",fontWeight:"bold",fontFamily:"serif"}}>Kitchen Status: 
            <span style={{color:"black", fontSize:"17px",fontFamily:"monospace"}}>
              {table.kitchen_status === 0 ? (
                <span class="badge rounded-pill bg-primary p-2" style={{color:"white"}}>New</span>
              ) : table.kitchen_status === 1 ? (
                <span class="badge rounded-pill bg-warning p-2" style={{color:"white"}}>Inprogress</span>
              ) : table.kitchen_status === 2 ? (
                <span class="badge rounded-pill bg-success p-2" style={{color:"white"}}>Ready</span>
              ) : table.kitchen_status === 3 ? (
                <span class="badge rounded-pill bg-danger p-2" style={{color:"white"}}>Delivered</span>
              ) : "-"
          }</span></p>
          </div>
        </div>
        
      </div>
    ))}
    
  </div>
  )} 
   </div>
    )

  }
  
  
</section>

</div>

{/* {
role === "Chef" || role === "Captain" || role === "waiter" ? null : (

<div id="hinFood">
<h3 style={{color:"green",textShadow:"2px 2px 2px black",fontWeight:"bolder",letterSpacing:"2px"}} id="hefo" > TODAY ORDERS</h3>


<div style={{ display: 'flex', alignItems: 'center', gap:"10px" }}>
<label style={{fontFamily:"serif",fontWeight:"bolder"}}>Type: 
</label>
<Select
    options={this.state.labelCodeOptions}
    placeholder="Select Order"
    value={this.state.selectedOrderLabel}
    onChange={this.handleOrderLabelSelectChange}
    isSearchable={true}
    menuPlacement="top"
    className="customSelectFood"
    styles={{ menu: (provided, state) => ({
      ...provided,
      zIndex: 9999 
  }) }}
/>
<button className="btn btn-danger text-white p-1" style={{fontSize:"15px",margin:"auto"}} onClick={this.handleOpenSelectDate}>Summary</button>

</div>
</div>
)} */}

{/* <div id={role === "Chef" || role === "Captain" || role === "waiter"  ? undefined : 'rightt-sside'}> */}
<div id={role === "Chef" || role === "Captain" || role === "waiter"  ? undefined : undefined}>
{/* {
role === "Chef" || role === "Captain" || role === "waiter" ? null : ( */}

  <div >
    
<Button 
  variant="light" 
  style={{
    border: "2px solid green",
    borderRadius: "50px",
    position: "fixed", // Set position to fixed
    right: "20px", // Adjust right position as needed
    bottom: "20px", // Adjust bottom position as needed
    zIndex: "999", // Set a high z-index to ensure the button is above other content
    margin: "10px", // Add margin as needed
  }} 
  onClick={this.handleShowServices}
>
  <i className="bi bi-stack fs-4" style={{ color: "green" }}></i>
  <span className="badge rounded-circle bg-dark" style={{ position: "absolute", top: "-5px", right: "-5px" }}>{orderHistoryItems.length}</span>
</Button>

      
      <Offcanvas show={this.state.showService} onHide={this.handleCloseServices} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{textAlign:"center"}}>TODAY ORDERS
          <div 
          // id="hinFood"
          >
{/* <h3 style={{color:"green",textShadow:"2px 2px 2px black",fontWeight:"bolder",letterSpacing:"2px"}} id="hefo" > TODAY ORDERS</h3> */}


<div style={{ display: 'flex', alignItems: 'center', gap:"10px" }}>
{/* <label style={{fontFamily:"serif",fontWeight:"bolder"}}>Type: 
</label> */}
<Select
    options={this.state.labelCodeOptions}
    placeholder="Select Order"
    value={this.state.selectedOrderLabel}
    onChange={this.handleOrderLabelSelectChange}
    isSearchable={true}
    menuPlacement="top"
    className="customSelectFood"
    styles={{ menu: (provided, state) => ({
      ...provided,
      zIndex: 9999 
  }) }}
/>
<button className="btn btn-danger text-white p-1" style={{fontSize:"15px",margin:"auto"}} onClick={this.handleOpenSelectDate}>Summary</button>
{/* <button className="btn btn-primary text-white p-1" style={{fontSize:"15px",margin:"auto"}}  onClick={this.toggleModalPrinter}><i class="bi bi-gear"></i></button> */}
</div>
</div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        
   <br/>





  <div id="sss">
  {
      this.state.orderLoading ? (
        <div class="text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      ) : (
        noResults ? (
      <p style={{textAlign:"center",fontWeight:"bolder"}}> Today No Orders </p>
    ) : (
  
      orderHistoryItems.slice().reverse().map((order, index) => (
        <div key={index} style={{marginTop:"-10px"}}>
           <div id={`billContent${index}`}>
          <Bill order={order} shopDetails={this.state.shopDetails} ref={(el) => (this[`componentRef${index}`] = el)} />
            </div>
          {
            
          role === "Chef" || role === "Captain" || role === "waiter" ? null : (
            <div style={{display: "flex", justifyContent: "center" }}>
              {
                 
                  this.state.subscriptions  && this.state.subscriptions.is_tableorder === 0 && 
              
            <>
            {/* <Tooltip
          isOpen={this.state.printTooltipOpen[index]} // Use tooltip state based on index
          target={`printButton${index}`} // Specify the target ID of the button
          toggle={() => this.togglePrintTooltip(index)}
        >
            Print
          </Tooltip> */}
            {/* <ReactToPrint
              trigger={() => (
                <div style={{ textAlign: 'center' }}>
                     <button className='btn-md btn-primary'  id={`printButton${index}`} style={{ borderRadius: "5px", border: "none", padding: "7px", margin:"5px", padding:"0px auto" }} onClick={() => this.togglePrintTooltip(index)}><i className="bi bi-printer-fill"></i></button>
                </div>
            )}
              content={() => this[`componentRef${index}`]}
              onAfterPrint={() => this.handlePrint(order.id)}
            /> */}
             {
          this.state.printLoader ? (
            <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          ) : this.state.printingType == "directPrint" ?   
            <button className='btn-md btn-warning' style={{ borderRadius: "5px", border: "none", padding: "7px", margin:"5px", padding:"0px auto" }} onClick={()=>this.handlePrints(index,order.id)}>  <i className='bi bi-printer-fill'></i></button> : (
              <ReactToPrint
              trigger={() => (
                <div style={{ textAlign: 'center' }}>
                     <button className='btn-md btn-primary'  id={`printButton${index}`} style={{ borderRadius: "5px", border: "none", padding: "7px", margin:"5px", padding:"0px auto" }} onClick={() => this.togglePrintTooltip(index)}><i className="bi bi-printer-fill"></i></button>
                </div>
            )}
              content={() => this[`componentRef${index}`]}
              onAfterPrint={() => this.handlePrint(order.id)}
            /> )
          }
            </>
    }
            {/* <hr style={{border:"1px dashed grey",position:"relative",bottom:"110px"}}/> */}
            {/* <button className="btn-md btn-success" style={{marginRight:"4px",borderRadius:"5px",border:"none",padding:"7px"}} 
            onClick={() => this.handlePaidStatus(order.customer_id,order.id)} disabled={order.paid_status === 1}
            >{ order.paid_status === 1 ? "Paid" : "Pay" }</button>
            <button className={`btn-md btn-danger ${order.paid_status === 2 ? 'smallText' : '' }`} style={{marginRight:"4px",borderRadius:"5px",border:"none",padding:"7px"}}
            onClick={() => this.handleCancelStatus(order.customer_id,order.id)} disabled={order.paid_status === 2}
            >{ order.paid_status === 2 ? "Cancelled" : "Cancel" }</button> */}
  
           {/* { order.parcel_status === 0 &&
            <button className="btn-md btn-warning" onClick={()=> this.updateParcelInfo(order.customer_id,order.id)} style={{marginTop:"10px",borderRadius:"5px",border:"none",padding:"7px"}}>Parcel</button>
           } */}
           {
                (order.pay_modes.some(mode => mode.amount > 0 && (mode.pay_mode_name === "CREDIT ORDER" || mode.pay_mode_name === "GROUP LOCATION CREDIT ORDER"))) ? null :
                <div style={{ textAlign: 'center' }}>
                   <Tooltip
          isOpen={this.state.puttooltipOpen[index]} // Use tooltip state based on index
          target={`putButton${index}`} // Specify the target ID of the button
          toggle={() => this.togglePutTooltip(index)}
        >
            Edit Paymode
          </Tooltip>
                <button className="btn btn-primary" id={`putButton${index}`} style={{borderRadius: "5px", border: "none", margin:"5px", padding:"0px 6px" }} onClick={() => {this.handleOpenEditPayModal(order); this.togglePutTooltip(index); }}><i class="bi bi-pencil-square" style={{fontSize:"14px"}}></i></button>
                <button className='btn-md btn-danger'  style={{ borderRadius: '5px', border: 'none', padding: '7px', margin: '5px', padding: '0px auto' }} onClick={()=>this.handleVoidStatus(order)}><i class="bi bi-trash"></i></button>
                </div>
              }
               </div>

          )}

          
         <br/>
          </div>
      
      ))
     )
      )
    } 

     
  </div>
  </Offcanvas.Body>
      </Offcanvas>
   
{/* </div> */}
   </div>
 {/* )
 } */}

</div>
  </div>


    <Modal isOpen={modalOpen} toggle={this.closeModal} className={this.props.className}>
      <ModalHeader>Today Order Summary</ModalHeader>
          <ModalBody>

          <h5 style={{fontFamily:"serif",fontWeight:"bolder",color:"green"}}>Order Type: {" "} 
            <span style={{textAlign:"center",color:"black",fontSize:"18px"}}>{this.state.selectedOrderLabel.label}</span></h5>
          
              <h5 style={{fontFamily:"serif",fontWeight:"bolder",color:"green"}}>Count: {" "} 
              <span style={{textAlign:"center",color:"black",fontSize:"18px"}}>{this.state.orderSummary.order_count ? this.state.orderSummary.order_count : 0}</span></h5>
           
              <h5 style={{fontFamily:"serif",fontWeight:"bolder",color:"green"}}>Price:{" "} 
              <span style={{textAlign:"center",color:"black",fontSize:"18px"}}>{this.state.orderSummary.total_sum ? (this.state.orderSummary.total_sum).toFixed(2) : 0}</span></h5>
           
     <br/>
         <div style={{textAlign:"center"}}>
         <Button variant="danger"  onClick={this.closeModal}>
            Close
          </Button>{' '} 
         
         </div>
            {/* <br/>
          <Input type="text" id="customer_name" placeholder="Customer Name" required />
       <Input type="number" id="phone_no" placeholder="Mobile No" maxLength="10" style={{marginTop:"3px"}} required/> 
       <br/>
          {selectedOrder && (
          <div  >
             <div style={{backgroundColor:"rgba(63, 68, 63, 0.082)",padding:"20px",width:"300px",margin:"auto"}}>
            <div className="hidden-on-screen">
               
                <h5 style={{textAlign:"center",fontWeight:"bolder",textTransform:"uppercase",color:"black",fontFamily:"Arial, Helvetica, sans-serif"}}>{localStorage.getItem('shopName')}</h5>
                
                <hr style={{border:"1px dashed black",position:"relative",top:"-15px"}}/>
            </div>
           
            <div style={{marginTop:"-30px"}}>
          
                <h5 style={{color:"black",fontWeight:"bolder",textAlign:"center",marginTop:"12px"}} className="Nota">Table No: {selectedOrder.table_no}</h5>
               
                <table key={selectedOrder.order_no} style={{width:"100%"}}>
            
           
            <tbody>
                {selectedOrder.items.map((item, itemIndex) => (
                <tr key={itemIndex}>
                    <td style={{color:"black",textAlign:"left",fontWeight:"bolder",padding:"1px"}}>{item.item_name}</td>
                    <td></td>
                    <td style={{color:"black",textAlign:"right",fontWeight:"bolder",padding:"1px"}}>{item.unit_price}</td>

                   
                </tr>
                ))} 
                <br/>
                <tr>
                  <td style={{color:"black",textAlign:"left",fontWeight:"bolder",padding:"1px"}}>Sub Total</td>
                  <td></td>
                  <td style={{color:"black",textAlign:"right",fontWeight:"bolder",padding:"1px"}}>${(Math.round(selectedOrder.subtotal * 100) / 100).toFixed(1)}</td>
                </tr>
                
                <tr>
                    <td style={{color:"black",textAlign:"left",fontWeight:"bolder",padding:"1px"}}>Service Charge</td>
                    <td></td>
                    <td  style={{color:"black",textAlign:"right",fontWeight:"bolder",padding:"1px"}}>$5</td>
                </tr>
               
            </tbody>
            
        </table>
            </div>
       <hr style={{border:"1px dashed black",position:"relative",top:"-15px"}}/>
        <h5 style={{padding:"1px",color:"black",textAlign:"center",fontWeight:"bolder",marginTop:"-20px",fontFamily:"monospace"}}>Total Amount: ${(Math.round(selectedOrder.total_amount * 100) / 100).toFixed(1)}</h5>
        <hr style={{border:"1px dashed black",position:"relative",top:"-15px"}} className="line"/>
      
      <h6 style={{textAlign:"left",fontFamily:"monospace",letterSpacing: "-2px",color:"black",marginTop:"-25px",fontSize:"15px"}} className="tableName">Table No: <span style={{color:"green",fontSize:"20px",color:"black"}}>{selectedOrder.table_no}</span></h6>
      <div class="flex-container" style={{marginTop:"-5px"}}>
               <div>
               <span style={{color:"black",fontSize:"12px",textTransform:"capitalize",fontWeight:"bolder"}}>{' '} {currentDateFormatted}{currentTime}</span>
                <h4 style={{captionSide:"top",fontSize:"13px",fontWeight:"bolder",color:"black",fontFamily: "monospace"}}>Customer: 
                <span style={{color:"black",fontSize:"13px",textTransform:"capitalize",fontWeight:"bolder"}}>{selectedOrder.cust_name}</span>
                <br/>Phone: <span style={{color:"black",fontSize:"13px",fontWeight:"bolder",marginLeft:"5px"}}>{' '} {selectedOrder.phone_no}</span></h4>
                </div>
                <div className="anode">
                <h4 style={{captionSide:"top",fontSize:"13px",fontWeight:"bolder",color:"black",marginTop:"25px",fontFamily: "monospace"}}>Attender: <span style={{color:"black",fontSize:"13px",fontWeight:"bolder",marginLeft:"2px"}}>{' '} {selectedOrder.attender_name}</span></h4>
                </div>
               </div>
 
               <div className="hidden-on-screen">
                <h6 style={{textAlign:"center",fontWeight:"bolder",color:"black",fontFamily:"monospace"}}>Thanks For Visiting...</h6>
            </div>
        </div>
           
              <br/>
              <br/>
          </div>
          )} */}
          </ModalBody>
          <ModalFooter>
          {/* <Button color="success" onClick={()=> this.updateCustomerInfo(selectedOrder.table_no,selectedOrder.total_amount)}>Update Info</Button>{' '}
          
            <ReactToPrint
              trigger={() => (
                <Button className="btn btn-success">Print</Button>
              )}
              content = {() => this[`componentRef${selectedOrder.table_no}`]}
              /> 
              
            <Button color="secondary" onClick={this.closeModal}>Cancel</Button> */}
          </ModalFooter>
        </Modal>  


        <Modal
        isOpen={this.state.isVoid}
        toggle={this.handleCloseVoidConfirmation}
        className={this.props.className}
        size="md" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <ModalHeader closeButton style={{padding:"12px",textAlign:"center"}}> Confirm!
        </ModalHeader>
        <ModalBody>
        <div style={{margin:"auto",padding:"20px"}}>
          <h6 style={{textAlign:"center"}}>Are you sure you want to push <span style={{color:"red", fontWeight:"bold", fontSize:"20px"}}> Order No: {this.state.voidOrder && this.state.voidOrder.order_no} </span>  to the void collection?</h6>
        </div>
        <div style={{textAlign:"center"}}>
         <Button color="danger"  onClick={this.handleCloseVoidConfirmation}>
            Cancel
          </Button>{' '} 
         
          <Button color="success"  onClick={this.handleVoidPut}>
            Confirm
          </Button>{' '} 
         </div>
        </ModalBody>
      </Modal>



        <Modal
        isOpen={this.state.isShowPrinters}
        toggle={this.toggleModalPrinter}
        className={this.props.className}
        size="md" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <ModalHeader closeButton style={{padding:"12px",textAlign:"center"}}> Available Printers
        </ModalHeader>
        <ModalBody>
        <div style={{ margin: "auto", padding: "20px" }}>
      <h2 style={{ fontWeight: "bold", fontFamily: "serif", textAlign:"center" }}>Select a Printer</h2>
      <br/>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        {this.state.printers.map(printer => (
          <li key={printer} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
            <span style={{fontWeight:"bold", fontSize:"18px"}}><i class="bi bi-printer"></i>{" "}{printer} {" "}
            {
              localStorage.getItem('selectedPrinter') === printer  ?  <span class="badge rounded-pill bg-success" style={{fontSize:"12px"}}> {" "}Connected </span> : <span class="badge rounded-pill bg-danger" style={{fontSize:"12px"}}> {" "} Not Connected </span>
            }
            </span>
            {
              localStorage.getItem('selectedPrinter') === printer  ?  <button onClick={() => this.removePrinter()} className="btn btn-danger">Remove</button> :  <button onClick={() => this.selectPrinter(printer)} className="btn btn-success">Connect</button>
            }
           
          </li>
        ))}
      </ul>
    </div>
      <ModalFooter>
      <Button variant="secondary"  onClick={this.toggleModalPrinter}>
            Close
          </Button>{' '} 

      </ModalFooter>
        </ModalBody>
      </Modal>



    <Modal isOpen={isOpenConfirmationModal} toggle={this.handleCloseConfirmationModal} className={this.props.className} size="lg">
      <ModalHeader style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"15px"}} toggle={this.handleCloseConfirmationModal}>Table No: {selectedTable && selectedTable.table_no} Order List
      {
                   this.state.subscriptions  && this.state.subscriptions.is_tableorder === 0 && <button className="btn btn-danger" style={{marginLeft:"355px"}} onClick={this.handleGoToKitchen}>
      <img src={pics} alt="image" style={{width:"25px",height:"25px"}} />{" "}
        Go to Kitchen</button>}
      </ModalHeader>
          <ModalBody>
            <div style={{padding:"10px"}}>
              {(!tableOrder || tableOrder.length === 0) && 
              <button className="btn btn-success" style={{ 
                display: "block", 
                margin: "auto", 
                textAlign: "center" 
              }}  onClick={this.handleShowItems}><i class="bi bi-plus-circle-fill" style={{color:"white", fontSize:"25px"}}></i> {" "}Take order in Table  {selectedTable && selectedTable.table_no} </button>
              }

              
              {
                tableOrder && tableOrder.map((order,index)=>(
                 <>
                 {role === "Chef" || role === "Captain" || role === "Waiter" ? null : (
                  <button className="btn btn-success" style={{borderRadius:"20px",float:"right"}} onClick={()=>this.handleCloseOrder(order.id,order.table_no)}>Complete Order</button> ) }
              <br/>

                  {
                    this.state.currentOrderLoading ? (
                      <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
                    ) : (
                      <div class="card" style={{ width: "30rem", margin: "0px auto", border: "1px solid red", padding: "10px", height: "auto", backgroundColor:"rgba(241, 10, 10, 0.063)",borderRadius:"15px" }} key={index}>
  <div class="card-body">
    <div className="row">
      <div className="col">
        <h6>Captain Name:{" "} {order.captain}</h6>
      </div>
      <div className="col">
        <h6>Date: {" "}{order.ordered_at}</h6>
      </div>
    </div>
    <div className="row">
      <div className="col">
        <h6>Table No: {" "}{order.table_no}</h6>
      </div>
      <div className="col">
        <h6>Kitchen Status: {" "}
        <span style={{color:"black", fontSize:"17px",fontFamily:"monospace"}}>
                  {order.kitchen_status === 0 ? (
                    <span class="badge rounded-pill bg-primary p-2" style={{color:"white"}}>New</span>
                  ) : order.kitchen_status === 1 ? (
                    <span class="badge rounded-pill bg-warning p-2" style={{color:"white"}}>Inprogress</span>
                  ) : order.kitchen_status === 2 ? (
                    <span class="badge rounded-pill bg-success p-2" style={{color:"white"}}>ready</span>
                  ) : order.kitchen_status === 3 ? (
                    <span class="badge rounded-pill bg-danger p-2" style={{color:"white"}}>Delivered</span>
                  ) : "-"
              }</span>
        </h6>
      </div>
    </div>
    
    <table className="table">
      <thead>
        <tr>
          <th style={{color:"red"}}>Item</th>
          <th style={{color:"red"}}>Quantity</th>
          <th style={{color:"red"}}>UnitPrice</th>
          <th style={{color:"red"}}>Amount</th>
          <th style={{color:"red"}}>Action</th>
        </tr>
      </thead>
      <tbody>
        {order.ordered_by.map((item,Itindex)=>(
          <tr key={Itindex}>
          <td>{item.food_name}</td>
          <td> 
          {putLoader[Itindex] ? (
            <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          ) :
          item.quantity === 1 ? (
    <i className="bi bi-dash-circle-fill" style={{fontSize:"17px",color:"grey"}}></i>
  ) : (
    <i className="bi bi-dash-circle-fill" style={{fontSize:"17px",color:"red"}} onClick={()=>this.handleEditQuantity(item.id,order.kitchen_order_no,item.food_master_id,Itindex, order.id)}></i>
  )}
          {" "}{item.quantity}</td>
          <td>{item.unit_price}</td>
          <td>{item.unit_price*item.quantity}</td>
          <td>
            {deleteLoader[Itindex] ? (
              <div class="text-center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            ):
            <i class="bi bi-trash"  onClick={() => this.handleOpenDeleteConfirmation(item.id, order.kitchen_order_no, item.food_master_id, Itindex, order.ordered_by.length, order.id, item.food_name)} style={{fontSize:"21px",color:"red"}}></i>
            // <i class="bi bi-trash" onClick={()=>this.handleDeleteItem(item.id,order.kitchen_order_no,item.food_master_id,Itindex, order.ordered_by.length, order.id)}  style={{fontSize:"21px",color:"red"}}></i>
            }</td>
        </tr>
        ))}
        
      </tbody>
    </table>
    <h6 style={{color:"red",fontWeight:"bolder",fontSize:"20px",fontFamily:"monospace"}}>Total Amount: {this.calculateTotal(order.ordered_by)} </h6>
    <button className="btn btn-primary" style={{borderRadius:"15px",float:"right", marginTop:"-30px"}} onClick={()=>this.handleAfterAddItems(order)}>Add</button>
  </div>
</div>
                    )
                  }

                 </>
                ))
              }
              

            </div>

            <hr/>

            <input type='date' name='date'  style={{border:"1px solid black",width:"230px",borderRadius:"25px",padding:"7px",float:"right",marginLeft:"5px",height:"40px"}} 
            placeholder='choose date' onChange={this.handleChange} value={this.state.searchDate}/>{' '}
            <input class="form-control" type="Search" placeholder="Search" id="example-search-input" 
      style={{width:"230px",borderRadius:"25px",border:"1px solid black",float:"right"}} onChange={this.handleSearchOrderHistoryInput}/>{' '}
      <br/>
      <br/>
      {

filteredOrderHistory === undefined  ? <h6 style={{textAlign:"center",color:"red", fontWeight:"bolder",fontFamily:"serif"}}>Order is Empty</h6> :
filteredOrderHistory && filteredOrderHistory.map((order,index)=>(
                 <>
                  <div class="card" style={{ width: "30rem", margin: "auto", border: "1px solid green", padding: "5px", height: "auto", backgroundColor:"rgba(10, 241, 33, 0.063)",borderRadius:"15px" }} key={index}>
                  {
                    this.state.HistoryOrderLoading ? (
                      <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
                    ) : (
                      <div class="card-body">
    <h6 style={{textAlign:"center"}}>Normal Table Order</h6>
    <div className="row">
      <div className="col">
        <h6>Captain Name:{" "} {order.captain_name ? order.captain_name: "-"}</h6>
      </div>
      <div className="col">
        <h6>Date: {" "}{order.ordered_at}</h6>
      </div>
    </div>
    <div className="row">
      <div className="col">
        <h6>Attender Name: {" "}{order.attender_name}</h6>
      </div>
      <div className="col">
        <h6>Time: {" "}{order.ordered_time}
        </h6>
      </div>
    </div>
    
    <table className="table">
      <thead>
        <tr>
          <th style={{color:"green"}}>Item</th>
          <th style={{color:"green"}}>Quantity</th>
          <th style={{color:"green"}}>UnitPrice</th>
          <th style={{color:"green"}}>Amount</th>
        </tr>
      </thead>
      <tbody>
        {order.ordered_by.map((item,index)=>(
          <tr key={index}>
          <td>{item.food_name}</td>
          <td>{item.quantity}</td>
          <td>{item.unit_price}</td>
          <td>{item.unit_price*item.quantity}</td>
        </tr>
        ))}
        
      </tbody>
    </table>
    <h6 style={{color:"green",fontWeight:"bolder",fontSize:"20px",fontFamily:"monospace"}}>Total Amount:{" "} {order.total} </h6>
    <hr className="hr-custom" />
    <h6>Customer Name:{" "} {order.cust_name ? order.cust_name : "-"}</h6>
  </div>
                    )}
</div>
<br/>


                 </>
                ))
              }
      
          </ModalBody>
          <ModalFooter>
         
            <Button color="danger" onClick={this.handleCloseConfirmationModal}>Close</Button>
          </ModalFooter>
        </Modal> 


    <Modal isOpen={isdeletemodalopen} toggle={this.handleDeletecloseConfirmation} size="md" >
    <ModalHeader toggle={this.handleDeletecloseConfirmation}>Confirm Deletion</ModalHeader>
    <ModalBody>
        Are you sure you want to delete <span style={{color:"red",fontWeight:"bolder"}}>{Dfood_name}</span> ?
    </ModalBody>
    <ModalFooter>
        <Button variant="secondary" onClick={this.handleDeletecloseConfirmation}>Cancel</Button>
        <Button color="danger" onClick={this.handleDeleteItem}>Delete</Button>
    </ModalFooter>
</Modal>

    <Modal isOpen={isOpenSelectDate} toggle={this.handleCloseDateConfirmation} size="md" >
        <ModalHeader  style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"12px"}}>
        </ModalHeader>
        <ModalBody>
          <div className="row">
            
            <div className="col">
              <label style={{color:"green", fontFamily:"serif", fontWeight:"bolder"}}>Start Date:</label>
              <input type='date' name='date'  style={{border:"2px ridge green",borderRadius:"15px",height:"35px",padding:"10px"}}
              placeholder='choose date' onChange={this.handleStartDateChange} value={this.formatDate(this.state.startDate)} />{" "}
            </div>
            <div className="col">
              <label style={{color:"green", fontFamily:"serif", fontWeight:"bolder"}}>End Date:</label>
              <input type="date" name='date'  placeholder="choose date" onChange={this.handleEndDateChange} 
                 style={{border:"2px ridge green",borderRadius:"15px",height:"35px",padding:"10px"}} value={this.formatDate(this.state.endDate)}
              />
            </div>
          </div>
     <br/>
         <div style={{textAlign:"center"}}>
         <Button variant="danger"  onClick={this.handleCloseDateConfirmation}>
            Close
          </Button>{' '} 
          <Button variant="success" style={{marginRight:"5px"}} onClick={this.handleSelectDateRange}>
            Ok
          </Button>
         </div>
        </ModalBody>
      </Modal>


      <Modal
        isOpen={this.state.isNormalModal}
        toggle={this.handleCloseNormalConfirmation}
        size="md" // Set size to large
      >
        <ModalHeader closeButton style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"12px"}}>
        Normal Order Preview
        </ModalHeader>
        <ModalBody>
        <div style={{margin:"auot",padding:"20px"}}>
       
         {
        this.state.normalLoading ? (
          <div class="text-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        ):
        <Button color="success"  onClick={this.generateNormalOrder} style={{float:"right",padding:"0px 7px"}}>Complete Order</Button>}
          
        
         <h6 style={{fontWeight:"bolder", fontFamily:"serif"}}>Cust.Name: <span style={{fontWeight:"normal", fontFamily:"serif"}}>{document.querySelector('#name') ? document.querySelector('#name').value : "-"}</span></h6>
         <h6 style={{fontWeight:"bolder", fontFamily:"serif"}}>Mobile No: <span style={{fontWeight:"normal", fontFamily:"serif"}}>{document.querySelector('#mobile') ? document.querySelector('#mobile').value : "-"}</span>
         <span style={{float:"right"}}>
         <div class="form-check form-check-inline" style={{float:"right",margin:"0px 5px 0px 5px"}}>
  <input
          className="form-check-input"
          type="checkbox"
          id="inlineCheckbox1"
          value="option1"
          checked={this.state.isPrintNowChecked}
          onChange={this.handlePrintCheckboxChange}
        />
         <label class="form-check-label text-dark" for="inlineCheckbox1" style={{fontSize:"15px"}}>Make Print Now</label>
      </div>
         </span>
         </h6>
         <h6 style={{fontFamily:"serif",fontWeight:"bolder", fontSize:"20px"}}>Bill Amount: <span style={{fontFamily:"monospace",fontWeight:"normal",color:"green"}}>{ this.state.tableOrder && this.state.tableOrder.length > 0
  ? this.state.tableOrder[0] && this.state.tableOrder[0].ordered_by && this.state.tableOrder[0].ordered_by.reduce((accumulator, cartItem) => {
    return accumulator + cartItem.quantity * cartItem.unit_price;
  }, 0) : 0}</span><span style={{float:"right"}}>Paymode Total: <b style={{color: CartTotalOrdr === modeTotal ? "green" : "red"}}>{this.state.selectedAmounts.reduce((accumulator, amount) => {
    return accumulator + amount;
  }, 0)}</b></span></h6>
         <br/>
       <div  style={{marginTop:"-20px"}}>
       <table class="table table-hover">
  <thead>
    <tr>
      <th scope="col" style={{textAlign:"center"}}>Paymode</th>
      <th scope="col" style={{textAlign:"center"}}>Amount</th>
    </tr>
  </thead>
  <tbody>
    {
      this.state.payModes
      .map((mode,index)=>(
    <tr key={index}>
      <td>
        <div class={`card ${this.state.selectedAmounts[index] > 0 ? 'bg-success' : ''} ${this.state.selectedAmounts[index] > 0 ? 'text-light' : ''}`} style={{width:"auto", height:"auto", margin:"0px"}} onClick={() => this.handlemodeCardClick(mode.id)}>
          <div class="card-body" style={{textAlign:"center"}}>
            {mode.pay_mode_name}
          </div>
        </div>
      </td>
      <td>
       <Input  type="number" id={`paymodeAmount${mode.id}`} placeholder="0" value={this.state.selectedAmounts[index]} onChange={(e) => this.handleAmountChange(index, e.target.value)} style={{width:"140px",margin:"auto"}} min="0" />    
      </td>
    </tr>
      ))
    }
    </tbody>
        </table>
       {/* <label style={{fontWeight:"bolder", fontFamily:"serif"}}>Pay Mode:</label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
           <Select
        options={this.state.payModeOptions}
        placeholder="Select mode"
        value={this.state.selectedPayMode}
        onChange={this.handlePayModeSelectChange}
        isSearchable={true}
        className="customSelectpaymode"
        required
    />
       </div> */}
    <br/>
    <div>
    <label style={{fontWeight:"bolder", fontFamily:"serif"}}>Remarks:</label>
       <Input  type="text" id="remark" placeholder="Remark" required  style={{width:"280px"}}/>    
    </div>
    
    
       </div>
       <br/>  

         <div style={{textAlign:"center"}}>
       {" "}
         <Button color="danger"  onClick={this.handleCloseNormalConfirmation}>
            Close
          </Button>{' '} 
         
         </div>
          
        </div>
        </ModalBody>
      </Modal>


      <Modal
        isOpen={this.state.isNormalCreditModal}
        toggle={this.handleCloseNormalCreditConfirmation}
        size="md" // Set size to large
      >
        <ModalHeader  style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"12px"}}>Normal Credit Order Preview
        </ModalHeader>
        <ModalBody>
        <div style={{margin:"auot",padding:"20px"}}>
       
         {
        this.state.normalLoading ? (
          <div class="text-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        ):
        <Button color="success"  onClick={this.generateCreditNormalOrder} style={{float:"right",padding:"0px 7px"}}>Complete Order</Button>}
          
       
         <h6 style={{fontWeight:"bolder", fontFamily:"serif"}}>Cust.Name: <span style={{fontWeight:"normal", fontFamily:"serif"}}>{this.state.selectedData && this.state.selectedData.label}</span></h6>
         <h6 style={{fontWeight:"bolder", fontFamily:"serif"}}>Mobile No: <span style={{fontWeight:"normal", fontFamily:"serif"}}>{this.state.selectedData && this.state.selectedData.value}</span>
         <span style={{float:"right"}}>
         <div class="form-check form-check-inline" style={{float:"right",margin:"0px 5px 0px 5px"}}>
  <input
          className="form-check-input"
          type="checkbox"
          id="inlineCheckbox1"
          value="option1"
          checked={this.state.isPrintNowChecked}
          onChange={this.handlePrintCheckboxChange}
        />
         <label class="form-check-label text-dark" for="inlineCheckbox1" style={{fontSize:"15px"}}>Make Print Now</label>
      </div>
         </span>
         </h6>
         <h6 style={{fontFamily:"serif",fontWeight:"bolder", fontSize:"20px"}}>Bill Amount: <span style={{fontFamily:"monospace",fontWeight:"normal",color:"green"}}>{this.state.tableOrder && this.state.tableOrder.length > 0
  ? this.state.tableOrder[0] && this.state.tableOrder[0].ordered_by && this.state.tableOrder[0].ordered_by.reduce((accumulator, cartItem) => {
    return accumulator + cartItem.quantity * cartItem.unit_price;
  }, 0) : 0}</span><span style={{float:"right"}}>Paymode Total: <b style={{color: CartTotalOrdr === modeTotal ? "green" : "red"}}>{this.state.selectedAmounts.reduce((accumulator, amount) => {
    return accumulator + amount;
  }, 0)}</b></span></h6>
         <br/>
       <div style={{marginTop:"-20px"}}>
       <table class="table table-hover">
  <thead>
    <tr>
      <th scope="col" style={{textAlign:"center"}}>Paymode</th>
      <th scope="col" style={{textAlign:"center"}}>Amount</th>
    </tr>
  </thead>
  <tbody>
    {
      this.state.payModes
      .map((mode,index)=>(
    <tr key={index}>
      <td>
        <div class={`card ${this.state.selectedAmounts[index] > 0 ? 'bg-success' : ''} ${this.state.selectedAmounts[index] > 0 ? 'text-light' : ''}`} style={{width:"auto", height:"auto", margin:"0px"}} onClick={() => this.handlemodeCardClick(mode.id)}>
          <div class="card-body" style={{textAlign:"center"}}>
            {mode.pay_mode_name}
          </div>
        </div>
      </td>
      <td>
       <Input  type="number" id={`paymodeAmount${mode.id}`} placeholder="0" value={this.state.selectedAmounts[index]} onChange={(e) => this.handleAmountChange(index, e.target.value)} style={{width:"140px",margin:"auto"}} min="0" />    
      </td>
    </tr>
      ))
    }
    </tbody>
        </table>
       {/* <label style={{fontWeight:"bolder", fontFamily:"serif"}}>Pay Mode:</label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
           <Select
        options={this.state.payModeOptions}
        placeholder="Select mode"
        value={this.state.selectedPayMode}
        onChange={this.handlePayModeSelectChange}
        isSearchable={true}
        className="customSelectpaymode"
        required
    />
       </div> */}
    <br/>
    <div>
    <label style={{fontWeight:"bolder", fontFamily:"serif"}}>Remarks:</label>
       <Input  type="text" id="remark" placeholder="Remark" required  style={{width:"280px"}}/>    
    </div>
    
    
       </div>
       <br/>  

         <div style={{textAlign:"center"}}>
         {" "}
         <Button color="danger"  onClick={this.handleCloseNormalCreditConfirmation}>
            Close
          </Button>{' '} 
         
         </div>
          
        </div>
        </ModalBody>
      </Modal>


      <Modal
        isOpen={this.state.isCreditModal}
        toggle={this.handleCloseCreditConfirmation}
        size="md" // Set size to large
      >
        <ModalHeader  style={{backgroundColor:"rgb(239, 156, 156)",padding:"12px"}}> Credit Order Preview
        </ModalHeader>
        <ModalBody>
        <div style={{margin:"auot",padding:"20px"}}>
       
         {
          this.state.creditLoading ? (
            <div class="text-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
          ):
          <Button color="success"  onClick={this.generateCreditOrder} style={{float:"right"}}>Complete Order</Button> }
          
       
         <h6 style={{fontWeight:"bolder", fontFamily:"serif"}}>Cust.Name: <span style={{fontWeight:"normal", fontFamily:"serif"}}>{this.state.selectedData && this.state.selectedData.label}</span></h6>
         <h6 style={{fontWeight:"bolder", fontFamily:"serif"}}>Mobile No: <span style={{fontWeight:"normal", fontFamily:"serif"}}>{this.state.selectedData && this.state.selectedData.value}</span>
         <span style={{float:"right"}}>
         <div class="form-check form-check-inline" style={{float:"right",margin:"0px 5px 0px 5px"}}>
  <input
          className="form-check-input"
          type="checkbox"
          id="inlineCheckbox1"
          value="option1"
          checked={this.state.isPrintNowChecked}
          onChange={this.handlePrintCheckboxChange}
        />
         <label class="form-check-label text-dark" for="inlineCheckbox1" style={{fontSize:"15px"}}>Make Print Now</label>
      </div>
         </span>
         </h6>
         <h6 style={{fontFamily:"serif",fontWeight:"bolder", fontSize:"20px"}}>Bill Amount: <span style={{fontFamily:"monospace",fontWeight:"normal",color:"red"}}>{this.state.tableOrder && this.state.tableOrder.length > 0
  ? this.state.tableOrder[0] && this.state.tableOrder[0].ordered_by && this.state.tableOrder[0].ordered_by.reduce((accumulator, cartItem) => {
    return accumulator + cartItem.quantity * cartItem.unit_price;
  }, 0) : 0}</span><span style={{float:"right"}}>Paymode Total: <b style={{color: "red"}}>{CartTotalOrdr}</b></span></h6>
         <br/>
       <div style={{marginTop:"-20px"}}>
       <table class="table table-hover">
  <thead>
    <tr>
      <th scope="col" style={{textAlign:"center"}}>Paymode</th>
      <th scope="col" style={{textAlign:"center"}}>Amount</th>
    </tr>
  </thead>
  <tbody>
  <tr>
      <td>
        <div class='card bg-danger text-light' style={{width:"auto", height:"auto", margin:"0px"}} >
          <div class="card-body" style={{textAlign:"center"}}>
          CREDIT ORDER
          </div>
        </div>
      </td>
      <td>
       <Input  type="number" id="paymodeAmount" value={CartTotalOrdr}  style={{width:"140px",margin:"auto"}} min="0" />    
      </td>
    </tr>
    </tbody>
        </table>
       {/* <label style={{fontWeight:"bolder", fontFamily:"serif"}}>Pay Mode: CREDIT ORDER</label> */}
       
    <br/>
    <div>
    <label style={{fontWeight:"bolder", fontFamily:"serif"}}>Remarks:</label>
       <Input  type="text" id="remark" placeholder="Remark" required  style={{width:"280px"}}/>    
    </div>
    
    
       </div>
       <br/>  

         <div style={{textAlign:"center"}}>
        {" "}
         <Button color="danger"  onClick={this.handleCloseCreditConfirmation}>
            Close
          </Button>{' '} 
         
         </div>
          
        </div>
        </ModalBody>
      </Modal>





      <Modal
        isOpen={this.state.isOrderConfirmation}
        toggle={this.handleCloseOrderConfirmation}
        size="md" // Set size to large
      >
        <ModalHeader closeButton style={{padding:"12px",textAlign:"center"}}>Confirmation
        </ModalHeader>
        <ModalBody>
        <div style={{margin:"auot",padding:"20px"}}>
          <h6 style={{textAlign:"center"}}>Thank you for the payment</h6>
          <h6 style={{textAlign:"center"}}>Pay Mode: <span style={{fontWeight:"bolder",fontFamily:"monospace",fontSize:"18px"}}>{this.state.enteredPaymodes}</span></h6>
          <h6 style={{textAlign:"center"}}>Bill Amount: <span style={{fontWeight:"bolder",fontFamily:"monospace",fontSize:"18px"}}>{this.state.orderResponse.totalAmount}</span></h6>
        </div>
        <div style={{textAlign:"center"}}>
         <Button color="danger"  onClick={this.handleCloseOrderConfirmation}>
            Close
          </Button>{' '} 
         
         </div>
        </ModalBody>
      </Modal>


      <Modal
        isOpen={this.state.isPremium}
        toggle={this.handleClosePremiumAlert}
        size="md" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <ModalHeader closeButton> 
        <h6><i class="bi bi-award-fill" style={{color:"red", fontSize:"20px"}}></i> Premium Features</h6>
        </ModalHeader>
        <ModalBody>

         <div style={{padding:"20px",backgroundColor:"white"}}>
          <h6 className='text-dark' style={{textAlign:"center",fontWeight:"bolder"}}>To activate premium features, please subscribe now!</h6> 
         </div>
         {' '} 
          <Button color="danger" style={{float:"right"}} onClick={this.handleClosePremiumAlert}>
            Close
          </Button>{' '} 
          <Button color="success" style={{float:"right",marginRight:"5px"}} onClick={this.routeMysubscription}>
            Activate
          </Button>
        </ModalBody>
      </Modal>



      <Modal
        isOpen={this.state.show}
        toggle={this.handleCloseConfirmation}
        size="md" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <ModalHeader closeButton style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"12px"}}>Logout
        </ModalHeader>
        <ModalBody>

         <div style={{padding:"20px",backgroundColor:"white"}}>
          <h6 className='text-dark' style={{textAlign:"center",fontWeight:"bolder"}}>Are You Sure You want to Logout ?</h6> 
         </div>
         {' '} 
          <Button color="danger" style={{float:"right"}} onClick={this.handleCloseConfirmation}>
            Close
          </Button>{' '} 
          <Button color="success" style={{float:"right",marginRight:"5px"}} onClick={this.handleModLogout}>
            Confirm
          </Button>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={this.state.isPayModeEdit}
        toggle={this.handleCloseaPayModeEdit}
        size="md" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <ModalHeader closeButton style={{padding:"12px",textAlign:"center"}}>Update Paymode
        </ModalHeader>
        <ModalBody>
          
        <div className="p-3">
        {
  this.state.isPayModeEditLoading ? (
    <div className="text-center">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <Button color="success"  onClick={this.handleUpdatePaymode} style={{float:"right"}}>
      <i className="bi bi-vector-pen"></i> Update
      </Button>
  )
}
<br/>
<h6 style={{color:"blue", textAlign:"left"}}>Order No: <b>{editPaymodeOrder ? editPaymodeOrder.order_no: " "}</b></h6>
          <h6 style={{color:"green"}}>Total Amount: <b>{editPaymodeOrder ? editPaymodeOrder.total : ""}</b> <span style={{float:"right"}}>Paymode Total : 
          <b style={{color: editPaymodeOrder && editPaymodeOrder.total == editModeTotal ? "green" : "red"}}>{this.state.editSelectedAmounts ? this.state.editSelectedAmounts.reduce((sum, currentItem) => sum + currentItem.amount, 0) : ""}</b></span></h6>
          
          <div className="row">
            <div className="col">
            <table class="table table-hover">
  <thead>
    <tr>
      <th scope="col" style={{textAlign:"center"}}>Paymode</th>
      <th scope="col" style={{textAlign:"center"}}>Amount</th>
    </tr>
  </thead>
  <tbody>
  {
  this.state.editSelectedAmounts &&
    this.state.editSelectedAmounts.map((mode, index) => (
      <tr key={index}>
        <td>
          <div
            className={`card ${mode.amount > 0 ? 'bg-success' : ''} ${mode.amount > 0 ? 'text-light' : ''}`}
            style={{ width: 'auto', height: 'auto', margin: '0px' }}
            onClick={() => this.handleEditCardClick(mode.id, this.state.editPaymodeOrder && this.state.editPaymodeOrder.total)}
          >
            <div className="card-body" style={{ textAlign: 'center' }}>
              {mode.pay_mode_name}
            </div>
          </div>
        </td>
        <td>
          <input
            type="number"
            id={`paymodeEditAmount${mode.id}`}
            value={mode.amount || ''}
            onChange={(e) => this.handleEditAmountChange(index, e.target.value)}
            style={{ width: '140px', margin: 'auto' }}
            min="0"
          />
        </td>
      </tr>
    ))
}
    </tbody>
        </table>
            {/* <label class="form-label text-success fw-bold m-0" style={{fontFamily:"serif"}}>Paymode</label>
            <Select
        options={this.state.payModeOptions}
        placeholder="Select mode"
        value={this.state.editpaymode}
        onChange={this.handlePayModeEditChange}
        isSearchable={true}
        className="customSelectpaymode"
    /> */}
            </div>
            <div className="col">
            <label class="form-label text-success fw-bold m-0" style={{fontFamily:"serif"}}>Remark</label>
            <Input  type="text" id="editremark" placeholder="Remark" required  style={{width:"280px"}} value={this.state.updateRemark} onChange={this.handleRemarkChange} />    
            </div>
          </div>
        </div>
        <div style={{textAlign:"center"}}>
        
       
         {" "}
         <Button color="danger"  onClick={this.handleCloseaPayModeEdit}>
            Close
          </Button>{' '} 
         
         </div>
        </ModalBody>
      </Modal>


      <Modal
        isOpen={this.state.isOpenAddInstant}
        toggle={this.handleCloseInstantAdding}
        size="lg" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <ModalHeader closeButton style={{padding:"12px",textAlign:"center"}}>Instant Food Adding
        </ModalHeader>
        <ModalBody>
        <div style={{margin:"auto",padding:"20px"}}>
        <div class="row">
         <div class="col">
            <label for="input1" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Food Name</label>
           <input type="text" class="form-control" placeholder="Enter food name" id="input1" name="food_name" style={{borderRadius:"12px"}} value={this.state.instantFoodName} onChange={this.handleInstandFoodChange} />
         </div>
         <div class="col">
           <label for="input3" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Unit Price</label>
           <input type="number" class="form-control" placeholder="Enter price" id="input3" name="quantity" style={{borderRadius:"12px"}} value={this.state.instantPrice} onChange={this.handleInstandPriceChange} required min="0.1"/>
         </div>
         <div class="col">
           <label for="input2" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Qty</label>
           <input type="number" class="form-control" placeholder="Enter quantity" id="input2" name="quantity" style={{borderRadius:"12px"}} value={this.state.instantQuantity} onChange={this.handleInstandQuantityChange} required min="1" />
         </div>
        </div>
        <br/>
        {
          this.state.instantQuantity && this.state.instantPrice &&
        <h6 style={{fontFamily:"monospace", fontWeight:"bolder", color:"blue"}}>Food Total: {this.state.instantQuantity * this.state.instantPrice}</h6>
    }
        </div>
        <div style={{textAlign:"center"}}>
        <Button color="success"  onClick={this.handleToInstantAddingFood}>
            Add
          </Button>{' '} 
         
         <Button color="danger"  onClick={this.handleCloseInstantAdding}>
            Close
          </Button>{' '} 
         
         </div>
        </ModalBody>
      </Modal>


      <Modal
        isOpen={this.state.showNotify}
        toggle={this.handleCloseNotifyConfirmation}
        size="xl" // Set size to large
      >
        <ModalHeader style={{padding:"10px"}}>
          <div style={{width:"1100px",fontSize:"25px",fontWeight:"bolder", textAlign:"center",fontFamily:"serif",textTransform:"uppercase"}}>Notifications<i class="bi bi-bell"></i>
          <i class="bi bi-x-circle-fill" style={{float:"right",fontSize:"25px",color:"red"}} onClick={this.handleCloseNotifyConfirmation}></i>
          {/* <Button color="danger" style={{float:"right"}}  onClick={this.handleCloseNotifyConfirmation}>
            Close
          </Button> */}
          </div>
        <div style={{textAlign:"right"}}>
         
         
         </div>
        </ModalHeader>
        <ModalBody>
        <input class="form-control" type="Search" placeholder="Search" id="example-search-input" 
      style={{width:"230px",borderRadius:"15px",border:"1px solid black",float:"right",marginRight:"30px"}} onChange={this.handleSearchNotifyInput}/>
      <br/>
        <div style={{margin:"auto",padding:"10px"}}>
        
        <Card  border="success" style={{borderRadius:"20px",width:"auto",height:"auto",margin:"10px"}}> 
        <Card.Body className="p-2">

        <Card className="m-0 border hover" style={{width:"auto",height:"auto"}}> 
          <Card.Body>
            <div style={{display:"flex", alignItems:"center", gap:"70px",padding:"0px 50px"}}>
              <h5 style={{fontFamily:"serif",fontWeight:"bolder",color:"green"}}>S.No</h5>
              <h5 style={{fontFamily:"serif",fontWeight:"bolder",color:"green"}}>Cust Name</h5>
              <h5 style={{fontFamily:"serif",fontWeight:"bolder",color:"green"}}>Mobile No</h5>
              <h5 style={{fontFamily:"serif",fontWeight:"bolder",color:"green"}}>Email Id</h5>
              <h5 style={{fontFamily:"serif",fontWeight:"bolder",color:"green"}}>Ordered Date</h5>
              <h5 style={{fontFamily:"serif",fontWeight:"bolder",color:"green"}}>Ordered Time</h5>
            </div>
          </Card.Body>
          </Card>

     <div className="notifydiv" style={{height:"450px",overflow:"scroll"}}>
     {
      this.state.isNotify ? (
        <div class="text-center" style={{marginTop:"20px"}}>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      ):(
        filteredNotifys.length === 0 ? (
          <div class="text-center" style={{marginTop:"20px",color:"red"}}>
         No Data Available
        </div>
        ) : (
          filteredNotifys.map((notify,index)=>(
            <Card className="m-0 hoverable-card" style={{width:"auto",height:"auto"}} key={index}> 
            <Card.Body>
              <div style={{display:"flex", alignItems:"center", gap:"70px",padding:"0px 50px"}}>
              <h5 style={{fontFamily:"monospace"}}>{this.state.startIndex +index+1}</h5>
                <h5 style={{fontFamily:"serif"}}>{notify.triggered_by_username.name}</h5>
                <h5 style={{fontFamily:"monospace"}}>{notify.triggered_by_username.phone_no}</h5>
                <h5 style={{fontFamily:"serif"}}>{notify.triggered_by_username.email}</h5>
                <h5 style={{fontFamily:"monospace"}}>{notify.ordered_at}</h5>
                <h5 style={{fontFamily:"monospace"}}>{notify.ordered_time}</h5>
              </div>
            </Card.Body>
            </Card>
          ))
        )
       )}
     </div>
       <br/>

<Pagination
    activePage={this.state.currentPage}
    itemsCountPerPage={this.state.itemsPerPage}
    totalItemsCount={this.state.totalItems} // Update to reflect total items for pagination
    pageRangeDisplayed={5}
    onChange={this.handlePageChange}
    itemClass="page-item"
    linkClass="page-link"
    innerClass="pagination justify-content-end"
    activeClass="active-pagination" // Custom class for the active pagination item
/>

        </Card.Body>
        </Card>
        </div>
        
        </ModalBody>
      </Modal>


    </>
    );
  }
}



export default withRouter(TableOrders);