import React, { useState,useEffect } from "react";
import 'bootstrap-icons/font/bootstrap-icons.css';
import Splash from "../../images/splash/shop_logo.jpeg";
import { Link, useHistory } from 'react-router-dom';
import image1 from './shopIma/truck.png';
import avatar1 from './shopIma/avatar.jpeg';
import star from './shopIma/star.png';
import Swal from "sweetalert2";
import axios from 'axios';
import './shopmas.css';
import {var_api} from "../constant";
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { swal } from "sweetalert2/dist/sweetalert2";
import { useRef } from 'react';
import { Tooltip } from "reactstrap";

function ShopMaster(){
  const [users, setUsers] = useState([]);
  const fileInputRef = useRef(null);
  const [currencys, setCurrencys] = useState([]);
  const history = useHistory();
  const [searchquery,setSearchQuery] = useState('');
  const [ loading,setLoading ] = useState(false);
  const [ show,setShow ] = useState(false);
  const [ isEdit,setIsEdit ] = useState(false);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState({ value: 'SGD', label: 'SGD' });
  const [formData, setFormData] = useState({
    shop_name:'',
    owner_name:'',
    address:'',
    state:'',
    country_name:'',
    created_by:'',
    shop_image:"",  
    shop_currency:selectedCurrency.value,
    shop_type:null  
  });

  const [updateFormData, setUpdateFormData] = useState({
    id:null,
    shop_name: "",
    owner_name: "",
    address: "",
    state: "",
    country_name: "",
    created_by: "",
    shop_image: "",  
    shop_currency: "" 
  });
  const [puttooltipOpen, setPutToolTipOpen] = useState([]);
  // const [databaseData, setDatabaseData] = useState([]);

  useEffect(() => {
    fetchcurrecny();
    fetchData();
  }, []);


  //fetch the shops
  const fetchData = async () => {
    setLoading(true);
    try {
      // const response = await axios.get('https://api.spbiller.com/api/shop/data');
     const create = localStorage.getItem('userId')
      const response = await axios.get(`${var_api}/api/shop/data/${create}`);
      const responseData = response.data;
      
      if (Array.isArray(responseData)) {
        setUsers(responseData);
      } else {
        // Handle the case when responseData is not an array
        console.error('API response is not an array:', responseData);
        setUsers([responseData]); // Set users to an empty array or handle the error appropriately
      }
    } catch (error) {
      console.log('Error fetching data:',error);
    }finally {
      setLoading(false);
    }
  };


  const togglePutTooltip = (index) => {
    const putTooltipOpen = [...puttooltipOpen];
    putTooltipOpen[index] = !putTooltipOpen[index];
    setPutToolTipOpen(putTooltipOpen);
  };

  


  //fetch currency
  const fetchcurrecny = async () => {
    try {
      const response = await axios.get(`${var_api}/api/currency-master/get-currency`);
      const responseData = response.data;
      
      if (Array.isArray(responseData)) {
        setCurrencys(responseData);
         // Map currency names to currencyOptions
      const options = responseData.map(currency => ({
        value: currency.currency_name,
        label: currency.currency_name
      }));

      setCurrencyOptions(options);
      } else {
        // Handle the case when responseData is not an array
        console.error('API response is not an array:', responseData);
        setCurrencys([responseData]); // Set users to an empty array or handle the error appropriately
      }
    } catch (error) {
      console.log('Error fetching data:',error);
    }
  };

  


  const showSuccessAlert = () => {
    Swal.fire({
      title: 'Success',
      text: 'Shop has been Created Successfully!',
      icon: 'success',
      confirmButtonColor: '#007500', // Set the custom color for the Confirm button
    });
  };

  const showFailureAlert = () => {
    Swal.fire({
      title: 'Success',
      text: 'Failed to create shop profile. Please try again.',
      icon: 'success',
      confirmButtonColor: '#007500', // Set the custom color for the Confirm button
    });
  };

  const handleButtonClick = () => {
    // Navigate to a specific route using history.push
    history.push('/home');
  };


  const handleShop = async()=>{
    setShow(true);
//     const { value: formValues } = await Swal.fire({
//       title: 'Add Shop',
//       html:`
//         <label class="swalupdate">Shop Name</label>
//         <input id="swal-input1" class="swal2-input noin" placeholder="Shop Name"> 
//         <br/>
//         <br/>
//         <label class="swalupdate">Owner Name</label>
//         <input id="swal-input2" class="swal2-input noin" placeholder="Owner Name">
//         <br/>
//         <br/>
//         <label  id="upaddress">Address</label>
//         <input id="swal-input3" class="swal2-input noin" placeholder="Address">
//         <br/>
//         <br/>
//         <label id="upstate">State</label>
//         <input id="swal-input41" class="swal2-input nain" placeholder="State">
//         <br/>
//         <br/>
//         <label id="upcountry">Country</label>
//         <input id="swal-input5" class="swal2-input noin" placeholder="Country">
//         <br/>
//           <br/>
//           <label class="swalLa">Currency</label>
//           <select id="swal-input6" class="swal2-input selin"> 
//           <option value="Select One" selected>Select One</option> 
//           <option value="USD">USD</option>  
//           <option value="INR">INR</option> 
//           <option value="SGD">SGD</option> 
//           <option value="EUR">EUR</option> 
//           <option value="SGD">GBP</option> 
//           <option value="EUR">MYR</option> 
//           </select>
//       <br/>
//       <br/>
//       <label class="swalupdate">Shop Image</label>
//         <input id="swal-input7" class="swal2-input nima" placeholder="Select File" type="file""> `,
//       focusConfirm: false,
//       confirmButtonColor: '#007500',
//       confirmButtonText: 'Submit',
//       showCancelButton: true,
//       cancelButtonColor: '#ff0000',
//       preConfirm: () => {
//         const input1 = document.getElementById('swal-input1').value;
//         const input2 = document.getElementById('swal-input2').value;
//         const input3 = document.getElementById('swal-input3').value;
//         const input4 = document.getElementById('swal-input41').value;
//         const input5 = document.getElementById('swal-input5').value;
//         const input6 = document.getElementById('swal-input6').value;
//         const input7 = document.getElementById('swal-input7').files[0];
        
        
//         if (!input1 || !input2 || !input3 || !input4 || !input5 || !input6) {
//           Swal.showValidationMessage('Please fill in all the required fields');
//         } else {
//           return [input1, input2, input3, input4, input5, input6,input7];
//         }
//       }
//     })
//     if (formValues) {
//       const [shopName, ownerName, address, state, country, currency, selectedFile] = formValues;
//       const created_by = parseInt(localStorage.getItem('userId') || "" );
    
//       try {
//        // Convert input1 and input2 to camelCase
//         const camelCaseInput1 = capitalizeEachWord(shopName);
//         const camelCaseInput2 = capitalizeEachWord(ownerName);
//         const camelCaseInput3 = capitalizeEachWord(address);
//         // Capitalize the first letter of state and countryName
//         const capitalizedState = capitalize(state);
//         const capitalizedCountryName = capitalize(country);
//         // Convert shop currency to uppercase
//         const uppercaseCurrency = currency.toUpperCase();


//         const formData = new FormData();
//        formData.append('shop_name', camelCaseInput1);
//        formData.append('owner_name', camelCaseInput2);
//        formData.append('address', camelCaseInput3);
//        formData.append('state', capitalizedState);
//        formData.append('country_name',capitalizedCountryName);
//        formData.append('shop_currency',uppercaseCurrency);
//        formData.append('shop_image',selectedFile);
//        formData.append('created_by',created_by);

//         const response = await axios.post(`${var_api}/api/shop/api/post`, formData, {
//           headers: {
//             'Content-Type': 'multipart/form-data',
//           },
//         });
  
//         if (response.status === 200) {
//           // Profile updated successfully
//           fetchData();
//           showSuccessAlert()
//           // window.location.reload(true);
//         } else {
//           showFailureAlert ()
//         }
//       } catch (error) {
//         console.error('Error updating profile:', error);
//         Swal.fire('Error', 'Failed to create shop profile. Please try again.', 'error');
//       }
//     }
  }

  // Function to convert string to camelCase
  const capitalizeEachWord = (str) => {
    return str.toLowerCase().replace(/(^|\s)\S/g, function (match) {
      return match.toUpperCase();
    });
  };

  // Function to capitalize the first letter of a string
const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
 };


  //update the email and government id
  const handleEditShop = async(user)=>{
    console.log(user);
    setIsEdit(true);
    setUpdateFormData({
      id:user.id,
      shop_name: user.shop_name,
      owner_name: user.owner_name,
      address: user.address,
      state: user.state,
      country_name: user.country_name,
      created_by: user.created_by,
      shop_image: user.shop_image,  
      shop_currency: user.shop_currency,
      shop_type: user.shop_type
    })
    console.log("updateFormData",updateFormData);
//     const { value: formValues } = await Swal.fire({
//       title: 'Update Shop',
//       html:`
//       <label class="swalupdate">Shop Name</label>
//       <input type="text" id="swal-input1" class="swal2-input crein" value=${user.shop_name}>
//       <br/>
//       <br/>
//       <label class="swalupdate">Owner Name</label>
//       <input type="text" id="swal-input2" class="swal2-input crein" value=${user.owner_name}>
//       <br/>
//       <br/>
//       <label  id="upaddress">Address</label>
//       <input type="text" id="swal-input3" class="swal2-input crein" value=${user.address}>
//       <br/>
//       <br/>
//       <label id="upstate">State</label>
//       <input type="text" id="swal-input4" class="swal2-input crein" value=${user.state}>
//       <br/>
//       <br/>
//       <label id="upcountry">Country</label>
//       <input type="text" id="swal-input5" class="swal2-input crein" value=${user.country_name}>
//       <br/>
//           <br/>
//           <label class="swalLa">Currency</label>
//           <select id="swal-input6" class="swal2-input selin"> 
//           <option value=${user.shop_currency} selected>${user.shop_currency}</option> 
//           <option value="USD">USD</option>  
//           <option value="INR">INR</option> 
//           <option value="SGD">SGD</option> 
//           <option value="EUR">EUR</option> 
//           <option value="SGD">GBP</option> 
//           <option value="EUR">MYR</option> 
//           </select>
//       <br/>
//       <br/>
//       <label class="swalupdate">Shop Image</label>
//       <input type="file" id="swal-input7" class="swal2-input" accept="image/*">
//       `

//        ,
//       focusConfirm: false,
//       confirmButtonColor: '#007500',
//       confirmButtonText: 'Update',
//       showCancelButton: true,
//       cancelButtonColor: '#ff0000',
//       preConfirm: () => {
//         return new Promise(async (resolve) => {
//         const input1 = document.getElementById('swal-input1').value;
//         const input2 = document.getElementById('swal-input2').value;
//         const input3 = document.getElementById('swal-input3').value;
//         const input4 = document.getElementById('swal-input4').value;
//         const input5 = document.getElementById('swal-input5').value;
//         const input6 = document.getElementById('swal-input6').value;
//         const input7 = document.getElementById('swal-input7').files[0];
        
//         if (!input1 || !input2 || !input3 || !input4 || !input5 || !input6) {
//           Swal.showValidationMessage('Please fill in all the required fields');
//         } else {
         
       
  
   
//       const id = user.id;

//     // Convert input1 and input2 to camelCase
// const camelCaseInput1 = capitalizeEachWord(input1);
// const camelCaseInput2 = capitalizeEachWord(input2);
// const camelCaseInput3 = capitalizeEachWord(input3);
// console.log(camelCaseInput1,camelCaseInput2);

// // Capitalize the first letter of state and countryName
// const capitalizedState = capitalize(input4);
// const capitalizedCountryName = capitalize(input5);

// // Convert shop currency to uppercase
// const uppercaseCurrency = input6.toUpperCase();

//       // Create a FormData object to send the file
//    const formData = new FormData();
//    formData.append('shop_name', camelCaseInput1 || user.shop_name);
//    formData.append('owner_name', camelCaseInput2 || user.owner_name);
//    formData.append('address', camelCaseInput3 || user.address);
//    formData.append('state', capitalizedState || user.state);
//    formData.append('country_name',capitalizedCountryName || user.country_name);
//    formData.append('shop_currency',uppercaseCurrency || user.shop_currency);
//    formData.append('shop_image', input7 || user.shop_image);
//    formData.append('created_by',user.created_by);
    
//       try {
//         const response = await axios.put(`${var_api}/api/shop/shop-put/${id}`, formData, {
//           headers: {
//             'Content-Type': 'multipart/form-data', // Set the content type for file upload
//           },
//         });

  
//         if (response.status === 200) {
//           // Profile updated successfully
//           console.log(response)
//           showSuccessEditAlert()
//           fetchData();
//         } else {
//            showFailureEditAlert ()
//         }
//       } catch (error) {
//         console.error('Error updating profile:', error);
//         Swal.fire('Error', 'Failed to update shop profile. Please try again.', 'error');
//       }
      
//       resolve(formData);
//     }
//   });
// },
// });
};

const showSuccessEditAlert = () => {
  Swal.fire({
    title: 'Success',
    text: 'shop has been updated successfully!',
    icon: 'success',
    confirmButtonColor: '#007500', // Set the custom color for the Confirm button
  });
};

const showFailureEditAlert = () => {
  Swal.fire({
    title: 'Success',
    text: 'Failed to update shop profile. Please try again.',
    icon: 'success',
    confirmButtonColor: '#007500', // Set the custom color for the Confirm button
  });
};



  // async function fetchData() {
  //   try {
  //     const shopsCollectionRef = collection(db, 'shops');
  //     const snapshot = await getDocs(shopsCollectionRef);
  
  //     const databaseDataObject = {};
  
  //     for (const shopDoc of snapshot.docs) {
  //       const shopData = shopDoc.data();
  //       const shopId = shopDoc.id;
  
  //       const categoriesQuery = query(collection(shopsCollectionRef, shopId, 'categories'));
  //       const categoriesSnapshot = await getDocs(categoriesQuery);
  
  //       const categories = {};
  
  //       for (const categoryDoc of categoriesSnapshot.docs) {
  //         const categoryId = categoryDoc.id;
  
  //         const foodMasterQuery = query(
  //           collection(shopsCollectionRef, shopId, 'categories', categoryId, 'foodmaster')
  //         );
  //         const foodMasterSnapshot = await getDocs(foodMasterQuery);
  
  //         const foodMaster = {};
  
  //         for (const foodDoc of foodMasterSnapshot.docs) {
  //           const foodId = foodDoc.id;
  //           const foodData = foodDoc.data();
  //           foodMaster[foodId] = foodData;
  //         }
  
  //         categories[categoryId] = foodMaster;
  //       }
  
  //       databaseDataObject[shopId] = {
  //         ...shopData,
  //         categories,
  //       };
  //     }
  
  //     console.log('Retrieved data:', databaseDataObject);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // }


  // useEffect(() => {
  //   fetchData();
  // }, []);

  // const downloadDataAsFile = () => {
  //   if (databaseData) {
  //     const dataBlob = new Blob([JSON.stringify(databaseData, null, 2)], {
  //       type: 'application/json',
  //     });
      // const url = URL.createObjectURL(dataBlob);
      // const link = document.createElement('a');
      // link.href = url;
      // link.download = 'database_data.json';
      // link.click();
      // var doc = new jsPDF();
      // databaseData.forEach(function(category,i){
      //   doc.text(5, 10 + (i * 50),
      //   // "Id:"+ category.id + "\n" +
      //   doc.addImage(category.data.image, 'JPG', 20, 60, 40, 40) + "\n" +
      //   " Category Name:"+ category.data.category_name
      //   );
      // });
      // doc.save("convertedPDFfromJSON.pdf")
  //     let doc = new jsPDF('l', 'mm', [1500, 1400]);
  //           let pdfjs = document.querySelector('#divID');
 
  //           doc.html(pdfjs, {
  //               callback: function(doc) {
  //                   doc.save("newpdf.pdf");
  //               },
  //               x: 12,
  //               y: 12
  //           });   
    
  //   }
  // };

  // const findAll = async () => {
  //   try {
  //     const shopname= localStorage.getItem('shopName');
  //     // console.log("cartegory name is:",shopname);
  //     const colRef = collection(db,`shops/${shopname}/categories`);
  //     const q = query(colRef);
  //     const unsub = onSnapshot(q, (snapshot) => {
  //       const categoriesName = snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }))
  //       setDatabaseData(categoriesName);
        
  //     })
  
      
  //   } catch (error) {
  //     console.log("Fetching error:", error);
  //   }
  // };
  
  // useEffect(() => {
  //   findAll();
  // }, []);

   
  const downloadData = () => {
    history.push('/download');
  }


  const filteredShop = users.filter((user) => {
    const shopLower = searchquery.toLowerCase();
    return(
      user.shop_name.toLowerCase().includes(shopLower) ||
      user.owner_name.toLowerCase().includes(shopLower)
    )
  })
  

  const noResults = filteredShop.length === 0;

  //close the modal
  const handleClose = () =>{
    setShow(false);
  }

  //close the update modal
  const handleEditClose = () =>{
    setIsEdit(false);
  }

//input onchange
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  //input onchange for update
  const handleUpdateInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateFormData({ ...updateFormData, [name]: value });
  };


//onchange function for currency
  const handleCurrencyChange = (selectedOption) => {
    setSelectedCurrency(selectedOption);
    console.log(selectedCurrency)
    setFormData({ ...formData, shop_currency: selectedOption.value });
  };


  //onchange function for currency for update
  const handleCurrencyUpdateChange = (selectedOption) => {
    console.log(selectedCurrency)
    setUpdateFormData({ ...updateFormData, shop_currency: selectedOption.value });
  };


//onchange function for file
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, image: file });
  };

  //onchange function for file for update
  const handleImageChangeUpdate = (e) => {
    const file = e.target.files[0];
    setUpdateFormData({ ...updateFormData, shop_image: file || updateFormData.shop_image});
  };

//post method for creating the shop
  const handleSubmit = async (e) => {
    console.log("update data before post",formData)
    e.preventDefault();  
    
    const created_by = localStorage.getItem('userId') || "";
    // Convert input1 and input2 to camelCase
        const camelCaseInput1 = capitalizeEachWord(formData.shop_name);
        const camelCaseInput2 = capitalizeEachWord(formData.owner_name);
        const camelCaseInput3 = capitalizeEachWord(formData.address);
        // Capitalize the first letter of state and countryName
        const capitalizedState = capitalize(formData.state);
        const capitalizedCountryName = capitalize(formData.country_name);
       

    const formDataToSend = new FormData();
    formDataToSend.append('shop_name', camelCaseInput1);
    formDataToSend.append('owner_name', camelCaseInput2);
    formDataToSend.append('address', camelCaseInput3);
    formDataToSend.append('state', capitalizedState);
    formDataToSend.append('country_name', capitalizedCountryName);
    formDataToSend.append('created_by', created_by); 
    formDataToSend.append('shop_image', formData.image);
    formDataToSend.append('shop_currency', formData.shop_currency);
    formDataToSend.append('shop_type',formData.shop_type);
    
  
     try {
      const response = await fetch(`${var_api}/api/shop/api/post`, {
        method: 'POST',
      body: formDataToSend,
    });
      if(response.status == 200){
        const data = await response.json();
        const user_id = localStorage.getItem('userId');
        const shop_id = data.insertedId;

        try {
          // Make POST request to your API endpoint
          const responsepaymode= await axios.post(`${var_api}/api/shop-paymode/post`, {
            user_id: parseInt(user_id),
            shop_id: parseInt(shop_id)
          });
          console.log(responsepaymode.data); // Log the response data
          setShow(false);
          // Handle success or update UI accordingly
        } catch (error) {
          console.error('Error:', error); // Log any errors
          // Handle error or show error message to the user
        }
       
        Swal.fire({
          title: 'Success',
          text: 'Shop Created Successfully.',
          icon: 'success',
          confirmButtonColor: '#007500', // Set the custom color for the Confirm button
        });
        setFormData({
          shop_name:'',
          owner_name:'',
          address:'',
          state:'',
          country_name:'',
          created_by:'',
          shop_image:null,  
          shop_currency:'',
          shop_type:null
        });
        setSelectedCurrency("");
         // Clear the value of the file input element
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    fetchData();
        const paymodedata = await response.json();
        console.log('Registration successful!',paymodedata);
     
      }else {
        console.error('Failed to create shop:', response.statusText);
        // Handle error, e.g., show an error message to the user
        Swal.fire({
          title: 'error',
          text: 'Failed to Create Shop...Please Try Again!',
          icon: 'error',
          confirmButtonColor: '#007500', // Set the custom color for the Confirm button
        });
      }
      
      // Handle success scenario as needed
    } catch (error) {
        console.error('Error:', error);
        // Handle error scenario as needed
      }
    };


    //reset form
    const handleResetShop = () => {
      setFormData({
        shop_name:'',
        owner_name:'',
        address:'',
        state:'',
        country_name:'',
        created_by:'',
        shop_image:null,  
        shop_currency:''  
      });
      setSelectedCurrency("");
       // Clear the value of the file input element
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    }

    //reset form for update
    const handleResetUpdateShop = () => {
      setUpdateFormData({
        shop_name:'',
        owner_name:'',
        address:'',
        state:'',
        country_name:'',
        created_by:'',
        shop_image:null,  
        shop_currency:''  
      });
      
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    }


    //post method for creating the shop
  const handleUpdateSubmit = async (e) => {
    console.log("update data before put",updateFormData)
    e.preventDefault(); 
    
      // Convert input1 and input2 to camelCase
      const camelCaseInput1 = capitalizeEachWord(updateFormData.shop_name);
      const camelCaseInput2 = capitalizeEachWord(updateFormData.owner_name);
      const camelCaseInput3 = capitalizeEachWord(updateFormData.address);
      // Capitalize the first letter of state and countryName
      const capitalizedState = capitalize(updateFormData.state);
      const capitalizedCountryName = capitalize(updateFormData.country_name);
    
   
    const formDataToSend = new FormData();
    formDataToSend.append('shop_name', camelCaseInput1);
    formDataToSend.append('owner_name', camelCaseInput2);
    formDataToSend.append('address', camelCaseInput3);
    formDataToSend.append('state', capitalizedState);
    formDataToSend.append('country_name', capitalizedCountryName);
    formDataToSend.append('created_by', updateFormData.created_by); 
    formDataToSend.append('shop_image', updateFormData.shop_image);
    formDataToSend.append('shop_currency', updateFormData.shop_currency);
    
  
     try {
      const response = await fetch(`${var_api}/api/shop/shop-put/${updateFormData.id}`, {
        method: 'PUT',
      body: formDataToSend,
    });
      if(response.status == 200){
        setIsEdit(false);
        Swal.fire({
          title: 'Success',
          text: 'Shop Updated Successfully.',
          icon: 'success',
          confirmButtonColor: '#007500', // Set the custom color for the Confirm button
        });
        const shop_id = parseInt(localStorage.getItem('shopId'));
        if(updateFormData.id == shop_id){
          console.log("this is the same",localStorage.getItem('shopName'),updateFormData.shop_name);
          localStorage.setItem('shopName',updateFormData.shop_name);
        }else{
          console.log("this is not a same");
        }
        setUpdateFormData({
          shop_name:'',
          owner_name:'',
          address:'',
          state:'',
          country_name:'',
          created_by:'',
          shop_image:null,  
          shop_currency:''  
        });
        setSelectedCurrency("");
         // Clear the value of the file input element
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    fetchData();
        const data = await response.json();
        console.log('Registration successful!',data);
     
      }else {
        console.error('Failed to update shop:', response.statusText);
        // Handle error, e.g., show an error message to the user
        Swal.fire({
          title: 'error',
          text: 'Failed to update Shop...Please Try Again!',
          icon: 'error',
          confirmButtonColor: '#007500', // Set the custom color for the Confirm button
        });
      }
      
      // Handle success scenario as needed
    } catch (error) {
        console.error('Error:', error);
        // Handle error scenario as needed
      }
    }


    const handleRadioChange = (event) => {
      const selectedValue = event.target.value;
      setFormData({
        ...formData,
        shop_type: selectedValue === 'foodShop' ? 1 : selectedValue === 'serviceBooking' ? 2 : null
      });
  
      if (selectedValue === 'foodShop') {
        // Save logic for Food Shop
        console.log('Selected Food Shop');
      } else if (selectedValue === 'serviceBooking') {
        // Save logic for Service Booking
        console.log('Selected Service Booking');
      }
    };

    return(
        <>
      <nav className="navbar navbar-expand-md navbar-light bg-white sticky-top" id="shopmnav">
  <div className="container-fluid">
    <Link to="/home" className="navbar-brand">
      <img src={Splash} alt="logo" style={{ marginLeft: "15px" }} />
    </Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
      aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <button type="button" className="btn btn-success" onClick={handleShop} style={{ backgroundColor: "green",marginRight:"5px" }}>
            <i className="bi bi-plus-square" style={{ color: "white", border: "none" }}></i>{" "}Add Shop
          </button>
        </li>
        <li className="nav-item">
          <button className="btn btn-success" style={{backgroundColor:"green",color:"white"}} onClick={handleButtonClick}>
            <i className="bi bi-house"></i> Go to home
          </button>
        </li>
      </ul>
    </div>
  </div>
</nav>


            
              <h1 className="hefosm">SHOP MASTER
              <span style={{float:"right"}}>
             <input type="text" placeholder="search" value={searchquery} onChange={(e) => setSearchQuery(e.target.value)} className="form-control" />
              </span>
              </h1>
              
            
{
  loading ? (
    <div className="shop-container">
      <div className="shopinner"></div>
    </div>
  ) : (


             
             
  <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-4 m-0" style={{padding:"0px 0px",margin:"auto"}}> 
  {
    noResults ? (
      <h5>No Shops Found</h5>
    ) : (
 filteredShop.map((user,index) => ( 
            <div class="col m-0 mb-2">
            <div className="card p-2" id="shopmcard">
           
            <img className="card-img-left" src={`${var_api}/${user.shop_image}`} alt={`Card ${index + 1}`}  id="shomcardimg"/>
            <div   style={{textAlign:"center",height:"120px",padding:"5px"}}>
              <h4 className="card-title" id="shopmcardtitle"><b>{user.shop_name}</b></h4>
              <div style={{margin:"auto",float:"left"}}>
              <p id="shopmname"><i class="bi bi-person-workspace" style={{fontSize:"18px"}}></i>{" "}Owner Name:{" "} <span style={{fontWeight:"normal",color:"black"}}>{user.owner_name}</span></p><br/>
              <p className="card-text" id="cardTest"><i class="bi bi-geo-alt-fill" style={{fontSize:"18px"}}></i>{" "}Address:{" "}<span style={{fontWeight:"normal",color:"black"}}>{user.address}-{" "}{user.state}</span> </p><br/>
              <p className="card-text" id="cardTest"><i class="bi bi-pin-map-fill" style={{fontSize:"18px"}}></i>{" "}Country:{" "}<span style={{fontWeight:"normal",color:"black"}}>{user.country_name}</span></p><br/>
              <p className="card-text" id="cardTest"><i  class="bi bi-shop" style={{fontSize:"18px"}}></i>{" "}Shop Type:{" "}<span style={{fontWeight:"normal",color:"black"}}>{user.shop_type === 1 ? <b style={{color:"blue"}}>Food Shop</b> :  <b style={{color:"orange"}}>Service Booking</b>}</span></p>
              </div>
            </div>
            <br/>
            <br/>
            <Tooltip
        isOpen={puttooltipOpen[index]} // Use tooltip state based on index
        target={`putButton${index}`} // Specify the target ID of the button
        toggle={() => togglePutTooltip(index)}
      >
          Edit Shop
        </Tooltip>
            
            <i class="bi bi-pencil-square"  id={`putButton${index}`} onClick={() => {handleEditShop(user); togglePutTooltip(index);}} style={{fontSize:"20px",marginLeft:'250px'}}></i>
          </div>
            </div>
        
  )))}

  
  

<Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title> Add Shop </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form  className="py-2" onSubmit={handleSubmit}>
          <div class="row">
            <div class="col">
               <label for="input1" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Shop Name</label>
              <input type="text" class="form-control" placeholder="enter shop name" id="input1"  name="shop_name"  value={formData.shop_name}  onChange={handleInputChange} style={{borderRadius:"12px"}} required/>
            </div>
            <div class="col">
              <label for="input2" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Owner Name</label>
              <input type="text" class="form-control" placeholder="enter owner name" id="input2" name="owner_name" value={formData.owner_name} onChange={handleInputChange} style={{borderRadius:"12px"}} required/>
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col">
               <label for="input3" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Address</label>
              <input type="text" class="form-control" placeholder="enter address" id="input3" name="address" value={formData.address} onChange={handleInputChange} style={{borderRadius:"12px"}} required/>
            </div>
            <div class="col">
              <label for="input4" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>State</label>
              <input type="text" class="form-control" placeholder="enter state" id="input4" name="state" value={formData.state} onChange={handleInputChange} style={{borderRadius:"12px"}} required/>
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col">
               <label for="input5" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Country</label>
              <input type="text" class="form-control" placeholder="enter country" id="input5" name="country_name" value={formData.country_name} onChange={handleInputChange} style={{borderRadius:"12px"}} required/>
            </div>
            <div class="col">
              <label for="input6" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Currency</label>
              <Select
    options={currencyOptions}
      placeholder="select currency"
      isSearchable={true}
      value={selectedCurrency}
      onChange={handleCurrencyChange}
      required
      styles={{
        // Styles for increasing width and height
        control: (provided, state) => ({
          ...provided,
          borderRadius: '15px'
        }),
      }}
  />
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col">
            <label for="formFile" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Shop Image</label>
              <input ref={fileInputRef} class="form-control" type="file" id="formFile" onChange={handleImageChange} style={{borderRadius:"12px"}} />
            </div>
            <div class="col">
            <label for="formFile" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Shop Type</label>
            <br/>
              <div class="form-check" style={{display:"inline-block"}}>
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="foodShop"
          checked={formData.shop_type === 1}
          onChange={handleRadioChange} required/>
                <label class="form-check-label" for="flexRadioDefault1">
                  Food Shop
                </label>
              </div>
              <div class="form-check" style={{display:"inline-block", marginLeft:"70px"}}>
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"  value="serviceBooking"
          checked={formData.shop_type === 2}
          onChange={handleRadioChange} required/>
                <label class="form-check-label" for="flexRadioDefault2">
                  Sevice Booking
                </label>
              </div>
              
            </div>
           
          </div>
          <br/>
          <button className="btn btn-success" style={{float:"right",margin:"5px",borderRadius:"15px"}}>Submit</button>
          </form>
          <button className="btn btn-secondary" onClick={handleResetShop} style={{borderRadius:"15px",float:"right",marginTop:"-1px"}}>Reset</button>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>


      <Modal
        show={isEdit}
        onHide={handleEditClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title> Update Shop </Modal.Title>
        </Modal.Header>
        <Modal.Body>
       {
       updateFormData && 
       <form  className="py-2" onSubmit={handleUpdateSubmit}>
       <div class="row">
         <div class="col">
            <label for="input1" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Shop Name</label>
           <input type="text" class="form-control" placeholder="enter shop name" id="input1"  name="shop_name"  value={updateFormData.shop_name}  onChange={handleUpdateInputChange} style={{borderRadius:"12px"}} required/>
         </div>
         <div class="col">
           <label for="input2" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Owner Name</label>
           <input type="text" class="form-control" placeholder="enter owner name" id="input2" name="owner_name" value={updateFormData.owner_name} onChange={handleUpdateInputChange} style={{borderRadius:"12px"}} required/>
         </div>
       </div>
       <br/>
       <div class="row">
         <div class="col">
            <label for="input3" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Address</label>
           <input type="text" class="form-control" placeholder="enter address" id="input3" name="address" value={updateFormData.address} onChange={handleUpdateInputChange} style={{borderRadius:"12px"}} required/>
         </div>
         <div class="col">
           <label for="input4" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>State</label>
           <input type="text" class="form-control" placeholder="enter state" id="input4" name="state" value={updateFormData.state} onChange={handleUpdateInputChange} style={{borderRadius:"12px"}} required/>
         </div>
       </div>
       <br/>
       <div class="row">
         <div class="col">
            <label for="input5" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Country</label>
           <input type="text" class="form-control" placeholder="enter country" id="input5" name="country_name" value={updateFormData.country_name} onChange={handleUpdateInputChange} style={{borderRadius:"12px"}} required/>
         </div>
         <div class="col">
           <label for="input6" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Currency</label>
           <Select
 options={currencyOptions}
   placeholder="select currency"
   isSearchable={true}
   value={{label: updateFormData.shop_currency, value: updateFormData.shop_currency}}
   onChange={handleCurrencyUpdateChange}
   required
   styles={{
     // Styles for increasing width and height
     control: (provided, state) => ({
       ...provided,
       borderRadius: '15px'
     }),
   }}
/>
         </div>
       </div>
       <br/>
       <div class="row">
         <div class="col">
         <label for="formFile" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>shop Image</label>
           <input ref={fileInputRef} class="form-control" type="file" id="formFile" onChange={handleImageChangeUpdate} style={{borderRadius:"12px"}} />
         </div>
         <div class="col">
         <label for="formFile" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>shop Type</label>
         <input className="form-control" type="text" id="shoptype" style={{ borderRadius: "12px" }} value={updateFormData.shop_type === 1 ? "Food Shop" : "Service Booking"} disabled />

         </div>
        
       </div>
       <br/>
       <button className="btn btn-success" style={{float:"right",margin:"5px",borderRadius:"15px"}}>Update</button>
       </form> 
       }
          <button className="btn btn-danger" onClick={handleEditClose} style={{borderRadius:"15px",float:"right",marginTop:"-1px"}}>Cancel</button>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>



     
 

  

</div>
  )
}
    
        </>
    );
}


export default ShopMaster;