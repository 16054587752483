import React from 'react';
import './employee-master.css';
import profile from '../../images/splash/user.png';
import { useState,useEffect } from 'react';
import axios from 'axios';
import { var_api } from '../constant';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { Tooltip } from 'reactstrap';


const EmployeeMaster = () => {
  const history = useHistory();
  const [show, setShow]  = useState(false);
  const [tables, setTables] = useState([]);
  const [shops, setShops] = useState([]);
  const [selectedShop, setSelectedShop] = useState(null);
  const [ searchQuery,setSearchQuery] = useState('');
  const [selectedItemCode, setSelectedItemCode] = useState(null);
  const [ isEdit,setIsEdit ] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ postloading,setPostLoading ] = useState(false);
  const [putLoader, setPutLoader] = useState(false);
  const ItemCodeOptions = [

    { value: 'Chef', label: 'Chef' },
    { value: 'Captain', label: 'Captain' },
    { value: 'Waiter', label: 'Waiter' },
    // Add more options as needed
  ];
  
  const [EmployeeD, setEmployeeD] = useState({});
  const [puttooltipOpen, setPutToolTipOpen] = useState([]);
  const [deletetooltipOpen, setDeleteToolTipOpen] = useState([]);
  const [formData, setFormData] = useState({
    e_name: "", 
    e_email: null, 
    e_password: null,
    e_phone: null,
    e_role: "",
    profile: null,
    shops: [],
    user_id: localStorage.getItem("userId")
});
const [updateformData, setUpdateFormData] = useState({
  id:null,
  e_name: "", 
  e_email: null, 
  e_password: null,
  e_phone: null,
  e_role: "",
  profile: null,
  shops: []
});

console.log(formData)

//change the role
  const handleCodeSelectChange = (selectedItem) => {
    console.log("selectedItem",selectedItem)
    const role = selectedItem.value
    setFormData({ ...formData, e_role: role });
  };


  const togglePutTooltip = (index) => {
    const putTooltipOpen = [...puttooltipOpen];
    putTooltipOpen[index] = !putTooltipOpen[index];
    setPutToolTipOpen(putTooltipOpen);
  };


  const toggleDeleteTooltip = (index) => {
    const deleteTooltipOpen = [...deletetooltipOpen];
    deleteTooltipOpen[index] = !deleteTooltipOpen[index];
    setDeleteToolTipOpen(deleteTooltipOpen);
  };

  
//change the shop
const handleShopSelectChange = (selectedItem) => {
  console.log("selectedItem",selectedItem)
  const role = selectedItem.value;
  setSelectedShop(role);
  fetchTables(role);
};

    useEffect(() => {
        fetchTables();
        fetchShops();
      }, []);


      const fetchTables = async (id) => {
        const userId = localStorage.getItem('userId');
        console.log(id)
        setLoading(true);
        const shopId = id === undefined ? 0 : id;
        try {
           const shop = localStorage.getItem('shopId')
           const response = await axios.get(`${var_api}/api/employees/get-emplyees/${userId}/${shopId}`);
           setLoading(false);
           console.log("response",response)
          const user =response.data.results;
          setTables(user);
          
        } catch (error) {
          console.log(error);
          setLoading(false);
          setTables([])
        }
      };
      

      const fetchShops = async () => {
        try {
           const shop = localStorage.getItem('userId')
           const response = await axios.get(`${var_api}/api/shop/data/${shop}`);
           console.log("response",response)
          const user =response.data;
          setShops(user);
          setFormData(prevState => ({
            ...prevState,
            shops: user.map(shop => ({ shop_id: shop.id, shop_name: shop.shop_name, is_active: 0 }))
          }));
        } catch (error) {
          console.log(error);
        }
      };

      const shopCodeOptions = [
        { value: 0, label: 'All' },
        ...shops.map(shop => ({
          value: shop.id,
          label: shop.shop_name
        }))
      ];
      
      

     //search the transaction data
     const filteredSuppliers = tables && tables.filter((order) => {
      const searchLower = searchQuery.toLowerCase();
      return (
        (order.e_name && order.e_name.toString().toLowerCase().includes(searchLower)) ||
        (order.e_email && order.e_email.toString().toLowerCase().includes(searchLower))  ||
        (order.e_phone && order.e_phone.toString().toLowerCase().includes(searchLower))  ||
        (order.e_role && order.e_role.toString().toLowerCase().includes(searchLower)) 
      );
    });

//handle image change
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        console.log("files",file);
        setFormData({ ...formData, profile: file });
      };

//handle text field change
const handleInputChange = (event) => {
  const { name, value } = event.target;
  setFormData({
    ...formData,
    [name]: value
  });
};


 // Function to handle checkbox change
 const handleCheckboxChange = (index) => {
  const updatedShops = [...formData.shops];
  const selectedShop = updatedShops[index];
  
  // Toggle is_active property
  updatedShops[index] = {
      ...selectedShop,
      is_active: selectedShop.is_active === 1 ? 0 : 1
  };

  setFormData(prevState => ({
      ...prevState,
      shops: updatedShops
  }));
};


  // Function to capitalize the first letter of a string
  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
   };
  

      //post the form data
    const Submit = async (e) => {
      e.preventDefault();
      setPostLoading(true); 

      try {

        const camelCaseEName = capitalize(formData.e_name);

        const formDataToSend = new FormData();
        // Append all form fields to the FormData object
        formDataToSend.append('e_name', camelCaseEName);
        formDataToSend.append('e_email', formData.e_email);
        formDataToSend.append('e_password', formData.e_password);
        formDataToSend.append('e_phone', formData.e_phone);
        formDataToSend.append('e_role', formData.e_role);
        formDataToSend.append('profile', formData.profile);
        formDataToSend.append('user_id', formData.user_id);
    
        // Append shop data (if any)
        formData.shops.forEach((shop, index) => {
          formDataToSend.append(`shops[${index}][shop_name]`, shop.shop_name);
          formDataToSend.append(`shops[${index}][shop_id]`, shop.shop_id);
          formDataToSend.append(`shops[${index}][is_active]`, shop.is_active);
        });
        

        const response = await axios.post(`${var_api}/api/employees/post`, formDataToSend, {
            headers: {
                'Content-Type': 'multipart/form-data' // Set content type to multipart/form-data
            }
        });
        const result = response.data;
        console.log("rrrr",result);
        if(result.message === "Employee created successfully"){
          fetchShops();
          fetchTables();
          setPostLoading(false); 
          Swal.fire({
            icon:"success",
            text:"Employee Created Successfully",
            confirmButtonColor:"#007500"
          })

          setFormData({...formData,
            e_name: "", 
            e_email: " ", 
            e_password: "",
            e_phone: "",
            e_role: "",
            profile: " "
          })
          

        }
        // Handle successful response (e.g., show a success message to the user)
      } catch (error) {
        // Handle request failure
    if (error.response) {
      setPostLoading(false); 
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('Server responded with error status:', error.response.status);
      if (error.response.data && error.response.data.message) {
       setPostLoading(false); 
        Swal.fire({
          icon:"error",
          text: error.response.data.message,
          confirmButtonColor:"#007500"
        })
      } else {
        alert('An error occurred. Please try again later.');
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received from the server:', error.request);
      alert('No response received from the server. Please try again later.');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error occurred while setting up the request:', error.message);
      alert('An error occurred. Please try again later.');
    }
        
      }
    };


    //reset the values
    const handleToReset = () => {
         // Reset the form fields individually
         setFormData({
          ...formData,
          e_name: " ", 
  e_email: "", 
  e_password:" ",
  e_phone:"",
  e_role:"",
  profile:null
      });
    }

    //Go to home page
    const handleButtonClick = () => {
      history.push('/home')
    }


    //get the value in search and set the setstate
    const handleSearchInput = (e) => {
      setSearchQuery(e.target.value)
      };


      

     //delete method for remove UOM
   const handleDeleteEmployeee = (id,e_name) => {
    setShow(true);
    setEmployeeD({id:id,employee_name: e_name})
    }


    //delete the employeee
    const handleDelete = () => {
      axios
      .delete(`${var_api}/api/employees/emp-delete/${EmployeeD.id}`)
      .then((response) => {
        if (response.status === 200) {
          fetchTables();
          setShow(false);
          Swal.fire({ icon:"success",
          text: " Employee Removed Successfully!",
          confirmButtonColor:"#007500"})
          // Perform any other actions you need on success
        } else if (response.status === 404) {
          console.log('Table not found');
          Swal.fire("error","Employee not found!","error")
        }
      })
      .catch((error) => {
        console.error('Error executing the request:', error);
        Swal.fire("error","Failed to Remove Employee!","error")
        // Handle the error as needed
      });
    }


    //Function for open the update modal
    const handleUpdateEmployee = (data) =>{
      setIsEdit(true);
      console.log("data",data)
      setUpdateFormData({
          id:data.id,
          e_name: data.e_name, 
          e_email: data.e_email, 
          e_password: data.e_password,
          e_phone: data.e_phone,
          e_role: data.e_role,
          profile: data.e_profile,
          shops: data.shops
      })
      console.log("shopssssssssss",data.shops)
    }

    //Function for update text field onchange
const handleInputUpdateChange = (e) => {
  const { name, value } = e.target;
  setUpdateFormData({ ...updateformData, [name]: value });
};


//Oncahnge function for update role
const handleRoleUpdateChange = (selectedOption) => {
  console.log("hjdahj",selectedOption.value)
  setUpdateFormData({ ...updateformData, e_role: selectedOption.value});
};

//onchange function for file 
const handleImageUpdateChange = (e) => {
  const file = e.target.files[0];
  console.log("file",file)
  setUpdateFormData({ ...updateformData, profile: file });
};


 // Function to handle update checkbox change
 const handleUpdateCheckboxChange = (index) => {
  const updatedShops = [...updateformData.shops];
  const selectedShop = updatedShops[index];
  
  // Toggle is_active property
  updatedShops[index] = {
      ...selectedShop,
      is_active: selectedShop.is_active === 1 ? 0 : 1
  };
  console.log("upd",updatedShops);
   // Check if at least one shop is active
   const atLeastOneActive = updatedShops.some(shop => shop.is_active === 1);

   // If no shop is active, prevent toggling the checkbox and alert the user
   if (!atLeastOneActive && updatedShops[index].is_active === 0) {
    setShowWarning(true);
    return;
  } else {
    setShowWarning(false); // Hide the warning message if at least one shop is active
  }
 

  setUpdateFormData(prevState => ({
      ...prevState,
      shops: updatedShops
  }));
};




//update method for employee
const handleUpdateSubmit = async (e) => {
  e.preventDefault();  
  console.log("updateformData",updateformData);
 setPutLoader(true);
  
  const camelCaseEName = capitalize(updateformData.e_name);

  const formDataToSend = new FormData();
  formDataToSend.append('e_name', camelCaseEName);
  formDataToSend.append('e_email', updateformData.e_email);
  formDataToSend.append('e_password', updateformData.e_password);
  formDataToSend.append('e_phone', updateformData.e_phone);
  formDataToSend.append('e_role', updateformData.e_role);
  formDataToSend.append('profile', updateformData.profile); 

   // Append shop data (if any)
   updateformData.shops.forEach((shop, index) => {
    formDataToSend.append(`shops[${index}][shop_name]`, shop.shop_name);
    formDataToSend.append(`shops[${index}][shop_id]`, shop.shop_id);
    formDataToSend.append(`shops[${index}][is_active]`, shop.is_active);
    formDataToSend.append(`shops[${index}][id]`, shop.id);
  });
  

   try {
    const response = await fetch(`${var_api}/api/employees/emp-put/${updateformData.id}`, {
      method: 'PUT',
    body: formDataToSend,
  });
    if(response.status == 200){
      fetchTables();
      setIsEdit(false);
      setPutLoader(false);
      Swal.fire({
        title: 'Success',
        text: 'Employee Updated Successfully.',
        icon: 'success',
        confirmButtonColor: '#007500', // Set the custom color for the Confirm button
      });
      
      const data = await response.json();
      console.log('Registration successful!',data);
   
    }else {
      console.error('Failed to Update employee:', response.statusText);
      // Handle error, e.g., show an error message to the user
      Swal.fire({
        title: 'error',
        text: 'Failed to Update employee...Please Try Again!',
        icon: 'error',
        confirmButtonColor: '#007500', // Set the custom color for the Confirm button
      });
      setPutLoader(false);
    }
    
    // Handle success scenario as needed
  } catch (error) {
      console.error('Error:', error);
      // Handle error scenario as needed
      setPutLoader(false);
    }
  };

 


    return(
        <div className='whole' style={{backgroundColor:"white"}}>
            <div class="split left">
                <div class="centered">
                    <h3 className='ushe'>EMPLOYEE MASTER</h3>
                    <div style={{marginLeft:"12px",padding:"12px"}}>

                    <form onSubmit={Submit}>
                        <div class="form-floating mb-3" style={{width:"350px",margin:"auto"}}>
                            <input type="text" class="form-control" id="floatingInput" placeholder="name" name='e_name' value={formData.e_name} onChange={handleInputChange}  style={{borderRadius:"15px",height:"50px"}} required/>
                            <label for="floatingInput">Employee Name</label>
                        </div>
                        <div class="form-floating mb-3" style={{width:"350px",margin:"auto"}}>
                            <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" name='e_email' value={formData.e_email} onChange={handleInputChange} style={{borderRadius:"15px",height:"50px"}} required/>
                            <label for="floatingInput">Email Address</label>
                        </div>
                        <div class="form-floating mb-3" style={{width:"350px",margin:"auto"}}>
                            <input type="text" class="form-control" id="floatingInput" placeholder="password" name='e_password' value={formData.e_password} onChange={handleInputChange} style={{borderRadius:"15px",height:"50px"}} required />
                            <label for="floatingInput">Password</label>
                        </div>
                        <div class="form-floating mb-3" style={{width:"350px",margin:"auto"}}>
                            <input type="number" class="form-control" id="floatingInput" placeholder="phone" name='e_phone' value={formData.e_phone} onChange={handleInputChange} style={{borderRadius:"15px",height:"50px"}} required/>
                            <label for="floatingInput">Phone No</label>
                        </div>
                        <label for="floatingInput" style={{marginLeft:"10px"}}>Select Role</label>
                        <div class="form-floating mb-3" style={{width:"350px",margin:"auto",zIndex:"2"}}>
                        <Select
                        options={ItemCodeOptions}
                        onChange={handleCodeSelectChange}
                        isSearchable={true}
                        className="customSelect mb-3"
                        styles={{
                          // Styles for increasing width and height
                          control: (provided, state) => ({
                            ...provided,
                            width: '350px', // Adjust width as needed
                            minHeight: '50px', // Adjust height as needed
                            borderRadius: '15px'
                          }),
                        }}
                         />
                         </div>

                         <div class="card mb-3" style={{width: "22rem",margin:"auto",height:"10rem",borderRadius:"15px",border:"none",overflowX:"scroll",zIndex:"1"}}>
                          <div class="card-body">
                            <h5 class="card-title" style={{fontSize:"14px",color:"green"}}>Select Shop</h5>
                            {formData.shops.map((shop, index) => (
        <div className="form-check" key={index}>
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id={`flexCheckDefault_${index}`}
            onChange={() => handleCheckboxChange(index)}
            checked={shop.is_active === 1}
          />
          <label className="form-check-label" htmlFor={`flexCheckDefault_${index}`}>{shop.shop_name}</label>
        </div>
      ))}   
                           
                          </div>
                         </div>

                        <div class="form-floating mb-3" style={{width:"350px",margin:"auto"}}>
                            <input type="file" class="form-control" id="formFile" onChange={handleImageChange} name='profile'  style={{borderRadius:"15px",height:"50px"}} />
                        </div>
                        <div class="text-center">
          {postloading ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
           
          />
        ) : (
          <button className='btn btn-success' style={{float:"right"}} disabled={postloading}>Create Employee</button>
        )}
        </div>       
            
            </form>
            
            <button className='btn btn-secondary' style={{float:"right",marginRight:"3px"}} onClick={handleToReset} >Reset</button> {' '}
                       
                        
                    </div>
                   
                </div>
            </div>

            <div class="split right">
            <nav className="navbar" 
            style={{boxShadow: "0px 0px 1px 0px black",backgroundColor:"green",border:"none",padding:"5px",height:"50px",width:"1065px",position:"relative",top:"-10px"}} >
<div style={{ display: "flex"}}>
                <h3 style={{ fontFamily: "cursive", fontWeight: "bolder",marginLeft: "400px", color: "white" }}>Employee List</h3>
                <button className="btn" onClick={handleButtonClick} style={{marginLeft:"300px",backgroundColor:"white",color:"green"}}
         ><i class="bi bi-house"></i> Go to Home</button>
            </div>
      </nav>
      <div style={{border:"none",padding:"20px",width:"1020px",height:"640px",margin:"auto",borderRadius:"15px",backgroundColor:"white",boxShadow:" rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px"}}>
      
      <div>
       <h5 style={{fontFamily:"serif",fontWeight:"bolder"}}>Total Employees: <span style={{color:"red"}}>{filteredSuppliers ? filteredSuppliers.length : 0}</span></h5> 
     
       <div class="row">
         <div class="col">
        
         </div>
         <div class="col">
           
         </div>
         <div class="col">
      <Select
        options={shopCodeOptions}
        placeholder="Select Shop"
        defaultValue={{value:0,label:"All"}}
        onChange={handleShopSelectChange}
        isSearchable={true}
        className="customSelect mb-3"
        styles={{
          // Styles for increasing width and height
          control: (provided, state) => ({
              ...provided,
              borderRadius: '20px',
              border:"1px solid black"
            }),
        }}
      />
         </div>
         <div class="col">
         <input class="form-control" type="Search" placeholder="Search" id="example-search-input" 
      style={{width:"230px",borderRadius:"20px",border:"1px solid black",float:"right"}} onChange={handleSearchInput}/>
         </div>
        
       </div>
    <br/>
      <div className="table-container" style={{ height:"500px", overflowX: 'auto', overflowY: 'scroll' }}>
     <table class="table table-hover small-first-col" style={{border:"none",borderColor:"grey",width:"90%",margin:"auto"}}>
  <thead>
    <tr style={{fontWeight:"bolder"}}>
      <th style={{textTransform:"capitalize",textAlign:"center"}} scope="col">S.No</th>
      <th style={{textTransform:"capitalize",textAlign:"center"}} scope="col">Profile</th>
      <th style={{textTransform:"capitalize",textAlign:"center"}} scope="col">Name</th>
      <th style={{textTransform:"capitalize",textAlign:"center"}} scope="col">Role</th>
      <th style={{textTransform:"capitalize",textAlign:"center"}} scope="col">Email</th>
      <th style={{textTransform:"capitalize",textAlign:"center"}} scope="col">Mobile No</th>
      <th style={{textTransform:"capitalize",textAlign:"center"}} scope="col">Action</th>
    </tr>
  </thead>
  {loading ? (
        <div className="loader-containeru">
      	  <div className="spinneru"></div>
        </div>
  ) : 
  <tbody class="body-half-screen">
   {filteredSuppliers && filteredSuppliers.length > 0 ? (
   filteredSuppliers && filteredSuppliers.map((data,index) =>(

    <tr style={{padding:"7px"}}>
      <th scope="row">{index+1}</th>
      <td style={{textTransform:"capitalize",textAlign:"center"}}><img src={`${var_api}/${data.e_profile}`}  alt='profile' width="65px" height="60px" /></td>
      <td style={{textTransform:"capitalize",textAlign:"center"}}>{data.e_name}</td>
      <td style={{textTransform:"capitalize",textAlign:"center"}}>
      <span class={data.e_role === "Admin" ? "badge rounded-pill bg-danger p-2": data.e_role === "Chef" ? "badge rounded-pill bg-success p-2" :data.e_role === "Captain" ? "badge rounded-pill bg-warning p-2" : "badge rounded-pill bg-info p-2"}>
        {data.e_role === "Admin" ? <i class="bi bi-person-fill-check" style={{fontSize:"18px"}}></i> : null}{" "}
        {data.e_role}</span>
      </td>
      <td style={{textAlign:"center"}}>{data.e_email}</td>
      <td>{data.e_phone}</td>
      <td>
        {data.e_role === "Admin" ? null : (
      <>
       <Tooltip
        isOpen={puttooltipOpen[index]} // Use tooltip state based on index
        target={`putButton${index}`} // Specify the target ID of the button
        toggle={() => togglePutTooltip(index)}
        placement='bottom'
      >
          Edit Employee
        </Tooltip>
      <i class="bi bi-pencil-square" id={`putButton${index}`}  onClick={()=>{handleUpdateEmployee(data); togglePutTooltip(index);}}   style={{fontSize:"20px",color:"rgb(9, 97, 9)"}}></i>{' '}
      <Tooltip
        isOpen={deletetooltipOpen[index]} // Use tooltip state based on index
        target={`deleteButton${index}`} // Specify the target ID of the button
        toggle={() => toggleDeleteTooltip(index)}
        placement='bottom'
      >
          Delete Employee
        </Tooltip>
      <i class="bi bi-trash" id={`deleteButton${index}`} onClick={()=>{handleDeleteEmployeee(data.id,data.e_name); toggleDeleteTooltip(index);}}   style={{fontSize:"21px",color:"rgb(175, 2, 2)"}}></i>
      </>
      )}
      </td>
    </tr>
   ))
   ) : (
    <tr>
      <td colSpan="9" style={{ textAlign: "center", color:"red", fontWeight:"bold", borderColor:"black" }}>
        Employess Not Found
      </td>
    </tr>
   )}
 

   </tbody>}

</table>

      </div>
      

</div>
        </div>
      
      </div>

      <Modal
        show={show}
        onHide={()=>setShow(false)}
        backdrop="static"
        keyboard={false}
        size="md" // Set size to large
      >
        <Modal.Header closeButton style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"22px"}}>
          {/* <Modal.Title>confirmation</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>

         <div style={{padding:"20px"}}>
         <h4 style={{fontFamily:"monospace",fontWeight:"bolder",textAlign:"center"}}>CONFIRM !</h4>
          <h6 className='text-dark' style={{textAlign:"center",fontWeight:"bolder"}}>Are You Sure want to Delete Employee Name 
          <span style={{color:"red",textTransform:"uppercase"}}> {EmployeeD.employee_name}</span> ?</h6> 
         </div>
         {' '} 
          <Button variant="danger" style={{float:"right"}} onClick={()=>setShow(false)}>
            Close
          </Button>{' '} 
          <Button variant="success" style={{float:"right",marginRight:"5px"}} onClick={handleDelete}>
            Confirm
          </Button>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirmation}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal
        show={isEdit}
        onHide={()=>setIsEdit(false)}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title> Update Employee </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form  className="py-2" onSubmit={handleUpdateSubmit}>
          <div class="row">
            <div class="col">
               <label for="input1" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Employee Name</label>
              <input type="text" class="form-control" placeholder="enter employee name" id="input1" value={updateformData.e_name}  name="e_name"  onChange={handleInputUpdateChange}  style={{borderRadius:"12px"}} required/>
            </div>
            <div class="col">
              <label for="input2" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Employee Email</label>
              <input type="email" class="form-control" placeholder="enter employee email" id="input2" name="e_email" value={updateformData.e_email} onChange={handleInputUpdateChange}  style={{borderRadius:"12px"}} required/>
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col">
               <label for="input3" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Password</label>
              <input type="text" class="form-control" placeholder="enter password" id="input3"  name="e_password" value={updateformData.e_password} onChange={handleInputUpdateChange} style={{borderRadius:"12px"}} required/>
            </div>
            <div class="col">
              <label for="input4" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Phone No</label>
              <input type="number" class="form-control" placeholder="enter phone no" id="input4" name="e_phone" value={updateformData.e_phone} onChange={handleInputUpdateChange} style={{borderRadius:"12px"}} required/>
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col" style={{zIndex:"2"}}>
               <label for="input5" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Role</label>
               <Select
                        options={ItemCodeOptions}
                        placeholder="Select Role"
                        isSearchable={true}
                        className="customSelect mb-3"
                        defaultValue={{ value: updateformData.e_role, label: updateformData.e_role }}
                        onChange={handleRoleUpdateChange}
                        styles={{
                          // Styles for increasing width and height
                          control: (provided, state) => ({
                            ...provided,
                            width: '370px', // Adjust height as needed
                            borderRadius: '12px'
                          }),
                        }}
                         />
            </div>
            <div class="col">
            <label for="input5" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Employee Image{" "}</label>
              <input type="file" class="form-control" id="formFile"  name='profile'  style={{borderRadius:"12px"}} onChange={handleImageUpdateChange} />
            </div>
          </div>
          <div class="row">
            <div class="col">
          <div class="card mb-3" style={{width: "22rem",height:"10rem",borderRadius:"15px",border:"none",overflowX:"scroll",float:"left",zIndex:"1"}}>
                          <div class="card-body">
                            <h5 class="card-title" style={{fontSize:"14px",color:"green"}}>Select Shop</h5>
                            {showWarning && (
        <div className="alert alert-warning" role="alert">
          At least one shop must be selected.
        </div>
      )}
                            {updateformData.shops.map((shop, index) => (
        <div className="form-check" key={index}>
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id={`flexCheckDefaultupdate_${index}`}
            onChange={() => handleUpdateCheckboxChange(index)}
            checked={shop.is_active === 1}
          />
          <label className="form-check-label" htmlFor={`flexCheckDefaultupdate_${index}`}>{shop.shop_name}</label>
        </div>
      ))}   
                           
                          </div>
                         </div>
       </div>
       <div class="col">
       </div> 
       </div>
         <br/>
         {
              putLoader ? (
               
                    <div style={{float:"right",margin:"5px"}}>
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>)
                : 
          <button className="btn btn-success" style={{float:"right",margin:"5px",borderRadius:"15px"}}>
            Update
            </button>
}
          </form>
          <button className="btn btn-danger" onClick={()=>setIsEdit(false)} style={{borderRadius:"15px",float:"right",marginTop:"-1px"}}>Cancel</button>
        </Modal.Body>
      </Modal> 



            </div>
       

        
    )
}

export default EmployeeMaster;