import React from 'react';
import './adminSupplierMaster.css';
import profile from '../../images/splash/user.png';
import { useState,useEffect } from 'react';
import axios from 'axios';
import { var_api } from '../constant';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';


const AdminTableMaster = () => {
  const history = useHistory();
  const shopName= localStorage.getItem('shopName');
  const [ postloading,setPostLoading ] = useState(false);
  const [formData, setFormData] = useState({
    table_no: null, 
    no_of_seats: null, 
    shop_id: localStorage.getItem('shopId') || "", 
    is_active: 0, 
    table_image:null,
  });
   const [tables, setTables] = useState([]);
   const [ searchQuery,setSearchQuery] = useState('');

    useEffect(() => {
        fetchTables();
      }, []);


      const fetchTables = async () => {
        try {
           const shop = localStorage.getItem('shopId')
           const response = await axios.get(`${var_api}/api/table-details/get-table/${shop}`);
           console.log("response",response)
          const user =response.data;
          setTables(user);
        } catch (error) {
          console.log(error);
        }
      };
      
      

     //search the transaction data
     const filteredSuppliers = tables.filter((order) => {
      const searchLower = searchQuery.toLowerCase();
      return (
        (order.table_no && order.table_no.toString().toLowerCase().includes(searchLower)) ||
        (order.no_of_seats && order.no_of_seats.toString().toLowerCase().includes(searchLower)) 
      );
    });

//handle image change
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setFormData({ ...formData, table_image: file });
      };

//handle text field change
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };


      //post the form data
    const Submit = async (e) => {
        e.preventDefault();  
        setPostLoading(true);

        const formDataToSend = new FormData();
        formDataToSend.append('table_no', formData.table_no);
        formDataToSend.append('no_of_seats', formData.no_of_seats);
        formDataToSend.append('table_image', formData.table_image);
        formDataToSend.append('is_active', formData.is_active);
        formDataToSend.append('shop_id', formData.shop_id);
        
      
         try {
          const response = await fetch(`${var_api}/api/table-details/post`, {
            method: 'POST',
          body: formDataToSend,
        });
          if(response.status == 200){
            const data = await response.json();
            console.log('Registration successful!',data);
            setPostLoading(false);
           // Reset the form fields
           setFormData({
            ...formData,
            table_no: "",
            no_of_seats: "",
            table_image: "", // Assuming you want to clear the file input
        });
              Swal.fire({
                icon:"success",
                text:'Table Created Successfully',
                confirmButtonColor:"#007500"
              })
              fetchTables();
         
          }else {
            console.error('Failed to create shop:', response.statusText);
            // Handle error, e.g., show an error message to the user
            setPostLoading(false);
            Swal.fire({
                icon:"error",
                text:'Failed to Create Table',
                confirmButtonColor:"#007500"
              })
          }
          
          // Handle success scenario as needed
        } catch (error) {
            console.error('Error:', error);
            setPostLoading(false);
            // Handle error scenario as needed
          }
    };


    //reset the values
    const handleToReset = () => {
         // Reset the form fields individually
       // Reset the form fields by setting them to empty strings
       setFormData({
        ...formData,
        table_no: "",
        no_of_seats: "",
        table_image: "", // Assuming you want to clear the file input
    });
    }

    //Go to home page
    const handleButtonClick = () => {
      history.push('/AdminHome')
    }


    //get the value in search and set the setstate
    const handleSearchInput = (e) => {
      setSearchQuery(e.target.value)
      };


       //to open the form for slot updation 
    const handleEditTable = async (data) => {
          const { value: formValues } = await Swal.fire({
            title: 'Update Table',
            html: `
             <label class="swalLa">Table No</label>
             <br/>
              <input id="swal-input1" class="swal2-input fin" name="table_no" placeholder="Table No" value="${data.table_no || ''}">
              <br/>
              <br/>
              <label class="swalLa">No.Of.Seats</label>
              <input id="swal-input2" class="swal2-input fin" name="no_of_seats" placeholder="No of Seats" value="${data.no_of_seats || ''}">
              <br/>
              <br/>
              <label class="swalLa">Table Image</label>
              <input id="swal-input3" class="swal2-input fin" name="table" type="file" accept="image/*">
            `,
            focusConfirm: false,
            showCancelButton: true,
            confirmButtonColor: '#007500',
            confirmButtonText: 'Submit',
            preConfirm: () => {
              return new Promise(async (resolve) => {
                const input1 = document.getElementById('swal-input1').value;
                const input2 = document.getElementById('swal-input2').value;
                const input3 = document.getElementById('swal-input3').files[0];
        
                if (!input1 || !input2) {
                  Swal.showValidationMessage('Please fill in all the required fields');
                  resolve(null);
                } else {

                  console.log("categories",data);
                  console.log("categories",data.image);
                  // Create a FormData object to handle the file upload
                  const formData = new FormData();
                  formData.append('table_no', input1 || data.table_no);
                  formData.append('no_of_seats', input2 || data.no_of_seats);
                  formData.append('table_image', input3 || data.table_image);
                  formData.append('is_active', data.is_active);
                  formData.append('shop_id',data.shop_id);
        
                  try {
                    // Make a PUT request to update the category
                    const categoryId = data.id; // Replace with the actual category ID
                    const response = await axios.put(`${var_api}/api/table-details/table-put/${categoryId}`, formData, {
                      headers: {
                        'Content-Type': 'multipart/form-data', // Set the content type for file upload
                      },
                    });
        
                    if (response.status === 200) {
                      fetchTables();
                      // Category updated successfully
                      Swal.fire({
                        icon: 'success',
                        text: 'Table updated successfully',
                        confirmButtonColor: '#007500',
                      });
                    } else {
                      Swal.fire({
                        icon: 'error',
                        text: 'Failed to update Table',
                        confirmButtonColor: '#007500',
                      });
                    }
                  } catch (error) {
                    console.error('Error updating Table:', error);
                    Swal.fire('Error', 'Failed to update Table. Please try again.', 'error');
                  }
        
                  resolve(formData);
                }
              });
            },
          });
    };


     //delete method for remove UOM
   const handleDeleteTable = (id) => {
      axios
      .delete(`${var_api}/api/table-details/table-delete/${id}`)
      .then((response) => {
        if (response.status === 200) {
          console.log(id)
          fetchTables();
          Swal.fire("success"," Table Removed Successfully!","success")
          // Perform any other actions you need on success
        } else if (response.status === 404) {
          console.log('Table not found');
          Swal.fire("error","Table not found!","error")
        }
      })
      .catch((error) => {
        console.error('Error executing the request:', error);
        Swal.fire("error","Failed to Remove Table!","error")
        // Handle the error as needed
      });
    }



    return(
        <div className='whole' style={{backgroundColor:"white"}}>
            <div class="split left">
                <div class="centered">
                    <h3 className='ushe'>TABLE MASTER</h3>
                    <br/>
                    <div style={{marginLeft:"12px",padding:"12px"}}>

                    <form onSubmit={Submit}>
            <div className='mb-5'>
            <lable style={{fontWeight:"bold",fontSize:"14px",marginLeft:"30px"}}>Table No</lable>
            <br/>
            <input  className="form-input" type='number' name='table_no' placeholder='Enter Table No'
             required
             value={formData.table_no}
             onChange={handleInputChange}/>
            </div>
            <br/>
            <div className='mb-5'>
            <lable style={{fontWeight:"bold",fontSize:"14px",marginLeft:"30px"}}>No.Of.Seats</lable>
            <input  className="form-input" type='number' name='no_of_seats' placeholder='Enter seats'
            required
             value={formData.no_of_seats}
             onChange={handleInputChange}/>
            </div>
            <br/>
            <div className='mb-5'>
            <lable style={{fontWeight:"bold",fontSize:"14px",marginLeft:"30px"}}>Table Image</lable>
            <input  className="form-input" type='file' name='table_image' onChange={handleImageChange}
            />
            </div>
            <br/>
            <br/>
            {postloading ? (
                  <div class="text-center"  style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                ) :  
            <button className='btn btn-success' style={{float:"right"}}>Create Table</button>} {' '}
            </form>
            
            <button className='btn btn-danger' style={{float:"right",marginRight:"3px"}} onClick={handleToReset} >Reset</button> {' '}
                       
                        
                    </div>
                   
                </div>
            </div>

            <div class="split right">
            <nav className="navbar" 
            style={{boxShadow: "0px 0px 1px 0px black",backgroundColor:"green",border:"none",padding:"5px",height:"50px",width:"1065px",position:"relative",top:"-10px"}} >
<div style={{ display: "flex"}}>
{shopName && <span style={{ fontSize:"21px",fontWeight: "bold",fontFamily:"serif", color: "white",marginLeft:"350px" }}>{shopName}</span>}
<button className="btn" onClick={handleButtonClick} style={{marginLeft:"200px",backgroundColor:"white",color:"green"}}
         ><i class="bi bi-house"></i> Go to Home</button>
            </div>
        
      </nav>
      <div style={{border:"none",padding:"20px",width:"1020px",height:"640px",margin:"auto",borderRadius:"15px",backgroundColor:"white",boxShadow:" rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px"}}>
      
      <div>
        
      <input class="form-control" type="Search" placeholder="Search" id="example-search-input" 
      style={{width:"230px",borderRadius:"25px",border:"1px solid black",float:"right"}} onChange={handleSearchInput}/>
      <h3 style={{textAlign:"center",fontFamily:"cursive",fontWeight:"bolder"}}>Current Tables</h3>
      <br/>
      <div className="table-container" style={{ height:"500px", overflowX: 'auto', overflowY: 'scroll' }}>
     <table class="table table-hover small-first-col" style={{border:"none",borderColor:"grey",width:"90%",margin:"auto"}}>
  <thead>
    <tr style={{fontWeight:"bolder"}}>
      <th scope="col">S.No</th>
      <th scope="col">Table Image</th>
      <th scope="col">Table No</th>
      <th scope="col">No Of Seats</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody class="body-half-screen">
   {filteredSuppliers.length > 0 ? (
   filteredSuppliers.map((data,index) =>(

    <tr style={{padding:"5px"}}>
      <th scope="row">{index+1}</th>
      <td style={{textTransform:"capitalize"}}><img src={`${var_api}/${data.table_image}`} class="rounded"  alt='profile' width="65px" height="50px" /></td>
      <td>{data.table_no}</td>
      <td>{data.no_of_seats}</td>
      <td>
      <i class="bi bi-pencil-square" onClick={()=>handleEditTable(data)}  style={{fontSize:"20px",color:"rgb(9, 97, 9)"}}></i>{' '}
      {/* <i class="bi bi-trash" onClick={()=>handleDeleteTable(data.id,data.table_no)}   style={{fontSize:"21px",color:"rgb(175, 2, 2)"}}></i> */}
      </td>
    </tr>
   ))
   ) : (
    <tr>
      <td colSpan="5" style={{ textAlign: "center" }}>
        No Data Available
      </td>
    </tr>
   )}
 

   </tbody>

</table>

      </div>

<br/>
<br/>


        </div>
      
      </div>
            </div>
        </div>

        
    )
}

export default AdminTableMaster;