import React from "react";
import axios from 'axios';
import { var_api } from '../constant';
import { useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Pagination from 'react-js-pagination';

const Outstandingpayment = () => {
   const history = useHistory();
   const [tables, setTables] = useState([]);
   const [ searchQuery,setSearchQuery] = useState('');
   const shopName= localStorage.getItem('shopName');
   const itemsPerPage = 25; 
   const [currentPage, setCurrentPage] = useState(1);
   const [totalItems, setTotalItems] = useState(0);
   const [startIndex, setStartIndex] = useState(0);
 
    useEffect(() => {
        fetchTables();
      }, []);

      


      const fetchTables = async () => {
        const startIndexCalc = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndexCalc + itemsPerPage;
        try {
           const shopid = localStorage.getItem('shopId')
           const response = await axios.get(`${var_api}/api/payment/outstanding-payment/${shopid}`);
           console.log("response",response)
          const user =response.data;
          if(user.length > 0){
            setTables(user.slice(startIndexCalc, endIndex));
            setTotalItems(user.length);
            setStartIndex(startIndexCalc); // Update startIndex state variable
          }else{
            console.log("No Data");
            setTables([]);
            setTotalItems(0);
            setStartIndex(0);
          }
        } catch (error) {
          console.log(error);
        }
      };
    
      
  //search the transaction data
  const filteredSuppliers = tables.filter((order) => {
    const searchLower = searchQuery.toLowerCase();
    return (
      (order.name && order.name.toString().toLowerCase().includes(searchLower) || order.phone_no && order.phone_no.toString().toLowerCase().includes(searchLower)) ||
      (order.total_sum && order.total_sum.toString().toLowerCase().includes(searchLower) || order.total_paid && order.total_paid.toString().toLowerCase().includes(searchLower)) || (order.balance && order.balance.toString().toLowerCase().includes(searchLower)) 
    );
  });
    
    //get the value in search and set the setstate
    const handleSearchInput = (e) => {
      setSearchQuery(e.target.value)
      };

       // Function to format date as dd-mm-yyyy
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB'); // 'en-GB' for dd-mm-yyyy format
    };

   
  const handleButtonClickhome = () => {
    // Navigate to a specific route using history.push
    history.push('/home');
  };


  //onchange function for pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber, () => {
        fetchTables(); // Fetch data for the new page
    })
};


  return(
    <div class="whole">
    <div className="container-fluid" style={{ textAlign: "center" }}>
    <nav className="navbar" style={{ boxShadow: "0px 0px 1px 0px black", backgroundColor: "green", border: "none", padding: "5px", height: "auto", width: "100%", position: "relative"}}>
            <div style={{ display: "flex"}}>
                <h3 style={{ fontFamily: "cursive", fontWeight: "bolder",marginLeft: "40px", color: "white" }}>{ shopName}</h3>
                <button className="btn" onClick={handleButtonClickhome} style={{ marginLeft: "780px", backgroundColor: "white", color: "green" }}>
                    <i class="bi bi-house"></i> Go to Home
                </button>
            </div>
        </nav>
    </div>
    <br/>
    <div style={{ border: "none", padding: "5px", width: "100%", height: "100%", margin: "auto", borderRadius: "15px", backgroundColor: "white", boxShadow: " rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px" }}>
        <div>
        <div style={{display: "flex", alignItems: "center", marginTop:"15px"}}>
        <h3 style={{ fontFamily: "cursive", fontWeight: "bolder",marginLeft: "450px", color: "black" }}>Credit Customer - Outstanding Payments</h3>
            <input class="form-control" type="Search" placeholder="Search" id="example-search-input"
                style={{ width: "250px", borderRadius: "25px", border: "1px solid black", float: "right", marginLeft:"200px"}} onChange={handleSearchInput} />
                </div>
            <br />
            <br />
            <h5 style={{fontFamily:"serif",color:"green",marginLeft:"30px"}}>No.Of.Customers: <span style={{fontFamily:"monospace",color:"black"}}>{filteredSuppliers.length > 0 ? filteredSuppliers.length : 0}</span></h5>
            <div className="table-container" style={{ height: "500px", overflowX: 'auto', overflowY: 'scroll' }}>
                <table class="table table-hover small-first-col" style={{ border: "none", borderColor: "grey", width: "95%", margin: "auto" }}>
                    <thead>
                        <tr style={{ fontWeight: "bolder" }}>
                            <th scope="col">S.No</th>
                            <th scope="col">Profile</th>
                            <th scope="col">Name</th>
                            <th scope="col">Mobile</th>
                            <th scope="col">Total Credit Amount</th>
                            <th scope="col">Total Paid Amount</th>
                            <th scope="col">Balance Amount</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody class="body-half-screen">
                        {filteredSuppliers.length > 0 ? (
                            filteredSuppliers.map((data, index) => (
                                <tr style={{ padding: "5px" }}>
                                    <th scope="row">{startIndex + index + 1}</th>
                                    <td><img src={`${var_api}/${data.profile}`} alt='user' width='65px' height='65px'  /></td>
                                    <td>{data.name}</td>
                                    <td>{data.phone_no}</td>
                                    <td>{data.total_sum}</td>
                                    <td>{data.total_paid}</td>
                                    <td>{data.balance}</td>
                                    <td style={{color:"red"}}><b>Pending</b></td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="8" style={{ textAlign: "center" }}>
                                    No Data Available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <Pagination
               activePage={currentPage}
               itemsCountPerPage={itemsPerPage}
               totalItemsCount={totalItems} // Update to reflect total items for pagination
               pageRangeDisplayed={5}
               onChange={handlePageChange}
               itemClass="page-item"
               linkClass="page-link"
               innerClass="pagination justify-content-center"
               activeClass="active-pagination" // Custom class for the active pagination item
            />
        </div>
    </div>
</div>


    
)
};


export default Outstandingpayment;
