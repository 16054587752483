import React, { useState, useEffect } from "react";
import './Parcel.css'
import pics from './parcelimg/tray.png';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import { useHistory } from 'react-router-dom';
import { Table, Row, Col, Card} from 'react-bootstrap';
import { var_api } from "../constant";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';


function ParcelStatus(){
  const [allOrders, setAllOrders] = useState([]);
  const [delOrders, setDelOrders] = useState([]);
  const [counts, setCounts] = useState({});
  const history = useHistory();
  const [pLoading, setPLoading] = useState([]);
  const [rLoading, setRLoading] = useState(false);
  const [cLoading, setCLoading] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [deSummary, setDeSummary] = useState({});
  const [ordersCount, setOrdersCount] = useState({});
  const [newOrdersCount, setNewOrdersCount] = useState(null);
  const [newloading, setNewLoading] = useState(false);
  const [newOrders, setNewOrders] = useState([]);
  const valtoday = new Date().toISOString().split('T')[0];
  const [deliveredloading, setDeliveredLoading] = useState(false);
  
  // Get today's date
  const today = new Date();
  const formattedStartDate = `${String(today.getDate()).padStart(2, '0')}-${String(today.getMonth() + 1).padStart(2, '0')}-${today.getFullYear()}`;
  const [startDate, setStartDate] = useState(formattedStartDate); // Moved inside the component body
  const [newStartDate, setNewStartDate] = useState(formattedStartDate);
  const handleButtonClick = () => {
    // Navigate to a specific route using history.push
    history.push('/home');
  };
  const handleButtonTableClick = () => {
    // Navigate to a specific route using history.push
    history.push('/tableOrders');
  };
  

  const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1; // January is 0, so we add 1
    const year = currentDate.getFullYear();

    // Pad single digit day and month with leading zero if needed
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    // Format the date as dd-mm-yyyy
    const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;

  const fetchData = async (date) => {
   const shop_id = localStorage.getItem('shopId');
   setNewLoading(true);
    try {
      const response = await axios.get(`${var_api}/api/main-parcel/get-new-orders/${shop_id}/${date}`); // Replace with your API endpoint
      setNewOrders(response.data.categorizedOrders); // Update the state with the fetched data
       setNewOrdersCount(response.data.counts)
       console.log("data",response.data)
       console.log("data1",response.data.categorizedOrders)
       console.log("data2",response.data.categorizedOrders.new)
       setNewLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchDeliveryData = async (startDate) => {
    const shop_id = localStorage.getItem('shopId');
    setDeliveredLoading(true);
    console.log("sss, true")
     try {
       const response = await axios.get(`${var_api}/api/main-parcel/get-delivered-orders/${shop_id}/${startDate}`); // Replace with your API endpoint
      const result = response.data;
      setDelOrders(result);
      setDeliveredLoading(false)
      console.log("sss, flae")
     } catch (error) {
       console.error('Error fetching data:', error);
       setDeliveredLoading(false)
       console.log("sss, flae")
     }
   };


  //  const fetchOrdersCount = async () => {
  //   const shop_id = localStorage.getItem('shopId');
     
  //    try {
  //      const response = await axios.get(`${var_api}/api/main-kitchen/get-orders-state-count/${shop_id}/${formattedDate}`); // Replace with your API endpoint
  //     const result = response.data;
  //     setOrdersCount(result);
  //    } catch (error) {
  //      console.error('Error fetching data:', error);
  //    }
  //  };


  //  const fetchDeliverySummary = async (startDate) => {
  //   const shop_id = localStorage.getItem('shopId');
     
  //    try {
  //      const response = await axios.get(`${var_api}/api/main-kitchen/get-kitchen-summary/${shop_id}/${startDate}`); // Replace with your API endpoint
  //     const result = response.data;
  //     setDeSummary(result);
  //    } catch (error) {
  //      console.error('Error fetching data:', error);
  //    }
  //  };

  useEffect(() => {
    fetchData(newStartDate);
    // Set up interval to call fetchNewData every 10 seconds
  const intervalId = setInterval(() => fetchData(newStartDate), 10000); // 10000 milliseconds = 10 seconds

  // Clean up function to clear interval when component unmounts or when dependencies change
  return () => clearInterval(intervalId);
  
  }, [newStartDate]); // Add startDate as dependency to re-poll when it changes



  useEffect(() => {
     fetchDeliveryData(startDate);
    
  }, [startDate]); // Add startDate as dependency to re-poll when it changes




   //update loading state for progeress
   const updateLoadingProgressState = (index, isLoading) => {
    setPLoading(prevStates => {
      const newLoadingStates = [...prevStates];
      newLoadingStates[index] = isLoading;
      return newLoadingStates;
    });
  };


    //update to progress
    const updateProgress = (id,index) => {
     
      updateLoadingProgressState(index, true);
      axios.put(`${var_api}/api/main-parcel/update-todelivery/${id}`)
          .then(response => {
              console.log(response.data.message);
              fetchData(newStartDate);
              updateLoadingProgressState(index, false);
              fetchDeliveryData(startDate);
              const audio = new Audio('https://spbiller.com/audio/to-deliver.mp3');
              audio.play();
          })
          .catch(error => {
              console.error('Error updating progress:', error);
              console.log('Error updating progress. Please try again.');
          });
          fetchData(newStartDate);
          updateLoadingProgressState(index, false);
  };
 

   //update to ready
   const updateReady= (id,kitchenOrderNo) => {
    const shop_id = localStorage.getItem('shopId');
    setRLoading(true);
    axios.put(`${var_api}/api/main-kitchen/update-ready/${id}/${kitchenOrderNo}/${shop_id}`)
        .then(response => {
            console.log(response.data.message);
            fetchData(newStartDate);
            setRLoading(false);
        })
        .catch(error => {
            console.error('Error updating progress:', error);
            console.log('Error updating progress. Please try again.');
            setRLoading(false);
        });
        fetchData(newStartDate);
        
};


const updateLoadingState = (index, isLoading) => {
  setCLoading(prevStates => {
    const newLoadingStates = [...prevStates];
    newLoadingStates[index] = isLoading;
    return newLoadingStates;
  });
};


 //update to deliver
 const updateComplete = (id,kitchenOrderNo,index) => {
  updateLoadingState(index, true);
  const shop_id = localStorage.getItem('shopId');
  axios.put(`${var_api}/api/main-kitchen/update-delivery/${id}/${kitchenOrderNo}/${shop_id}`)
      .then(response => {
          console.log(response.data.message);
          fetchDeliveryData();
          updateLoadingState(index, false);
      })
      .catch(error => {
          console.error('Error updating progress:', error);
          console.log('Error updating progress. Please try again.');
          updateLoadingState(index, false);
      });
      fetchDeliveryData();
      
};

const formatDate = (dateString) => {
  const [day, month, year] = dateString.split('-');
  return `${year}-${month}-${day}`;
}


//open the summary modal
const handleOpenSummary = () => {
  setIsOpen(true);
}


const handleToChangeDate = (event) => {
  const startDate = event.target.value;
  // Split the date string into year, month, and day
 const [year, month, day] = startDate.split('-');
 // Create a new date string with the desired format
 const formattedDate = `${day}-${month}-${year}`;
 // Do whatever you want with the formatted date
 console.log('Formatted date:', formattedDate);
  // Do something with the start date, e.g., store it in state
  setStartDate(formattedDate);
  // fetchDeliverySummary(formattedDate);
  fetchDeliveryData(formattedDate);
  
}


const handleToChangeNewDate = (event) => {
  const startDate = event.target.value;
  // Split the date string into year, month, and day
 const [year, month, day] = startDate.split('-');
 // Create a new date string with the desired format
 const formattedDate = `${day}-${month}-${year}`;
 // Do whatever you want with the formatted date
 console.log('Formatted date:', formattedDate);
  // Do something with the start date, e.g., store it in state
  setNewStartDate(formattedDate);
  // fetchDeliverySummary(formattedDate);
  fetchData(formattedDate);
  
}

function parseTime(timeString) {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  return {
    hours,
    minutes,
    seconds
  };
}

// Function to calculate duration in milliseconds
function calculateDuration(start, end) {
   // Extract hours, minutes, and seconds from start and end times
   const startParts = start.split(':').map(Number);
   const endParts = end.split(':').map(Number);
 
   // Calculate the duration components
   let hours = endParts[0] - startParts[0];
   let minutes = endParts[1] - startParts[1];
   let seconds = endParts[2] - startParts[2];
 
   // Adjust for negative values
   if (seconds < 0) {
     seconds += 60;
     minutes--;
   }
   if (minutes < 0) {
     minutes += 60;
     hours--;
   }
   if (hours < 0) {
     hours += 24;
   }
 
   // Format the duration
   const formattedHours = String(hours).padStart(2, '0');
   const formattedMinutes = String(minutes).padStart(2, '0');
   const formattedSeconds = String(seconds).padStart(2, '0');
 
   // Return the formatted duration
   return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}


return(

  // <OrderProvider>
<>
<div id="thalai">
      <nav className="navbar navbar-light py-1 sticky-top" id="parnav" >
        <h5 id="fontpar">
        Parcel Status
        <img src={pics} alt="image" style={{width:"35px",height:"35px"}} />
        </h5>
      
        <button className="btn" id="homepar" onClick={handleButtonClick}><i class="bi bi-house"></i> Go to home</button>
      </nav>
      <div>
        <div className="row" style={{padding:"0px 30px",marginTop:"20px"}} >



          <div className="col mb-0 ftco-animate">
            <div className="card" id="parnew">
              <div class="card-header" id="parcardhe">New{" "}
              <span class="badge rounded-circle bg-dark" style={{color:"white"}}>{newOrdersCount && newOrdersCount.new}</span>
              <div style={{float:"right"}}>
                  Search by Date:{" "}
                <input type='date' name='date'  style={{border:"2px ridge green",borderRadius:"15px",height:"auto",padding:"2px", width:"auto"}}
              placeholder='choose date' value={formatDate(newStartDate)} onChange={handleToChangeNewDate}  max={valtoday} />
                </div>
              </div>
              <div className="card-body" id="scrpar" >
                {/* <br/>
                <h6 style={{margin:"10px 10px 10px 5px"}}><span style={{float:"right"}}><i class="bi bi-calendar"></i>{" "}{formattedDate}</span></h6> */}
                <br/>
                {newloading ? (
                  <div class="text-center" >
                  <div class="spinner-border" role="status" >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                ) :
              <Row>
              {
                newOrders && newOrders.new && newOrders.new.length === 0 ? (
               <div class="text-center" >
                <br/>
                <h6>No Orders Found</h6>
              </div>
                ) :
              newOrders && newOrders.new && newOrders.new.map((order,index) => (
           
           <Col key={index}>
            <Card  border="success" id="parnewocard"> 
          
           <Card.Body className="p-2">
            <div>
              <div className="Crowpar">
                <div className="Ccolumnpar">
                  <p style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>S.No: <span style={{fontWeight:"normal"}}>{order.order_count_no}</span></p>
                  <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}} >Items: <span style={{fontWeight:"normal"}}>{order.ordered_by.length}</span></p>
                </div>
                <div className="Ccolumnpar">
                  <p  style={{fontFamily:"serif",fontSize:"14px"}}>{order.is_table === 0 ? (
                    <span class="badge rounded-pill bg-primary p-2" style={{color:"white"}}>Table Order</span>
                  ) : (
                    <span class="badge rounded-pill bg-info p-2" style={{color:"white"}}>Parcel Order{order.is_table}</span>
                  )}</p>
                  {order.is_table === 0 && <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>Table No: <span style={{fontWeight:"normal"}}>{order.table_no}</span></p>}
                </div>
                <div className="Ccolumnpar">
                  <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>Parcel Order: <span style={{fontWeight:"normal"}}>{order.parcel_order_no}</span></p>
                  <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>Captain: <span style={{fontWeight:"normal"}}>{order.employee_name}</span></p>
                </div>
              </div>
            </div>
            <hr/>
          
                
               <Table striped="columns" className="scrollable-table" borderless style={{marginTop:"-20px"}}>
                <thead style={{border:"none"}}>
                <th style={{border:"none"}}>S.No</th>
                <th style={{border:"none"}}>Food</th>
                <th style={{border:"none"}}>Items</th>
                <th style={{border:"none"}}>Qty</th>
              </thead>
              {order.ordered_by.map((item, index) => (
                    <tr key={index}>
                    <td>{index+1}</td>
                    <td style={{textAlign:"center"}}><img src = { item.image ? `${var_api}/${item.image}` : `${var_api}/food_default_image.jpg`} alt="food_img" width="80px" height="80px"/></td>
                    <td>{item.food_name}</td>
                    <td>{item.quantity}</td>
                    </tr>
              ))}
    </Table>
    <hr/>
           
                 <button type="button"  class="btn btn-outline-success" style={{float:"right"}} onClick={()=>updateProgress(order.id,index)}>
                 {pLoading[index] ?  (
                    <div class="text-center">
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                  ) : (
       <>
       <i className="bi bi-arrow-right"></i>
       {" "}to deliver
     </>) }
                 </button>
               
               {/* <button type="button" onClick={() => moveOrderToInProgress(order.id)} class="btn btn-outline-success" style={{marginTop:"10px"}}>New</button> */}
              </Card.Body>
          
            </Card>
          </Col>
         )
        )}
      </Row>
}
              </div>
            </div>
          </div>




          



          <div className="col mb-0 ftco-animate">
            <div className="card" id="pardel" >
              <div class="card-header" id="parcardhe1">Delivered{" "}
              <span class="badge rounded-circle bg-dark" style={{color:"white"}}>{delOrders  && delOrders.length}</span>
              <div style={{float:"right"}}>
                  Search by Date:{" "}
                <input type='date' name='date'  style={{border:"2px ridge green",borderRadius:"15px",height:"auto",padding:"2px", width:"auto"}}
              placeholder='choose date' value={formatDate(startDate)} onChange={handleToChangeDate}  max={valtoday} />
                </div>
              </div>
              <div className="card-body" id="scr" style={{padding:"30px",margin:"auto"}}>
              <br/>
              {deliveredloading ? (
                 <div class="text-center" >
                 <div class="spinner-border" role="status" >
                   <span class="visually-hidden">Loading...</span>
                 </div>
               </div>
                ) :
              <Row>
              {delOrders  && delOrders.length === 0 ? (
                <div class="text-center" >
                <h6>No Orders Found</h6>
              </div>
              ) : 
              delOrders.map((order,index) => (
           
           <Col key={index}>
            <Card  border="success" style={{borderRadius:"20px",width:"320px",marginBottom:"0px",marginTop:"12px",height:"auto",zIndex:"1"}}> 
          
           <Card.Body className="p-2">
            <div>
              <div className="Crowpar">
                <div className="Ccolumnpar">
                  <p style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>S.No: <span style={{fontWeight:"normal"}}>{order.order_count_no}</span></p>
                  <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}} >Items: <span style={{fontWeight:"normal"}}>{order.ordered_by.length}</span></p>
                </div>
                <div className="Ccolumnpar">
                  <p  style={{fontFamily:"serif",fontSize:"14px"}}>{order.is_table === 0 ? (
                    <span class="badge rounded-pill bg-primary p-2" style={{color:"white"}}>Table Order</span>
                  ) : (
                    <span class="badge rounded-pill bg-info p-2" style={{color:"white"}}>Parcel Order</span>
                  )}</p>
                  {order.is_table === 0 && <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>Table No: <span style={{fontWeight:"normal"}}>{order.table_no}</span></p>}
                </div>
                <div className="Ccolumnpar">
                  <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>parcel Order: <span style={{fontWeight:"normal"}}>{order.parcel_order_no}</span></p>
                  <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>Captain: <span style={{fontWeight:"normal"}}>{order.employee_name}</span></p>
                </div>
              </div>
            </div>
            <hr/>
          
                
               <Table striped="columns" className="scrollable-table" borderless style={{marginTop:"-20px"}}>
                <thead style={{border:"none"}}>
                <th style={{border:"none"}}>S.No</th>
                <th style={{border:"none"}}>Food</th>
                <th style={{border:"none"}}>Items</th>
                <th style={{border:"none"}}>Qty</th>
              </thead>
              {order.ordered_by.map((item, index) => (
                    <tr key={index}>
                    <td>{index+1}</td>
                    <td style={{textAlign:"center"}}><img src = {item.image ? `${var_api}/${item.image}` : `${var_api}/food_default_image.jpg`} alt="food_img" width="80px" height="80px"/></td>
                    <td>{item.food_name}</td>
                    <td>{item.quantity}</td>
                    </tr>
              ))}
    </Table>
    <hr/>
           
    <h6 style={{fontFamily:"serif",fontWeight:"bold",textAlign:"left"}}>
  Preparation Time: {calculateDuration(order.start_time,order.end_time)}
</h6>


               {/* <button type="button" onClick={() => moveOrderToInProgress(order.id)} class="btn btn-outline-success" style={{marginTop:"10px"}}>New</button> */}
              </Card.Body>
          
            </Card>
          </Col>
         )
        )}
      </Row>
}
      
              </div>
            </div>
          </div>
        </div>
      </div>

     
    </div>
    <Modal
  show={isOpen}
  backdrop="static"
  keyboard={false}
  onHide={()=>setIsOpen(false)}
  size="md"
>
  <Modal.Header closeButton  style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"12px"}}>
    <Modal.Title style={{fontSize:"19px", fontWeight:"bold"}}>Kitchen Summary ({startDate})</Modal.Title>
  </Modal.Header>

  <Modal.Body>
      <h6 style={{fontFamily:"serif", fontWeight:"bolder"}}>Total Table-Split Orders: <span style={{fontFamily:"monospace", fontWeight:"normal"}}>{deSummary.table_split_orders}</span></h6>
      <hr/>
      <h6 className="text-primary" style={{fontFamily:"serif", fontWeight:"bolder"}}>Total Table Orders: {" "}<span className="text-dark"style={{fontFamily:"monospace", fontWeight:"normal"}}>{deSummary.table_orders}</span></h6>
      <h6 className="text-primary" style={{fontFamily:"serif", fontWeight:"bolder"}}>Total Parcel Orders: {" "}<span className="text-dark" style={{fontFamily:"monospace", fontWeight:"normal"}}>{deSummary.parcel_orders}</span></h6>
      <h6 className="text-danger" style={{fontFamily:"serif", fontWeight:"bolder"}}>Total Orders: {" "}<span className="text-dark"style={{fontFamily:"monospace", fontWeight:"normal"}}>{deSummary.total_orders}</span></h6>
  </Modal.Body>
</Modal>
      

    </>

  // </OrderProvider>
      
    )
}

export default ParcelStatus;