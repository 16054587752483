import React, { useState, useEffect } from "react";
import './SubKitchen.css'
import 'bootstrap/dist/css/bootstrap.min.css'; 
import { useHistory } from 'react-router-dom';
import { Table, Row, Col, Card} from 'react-bootstrap';
import { var_api } from "../constant";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import pics from '../kitchen/kitImag/tray.png';
import { Tooltip } from "reactstrap";


function SubKitchen(){
  const [allDevices, setAllDevices] = useState([]);
  const [showPart, setShowPart] = useState("device");
  const [ deviceId, setDeviceId] = useState(null);
  const [newOrders, setNewOrders] = useState([]);
  const [delOrders, setDelOrders] = useState([]);
  const valtoday = new Date().toISOString().split('T')[0];
  const valTodotoday = new Date().toISOString().split('T')[0];
  const [progressOrders, setProgressOrders] = useState([]);
  const [readyOrders, setReadyOrders] = useState([]);
  const [newOrdersCount, setNewOrdersCount] = useState(null);
  const [progressOrdersCount, setProgressOrdersCount] = useState(null);
  const [readyOrdersCount, setReadyOrdersCount] = useState(null);
  const [delOrdersCount, setDelOrdersCount] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isView, setIsView] = useState(false);
  const [deSummary, setDeSummary] = useState([]);
  const [viewDetails, setViewDetails] = useState([]);
  
  const [counts, setCounts] = useState({});
  const history = useHistory();
  const [pLoading, setPLoading] = useState([]);
  const [loadingStates, setLoadingStates] = useState([]);
  const [cLoading, setCLoading] = useState([]);
 
  
  const [ordersCount, setOrdersCount] = useState({});
  const shopName= localStorage.getItem('shopName');
  const [newloading, setNewLoading] = useState(false);
  const [inprogressloading, setInprogressLoading] = useState(false);
  const [readyloading, setReadyLoading] = useState(false);
  const [deliveredloading, setDeliveredLoading] = useState(false);
  const [actiontooltipOpen, setActionToolTipOpen] = useState([]);
  
  // Get today's date
  const today = new Date();
  const formattedStartDate = `${String(today.getDate()).padStart(2, '0')}-${String(today.getMonth() + 1).padStart(2, '0')}-${today.getFullYear()}`;
  const [startDate, setStartDate] = useState(formattedStartDate); // Moved inside the component body
  const [startTodoDate, setStartTodoDate] = useState(formattedStartDate);
  const role = localStorage.getItem("e_role");
  
  const handleButtonClick = () => {
    // Navigate to a specific route using history.push
    history.push('/home');
  };


  const handleButtonTableClick = () => {
    // Navigate to a specific route using history.push
    history.push('/tableOrders');
  };

  const toggleActionTooltip = (index) => {
    const actionTooltipOpen = [...actiontooltipOpen];
    actionTooltipOpen[index] = !actionTooltipOpen[index];
    setActionToolTipOpen(actionTooltipOpen);
  };


  const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1; // January is 0, so we add 1
    const year = currentDate.getFullYear();

    // Pad single digit day and month with leading zero if needed
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    // Format the date as dd-mm-yyyy
    const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;

    //fetch the devices
  const fetchData = async () => {
   const shop_id = localStorage.getItem('shopId');
    
    try {
      const response = await axios.get(`${var_api}/api/sub-kitchen-master/get-active-device/${shop_id}`); // Replace with your API endpoint
      setAllDevices(response.data); // Update the state with the fetched data
      console.log("data",response.data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  //to show the orders
  const handleToOpenOrder = (id,deName) => {
    setShowPart('orders');
    setDeviceId({id:id, Dname:deName});
    console.log("idd",id);
    fetchNewData(startDate,id);
    fetchDeliveryData(startDate,id);
    fetchDeliverySummary(startDate,id)
  }


  const handleToGoBack = () => {
    setShowPart('device');
    fetchData();
  }


  const fetchNewData = async (startDate,id) => {
    const shop_id = localStorage.getItem('shopId');
    setNewLoading(true);
     try {
       const response = await axios.get(`${var_api}/api/sub-kitchen/get-todo-orders/${shop_id}/${startDate}/${id}`); // Replace with your API endpoint
       setNewOrders(response.data.categorizedOrders.new); // Update the state with the fetched data
       setNewOrdersCount(response.data.counts)
       console.log("data",response.data)
       console.log("data1",response.data.categorizedOrders)
       console.log("data2",response.data.categorizedOrders.new)
       setNewLoading(false);
     } catch (error) {
       console.error('Error fetching data:', error);
       setNewLoading(false);
     }
   };


    //update loading state for progeress
  const updateLoadingProgressState = (index, isLoading) => {
    setPLoading(prevStates => {
      const newLoadingStates = [...prevStates];
      newLoadingStates[index] = isLoading;
      return newLoadingStates;
    });
  };

    //update to progress
    const updateProgress = (id,index) => {
      updateLoadingProgressState(index, true);
      axios.put(`${var_api}/api/sub-kitchen/put/${id}`)
          .then(response => {
              console.log(response.data.message);
              fetchNewData(startDate,deviceId.id);
              updateLoadingProgressState(index, false);
              const audio = new Audio('https://spbiller.com/audio/to-ip-ready.mp3');
              audio.play();
              fetchDeliveryData(startDate,deviceId.id);
              fetchDeliverySummary(startDate,deviceId.id)
          })
          .catch(error => {
              console.error('Error updating progress:', error);
              console.log('Error updating progress. Please try again.');
              updateLoadingProgressState(index, false);
              fetchNewData(startDate,deviceId.id);
          });
  };


  //update loading state for ready
  const updateItemLoadingState = (orderIndex, itemIndex, isLoading) => {
    setLoadingStates(prevStates => {
      const newStates = [...prevStates];
      newStates[orderIndex][itemIndex] = isLoading;
      return newStates;
    });
  };

  // Function to initialize loading states for each order
const initializeLoadingStates = () => {
  const states = newOrders.map(order => (
    order.ordered_by.map(item => false)
  ));
  setLoadingStates(states);
};

// Call initializeLoadingStates when newOrders changes
useEffect(() => {
  initializeLoadingStates();
}, [newOrders]);

  
   //update to ready
   const updateFoodStatus = (id, orderIndex, itemIndex) => {
    const shop_id = localStorage.getItem('shopId');
    updateItemLoadingState(orderIndex, itemIndex, true);
    axios.put(`${var_api}/api/sub-kitchen-items/put/${id}`)
        .then(response => {
            console.log(response.data.message);
            fetchNewData(startDate,deviceId.id);
            updateItemLoadingState(orderIndex, itemIndex, false);
            // const audio = new Audio('https://spbiller.com/audio/to-ip-ready.mp3');
            //   audio.play();
        })
        .catch(error => {
            console.error('Error updating progress:', error);
            console.log('Error updating progress. Please try again.');
            updateItemLoadingState(orderIndex, itemIndex, false);
            fetchNewData(startDate,deviceId.id);
        });
        
        
};

const fetchDeliveryData = async (startDate,id) => {
    const shop_id = localStorage.getItem('shopId');
    setDeliveredLoading(true)
     try {
       const response = await axios.get(`${var_api}/api/sub-kitchen/get-deliver-orders/${shop_id}/${startDate}/${id}`); // Replace with your API endpoint
      const result = response.data.categorizedOrders;
      setDelOrders(result);
      setDeliveredLoading(false)
     } catch (error) {
       console.error('Error fetching data:', error);
       setDeliveredLoading(false)
     }
   };


   const fetchDeliverySummary = async (startDate,id) => {
    const shop_id = localStorage.getItem('shopId');
     
     try {
       const response = await axios.get(`${var_api}/api/sub-kitchen/subkitchen-summary/${shop_id}/${startDate}/${id}`); // Replace with your API endpoint
      const result = response.data;
      setDeSummary(result);
     } catch (error) {
       console.error('Error fetching data:', error);
     }
   };

   //to open the summary
   const handleOpenOrderSummary = (id) => {
    fetchDeliverySummary(startDate,id);
    setIsOpen(true);
   }

   //open the summary modal
const handleOpenSummary = () => {
  setIsOpen(true);
}



const fetchViewDetails = async (id) => {
  const shop_id = localStorage.getItem('shopId');
   try {
     const response = await axios.get(`${var_api}/api/sub-kitchen-foods/get/device-items/${shop_id}/${id}`); // Replace with your API endpoint
     setViewDetails(response.data); // Update the state with the fetched data
     console.log("data",response.data)
   } catch (error) {
     console.error('Error fetching data:', error);
   }
 };


 //to open view modal
 const handleOpenViewDetails = (DeID,DeName) => {
  setIsView(true);
  fetchViewDetails(DeID);
  setDeviceId({id:DeID, Dname:DeName})
 }



 


   const fetchProgressData = async () => {
    const shop_id = localStorage.getItem('shopId');
    setInprogressLoading(true); 
     try {
       const response = await axios.get(`${var_api}/api/main-kitchen/get-progress-orders/${shop_id}/${formattedDate}`); // Replace with your API endpoint
       setProgressOrders(response.data.categorizedOrders); // Update the state with the fetched data
       setProgressOrdersCount(response.data.counts)
       console.log("data",response.data)
       console.log("data1",response.data.categorizedOrders)
       console.log("data2",response.data.counts.progress)
       setInprogressLoading(false);
     } catch (error) {
       console.error('Error fetching data:', error);
       setInprogressLoading(false);
     }
   };


   const fetchReadyData = async () => {
    const shop_id = localStorage.getItem('shopId');
    setReadyLoading(true); 
     try {
       const response = await axios.get(`${var_api}/api/main-kitchen/get-ready-orders/${shop_id}/${formattedDate}`); // Replace with your API endpoint
       setReadyOrders(response.data.categorizedOrders); // Update the state with the fetched data
       setReadyOrdersCount(response.data.counts)
       console.log("data",response.data)
       console.log("data1",response.data.categorizedOrders)
       console.log("data2",response.data.categorizedOrders.new)
       setReadyLoading(false);
     } catch (error) {
       console.error('Error fetching data:', error);
       setReadyLoading(false);
     }
   };

  


   const fetchOrdersCount = async () => {
    const shop_id = localStorage.getItem('shopId');
     
     try {
       const response = await axios.get(`${var_api}/api/main-kitchen/get-orders-state-count/${shop_id}/${formattedDate}`); // Replace with your API endpoint
      const result = response.data;
      setOrdersCount(result);
     } catch (error) {
       console.error('Error fetching data:', error);
     }
   };


 

  useEffect(() => {
   fetchData();
  }, []); // Add startDate as dependency to re-poll when it changes


 


const updateLoadingState = (index, isLoading) => {
  setCLoading(prevStates => {
    const newLoadingStates = [...prevStates];
    newLoadingStates[index] = isLoading;
    return newLoadingStates;
  });
};


 //update to deliver
 const updateComplete = (id,kitchenOrderNo,index,table_status) => {
  updateLoadingState(index, true);
  const shop_id = localStorage.getItem('shopId');
  axios.put(`${var_api}/api/main-kitchen/update-delivery/${id}/${kitchenOrderNo}/${shop_id}/${table_status}`)
      .then(response => {
          console.log(response.data.message);
          fetchDeliveryData(startDate);
          fetchDeliverySummary(startDate);
          fetchReadyData();
          updateLoadingState(index, false);
          const audio = new Audio('https://spbiller.com/audio/to-deliver.mp3');
              audio.play();
      })
      .catch(error => {
          console.error('Error updating progress:', error);
          console.log('Error updating progress. Please try again.');
          updateLoadingState(index, false);
          fetchDeliveryData(startDate);
      fetchDeliverySummary(startDate);
      fetchReadyData();
      });
      
      
};

const formatDate = (dateString) => {
  const [day, month, year] = dateString.split('-');
  return `${year}-${month}-${day}`;
}





const handleToChangeDate = (event) => {
  const startDate = event.target.value;
  // Split the date string into year, month, and day
 const [year, month, day] = startDate.split('-');
 // Create a new date string with the desired format
 const formattedDate = `${day}-${month}-${year}`;
 // Do whatever you want with the formatted date
 console.log('Formatted date:', formattedDate);
  // Do something with the start date, e.g., store it in state
  setStartDate(formattedDate);
  fetchDeliverySummary(formattedDate,deviceId.id);
  fetchDeliveryData(formattedDate,deviceId.id);
  // fetchNewData(formattedDate,deviceId.id);
  
}


const handleTodoChangeDate = (event) => {
    const startDate = event.target.value;
    // Split the date string into year, month, and day
   const [year, month, day] = startDate.split('-');
   // Create a new date string with the desired format
   const formattedDate = `${day}-${month}-${year}`;
   // Do whatever you want with the formatted date
   console.log('Formatted date:', formattedDate);
    // Do something with the start date, e.g., store it in state
    setStartTodoDate(formattedDate);
    // fetchDeliverySummary(formattedDate,deviceId.id);
    // fetchDeliveryData(formattedDate,deviceId.id);
    fetchNewData(formattedDate,deviceId.id);
    
  }

function parseTime(timeString) {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  return {
    hours,
    minutes,
    seconds
  };
}

// Function to calculate duration in milliseconds
function calculateDuration(start, end) {
   // Extract hours, minutes, and seconds from start and end times
   const startParts = start.split(':').map(Number);
   const endParts = end.split(':').map(Number);
 
   // Calculate the duration components
   let hours = endParts[0] - startParts[0];
   let minutes = endParts[1] - startParts[1];
   let seconds = endParts[2] - startParts[2];
 
   // Adjust for negative values
   if (seconds < 0) {
     seconds += 60;
     minutes--;
   }
   if (minutes < 0) {
     minutes += 60;
     hours--;
   }
   if (hours < 0) {
     hours += 24;
   }
 
   // Format the duration
   const formattedHours = String(hours).padStart(2, '0');
   const formattedMinutes = String(minutes).padStart(2, '0');
   const formattedSeconds = String(seconds).padStart(2, '0');
 
   // Return the formatted duration
   return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}


return(

  // <OrderProvider>
<>
<div id="subdiv">
<nav className="navbar  navbar-light py-1 sticky-top" id="subnav">
<div className="container-fluid">
        <h5 id="kifosub">
            {
                showPart === 'orders' ? 
                <span><i class="bi bi-arrow-left-circle-fill" style={{fontSize:"20px",color:"white",padding:"5px"}} onClick={handleToGoBack}></i>Sub Kitchen {deviceId.Dname}</span> :  "Sub kitchen"
            }
        
       
        <img src={pics} alt="image" style={{width:"35px",height:"35px"}} />
        </h5>
        <h4 id="subshop">{ shopName }</h4>
        <div className="d-flex flex-row" id="navbuttons">
        <button className="btn" style={{backgroundColor:"white",color:"green"}} onClick={handleButtonTableClick}><i class="bi bi-house"></i> Go to Table Order</button>
        {
   role === "Chef" || role === "Captain" || role === "Waiter" ? null : (
        <button className="btn" style={{backgroundColor:"white",color:"green"}} onClick={handleButtonClick}><i class="bi bi-house"></i> Go to home</button>)}
        </div>
    </div>
      </nav>
      
      {
        showPart === "device" ? (

            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4" style={{padding:"20px 30px"}} >


            {
                  allDevices.map((device, index) => (
      <div className="col mb-0 ftco-animate" key={index}>
        <div className="card" id="subcardde">
        <div className="card-header" style={{ border: "none", fontWeight: "bolder", fontSize: "14px", display: "flex", alignItems: "center" }}>
  <div style={{ marginRight: "auto" }}>S.No: <b>{index + 1}</b></div>
  <div style={{ textAlign: "center", flex: "1" }}>
    <span style={{ fontSize: "20px", color: "green" }}>{device.device_name}</span>
  </div>
  <div>
  <Tooltip
        isOpen={actiontooltipOpen[index]} // Use tooltip state based on index
        target={`actionButton${index}`} // Specify the target ID of the button
        toggle={() => toggleActionTooltip(index)}
        placement='bottom'
      >
         Open Device
        </Tooltip>
    <i className="bi bi-arrow-right-circle-fill" id={`actionButton${index}`}  style={{ fontSize: "25px", color: "red" }} onClick={()=>{handleToOpenOrder(device.id,device.device_name); toggleActionTooltip(index);}}></i>
  </div>
</div>

          <div className="card-body" >
             <h6 style={{color:"green"}}>Description: <span style={{color:"black", fontWeight:"bolder",fontSize:"16px"}}>{device.description}</span></h6>
             <h6 style={{color:"green"}}>No.Of.Menus: <span style={{color:"black", fontWeight:"bolder",fontSize:"16px"}}>{device.food_count ? device.food_count : 0} {" "}</span>
             {
              device.food_count && device.food_count > 0 ?
              <button className="btn btn-secondary" style={{padding:"0px 5px 0px 5px", borderRadius:"20px",float:"right"}} onClick={()=>handleOpenViewDetails(device.id,device.device_name)}><i class="bi bi-eye-fill"></i> View</button> :null
             }
            
             </h6>
             <h6 style={{color:"green"}}>Todo Count: <span style={{color:"black", fontWeight:"bolder",fontSize:"16px"}}>{device.todo_count ? device.todo_count : 0}</span></h6>
             <h6 style={{color:"green"}}>Delivery Count: <span style={{color:"black", fontWeight:"bolder",fontSize:"16px"}}>{device.done_count ? device.done_count : 0}</span></h6>
             <button className="btn btn-success" style={{padding:"0px 5px 0px 5px", borderRadius:"20px",float:"right"}} onClick={()=>handleOpenOrderSummary(device.id)}><i class="bi bi-clipboard2-data-fill"></i> Today Summary</button>
          </div>
          </div>
          </div>
             
      
                  ))
              }
      
           
          </div>

        ) : (

           <>
           

  
           <button className="btn btn-danger" style={{float:"right",borderRadius:"25px",margin:"20px",fontWeight:"bolder"}} onClick={handleOpenSummary}>Summary</button>
<br/>
  <div className="row" style={{padding:"0px 30px"}} >


<div className="col mb-0 ftco-animate">
  <div className="card" id="subtodo">
    <div class="card-header" id="subcardhe" style={isOpen ? {} : { zIndex: "9999" }}>Todo{" "}
    <span class="badge rounded-circle bg-light" style={{color:"black"}}> {newOrders  && newOrders.length}</span>
    <span style={{float:"right"}}> <input type='date' name='date'  style={{border:"2px ridge green",borderRadius:"15px",height:"auto",padding:"2px", width:"auto"}}
    placeholder='choose date'
     value={formatDate(startTodoDate)} onChange={handleTodoChangeDate}  max={valTodotoday} 
     /></span>
    </div>
    <div className="card-body" id="scrsub">
      {/* <br/>
      <h6 style={{margin:"10px 10px 10px 5px"}}><span style={{float:"right"}}><i class="bi bi-calendar"></i>{" "}{formattedDate}</span></h6> */}
      <br/>
      {/* <div className="row" style={{marginTop:"15px"}}>
 
      <div className="col" style={{float:"right"}}>
        Search by Date:{" "}
      <input type='date' name='date'  style={{border:"2px ridge green",borderRadius:"15px",height:"auto",padding:"2px", width:"auto"}}
    placeholder='choose date'
     value={formatDate(startTodoDate)} onChange={handleTodoChangeDate}  max={valTodotoday} 
     />
      </div>
    
      
      
    </div> */}
      {newloading ? (
        <div class="text-center" >
        <div class="spinner-border" role="status" >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      ) :
    <Row >
    {
      newOrders  && newOrders.length === 0 ? (
     <div class="text-center" >
      <br/>
      <h6>No Orders Found</h6>
    </div>
      ) :
    newOrders && newOrders.map((order,orderIndex) => {
      // Check if all items' ready_status is 1
  const allItemsReady = order.ordered_by.every(item => item.ready_status === 1);
  return (
 
 <Col key={orderIndex}>
  <Card  border="success" style={{borderRadius:"20px",width:"350px",marginBottom:"0px",marginTop:"15px",height:"auto",margin:"auto"}}> 

 <Card.Body className="p-2">
  <div>
    <div className="Crowsub">
      <div className="Ccolumnsub">
        <p style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>S.No: <span style={{fontWeight:"normal"}}>{orderIndex+1}</span></p>
        <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}} >Items: <span style={{fontWeight:"normal"}}>{order.ordered_by.length}</span></p>
      </div>
      <div className="Ccolumnsub">
        <p  style={{fontFamily:"serif",fontSize:"14px"}}>{order.is_table === 0 ? (
          <span class="badge rounded-pill bg-primary p-2" style={{color:"white"}}>Table Order</span>
        ) : (
          <span class="badge rounded-pill bg-info p-2" style={{color:"white"}}>Normal Order</span>
        )}</p>
        {order.is_table === 0 && <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>Table No: <span style={{fontWeight:"normal"}}>{order.table_no}</span></p>}
      </div>
      <div className="Ccolumnsub">
        <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>Kitchen Order No: <span style={{fontWeight:"normal"}}>{order.kitchen_order_no}</span></p>
        <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>Captain: <span style={{fontWeight:"normal"}}>{order.employee_name}</span></p>
      </div>
    </div>
  </div>
  <hr/>

      
     <Table striped="columns" className="scrollable-table" borderless style={{marginTop:"-20px"}}>
      <thead style={{border:"none"}}>
      <th style={{border:"none"}}>S.No</th>
      <th style={{border:"none"}}>Food</th>
      <th style={{border:"none"}}>Items</th>
      <th style={{border:"none"}}>Qty</th>
      <th style={{border:"none"}}></th>
    </thead>
    {order.ordered_by.map((item, itemIndex) => (
          <tr key={itemIndex}>
          <td>{itemIndex+1}</td>
          <td style={{textAlign:"center"}}><img src = { item.image ? `${var_api}/${item.image}` : `${var_api}/food_default_image.jpg`} alt="food_img" width="80px" height="80px"/></td>
          <td>{item.food_name}</td>
          <td>{item.quantity}</td>
          <td>
          {
            item.ready_status === 0 ? (
              loadingStates[orderIndex][itemIndex]?  (
                    <div class="text-center">
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                  ) : (
                      <i class="bi bi-arrow-right-circle-fill" style={{fontSize:"20px",color:"green",padding:"5px"}} onClick={()=>updateFoodStatus(item.id, orderIndex, itemIndex)} ></i>)
            ) : <i class="bi bi-check-circle-fill" style={{fontSize:"20px", color:"rgb(6, 197, 6)"}}></i>
          
         }
           </td>
          </tr>
    ))}
</Table>
<hr/>
{allItemsReady && (
       <button type="button"  class="btn btn-outline-success" style={{float:"right"}} onClick={()=>updateProgress(order.id,orderIndex)}>
       {pLoading[orderIndex] ?  (
          <div class="text-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        ) : (
<>
<i className="bi bi-arrow-right"></i>
{" "}to deliver
</>) }
       </button>
)}
     
     
    </Card.Body>

  </Card>
</Col>
  )
    }
)}
</Row>
}
    </div>
  </div>
</div>








<div className="col mb-0 ftco-animate">
  <div className="card" id="subdel">
    <div class="card-header" id="subcardhe1" style={isOpen ? {} : { zIndex: "9999" }}>Delivered{" "}
    <span class="badge rounded-circle bg-light" style={{color:"black"}}>{delOrders && delOrders.deliver  && delOrders.deliver.length}</span>
    <span style={{float:"right"}}>
    <input type='date' name='date'  style={{border:"2px ridge green",borderRadius:"15px",height:"auto",padding:"2px", width:"auto"}}
    placeholder='choose date'
     value={formatDate(startDate)} onChange={handleToChangeDate}  max={valtoday} 
     />
    </span>
    </div>
    <div className="card-body" id="scrsub">
    <br/>
    {/* <div className="row">
   
      <div className="col" style={{float:"right"}}>
        Search by Date:{" "}
      <input type='date' name='date'  style={{border:"2px ridge green",borderRadius:"15px",height:"auto",padding:"2px", width:"auto"}}
    placeholder='choose date'
     value={formatDate(startDate)} onChange={handleToChangeDate}  max={valtoday} 
     />
     
      </div>
    
      
      
    </div> */}
    <br/>
    {deliveredloading ? (
        <div class="text-center" >
        <div class="spinner-border" role="status" >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      ) :
    <Row>
    {
      delOrders && delOrders.deliver && delOrders.deliver.length === 0 ? (
     <div class="text-center" >
      <br/>
      <h6>No Orders Found</h6>
    </div>
      ) :
      delOrders && delOrders.deliver && delOrders.deliver.map((order,index) => (
 
 <Col key={index}>
  <Card  border="success" style={{borderRadius:"20px",width:"350px",marginBottom:"0px",marginTop:"12px",height:"auto",margin:"auto"}}> 

 <Card.Body className="p-2">
  <div>
    <div className="Crowsub">
      <div className="Ccolumnsub">
        <p style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>S.No: <span style={{fontWeight:"normal"}}>{index+1}</span></p>
        <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}} >Items: <span style={{fontWeight:"normal"}}>{order.ordered_by.length}</span></p>
      </div>
      <div className="Ccolumnsub">
        <p  style={{fontFamily:"serif",fontSize:"14px"}}>{order.is_table === 0 ? (
          <span class="badge rounded-pill bg-primary p-2" style={{color:"white"}}>Table Order</span>
        ) : (
          <span class="badge rounded-pill bg-info p-2" style={{color:"white"}}>Normal Order</span>
        )}</p>
        {order.is_table === 0 && <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>Table No: <span style={{fontWeight:"normal"}}>{order.table_no}</span></p>}
      </div>
      <div className="Ccolumnsub">
        <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>Kitchen Order No: <span style={{fontWeight:"normal"}}>{order.kitchen_order_no}</span></p>
        <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>Captain: <span style={{fontWeight:"normal"}}>{order.employee_name}</span></p>
      </div>
    </div>
  </div>
  <hr/>

      
     <Table striped="columns" className="scrollable-table" borderless style={{marginTop:"-20px"}}>
      <thead style={{border:"none"}}>
      <th style={{border:"none"}}>S.No</th>
      <th style={{border:"none"}}>Food</th>
      <th style={{border:"none"}}>Items</th>
      <th style={{border:"none"}}>Qty</th>
    </thead>
    {order.ordered_by.map((item, index) => (
          <tr key={index}>
          <td>{index+1}</td>
          <td style={{textAlign:"center"}}><img src = { item.image ? `${var_api}/${item.image}` : `${var_api}/food_default_image.jpg`} alt="food_img" width="80px" height="80px"/></td>
          <td>{item.food_name}</td>
          <td>{item.quantity}</td>
         
          </tr>
    ))}
</Table>
<hr/>
 
     
     
    </Card.Body>

  </Card>
</Col>
)
)}
</Row>
}

    </div>
  </div>
</div>
</div>
           </>

        )
       }
     
    </div>
    <Modal
  show={isOpen}
  backdrop="static"
  keyboard={false}
  onHide={() => setIsOpen(false)}
  size="xl"
>
  <Modal.Header closeButton style={{ backgroundColor: "rgba(6, 105, 16, 0.367)", padding: "12px" }}>
    <Modal.Title style={{ fontSize: "19px", fontWeight: "bold" }}>Kitchen Summary ({startDate})</Modal.Title>
  </Modal.Header>
  {
          deSummary.length > 0 ? (
            <Modal.Body style={{ display: "flex", flexDirection: "row" }}>
    <div style={{ flex: 1, borderRight: "1px solid #ccc" }}>
      <h6 style={{ fontFamily: "serif", fontWeight: "bolder", color: "blue" }}>Total Summary:</h6>
      <Table className="scrollable-table" borderless style={{ maxWidth: "auto", margin: "auto" }}>
        
        {deSummary && deSummary.map((item, index) => (
          <tr key={index} style={{ border: "none" }}>
            <td style={{ textAlign: "right", border: "none" }}><img src={item.image ? `${var_api}/${item.image}` : `${var_api}/food_default_image.jpg`} alt="food_img" width="80px" height="80px" /></td>
            <td style={{ border: "none", fontWeight: "bolder" }}>{item.food_name}: <b style={{ color: "blue", fontFamily: "monospace" }}>{item.overall_quantity}</b></td>
          </tr>
        ))}
      </Table>
    </div>

    <div style={{ flex: 1, borderRight: "1px solid #ccc" }}>
      <h6 style={{ fontFamily: "serif", fontWeight: "bolder", color: "red" }}>Todo Summary:</h6>
      <Table className="scrollable-table" borderless style={{ maxWidth: "auto", margin: "auto" }}>
        {deSummary && deSummary.map((item, index) => (
          <tr key={index} style={{ border: "none" }}>
            <td style={{ textAlign: "right", border: "none" }}><img src={item.image ? `${var_api}/${item.image}` : `${var_api}/food_default_image.jpg`} alt="food_img" width="80px" height="80px" /></td>
            <td style={{ border: "none", fontWeight: "bolder" }}>{item.food_name}: <b style={{ color: "red", fontFamily: "monospace" }}>{item.todo_count}</b></td>
          </tr>
        ))}
      </Table>
    </div>

    <div style={{ flex: 1 }}>
      <h6 style={{ fontFamily: "serif", fontWeight: "bolder", color: "green" }}>Delivery Summary:</h6>
      <Table className="scrollable-table" borderless style={{ maxWidth: "auto", margin: "auto" }}>
        {deSummary && deSummary.map((item, index) => (
          <tr key={index} style={{ border: "none" }}>
            <td style={{ textAlign: "right", border: "none" }}><img src={item.image ? `${var_api}/${item.image}` : `${var_api}/food_default_image.jpg`} alt="food_img" width="80px" height="80px" /></td>
            <td style={{ border: "none", fontWeight: "bolder" }}>{item.food_name}: <b style={{ color: "green", fontFamily: "monospace" }}>{item.deliver_count}</b></td>
          </tr>
        ))}
      </Table>
    </div>
  </Modal.Body>
            
          ): (
            <Modal.Body >
              <div className="text-center">
                 <h6 style={{textAlign:"center",fontFamily:"serif",fontWeight:"bolder",color:"red"}}>No Available Data</h6>
              </div>
          </Modal.Body>
          )
        }
 
</Modal>


<Modal
  show={isView}
  backdrop="static"
  keyboard={false}
  onHide={()=>setIsView(false)}
  size="md"
>
  <Modal.Header closeButton  style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"12px"}}>
    <Modal.Title style={{fontSize:"19px", fontWeight:"bold"}}>Device Details</Modal.Title>
  </Modal.Header>

  <Modal.Body>
    <div style={{display:"flex",gap:"160px"}}>
    <h6 style={{fontFamily:"serif", fontWeight:"bolder",color:"green",marginRight:"10px"}}>Device Name: <span style={{color:"black",fontSize:"19px"}}>{deviceId && deviceId.Dname}</span></h6>
     <h6 style={{fontFamily:"serif", fontWeight:"bolder",color:"green"}}>No.of.Foods: <span style={{color:"black",fontSize:"19px"}}>{viewDetails && viewDetails.length}</span></h6>
    </div>
      <Table className="scrollable-table" borderless style={{maxWidth:"auto",margin:"auto"}}>
    {viewDetails&& viewDetails.map((item, index) => (
          <tr key={index} style={{border:"none"}}>
          <td style={{textAlign:"right", border:"none"}}><img src = { item.food_image? `${var_api}/${item.food_image}` : `${var_api}/food_default_image.jpg`} alt="food_img" width="80px" height="80px"/></td>
          <td style={{border:"none",fontWeight:"bolder"}}>{item.food_name}</td>
          {/* <td style={{border:"none",float:"left"}}>{item.overall_quantity}</td> */}
          </tr>
    ))}
</Table>
  </Modal.Body>
</Modal>
      

    </>

  // </OrderProvider>
      
    )
}

export default SubKitchen;