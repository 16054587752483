import React, { useRef } from 'react';
import axios from 'axios';
import { Modal, Button, ModalFooter } from 'react-bootstrap';
import {  Input, Tooltip } from 'reactstrap';
import Select from 'react-select';
import { var_api } from "../constant";
import { useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import ReactToPrint from 'react-to-print';
import Bill from '../Foods/bill';
import Swal from 'sweetalert2';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import qz from 'qz-tray';
import sha256 from 'js-sha256';
import forge from 'node-forge';
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
toast.configure()

const Orderhistory = () => {
    const history = useHistory();
    const [selectedOrderLabel, setSelectedOrderLabel] = useState({ value: '0', label: 'All' });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [startIndex, setStartIndex] = useState(0);
    const [ searchQuery,setSearchQuery] = useState('');
    const [isOpenBill, setIsOpenBill] = useState(false);
    const [ selectedOrderBill,setSelectedOrderBill] = useState({});
    const [ billIndex,setBillIndex] = useState(null);
    const [subscriptions, setSubscriptions] = useState([]);
    const componentRefs = useRef([]);
    const itemsPerPage = 25; 
    const [labelCodeOptions] = useState([
        { value: '0', label: 'All' },
        { value: '1', label: 'Normal Order' },
        { value: '2', label: 'Credit Order' },
        { value: '3', label: 'Group Location Order' },
        { value: '4', label: 'Normal Table Order' },
        { value: '5', label: 'Credit Table Order' },
    ]);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [connected, setConnected] = useState(false);
    const [connectionAttempts, setConnectionAttempts] = useState(0);
    const [disconnecting, setDisconnecting] = useState(false);
    const [printers, setPrinters] = useState([]);
    const [isShowPrinters, setIsShowPrinters] = useState(false);
    const [printLoader, setprintLoader] = useState(false);
    const [shopDetails, setShopDetails] = useState(null);

    const toggleTooltip = () => {
      setTooltipOpen(!tooltipOpen);
    };
  

    // Function to get formatted date (dd-mm-yyyy) from a Date object
    const getFormattedDate = (date) => {
        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const yyyy = date.getFullYear();
        return `${dd}-${mm}-${yyyy}`;
    };

    const [startDate, setStartDate] = useState(getFormattedDate(new Date()));
    const [endDate, setEndDate] = useState(getFormattedDate(new Date()));
    const [isOpenSelectDate, setIsOpenSelectDate] = useState(false);
    const [orderHistory, setOrderhistory] = useState({});
    const [orderSummary, setOrderSummary] = useState({});
    const [isOpenOrderSummary, setIsOpenOrderSummary] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedOrderNo, setSelectedorderNo] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ isPayModeEdit, setIsPayModeEdit] = useState(false);
    const [ editPaymodeOrder, setEditPaymodeOrder] = useState(null);
    const [ editSelectedAmounts, setEditSelectedAmounts] = useState([]);
    const [ updateRemark, setUpdateRemark ] = useState("");
    const [ isPaymodeEditLoading, setIsPaymodeEditLoading ] = useState(false);
    const [ editModeTotal, setEditModeTotal ] = useState(null);
    const shopName = localStorage.getItem('shopName');
    const role = localStorage.getItem('e_role');
    const [puttooltipOpen, setPutToolTipOpen] = useState([]);
    const [printtooltipOpen, setPrintToolTipOpen] = useState([]);
    const printingType  = localStorage.getItem('printingType');

    useEffect(() => {
        // Fetch orders history and summary when selectedOrderLabel, startDate, or endDate changes
        if (selectedOrderLabel.value !== '' && startDate !== '' && endDate !== '') {
            fetchAllOrdersHistory(selectedOrderLabel.value);
            fetchAllOrdersSummary(selectedOrderLabel.value);
        }
    }, [selectedOrderLabel, startDate, endDate, currentPage]);


  const handleOrderLabelSelectChange = (selectedOption) => {
    setSelectedOrderLabel(selectedOption);
    fetchAllOrdersHistory(selectedOption.value);
    fetchAllOrdersSummary(selectedOption.value);
  };

  const handleStartDateChange = (event) => {
    const startDate = event.target.value;
    setStartDate(formatDate(startDate));
  };

  const handleEndDateChange = (event) => {
    const endDate = event.target.value;
    setEndDate(formatDate(endDate));
  };

  const handleOpenSelectDate = () => {
    setIsOpenSelectDate(true);
  };

  const handleCloseDateConfirmation = () => {
    setIsOpenSelectDate(false);
  };

  const handleSelectDateRange = () => {
    fetchAllOrdersHistory(selectedOrderLabel.value);
    setIsOpenSelectDate(false);
    fetchAllOrdersSummary(selectedOrderLabel.value);
  };


  const togglePutTooltip = (index) => {
    const putTooltipOpen = [...puttooltipOpen];
    putTooltipOpen[index] = !putTooltipOpen[index];
    setPutToolTipOpen(putTooltipOpen);
  };
  
  
  const togglePrintTooltip = (index) => {
    const printTooltipOpen = [...printtooltipOpen];
    printTooltipOpen[index] = !printTooltipOpen[index];
    setPrintToolTipOpen(printTooltipOpen);
  };
 
  const handleOpenOrderSumModal = () => {
    setIsOpenOrderSummary(true);
};

// Function to close the order summary modal
const handleCloseOrderSumModal = () => {
    setIsOpenOrderSummary(false);
};

  const handleCloseSummaryConfirmation = () => {
    setIsOpenOrderSummary(false);
  };

  //go to home page
  const handleButtonClickhome = () => {
    // Navigate to a specific route using history.push
    history.push('/home');
  };

//go to table order page
  const handleToRedirectTableOrder = () => {
    // Navigate to a specific route using history.push
    history.push('/tableOrders');
  };


  useEffect(() => {
    fetchData();
    fetchShopDetails();
  }, []); // Empty dependency array to run only once on component mount

  const fetchData = async () => {
    const userid = localStorage.getItem('userId');
    try {
      const response = await axios.get(`${var_api}/api/user/user-subscription-detail/${userid}`);
      setSubscriptions(response.data);
    } catch (error) {
      console.log('Error fetching data:', error);
      setSubscriptions([]);
    }
  };



  const openModal = (item,data) => {
    setSelectedItem(item);
    setSelectedorderNo(data);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const fetchAllOrdersHistory = async (order_label) => {
    const startIndexCalc = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndexCalc + itemsPerPage;
    const shopId = localStorage.getItem('shopId');
    try {
        const response = await axios.get(`${var_api}/api/food/normaldate/related-data/${shopId}/${order_label}/${startDate}/${endDate}`);
        const orderHistoryData = response.data;
        if (orderHistoryData.length > 0) {
            setOrderhistory(orderHistoryData.slice(startIndexCalc, endIndex));
            setTotalItems(orderHistoryData.length);
            setStartIndex(startIndexCalc); // Update startIndex state variable
        } else {
            console.log('No data found.');
            setOrderhistory([]);
            setTotalItems(0);
            setStartIndex(0); // Reset startIndex state variable
        }
    } catch (error) {
        console.log('Error fetching data:', error);
    }
};

  const fetchAllOrdersSummary = async (order_label) => {
    const shopId = localStorage.getItem('shopId');
    try {
      const response = await axios.get(`${var_api}/api/food/summary/related-data/${shopId}/${order_label}/${startDate}/${endDate}`);
      const ordersummary = response.data;
        setOrderSummary(ordersummary);
     
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
};

  const formatDate =(dateString) =>{
    const [day, month, year] = dateString.split('-');
    return `${year}-${month}-${day}`;
  }


  //get the value in search and set the setstate
  const handleSearchInput = (e) => {
    setSearchQuery(e.target.value)
    };



    
    const filteredOrders = Array.isArray(orderHistory) && orderHistory.filter((order)=> {
        const searchLower = searchQuery.toLowerCase();
        return(
            (order.order_no && order.order_no.toString().toLowerCase().includes(searchLower)) ||
            (order.cust_name && order.cust_name.toString().toLowerCase().includes(searchLower)) || 
            (order.captain_name && order.captain_name.toString().toLowerCase().includes(searchLower)) ||
            ((order.order_type === 0 && order.order_label === 0 && order.is_group_location_order === 0) && "normal order".includes(searchLower)) ||
            ((order.order_type === 0 && order.order_label === 1 && order.is_group_location_order === 0) && "credit order".includes(searchLower)) ||
            ((order.order_type === 0 && order.order_label === 1 && order.is_group_location_order === 1) && "group location order".includes(searchLower)) ||
            ((order.order_type === 1 && order.order_label === 0 && order.is_group_location_order === 0) && "table normal order".includes(searchLower)) ||
            ((order.order_type === 1 && order.order_label === 1 && order.is_group_location_order === 0) && "table credit order".includes(searchLower))
        )
    })

    //open the bill modal
    const  handleOpenPrintBill = (data,index) => {
        setIsOpenBill(true);
        setSelectedOrderBill(data);
        setBillIndex(index);
    }


    //to open the paymode edit modal
    const handleOpenEditModal = (order) => {
        const sortedPayModes = order.pay_modes.sort((a, b) => a.paymode_id - b.paymode_id);
        setIsPayModeEdit(true);
        setEditPaymodeOrder(order);
        setEditSelectedAmounts(sortedPayModes);
        setUpdateRemark(order.remarks);
    }


    const handleEditCardClick = (id, total) => {
        // Find the index of the pay mode with the given ID
        const index = editSelectedAmounts.findIndex(mode => mode.id === id);
        console.log("inddx", index,editSelectedAmounts);
        
        const updatedEditSelectedAmounts = editSelectedAmounts.map(item => ({
          ...item,
          amount: 0
      }));
      
      if (index !== -1) {
        updatedEditSelectedAmounts[index].amount = total;
      }
      
      // Update the component state with the new array
      setEditSelectedAmounts(updatedEditSelectedAmounts);
       
       
        console.log("after setting", updatedEditSelectedAmounts);
        const modeTO= editSelectedAmounts && editSelectedAmounts.reduce((sum, currentItem) => sum + currentItem.amount, 0)
        setEditModeTotal(modeTO)
      }
      
      
      const handleEditAmountChange = (index, value) => {
       const newSelectedAmounts = [...editSelectedAmounts];
       console.log("after setting textfield", newSelectedAmounts);
        // Set the amount for the corresponding index
        newSelectedAmounts[index].amount = parseFloat(value) || 0;
        console.log("after setting textfield", newSelectedAmounts);
        // Clear the card clicked index
        setEditSelectedAmounts(newSelectedAmounts);
        const modeTO= editSelectedAmounts && editSelectedAmounts.reduce((sum, currentItem) => sum + currentItem.amount, 0)
        setEditModeTotal(modeTO)
      }

     

      // Update the remark value in state when input changes
    const handleRemarkChange = (event) => {
    setUpdateRemark( event.target.value );
  };


  //update method for paymode
const handleUpdatePaymode = () => { 
    const ModeTotal = editSelectedAmounts && editSelectedAmounts.reduce((sum, currentItem) => sum + currentItem.amount, 0)
      if(editPaymodeOrder.total !== ModeTotal){
        Swal.fire({
                icon:"error",
                title:"Oops...",
                text:"Paymode Total is not Equal to Total Amount",
                confirmButtonColor:"#007500"
              })
    
      }
      else{
      setIsPaymodeEditLoading(true);
      const shopID = localStorage.getItem('shopId');
    
       console.log("update",editSelectedAmounts)
       const paymodesArray = editSelectedAmounts.map((mode, index) => ({
        amount:  mode.amount,
        id: mode.id,
        shop_id: parseInt(shopID),
        remarks : updateRemark,
        order_id: mode.order_id
    }));
    
    
       axios
       .put(`${var_api}/api/order-paymode/edit-order-paymodes`, paymodesArray)
       .then((response) => {
      
         toast('Updated Successfully',{autoClose:2000});
         fetchAllOrdersHistory(selectedOrderLabel.value);
         setIsPayModeEdit(false);
         setIsPaymodeEditLoading(false);
         console.log('Data updated successfully:', response.data.message);
       })
       .catch((error) => {
         console.error('Error updating data:', error);
         setIsPaymodeEditLoading(false);
         // Handle error, e.g., show an error message or update the UI accordingly
       });
      }
    };


    // useEffect(() => {
    //   initializeQZTray();
    //   return () => {
    //     disconnectFromQZTray();
    //   };
    // }, []);


    const signData = (toSign, privateKeyPem) => {
      const privateKey = forge.pki.privateKeyFromPem(privateKeyPem);
      const md = forge.md.sha512.create();
      md.update(toSign, 'utf8');
      const signature = privateKey.sign(md);
      return forge.util.encode64(signature);
    };


  
    const initializeQZTray = async () => {
      try {
        qz.security.setCertificatePromise(function(resolve, reject) {
          //Preferred method - from server
    //        fetch("assets/signing/digital-certificate.txt", {cache: 'no-store', headers: {'Content-Type': 'text/plain'}})
    //          .then(function(data) { data.ok ? resolve(data.text()) : reject(data.text()); });
    
          //Alternate method 1 - anonymous
    //        resolve();  // remove this line in live environment
    
          //Alternate method 2 - direct
          resolve("-----BEGIN CERTIFICATE-----\n" +
    "MIIECzCCAvOgAwIBAgIGAZBtKKX2MA0GCSqGSIb3DQEBCwUAMIGiMQswCQYDVQQG\n" +
    "EwJVUzELMAkGA1UECAwCTlkxEjAQBgNVBAcMCUNhbmFzdG90YTEbMBkGA1UECgwS\n" +
    "UVogSW5kdXN0cmllcywgTExDMRswGQYDVQQLDBJRWiBJbmR1c3RyaWVzLCBMTEMx\n" +
    "HDAaBgkqhkiG9w0BCQEWDXN1cHBvcnRAcXouaW8xGjAYBgNVBAMMEVFaIFRyYXkg\n" +
    "RGVtbyBDZXJ0MB4XDTI0MDYzMDA3MTgxOFoXDTQ0MDYzMDA3MTgxOFowgaIxCzAJ\n" +
    "BgNVBAYTAlVTMQswCQYDVQQIDAJOWTESMBAGA1UEBwwJQ2FuYXN0b3RhMRswGQYD\n" +
    "VQQKDBJRWiBJbmR1c3RyaWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMs\n" +
    "IExMQzEcMBoGCSqGSIb3DQEJARYNc3VwcG9ydEBxei5pbzEaMBgGA1UEAwwRUVog\n" +
    "VHJheSBEZW1vIENlcnQwggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQDd\n" +
    "snZmXeIgZFbb/TgULomwgto15aNCLZJBy86Jsx9DF05IhEQLOYJ8x8QgNc7bTFfc\n" +
    "ywnZvtsv2VdYip197R+g2LVL4f0FUTY3zTkV4RMWjgHt8ohuxww/MLKhy5R3Fknp\n" +
    "Xn5ovDUZOyIbs9gVCZepR7MvoCOcE/KP9R7gGF2l3zIpCI1EEntr13CIrnAZrhs+\n" +
    "JA5DHzlDEV0rZpCEQnysA4exgGpmNGpstDRy2Kdj3RtKXyTaH3dD/I2NQ5BUOBeJ\n" +
    "wVOWHgLxP9kE0s1i0XgDFSjt9Di8gk1SEK9BRHGComonX2vFW+c0a39W6zkhpPhv\n" +
    "wCQyUEJ4X359VrjCQU4TAgMBAAGjRTBDMBIGA1UdEwEB/wQIMAYBAf8CAQEwDgYD\n" +
    "VR0PAQH/BAQDAgEGMB0GA1UdDgQWBBTJ4yKVPVX6NPawD8kSkOSlpg1PcjANBgkq\n" +
    "hkiG9w0BAQsFAAOCAQEAzbyjcQ90HaxQKyazZUuB128q4dsVDXyIam6NMnT5Lkv9\n" +
    "AROiVnef2XityBucBJnC9Pf4HW98msTSeyPjSDPDLX6k3Ib8/f76gU6uu6WgApHI\n" +
    "EYBkIVpoeKq4ch35yg9SBZZNwv/xJeW6EjA9zLQ1Poje75AYOouXI2j6I0eqk6RD\n" +
    "MCtThm8CS6JlDcArMBUZwFk7otsWS90PQTIfaFkXbXoyFcSZmna1gOW0ZZ4AHgKY\n" +
    "rXVmWlaSLstc5jUFCKDDfTfIay9bqLjbc4EaTmayU8qdmJrhcvULFMmGCvu1FGgA\n" +
    "T+/fhNSt4qhYlDrqkTIAV1ICXEIOWnh/vHx3fb/5SA==\n" +
    "-----END CERTIFICATE-----");
      });
    
      qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
      qz.security.setSignaturePromise((toSign) => {
        return (resolve, reject) => {
          try {
            const privateKey = "-----BEGIN PRIVATE KEY-----\n" +
    "MIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQDdsnZmXeIgZFbb\n" +
    "/TgULomwgto15aNCLZJBy86Jsx9DF05IhEQLOYJ8x8QgNc7bTFfcywnZvtsv2VdY\n" +
    "ip197R+g2LVL4f0FUTY3zTkV4RMWjgHt8ohuxww/MLKhy5R3FknpXn5ovDUZOyIb\n" +
    "s9gVCZepR7MvoCOcE/KP9R7gGF2l3zIpCI1EEntr13CIrnAZrhs+JA5DHzlDEV0r\n" +
    "ZpCEQnysA4exgGpmNGpstDRy2Kdj3RtKXyTaH3dD/I2NQ5BUOBeJwVOWHgLxP9kE\n" +
    "0s1i0XgDFSjt9Di8gk1SEK9BRHGComonX2vFW+c0a39W6zkhpPhvwCQyUEJ4X359\n" +
    "VrjCQU4TAgMBAAECggEADFngx8jg4WNwWrbg9MWM9+T/tDNUJPU1/0K+rHmVZcml\n" +
    "cQZ8EKYGaN5A78x09vm/XAvXGNGjE3zdJWUqQLWWZqx8GTtWEIGa7taY9hOWhSdU\n" +
    "izBREomLW0qNa9R+3nhC649PT+rLCISnXKS92++EtV0gaf2sI4oRoEc3OPk9NRGU\n" +
    "zj3IEFLyx3E2saYI129w29evDMplo6C3NRtaAFEp+cWSG5DI+sCc+Juzbu1CblSl\n" +
    "9+8W3vV4TPK4lwbe1XmUauUFam0n+2j/X9Ipr+yZy92O1eP5isVRW9/wBnLunSz3\n" +
    "kNYD50DNgEkvWDrd6061JEzhSpnx4q+c48n4kdYcGQKBgQD9YHjrVAH1mMl6EZWH\n" +
    "kNy/QWnZ+OkB5okPDLLh4SzmDAiJ8DKqhO5nALtMkv3mBYceVGKMySmfTBMAuwh5\n" +
    "ogWMoyipoS3YHslzVMpJXzZtSTNXBY4SAoCtyYz9yKjcAg/Ykv7jMaiXelwfTkMq\n" +
    "wWQTTEe0ghDIFLOl2tpFK1iVOQKBgQDf/gds4IKLkd8yzvhLSAAd533I40mMp3LH\n" +
    "pBQ76vOt3FiIU7Xg0cuH6T5H/hhuW7N3BkvEXRSzABo98sRKDd3aCd5HEFkQqivh\n" +
    "auLRHFL+0hfyLQH8UzprJ+YtGk6AEx41UpeddsaBR6ezmsuj+gGA3MseSWiK81kM\n" +
    "Gw7TWyupqwKBgQCWuPGVQ2ba+uU3CQjDGOhHmRjnggrRh5qWaj5q4bD8s8HMlCb/\n" +
    "io3qlePU/EeusjEbWKU7DFsHGJDhSWEUQc49R71jkq6btkytn42pscrFndq9dF97\n" +
    "1SNIvfFx8hCIt21HrsGS0rJwUWhFcx+/akUmuaSZ+xkSfEYVbieTJ+LM+QKBgQCg\n" +
    "XrZeQ5fz6arOK9UlwRUkU/uA+wpLxA+v1FAQXjW9gwd4A8cygcKhOmO3E7vWN52U\n" +
    "WFOi8anCRw6Cz++tvaPbWjlk09WQ0uW77NUcNpVNkIpIf+TuDYDD+7sXC8CsfgOg\n" +
    "1dNrkACryuvM8cVfT74Hzw02UASjh3t36V+B/8KRJQKBgQCEq+FX7l6VrZIdvyAK\n" +
    "f+A4Vxr+S+cRvqXphilnjcuPEMTdRzTD8ROHVeQ+GajptVyC+ODOq1roBqp9VXRI\n" +
    "+zxhjo2vBbWjXKWD0fFssCPam9C5OzavJ6ZAI/FtujwjPnxoDOhrGzBOhF0nzAA3\n" +
    "5/7Etv6n5dzVZ9Px1dM0Vn1KSQ==\n" +
    "-----END PRIVATE KEY-----";
            const signedData = signData(toSign, privateKey);
            resolve(signedData);
          } catch (error) {
            reject(error);
          }
        };
      });
    
        qz.api.setSha256Type((data) => sha256(data));
        qz.api.setPromiseType((resolver) => new Promise(resolver));
        await connectToQZTray();
      } catch (error) {
        console.error('Failed to connect to QZ Tray', error);
        alert('Failed to connect to QZ Tray. Please ensure QZ Tray is running.');
      }
    };

  
    const connectToQZTray = async () => {
      try {
        await qz.websocket.connect({ retries: 5, delay: 1000 });
        setConnected(true);
        console.log('Connected to QZ Tray');
      } catch (error) {
        setConnectionAttempts((prevAttempts) => prevAttempts + 1);
        console.error('Failed to connect to QZ Tray:', error);
        if (connectionAttempts < 5) {
          setTimeout(connectToQZTray, 2000); // Retry connection after 2 seconds
        } else {
          alert('Failed to connect to QZ Tray after multiple attempts. Please ensure QZ Tray is running.');
        }
      }
    };
  
    const disconnectFromQZTray = async () => {
      if (connected && !disconnecting) {
        setDisconnecting(true);
        try {
          await qz.websocket.disconnect();
          console.log('Disconnected from QZ Tray');
        } catch (error) {
          console.error('Error disconnecting from QZ Tray:', error);
        } finally {
          setConnected(false);
          setDisconnecting(false);
        }
      }
    };
  
    const handlePrintss = async (index) => {
      console.log('Print button clicked');
      if (!connected) {
        console.error('Not connected to QZ Tray');
        alert('Not connected to QZ Tray. Please ensure QZ Tray is running.');
        return;
      }
      const billContent = document.getElementById(`billContent${index}`).innerHTML;
      const selectedPrinter = localStorage.getItem('selectedPrinter');
      console.log("localstorage printer",selectedPrinter);
      // if(selectedPrinter){
      try {
        const config = qz.configs.create(selectedPrinter); // Replace 'POS58 PRO' with your printer name
        const data = [
          { type: 'html', format: 'plain', data: billContent },
        ];
  
        await qz.print(config, data);
        console.log('Print command executed successfully');
        await handlePrint();
        setIsOpenBill(false);
      } catch (error) {
        console.error('Error printing', error);
      }
    // }
    //   else{
    //     alert("printer is not selected");
    //     setIsShowPrinters(true);
    //   }
    };


    //handle to change the print status
    const handlePrint = () => {
      const shopID = localStorage.getItem('shopId');
      const orderId = selectedOrderBill.id;
      console.log("ids", orderId);
       
      const updatedStatus = {
        print_status:1,
       }
    
       axios
       .put(`${var_api}/api/food/put/print/${shopID}/${orderId}`, updatedStatus)
       .then((response) => {
        toast('print status updated',{autoClose:2000})
         console.log('Data updated successfully:', response.data.message);
         fetchAllOrdersHistory(selectedOrderLabel.value);
       })
       .catch((error) => {
         console.error('Error updating data:', error);
       });
    };

    const toggleModalPrinter = () => {
      setIsShowPrinters(prevState => !prevState);
  };
  
    
    const selectPrinter = (printer) => {
      console.log(printer );
      localStorage.setItem('selectedPrinter', printer);
     toggleModalPrinter();
    };
    
    
    const removePrinter = () => {
      localStorage.removeItem('selectedPrinter');
      console.log( "remove printer",localStorage.removeItem('selectedPrinter'))
      toggleModalPrinter();
    };




    const handlePrints = async (index) => {
      try {
          const element = document.getElementById(`billContent${index}`);
    
          if (!element) {
              console.error('Element to print does not exist.');
              return;
          }
          setprintLoader(true);
    
          // Capture the element as an image
          const canvas = await html2canvas(element, {
              scale: 2, // Adjust the scale for better quality
              useCORS: true
          });
    
          // Convert the canvas to an image
          const imgData = canvas.toDataURL('image/png');
    
          // Get natural dimensions of the canvas to maintain aspect ratio
          const imgWidth = 1.98; // Width in inches
          const imgHeight = (canvas.height / canvas.width) * imgWidth; // Height maintaining aspect ratio
          
          // Create a PDF and add the image to it
          const pdf = new jsPDF({
              orientation: 'portrait',
              unit: 'in',
              format: [imgWidth, imgHeight] // Set the dimensions of the PDF
          });
    
          pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
    
          // Convert the PDF to base64
          const pdfData = pdf.output('datauristring');
          const printerName = localStorage.getItem("selectedPrinter");
    
          // Send the base64 PDF data to the backend
          await axios.post('http://localhost:3001/print', {
              data: pdfData.split(',')[1],
              printerName: printerName
          });
    
          toast('Print successfully');
          setprintLoader(false);
          await handlePrint()
      } catch (err) {
          console.error('Error printing:', err);
          setprintLoader(false);
          toast('Please run the print server!');
      }
    };



    const fetchShopDetails = async () => {
      const shopID = localStorage.getItem('shopId');
      try {
        const response = await axios.get(`${var_api}/api/shop/shopDetails/${shopID}`);
        setShopDetails(response.data);
      } catch (error) {
        console.log('Error fetching data:', error);
        setShopDetails([]);
      }
    };



  //   const handlePrints= async (index) =>{
  //     try {
  //     const element = document.getElementById(`billContent${index}`);
  //     if (!element) {
  //         console.error('Element to print does not exist.');
  //         return;
  //     }
  //     setprintLoader(true);
  
  //     // Convert the div to a PDF
  //     const options = {
  //         margin: [0, 0, 0, 0],
  //         filename: `bill_${index}.pdf`,
  //         image: { type: 'jpeg', quality: 0.98 },
  //         html2canvas: { scale: 2, useCORS: true },
  //         jsPDF: { unit: 'in', format:  [2.28, 1.90], orientation: 'portrait' }  // Adjust size as needed
  //     };
  
  //     const pdf = html2pdf().from(element).set(options);
  //     const pdfData = await pdf.outputPdf('datauristring');
  
  //     // Convert PDF to base64
  //     const base64Data = pdfData.split(',')[1];
  
  //     // Send the base64 PDF data to the backend
  //     await axios.post('http://localhost:3001/print', {
  //         data: base64Data,
  //     });
  //     toast('Print successfully');
  //      setprintLoader(false);
  //     await handlePrint()
  //     //need to change print status 0 to 1 so call the update api
  // } catch (err) {
  //     console.error('Error printing:', err);
  //      setprintLoader(false);
  //     toast('Please run the print server!')
  // } 
  // };
    
    
    
  


  return(
    <div class="whole"  style={{ textAlign: "center" ,height:"100%"}}>
    <div className="container-fluid">
        <nav className="navbar" style={{ boxShadow: "0px 0px 1px 0px black", backgroundColor: "green", border: "none", padding: "5px", height: "50px", width: "100%", position: "relative"}}>
            <div style={{ display: "flex"}}>
                <h3 style={{ fontFamily: "cursive", fontWeight: "bolder",marginLeft: "400px", color: "white" }}>{ shopName }</h3>
                <button className="btn" onClick={handleToRedirectTableOrder} style={{  backgroundColor: "white", color: "green", marginLeft:"150px" }}>
                <i class="bi bi-easel"></i> Go to Table Order
                </button>
                {role === "Chef" || role === "Captain" || role === "Waiter" ? null : (
                <button className="btn" onClick={handleButtonClickhome} style={{ backgroundColor: "white", color: "green" , marginLeft:"10px"}}>
                    <i class="bi bi-house"></i> Go to Home
                </button> )}
            </div>
        </nav>
    </div>
      <div>
            <br />
        <h3 style={{ fontFamily: "cursive", fontWeight: "bolder",textAlign: "center", color: "black" }}>Order History</h3>
            <div className="card" style={{ width: '50%', height:'auto',margin:"auto"}}>
    <div className="card-body d-flex flex-column justify-content-center align-items-center">
        <div style={{ display: 'flex', justifyContent: 'center', gap: "35px" }}>
        <Select
                options={labelCodeOptions}
                placeholder="Select Order"
                value={selectedOrderLabel}
                onChange={handleOrderLabelSelectChange}
                isSearchable={true}
                menuPlacement="top"
                className="customSelectFood"
            />
            <label style={{ fontFamily: "serif", fontWeight: "bolder" }}>Date Range: <span className="text-primary" onClick={handleOpenSelectDate}>{startDate} to {endDate}</span>
            <Tooltip
        isOpen={tooltipOpen}
        target="DateIcon" // Specify the target ID of the button
        toggle={toggleTooltip}
        placement="bottom"
      >
        Change Date
      </Tooltip>
            <i className="bi bi-calendar text-primary"  id="DateIcon" style={{ marginLeft: '5px', fontSize: "20px" }} onClick={()=>{handleOpenSelectDate(); toggleTooltip();}}></i> </label>
            <button className="btn btn-danger text-white p-1" onClick={handleOpenOrderSumModal}>Order Summary</button>

        </div>

        {/* <div style={{ display: 'flex', alignItems: 'center', gap: "10px", marginTop: "10px" }}>
            <Select
                options={labelCodeOptions}
                placeholder="Select Order"
                value={selectedOrderLabel}
                onChange={handleOrderLabelSelectChange}
                isSearchable={true}
                menuPlacement="top"
                className="customSelectFood"
            />
            <button className="btn btn-danger text-white p-1" onClick={handleOpenOrderSumModal}>Order Summary</button>
        </div> */}
    </div>
</div>

        </div>
        <input class="form-control" type="Search" placeholder="Search" id="example-search-input" 
      style={{width:"230px",borderRadius:"15px",border:"1px solid black",float:"right",marginRight:"30px"}} onChange={handleSearchInput}/>
<br />
            <br />
            
            <div className="table-container" style={{ height: "auto", overflowX: 'auto', overflowY: 'scroll' }}>
                <table class="table table-hover small-first-col" style={{ border: "none", borderColor: "grey", width: "95%", margin: "auto" ,height:"50%"}}>
                    <thead>
                        <tr style={{ fontWeight: "bolder" }}>
                            <th scope="col">S.No</th>
                            <th scope="col">Order No</th>
                            <th scope="col">Order Type</th>
                            <th scope="col">Cust Name</th>
                            <th scope="col">Captian Name</th>
                            <th scope="col">No.of Items</th>
                            <th scope="col">Pay Mode</th>
                            <th scope="col">Total Amount</th>
                            {
                   subscriptions  && subscriptions.is_tableorder === 0 && 
                            <th scope="col">Print</th>}
                            <th scope='col'>Action</th>
                        </tr>
                    </thead>
                    <tbody class="body-half-screen">
                        {filteredOrders.length > 0 ? (
                            filteredOrders.slice().reverse().map((data, index) => (
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">{startIndex + index + 1}</td>
                                    <td>{data.order_no}</td>
                                    <td>
                                    {data.order_type === 0 && data.order_label === 0 && data.is_group_location_order ===0 && "Normal Order"}
                                    {data.order_type === 0 && data.order_label === 1 && data.is_group_location_order ===0 && "Credit Order"}
                                    {data.order_type === 0 && data.order_label === 1 && data.is_group_location_order ===1 && "Group Location Order"}
                                    {data.order_type === 1 && data.order_label === 0 && data.is_group_location_order ===0 && "Table Normal Order"}
                                    {data.order_type === 1 && data.order_label === 1 && data.is_group_location_order ===0 && "Table Credit Order"}</td>
                                    <td>{data.cust_name ? data.cust_name : "-"}</td>
                                    <td>{data.captain_name}</td>
                                    <td style={{cursor: 'pointer', color:'red', fontWeight:'bold'}} onClick={() => openModal(data.items, data)}>{data.items.length} {data.items.length === 1 ? 'Item' : 'Items'}</td>
                                    <td>
                                    {data.pay_modes.filter(mode => mode.amount > 0).map((mode, index) => (
                                    <React.Fragment key={index}>
                                      {index > 0 && ", "}
                                      {mode.pay_mode_name}
                                      </React.Fragment>
                                    ))}
                                    </td>
                                    <td>{(data.total).toFixed(2)}</td>
                                    {
                   subscriptions  && subscriptions.is_tableorder === 0 && 
                                    <td>
                                    <Tooltip
        isOpen={printtooltipOpen[index]} // Use tooltip state based on index
        target={`printButton${index}`} // Specify the target ID of the button
        toggle={() => togglePrintTooltip(index)}
        placement='left'
      >
         Print
        </Tooltip>
                                    <button className='btn-md btn-primary'  id={`printButton${index}`}  style={{ borderRadius: "5px", border: "none", padding: "7px", margin:"5px", padding:"0px auto" }} onClick={()=>{handleOpenPrintBill(data,index); togglePrintTooltip(index);}}><i class="bi bi-printer"></i>{" "}</button>
                                    </td>}
                                    <td>
                                    <Tooltip
        isOpen={puttooltipOpen[index]} // Use tooltip state based on index
        target={`putButton${index}`} // Specify the target ID of the button
        toggle={() => togglePutTooltip(index)}
        placement='right'
      >
          Edit Paymode
        </Tooltip>
                                    <button className="btn btn-danger" id={`putButton${index}`}  style={{borderRadius: "5px", border: "none", margin:"5px", padding:"0px 6px" }} onClick={()=>{handleOpenEditModal(data); togglePutTooltip(index);}}><i class="bi bi-pencil-square" style={{fontSize:"14px"}}></i></button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="7" style={{ textAlign: "center" }}>
                                    No Data Available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <br />
                <Pagination
    activePage={currentPage}
    itemsCountPerPage={itemsPerPage}
    totalItemsCount={totalItems} // Update to reflect total items for pagination
    pageRangeDisplayed={5}
    onChange={handlePageChange}
    itemClass="page-item"
    linkClass="page-link"
    innerClass="pagination justify-content-end"
    activeClass="active-pagination" // Custom class for the active pagination item
/>
            </div>
            <Modal
             show={isModalOpen} 
             onHide={closeModal} 
             backdrop="static"
                keyboard={false} 
                size="md" style={{ borderRadius: '20px' }}>
                    <Modal.Header closeButton style={{ backgroundColor: "rgba(6, 105, 16, 0.367)", padding: "12px" }}>
                        <Modal.Title style={{ fontSize: "19px", fontWeight: "bold" }}>Item Details - (Order No:{ selectedOrderNo && selectedOrderNo.order_no})</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
  <div style={{ borderBottom: "2px solid black", paddingBottom: "10px", marginBottom: "10px", display: 'flex', justifyContent: 'space-between' }}>
  <div style={{ width: "30%", textAlign: "left" }}><strong>Food Image</strong></div>
    <div style={{ width: "30%", textAlign: "left" }}><strong>Food Name</strong></div>
    <div style={{ width: "20%", textAlign: "center" }}><strong>Quantity</strong></div>
    <div style={{ width: "20%", textAlign: "center" }}><strong>Unit Price</strong></div>
    <div style={{ width: "20%", textAlign: "center" }}><strong>Item Total</strong></div>
  </div>
  {selectedItem && selectedItem.map((item, index) => {
    const total = item.quantity * item.unit_price;
    return (
      <div className="content" key={index} style={{ borderBottom: "1px solid black", paddingBottom: "5px", marginBottom: "5px", display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: "30%", textAlign: "center" }}> <img src={`${var_api}/${item.image}`} alt="food_image" height="50px" width="50px" /> </div>
        <div style={{ width: "30%", textAlign: "left" }}>{item.food_name}</div>
        <div style={{ width: "20%", textAlign: "center" }}>{item.quantity}</div>
        <div style={{ width: "20%", textAlign: "center" }}>{item.unit_price}</div>
        <div style={{ width: "20%", textAlign: "center" }}>{total}</div>
      </div>
    );
  })}
  <div style={{ textAlign: "right", marginRight:"35px"}}>
    <strong>Order Total:  </strong>
    {selectedItem && selectedItem.length > 0 ? (
        <span style={{ color: "red",fontWeight:"bold"}}>
            {selectedItem.reduce((acc, item) => acc + (item.quantity * item.unit_price), 0)}
        </span>
    ) : (
        <span style={{ color: "red",fontWeight:"bold"}}>0</span> // Default value if selectedItem is null or empty
    )}
</div>

  <br />
  <div style={{ textAlign: "center"}}>
    <Button variant="danger" onClick={closeModal}>
      Close
    </Button>
  </div>
</Modal.Body>


            </Modal>

            <Modal
                show={isOpenSelectDate}
                onHide={handleCloseDateConfirmation}
                backdrop="static"
                keyboard={false}
                size="md"
                style={{ borderRadius: '20px' }}
            >
                <Modal.Header closeButton style={{ backgroundColor: "rgba(6, 105, 16, 0.367)", padding: "12px" }}>
                    <Modal.Title style={{ fontSize: "19px", fontWeight: "bold" }}>Select Date Range</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                    <div className="col">
                            <label style={{ color: "green", fontFamily: "serif", fontWeight: "bolder" }}>Start Date:</label>
                            <input
                                type='date'
                                name='startDate'
                                style={{ border: "2px ridge green", borderRadius: "15px", height: "35px", padding: "10px" }}
                                placeholder='choose date'
                                onChange={handleStartDateChange}
                                value={formatDate(startDate)}
                            />
                        </div>
                        <div className="col">
                            <label style={{ color: "green", fontFamily: "serif", fontWeight: "bolder" }}>End Date:</label>
                            <input
                                type="date"
                                name='date'
                                placeholder="choose date"
                                onChange={handleEndDateChange}
                                style={{ border: "2px ridge green", borderRadius: "15px", height: "35px", padding: "10px" }}
                                value={formatDate(endDate)}
                                min={formatDate(startDate)}
                            />
                        </div>
                    </div>
                    <br />
                    <div style={{ textAlign: "center" }}>
                        <Button variant="danger" onClick={handleCloseDateConfirmation}>Close</Button>{' '}
                        <Button variant="success" style={{ marginRight: "5px" }} onClick={handleSelectDateRange}>Ok</Button>
                    </div>
                </Modal.Body>
            </Modal>
            
            <Modal
    show={isOpenOrderSummary}
    onHide={handleCloseOrderSumModal}
    backdrop="static"
    keyboard={false}
    size="md"
    style={{ borderRadius: '20px' }}
>
    <Modal.Header closeButton style={{ backgroundColor: "rgba(6, 105, 16, 0.367)", padding: "12px" }}>
        <Modal.Title style={{ fontSize: "19px", fontWeight: "bold" }}>Order Summary</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="row">
            <div className="col">
                <h6>Order Type </h6>
                <h4>{selectedOrderLabel.label}</h4>
            </div>
            <div className="col">
                <h6>Count </h6>
                <h5>{orderSummary.order_count}</h5>
            </div>
            <div className="col">
                <h6>Total Price </h6>
                <h5>{orderSummary.total_sum ? (orderSummary.total_sum).toFixed(2) : 0}</h5>
            </div>
        </div>
        <br />
        <div style={{ textAlign: "center" }}>
            <Button variant="danger" onClick={handleCloseOrderSumModal}>
                Close
            </Button>
        </div>
    </Modal.Body>
</Modal>


<Modal
        show={isShowPrinters}
        onHide={toggleModalPrinter}
        backdrop="static"
        keyboard={false}
        size="md" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <Modal.Header closeButton style={{padding:"12px",textAlign:"center"}}>
        <Modal.Title style={{fontSize:"19px", fontWeight:"bold",textAlign:"center"}}> Available Printers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div style={{ margin: "auto", padding: "20px" }}>
      <h2 style={{ fontWeight: "bold", fontFamily: "serif", textAlign:"center" }}>Select a Printer</h2>
      <br/>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        {printers.map(printer => (
          <li key={printer} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
            <span style={{fontWeight:"bold", fontSize:"18px"}}><i class="bi bi-printer"></i>{" "}{printer} {" "}
            {
              localStorage.getItem('selectedPrinter') === printer  ?  <span class="badge rounded-pill bg-success" style={{fontSize:"12px"}}> {" "}Connected </span> : <span class="badge rounded-pill bg-danger" style={{fontSize:"12px"}}> {" "} Not Connected </span>
            }
            </span>
            {
              localStorage.getItem('selectedPrinter') === printer  ?  <button onClick={() => removePrinter()} className="btn btn-danger">Remove</button> :  <button onClick={() => selectPrinter(printer)} className="btn btn-success">Connect</button>
            }
           
          </li>
        ))}
      </ul>
    </div>
    {/* <ModalFooter>
         <Button variant="secondary"  onClick={toggleModalPrinter}>
            Close
          </Button>{' '} 
    </ModalFooter> */}
       
        </Modal.Body>
      </Modal>


<Modal
    show={isOpenBill}
    onHide={()=>setIsOpenBill(false)}
    backdrop="static"
    keyboard={false}
    size="md"
    style={{ borderRadius: '20px' }}
>
    <Modal.Header closeButton style={{ backgroundColor: "rgba(6, 105, 16, 0.367)", padding: "12px" }}>
        <Modal.Title style={{ fontSize: "19px", fontWeight: "bold" }}>Order Bill</Modal.Title>
    </Modal.Header>
    <Modal.Body >
    
        <br />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ marginBottom: '10px' }} id={`billContent${billIndex}`}>
            <Bill order={selectedOrderBill} shopDetails={shopDetails} ref={(el) => (componentRefs.current[billIndex] = el)} />
        </div>
        <div style={{ textAlign: 'center' }}>
            {/* <ReactToPrint
                trigger={() => (
                    <button className='btn-md btn-primary' style={{ borderRadius: "5px", border: "none", padding: "7px", margin:"5px" }} ><i class="bi bi-printer"></i>{" "}Print</button>
                )}
                content={() => componentRefs.current[billIndex]}
            /> */}
             {
          printLoader ? (
            <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          ) : printingType == "directPrint" ?   
             <button
        className='btn-md btn-warning'
        id='printButton'
        style={{ borderRadius: '5px', border: 'none', padding: '7px', margin: '5px', padding: '0px auto' }}
        onClick={() => handlePrints(billIndex)} // Adjust how you pass index here
      >
        <i className='bi bi-printer-fill'></i> Print
      </button> : (
        <ReactToPrint
        trigger={() => (
            <button className='btn-md btn-primary' style={{ borderRadius: "5px", border: "none", padding: "7px", margin:"5px" }} ><i class="bi bi-printer"></i>{" "}Print</button>
        )}
        content={() => componentRefs.current[billIndex]}
    />
      )
}
        </div>
    </div>
    </Modal.Body>
</Modal>

<Modal
    show={isPayModeEdit}
    onHide={()=>setIsPayModeEdit(false)}
    backdrop="static"
    keyboard={false}
    size="md"
    style={{ borderRadius: '20px' }}
>
    <Modal.Header closeButton style={{ backgroundColor: "rgba(6, 105, 16, 0.367)", padding: "12px" }}>
        <Modal.Title style={{ fontSize: "19px", fontWeight: "bold" }}>Update Paymode</Modal.Title>
    </Modal.Header>
    <Modal.Body >
    <div className="p-3">
           
           {
    isPaymodeEditLoading ? (
       <div className="text-center">
         <div className="spinner-border" role="status">
           <span className="visually-hidden">Loading...</span>
         </div>
       </div>
     ) : (
       <Button variant="success"  onClick={handleUpdatePaymode} style={{float:"right"}}>
         <i className="bi bi-vector-pen"></i> Update
         </Button>
     )
   }
   <br/>
             <h6 style={{color:"blue", textAlign:"left"}}>Order No: <b>{editPaymodeOrder ? editPaymodeOrder.order_no: " "}</b></h6>
             <h6 style={{color:"green"}}>Total Amount: <b>{editPaymodeOrder ? editPaymodeOrder.total : ""}</b> <span style={{float:"right"}}>Paymode Total: 
             <b style={{color: editPaymodeOrder && editPaymodeOrder.total == editModeTotal ? "green" : "red"}}>{editSelectedAmounts ? editSelectedAmounts.reduce((sum, currentItem) => sum + currentItem.amount, 0) : ""}</b></span></h6>
             
             <div className="row">
               <div className="col">
               <table class="table table-hover">
     <thead>
       <tr>
         <th scope="col" style={{textAlign:"center"}}>Paymode</th>
         <th scope="col" style={{textAlign:"center"}}>Amount</th>
       </tr>
     </thead>
     <tbody>
       {
        editSelectedAmounts && editSelectedAmounts
         .filter(mode => mode.pay_mode_name !== "CREDIT ORDER" && mode.pay_mode_name !== "GROUP LOCATION CREDIT ORDER")
         .map((mode,index)=>(
       <tr key={index}>
         <td>
           <div class={`card ${editSelectedAmounts[index].amount > 0 ? 'bg-success' : ''} ${editSelectedAmounts[index].amount > 0 ? 'text-light' : ''}`} style={{width:"auto", height:"auto", margin:"0px"}} onClick={() =>handleEditCardClick(mode.id, editPaymodeOrder && editPaymodeOrder.total)}>
             <div class="card-body" style={{textAlign:"center"}}>
               {mode.pay_mode_name}
             </div>
           </div>
         </td>
         <td>
          <Input  type="number" id={`paymodeEditAmount${mode.id}`}  value={mode.amount} onChange={(e) => handleEditAmountChange(index, e.target.value)} style={{width:"140px",margin:"auto"}} min="0" />    
         </td>
       </tr>
         ))
       }
       </tbody>
           </table>
               {/* <label class="form-label text-success fw-bold m-0" style={{fontFamily:"serif"}}>Paymode</label>
               <Select
           options={payModeOptions}
           placeholder="Select mode"
           value={editpaymode}
           onChange={this.handlePayModeEditChange}
           isSearchable={true}
           className="customSelectpaymode"
       /> */}
               </div>
               <div className="col">
               <label class="form-label text-success fw-bold m-0" style={{fontFamily:"serif"}}>Remark</label>
               <Input  type="text" id="editremark" placeholder="Remark" required  style={{width:"280px"}} value={updateRemark} onChange={handleRemarkChange} />    
               </div>
             </div>
           </div>
           <div style={{textAlign:"center"}}>
         
             {" "}
            <Button variant="danger"  onClick={()=>setIsPayModeEdit(false)}>
               Close
             </Button>{' '} 
            
            </div>
    </Modal.Body>
</Modal>

    
</div>


    
)
};


export default Orderhistory;