import React, { useState,useEffect } from "react";
import { useLocation,Link,useParams   } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Swal from "sweetalert2";
import Splash from "../../images/splash/shop_logo.jpeg";
import { collection, addDoc, onSnapshot, query,add} from "firebase/firestore";
import{ db } from "../../firebase.js";
import './foodm.css';
import axios from 'axios';
import { var_api } from "../constant";
import { text } from "@fortawesome/fontawesome-svg-core";
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';

const FoodMaster = (props) => {
    const location = useLocation();
    const { id } = useParams();
    const searchparams = new URLSearchParams(location.search);
    const[images,setImages]=useState([]);
    const[categories,setCategories]=useState([]);
    const [ isEdit,setIsEdit ] = useState(false);
  const [ show,setShow ] = useState(false);
  const [currencyOptions, setCurrencyOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [searchFood,setSearchFood] = useState('');
    const [ loading,setLoading ] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState({ value: 'SGD', label: 'SGD' });
    const [selectedCategory, setSelectedCategory] = useState();
    const [activeCount, setActiveCount] = useState(null);
    const [inActiveCount, setInactiveCount] = useState(null);
    const [switches, setSwitches] = useState([]);
    const [formData, setFormData] = useState({
      food_name:'',
      item_code:'',
      text:'',
      currency:'',
      category_id:'',
      category_name:'',
      food_image:null,  
      category_code:'',
      uom:'',
      price:null,
      cost: null
    });

    const [updateformData, setUpdateFormData] = useState({
      id:null,
      food_name:'',
      item_code:'',
      text:'',
      currency:'',
      category_id:'',
      category_name:'',
      food_image:null,  
      category_code:'',
      uom:'',
      price:null,
      cost: null
    });

   const title = searchparams.get('title');
   const code = searchparams.get('code');
   const cateid = searchparams.get('id');
   const shopId =  localStorage.getItem('shopId');
    
    // console.log("title:",title);
    console.log("code:",cateid);

    // const sub = location.state && location.state.sub;
    
   
    
    const handleForm = async () => {
      setShow(true);
      // const swalOptions = {
      //   title: 'Add Food',
      //   html:
      //     '<label class="swalLa">Food Name</label>'+
      //     '<br/>'+
      //     '<input id="swal-input1" class="swal2-input selin" type="text" name="food name" placeholder="food name">' +
      //     '<br/>'+
      //     '<br/>'+
      //     "<label class='swalLa'>Item's Code </label>"+
      //     '<input id="swal-input2" class="swal2-input selin" type="text" name="item code" placeholder="item code">' +
      //     '<br/>'+
      //     '<br/>'+
      //     '<label class="swalLa">Description</label>'+
      //     '<input id="swal-input3" class="swal2-input selin" type="text" name="text" placeholder="text">' +
      //     '<br/>'+
      //     '<br/>'+
      //     '<label class="swalLa">Select Currency</label>'+
      //     '<select id="swal-input4" class="swal2-input selin">' +
      //     '<option value="" disabled selected>Select Currency</option>' +
      //     '<option value="USD">USD</option>' + 
      //     '<option value="INR">INR</option>' +
      //     '<option value="SGD">SGD</option>' +
      //     '<option value="EUR">EUR</option>' +
      //     '</select>'+
      //     '<br/>'+
      //     '<br/>'+
      //     "<label class='swalLa'>Item's Price </label>"+
      //     '<input id="swal-input5" class="swal2-input selin" type="number" name="price" placeholder="Price">' +
      //     '<br/>'+
      //     '<br/>'+
      //     '<label class="swalLa">Select Category </label>'+
      //     '<select id="swal-input6" class="swal2-input selin">' +
      //     '<option value="" disabled selected>Select Category</option>' +
      //     categoryOptions +
      //     '</select>' +
      //     '<br/>'+
      //     '<br/>'+
      //     "<label class='swalLa'>Item's Cost </label>"+
      //     '<input id="swal-input7" class="swal2-input selin" type="number" name="cost" placeholder="Cost">' +
      //     '<br/>'+
      //     '<br/>'+
      //     "<label class='swalLa'>Item's UOM </label>"+
      //     '<input id="swal-input8" class="swal2-input selin" type="text" name="uom" placeholder="UOM">' +
      //     '<br/>'+
      //     '<br/>'+
      //     "<label class='swalLa'>Item's Image </label>"+
      //     '<input id="swal-input9" class="swal2-input selin" name="file" placeholder="select file" type="file">',
      //   inputValidator: (value) => {
      //     return new Promise((resolve) => {
      //       if (value) {
      //         resolve();
      //       } else {
      //         resolve('You need to select a category');
      //       }
      //     });
      //   },
      //   focusConfirm: false,
      //   confirmButtonColor: '#007500',
      //   confirmButtonText: 'Submit',
      //   showCancelButton: true,
      //   cancelButtonColor: '#ff0000', 
      //   preConfirm: () => {
      //     const input1 = document.getElementById('swal-input1').value;
      //     const input2 = document.getElementById('swal-input2').value;
      //     const input3 = document.getElementById('swal-input3').value;
      //     const input4 = document.getElementById('swal-input4').value;
      //     const input5 = document.getElementById('swal-input5').value;
      //     const input6 = document.getElementById('swal-input6').value;
      //     const input7 = document.getElementById('swal-input7').value;
      //     const input8 = document.getElementById('swal-input8').value;
      //     const input9 = document.getElementById('swal-input9').files[0];
    
      //     if (!input1 || !input2 || !input3 || !input4 || !input5 || !input6 || !input7 || !input8) {
      //       Swal.showValidationMessage('Please fill in all the required fields');
      //     } else {
      //       return [input1, input2, input3, input4, input5, input6, input7, input8, input9];
      //     }
      //   }
      // };
    
      // const { value: formValues } = await Swal.fire(swalOptions);
    
      // if (formValues) {
      //   const [foodName, itemCode, text, currency, price, category, cost, uom, image] = formValues;
      //   const shopName = localStorage.getItem('shopName');
    
      //   // Make sure all required values are defined
      //   if (foodName && itemCode && text && currency && price && category && cost && uom) {
      //     try {
           
      //   if (!id || !title) {
      //     console.error('`id` and/or `title` are not defined.');
      //     return;
      //   }else{
      //     console.log('ok  here');
      //   }

            // // const parentDocRef = db.collection('categories').doc(id);
            // const docRef = await addDoc(collection(db, `shops/${shopName}/categories/${id}/foodMaster`), {
            //   food_name: foodName,
            //   item_code: itemCode,
            //   text: text,
            //   currency: currency,
            //   price: price,
            //   category: category,
            //   image:imageUrl,
            //   category_code:code,
            // });


            //create a FormData object to send the file
    //         const formsData = new FormData(); 
    //         formsData.append('food_name',foodName);
    //         formsData.append('item_code',itemCode);
    //         formsData.append('text',text);
    //         formsData.append('currency',currency);
    //         formsData.append('price',price);
    //         formsData.append('category_name',category);
    //         formsData.append('food_image',image);
    //         formsData.append('category_code',code);
    //         formsData.append('category_id',cateid);
    //         formsData.append('shop_id',shopId);
    //         formsData.append('cost',cost);
    //         formsData.append('uom',uom);

    //           // Send the order data to the Node.js API using axios
    //   const response = await fetch(`${var_api}/api/foodMaster/api/post`, {
    //     method: 'POST',
    //   body: formsData,
    // });
    //   if (response.status === 200) {
    //     // Handle successful order submission to both databases
    //     Swal.fire({
    //       icon: 'success',
    //       text: 'food added successfully',
    //       confirmButtonColor: '#007500',
    //     });
    //     fetchData()
    //   }else{
    //     Swal.fire({
    //       icon: 'error',
    //       text: 'error occured',
    //       confirmButtonColor: '#007500',
    //     });
    //   }
    
    //         // console.log('Document written with ID:', docRef.id);
    //       } catch (error) {
    //         console.error('Error posting data to Firestore:', error);
    //       }
    //     } else {
    //       console.error('One or more fields are undefined:', formValues);
    //     }
    //   }
    };



    const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    };


    useEffect(() => {
      fetchData();
      fetchcurrecny();
      // setLoading(true);
      // const shopName = localStorage.getItem('shopName');
      // const colRef = collection(db, `shops/${shopName}/categories/${id}/foodMaster`);
      // const q = query(colRef);
      // const unsub = onSnapshot(q, (snapshot) => {
      //   setImages(snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() })));
      //   setLoading(false);
      // });
  
      // return () => {
      //   unsub();
      // };
    }, []);

    //fetch currency
  const fetchcurrecny = async () => {
    try {
      const response = await axios.get(`${var_api}/api/currency-master/get-currency`);
      const responseData = response.data;
      
      if (Array.isArray(responseData)) {
         // Map currency names to currencyOptions
      const options = responseData.map(currency => ({
        value: currency.currency_name,
        label: currency.currency_name
      }));

      setCurrencyOptions(options);
      } else {
        // Handle the case when responseData is not an array
        console.error('API response is not an array:', responseData);
        
      }
    } catch (error) {
      console.log('Error fetching data:',error);
    }
  };


    const fetchData = async () => {
      setLoading(true);
     try {
      const response = await axios.get(`${var_api}/api/foodMaster/api/subFood/${cateid}` );
      const use = response.data

      const initialSwitches = use.map((location) => location.is_active === 1);
      console.log("initialSwitches",initialSwitches)
      setSwitches(initialSwitches);

      // Initialize counters
      let activeCount = 0;
      let inactiveCount = 0;

      // Iterate through the data array and count active and inactive items
      use.forEach(item => {
        if (item.is_active === 1) {
          activeCount++;
        } else if (item.is_active === 0) {
          inactiveCount++;
        }
      });
      setActiveCount(activeCount);
      setInactiveCount(inactiveCount);

     console.log("Active count:", activeCount);
     console.log("Inactive count:", inactiveCount);

    console.log("categories",use)
      setImages(use);
      setLoading(false);
    }catch (error) {
      console.log('Error fetching data:', error);
      
      }
    };

    // console.log(JSON.stringify(images))


    const getCategories = async () => {
      setLoading(true);
      const shopId = localStorage.getItem('shopId');
     try {
      const response = await axios.get(`${var_api}/api/uom-master/get-uom/${shopId}` );
      const use = response.data
    
       setCategories(use);
  //   // Now you can use options as needed, e.g., set it in the state
       setCategoryOptions( use.map((cate)=>(
        {value:cate.uom_name, label:cate.uom_name}
       )));
    }catch (error) {
      console.log('Error fetching data:', error);
      
      }
    };
 
  // const shopName = localStorage.getItem('shopName');
  // const colRef = collection(db, `shops/${shopName}/categories`);
  // const q = query(colRef);
  
  // onSnapshot(q, (snapshot) => {
  //   const categoriesData = snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }));
  //   // Now that you have the categories data, generate options and update state
  //   const options = categoriesData.map((categorie) => (
  //     `<option key="${categorie.id}" value="${categorie.data.category_name}">${categorie.data.category_name}</option>`
  //   ));
    
  //   // Update state with categories data
  //   setCategories(categoriesData);
  //   // Now you can use options as needed, e.g., set it in the state
  //   setCategoryOptions(options.join(''));
  // });



useEffect(() => {
  getCategories();
}, []); // Call getCategories when component mounts



const filteredFood = images.filter((image)=> {
  const searchlower = searchFood.toLowerCase();
  return(
    image.food_name.toLowerCase().includes(searchlower)
  
  )
})

const noResults = filteredFood.length === 0;

const handleFoodUpdate = async (data) => {
  console.log("data",data)
  setIsEdit(true);
  setUpdateFormData({
    id: data.id,
    food_name:data.food_name,
      item_code: data.item_code,
      text: data.text,
      currency: data.currency,
      category_id: data.category_id,
      category_name: data.category_name,
      food_image: data.image,  
      category_code: data.category_code,
      uom: data.uom,
      price: data.price,
      cost: data.cost
  })

  // const { value: formValues } = await Swal.fire({
  //   title: 'Update Item',
  //   html: `
  //     <label class="swalLa">Food Name</label>
  //     <br/>
  //     <input id="swal-input1" class="swal2-input selin" name="food_name" placeholder="Item Name" value="${data.food_name || ''}"> 
  //     <br/>
  //     <br/>
  //     <label class="swalLa">Items's Code</label>
  //     <input id="swal-input2" class="swal2-input selin" name="item_code" placeholder="Item Code" value="${data.item_code || ''}">
  //     <br/>
  //     <br/>
  //     <label class="swalLa">Items's Price</label>
  //     <input id="swal-input3" class="swal2-input selin" name="price" placeholder="price" value="${data.price || ''}">
  //     <br/>
  //     <br/>
  //     <label class="swalLa">Items's Cost</label>
  //     <input id="swal-input41" class="swal2-input selin" name="cost" placeholder="Cost" value="${data.cost || ''}">
  //     <br/>
  //     <br/>
  //     <label class="swalLa">Items's UOM</label>
  //     <input id="swal-input5" class="swal2-input selin" name="uom" placeholder="UOM" value="${data.uom || ''}">
  //     <br/>
  //     <br/>
  //     <label class="swalLa">Items's Image</label>
  //     <input id="swal-input61" class="swal2-input selin" name="image" type="file" accept="image/*">
  //   `,
  //   focusConfirm: false,
  //   confirmButtonColor: '#007500',
  //   confirmButtonText: 'Update',
  //   showCancelButton: true,
  //   cancelButtonColor: '#ff0000', 
  //   preConfirm: () => {
  //     return new Promise(async (resolve) => {
  //       const input1 = document.getElementById('swal-input1').value;
  //       const input2 = document.getElementById('swal-input2').value;
  //       const input3 = document.getElementById('swal-input3').value;
  //       const input4 = document.getElementById('swal-input41').value;
  //       const input5 = document.getElementById('swal-input5').value;
  //       const input6 = document.getElementById('swal-input61').files[0];

  //       if (!input1 || !input2) {
  //         Swal.showValidationMessage('Please fill in all the required fields');
  //         resolve(null);
  //       } else {
  //         console.log("sub categories", data)
  //         // Create a FormData object to handle the file upload
  //         const formData = new FormData();
  //         formData.append('food_name', input1 || data.food_name);
  //         formData.append('item_code', input2 || data.item_code);
  //         formData.append('price', parseFloat(input3) || data.price);
  //         formData.append('cost', parseFloat(input4) || data.cost);
  //         formData.append('uom',(input5) || data.uom);
  //         formData.append('food_image', input6 || data.image);
  //         formData.append('category_name', data.category_name);
  //         formData.append('category_id',data.category_id);
  //         formData.append('text', data.text);
  //         formData.append('currency',data.currency);
  //         formData.append('shop_id',data.shop_id);
  //         formData.append('category_code',data.category_code);

  //         try {
  //           // Make a PUT request to update the category
  //           const foodId = data.id; // Replace with the actual food ID
  //           const response = await axios.put(`${var_api}/api/foodMaster/api/foodput/${foodId}`, formData, {
  //             headers: {
  //               'Content-Type': 'multipart/form-data', // Set the content type for file upload
  //             },
  //           });

  //           if (response.status === 200) {
  //             fetchData();
  //             // Category updated successfully
  //             Swal.fire({
  //               icon: 'success',
  //               text: 'Item data updated successfully',
  //               confirmButtonColor: '#007500',
  //             });
  //           } else {
  //             Swal.fire({
  //               icon: 'error',
  //               text: 'Failed to Item category',
  //               confirmButtonColor: '#007500',
  //             });
  //           }
  //         } catch (error) {
  //           console.error('Error updating category:', error);
  //           Swal.fire('Error', 'Failed to update Item. Please try again.', 'error');
  //         }

  //         resolve(formData);
  //       }
  //     });
  //   },
  // });
};

//close the modal
const handleClose = () =>{
  setShow(false);
}

//close the modal for update
const handleEditClose = () =>{
  setIsEdit(false);
}

//input onchange
const handleInputChange = (e) => {
  const { name, value } = e.target;
  setFormData({ ...formData, [name]: value });
};

//input onchange for update food
const handleInputUpdateChange = (e) => {
  const { name, value } = e.target;
  setUpdateFormData({ ...updateformData, [name]: value });
};


//onchange function for currency
const handleCurrencyChange = (selectedOption) => {
  setSelectedCurrency(selectedOption);
  console.log(selectedCurrency)
  setFormData({ ...formData, currency: selectedOption.value  || selectedCurrency.value});
};


//onchange function for currency
const handleCurrencyUpdateChange = (selectedOption) => {
  console.log(selectedCurrency)
  setUpdateFormData({ ...updateformData, currency: selectedOption.value  || updateformData.currency});
};


//onchange function for uom
const handleCategoryChange = (selectedOption) => {
  setSelectedCategory(selectedOption);
  console.log(selectedCategory)
  setFormData({ ...formData, uom: selectedOption.value });
};

//onchange function for uom update
const handleUOMUpdateChange = (selectedOption) => {
  console.log(selectedCategory)
  setUpdateFormData({ ...updateformData, uom: selectedOption.value || updateformData.uom });
};


//onchange function for file
const handleImageChange = (e) => {
  const file = e.target.files[0];
  setFormData({ ...formData, food_image: file });
};


//onchange function for file for update
const handleImageUpdateChange = (e) => {
  const file = e.target.files[0];
  setUpdateFormData({ ...updateformData, food_image: file });
};


// Function to convert string to camelCase
const capitalizeEachWord = (str) => {
  return str.toLowerCase().replace(/(^|\s)\S/g, function (match) {
    return match.toUpperCase();
  });
};



//post method for creating the food
const handleSubmit = async (e) => {
  e.preventDefault();  
  
  const camelCaseFoodName = capitalizeEachWord(formData.food_name);
  const camelCasetext = capitalizeEachWord(formData.text);

  const formDataToSend = new FormData();
  formDataToSend.append('food_name', camelCaseFoodName);
  formDataToSend.append('item_code', formData.item_code);
  formDataToSend.append('text', camelCasetext);
  formDataToSend.append('currency', formData.currency);
  formDataToSend.append('price', parseFloat(formData.price));
  formDataToSend.append('category_name', title); 
  formDataToSend.append('category_code',code);
  formDataToSend.append('category_id',cateid); 
  formDataToSend.append('food_image', formData.food_image);
  formDataToSend.append('cost', parseFloat(formData.cost));
  formDataToSend.append('shop_id',shopId);
  formDataToSend.append('uom',formData.uom);
  

   try {
    const response = await fetch(`${var_api}/api/foodMaster/api/post`, {
      method: 'POST',
    body: formDataToSend,
  });
    if(response.status == 200){
      fetchData();
      setShow(false);
      Swal.fire({
        title: 'Success',
        text: 'Shop Created Successfully.',
        icon: 'success',
        confirmButtonColor: '#007500', // Set the custom color for the Confirm button
      });
      setFormData({
        food_name:'',
      item_code:'',
      text:'',
      currency:'',
      category_id:'',
      category_name:'',
      food_image:null,  
      category_code:'',
      uom:'',
      price:null,
      cost: null  
      });
      setSelectedCategory("");
      setSelectedCurrency("");
      const data = await response.json();
      console.log('Registration successful!',data);
   
    }else {
      console.error('Failed to create shop:', response.statusText);
      // Handle error, e.g., show an error message to the user
      Swal.fire({
        title: 'error',
        text: 'Failed to Create Shop...Please Try Again!',
        icon: 'error',
        confirmButtonColor: '#007500', // Set the custom color for the Confirm button
      });
    }
    
    // Handle success scenario as needed
  } catch (error) {
      console.error('Error:', error);
      // Handle error scenario as needed
    }
  };


  //post method for updating the food
const handleUpdateSubmit = async (e) => {
  console.log("updateformData",updateformData);
  e.preventDefault();  
  
  const camelCaseFoodName = capitalizeEachWord(updateformData.food_name);
  const camelCasetext = capitalizeEachWord(updateformData.text);

  const formDataToSend = new FormData();
  formDataToSend.append('food_name', camelCaseFoodName);
  formDataToSend.append('item_code', updateformData.item_code);
  formDataToSend.append('text', camelCasetext);
  formDataToSend.append('currency', updateformData.currency);
  formDataToSend.append('price', parseFloat(updateformData.price));
  formDataToSend.append('category_name', title); 
  formDataToSend.append('category_code',code);
  formDataToSend.append('category_id',cateid); 
  formDataToSend.append('food_image', updateformData.food_image);
  formDataToSend.append('cost', parseFloat(updateformData.cost));
  formDataToSend.append('shop_id',shopId);
  formDataToSend.append('uom',updateformData.uom);
  

   try {
    const response = await fetch(`${var_api}/api/foodMaster/api/foodput/${updateformData.id}`, {
      method: 'PUT',
    body: formDataToSend,
  });
    if(response.status == 200){
      fetchData();
      setIsEdit(false);
      Swal.fire({
        title: 'Success',
        text: 'Food Updated Successfully.',
        icon: 'success',
        confirmButtonColor: '#007500', // Set the custom color for the Confirm button
      });
      setUpdateFormData({
        food_name:'',
      item_code:'',
      text:'',
      currency:'',
      category_id:'',
      category_name:'',
      food_image:null,  
      category_code:'',
      uom:'',
      price:null,
      cost: null  
      });
      setSelectedCategory("");
      setSelectedCurrency("");
      const data = await response.json();
      console.log('Registration successful!',data);
   
    }else {
      console.error('Failed to Update Food:', response.statusText);
      // Handle error, e.g., show an error message to the user
      Swal.fire({
        title: 'error',
        text: 'Failed to Update Food...Please Try Again!',
        icon: 'error',
        confirmButtonColor: '#007500', // Set the custom color for the Confirm button
      });
    }
    
    // Handle success scenario as needed
  } catch (error) {
      console.error('Error:', error);
      // Handle error scenario as needed
    }
  };

  //reset the create form
  const handleResetShop = () => {
    setFormData({food_name:'',
    item_code:'',
    text:'',
    currency:'',
    category_id:'',
    category_name:'',
    food_image:null,  
    category_code:'',
    uom:'',
    price:null,
    cost: null})
    setSelectedCurrency("");
  }


  //reset the update form
  const handleResetUpdateShop = () =>{
    setUpdateFormData({
      food_name:'',
      item_code:'',
      text:'',
      currency:'',
      category_id:'',
      category_name:'',
      food_image:null,  
      category_code:'',
      uom:'',
      price:null,
      cost: null
    })
  }


   //toggle switch for is_active updation
   const handleToggle = async (index,id) => {
    const newSwitches = [...switches];
    console.log('newSwitches', newSwitches);
    console.log('newSwitches', !newSwitches[index]);
    newSwitches[index] = !newSwitches[index];
    console.log('newSwitches', newSwitches[index]);
   
    setSwitches(newSwitches);
    console.log('newSwitches', newSwitches);
    //  Extract the location's ID (you need to replace 'id' with the actual ID property in your data)
    const locationId = images[index]?.id;
console.log('location Id', locationId);

// Make an API request to update the location_status
try {
const response = await axios.put(`${var_api}/api/foodMaster/active-status-put/${id}`, { is_active: newSwitches[index] ? 1 : 0 });
if (response.status === 200) {
  console.log('active status updated successfully');
  fetchData();
}
} catch (error) {
console.log('Error updating active status:', error);
// Revert the switch state in case of an error
newSwitches[index] = !newSwitches[index];
setSwitches(newSwitches);
}
  };


    
       

    return(
        <>
       <nav className="navbar navbar-expand navbar-light bg-white py-1 sticky-top" style={{boxShadow: "0px 0px 1px 0px green",height:"80px"}} >
       <Link to='/cate'>
       <i class="bi bi-arrow-left-circle" style={{fontSize:"25px",color:"green"}}></i>
       </Link>
      
      <Link to="/home" className="navbar-brand">
        <img src={Splash} alt="logo" style={{marginLeft:"15px"}} />
      </Link>

       
   
          <div className='container'>
                <div className='search-box col-md-6 '>
                  <button type="button" class="btn my-6 my-md-4" style={{backgroundColor:"green"}} onClick={handleForm}><i class="bi bi-plus-square" style={{color:"white",border:"none"}}> Add Food</i></button>
              </div>
            <input type='text' placeholder="Search" value={searchFood} onChange={(e) => setSearchFood(e.target.value)}
            style={{marginRight:"-320px",height:"40px",width:"350px",borderRadius:"5px",padding:"10px"}}
            /> 
            </div>

            <div className='search-box col-md-4 '>
              <Link to='/home'>
              <button className="btn" id="home" style={{marginLeft:"350px"}}><i class="bi bi-house"></i> Go to Home</button>
              </Link>
            {/* <input
                      type="text"
                      id="query"
                      className='form-control mr-sm-2'
                      placeholder='Search Food Item'
                  />
                  <Link>
                      <button className='btn btn-outline-success my-2 my-sm-0 search-btn'>
                          Search
                  </button>
                  </Link> */}
                 
              </div>
              </nav>
             
         <h2 className="hefo" style={{textAlign:"left",color:"green",fontWeight:"bold",marginLeft:"45px",padding:"20px",textTransform:"capitalize"}}>{title}
          <span style={{float:"right",fontWeight:"normal",fontSize:"20px",color:"black", color:"red"}}>Inactive Items:<b>{inActiveCount}</b>{" "}</span>{" "} 
          <span style={{float:"right",fontWeight:"normal",fontSize:"20px",color:"black", color:"green"}}>Active Items:<b>{activeCount}</b>,{" "}</span>{" "} 
          <span style={{float:"right",fontWeight:"normal",fontSize:"20px",color:"black", color:"orange"}}>No.Of.Items:<b>{images.length} </b>,{" "}</span>{" "}
         </h2>


         {loading ? (
        <div className="foodtainer">
      	  <div className="mastinner"></div>
        </div>
      ) : (
      <div className="card-group">
        { noResults ? (
          <h5>No food items found</h5>
        ) : (
        filteredFood.map((item,index) => ( 
        <React.Fragment key={index}>
          <div  style={{border:"none",margin:"auto"}}>
          <div class="row" >
            <div class="col-md-4">
            <div className="card p-2" style={{border: "1px solid green",borderRadius: "60px",padding: "5px",boxShadow:"2px 2px 2px black",height:"28rem",width:"20rem",backgroundColor:"rgba(4, 234, 27, 0.073)"}}>
            <img className="card-img-left" src={`${var_api}/${item.image}`} alt={`Card ${index + 1}`}  style={{height:"12rem",border: "1px solid green",borderRadius: "50px",padding: "5px",backgroundColor:"white"}}/>
            <div   style={{textAlign:"center",height:"120px",padding:"5px"}}>
              <h4 className="card-title" style={{color:"green",textShadow:"1px 1px 2px black",textTransform:"uppercase"}}><b>{item.food_name} {" "}
              <span style={{fontSize:"13px"}}>({item.item_code})</span>
              </b></h4>
              {/* <p style={{textAlign:"left",fontSize:"16px",margin:"3px 8px"}}><b>Item Code:</b> {item.item_code}</p> */}
              <p className="card-text" style={{textAlign:"left",fontSize:"16px",margin:"3px 8px"}}><b>Desc:</b> {item.text}</p>
              <p className="card-text" style={{textAlign:"left",fontSize:"16px",margin:"3px 8px"}}><b>Cost:</b> {item.cost}</p>
              <p className="card-text" style={{textAlign:"left",fontSize:"16px",margin:"3px 8px"}}><b>UOM:</b> {item.uom}</p>
              <div className="form-check form-switch" style={{ marginLeft: "0px" }}>
                <label className="form-check-label" htmlFor="flexSwitchCheckDefault" style={{color:"black",float:"left", marginLeft:"-35px"}}>Active Status:</label>
                <input 
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id={`flexSwitchCheckChecked_${index}`}
                  style={{ backgroundColor: 'green', borderColor: 'green', float: 'left', marginLeft:"12px" }}
                  checked={switches[index]} 
                  onChange={() => handleToggle(index,item.id)}
                  />
                </div>
              <h5 className="card-text" style={{color:"green"}}><b>{parseFloat(item.price)} {item.currency}</b></h5>
              <button className="btn btn-success" onClick={() => handleFoodUpdate(item)}>update</button>
            </div>
          </div>
            </div>
          </div>
          </div>
        </React.Fragment>
        ))
      )}
      </div>
      )}


<Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title> Add Food </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form  className="py-2" onSubmit={handleSubmit}>
          <div class="row">
            <div class="col">
               <label for="input1" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Food Name</label>
              <input type="text" class="form-control" placeholder=" enter food name" id="input1"  name="food_name"  value={formData.food_name}  onChange={handleInputChange} style={{borderRadius:"12px"}} required/>
            </div>
            <div class="col">
              <label for="input2" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Item Code</label>
              <input type="text" class="form-control" placeholder=" enter item code" id="input2" name="item_code" value={formData.item_code} onChange={handleInputChange} style={{borderRadius:"12px"}} required/>
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col">
               <label for="input3" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Description</label>
              <input type="text" class="form-control" placeholder="enter description" id="input3" name="text" value={formData.description} onChange={handleInputChange} style={{borderRadius:"12px"}} required/>
            </div>
            <div class="col">
              <label for="input4" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>price</label>
              <input type="number" class="form-control" placeholder="enter price" id="input4" name="price" value={formData.price} onChange={handleInputChange} style={{borderRadius:"12px"}} required/>
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col">
               <label for="input5" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Cost</label>
              <input type="number" class="form-control" placeholder="enter cost" id="input5" name="cost" value={formData.cost} onChange={handleInputChange} style={{borderRadius:"12px"}} required/>
            </div>
            <div class="col">
              <label for="input6" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Currency</label>
              <Select
    options={currencyOptions}
      placeholder="select currency"
      isSearchable={true}
      value={selectedCurrency}
      onChange={handleCurrencyChange}
      required
      styles={{
        // Styles for increasing width and height
        control: (provided, state) => ({
          ...provided,
          borderRadius: '15px'
        }),
      }}
  />
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col">
            <label for="input7" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Uom</label>
              <Select
    options={categoryOptions}
      placeholder="select uom"
      isSearchable={true}
      value={selectedCategory}
      onChange={handleCategoryChange}
      required
      styles={{
        // Styles for increasing width and height
        control: (provided, state) => ({
          ...provided,
          borderRadius: '15px'
        }),
      }}
  />
           
            </div>
            <div class="col">
               <label for="input8" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Category</label>
              <input type="text" class="form-control" placeholder="category" id="input8" name="category" value={title}  style={{borderRadius:"12px"}} readOnly required/>
            </div>
           
          </div>
          <br/>
          <div class="row">
            <div class="col">
            <label for="formFile" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Item Image</label>
              <input class="form-control" type="file" id="formFile" onChange={handleImageChange} style={{borderRadius:"12px"}} />
           
            </div>
            <div class="col">
            
            </div>
           
          </div>
          <br/>
          <button className="btn btn-success" style={{float:"right",margin:"5px",borderRadius:"15px"}}>Submit</button>
          </form>
          <button className="btn btn-secondary" onClick={handleResetShop} style={{borderRadius:"15px",float:"right",marginTop:"-1px"}}>Reset</button>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal
        show={isEdit}
        onHide={handleEditClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title> Update Food </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form  className="py-2" onSubmit={handleUpdateSubmit}>
          <div class="row">
            <div class="col">
               <label for="input1" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Food Name</label>
              <input type="text" class="form-control" placeholder=" enter food name" id="input1"  name="food_name"  value={updateformData.food_name}  onChange={handleInputUpdateChange} style={{borderRadius:"12px"}} required/>
            </div>
            <div class="col">
              <label for="input2" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Item Code</label>
              <input type="text" class="form-control" placeholder=" enter item code" id="input2" name="item_code" value={updateformData.item_code} onChange={handleInputUpdateChange} style={{borderRadius:"12px"}} required/>
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col">
               <label for="input3" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Description</label>
              <input type="text" class="form-control" placeholder="enter description" id="input3" name="text" value={updateformData.text} onChange={handleInputUpdateChange} style={{borderRadius:"12px"}} required/>
            </div>
            <div class="col">
              <label for="input4" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>price</label>
              <input type="number" class="form-control" placeholder="enter price" id="input4" name="price" value={updateformData.price} onChange={handleInputUpdateChange} style={{borderRadius:"12px"}} required/>
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col">
               <label for="input5" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Cost</label>
              <input type="number" class="form-control" placeholder="enter cost" id="input5" name="cost" value={updateformData.cost} onChange={handleInputUpdateChange} style={{borderRadius:"12px"}} required/>
            </div>
            <div class="col">
              <label for="input6" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Currency</label>
              <Select
    options={currencyOptions}
      placeholder="select currency"
      isSearchable={true}
      value={{label:updateformData.currency,value:updateformData.currency}}
      onChange={handleCurrencyUpdateChange}
      required
      styles={{
        // Styles for increasing width and height
        control: (provided, state) => ({
          ...provided,
          borderRadius: '15px'
        }),
      }}
  />
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col">
            <label for="input7" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Uom</label>
              <Select
    options={categoryOptions}
      placeholder="select uom"
      isSearchable={true}
      value={{label:updateformData.uom,value:updateformData.uom}}
      onChange={handleUOMUpdateChange}
      required
      styles={{
        // Styles for increasing width and height
        control: (provided, state) => ({
          ...provided,
          borderRadius: '15px'
        }),
      }}
  />
           
            </div>
            <div class="col">
               <label for="input8" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Category</label>
              <input type="text" class="form-control" placeholder="category" id="input8" name="category" value={title}  style={{borderRadius:"12px"}} required/>
            </div>
           
          </div>
          <br/>
          <div class="row">
            <div class="col">
            <label for="formFile" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Item Image</label>
              <input class="form-control" type="file" id="formFile" onChange={handleImageUpdateChange} style={{borderRadius:"12px"}} />
           
            </div>
            <div class="col">
            
            </div>
           
          </div>
          <br/>
          <button className="btn btn-success" style={{float:"right",margin:"5px",borderRadius:"15px"}}>Update</button>
          </form>
          <button className="btn btn-danger" onClick={handleEditClose} style={{borderRadius:"15px",float:"right",marginTop:"-1px"}}>Cancel</button>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

        </>
    );
}


export default FoodMaster;


