import React, { useState, useEffect, useRef} from "react";
import { useHistory } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import './groupOrders.css';
import Profile  from "../../images/location.png";
import SearchableDropdown from "../creditCustomers/search";
import image from "../../images/motorcycle.avif";
import Swal from 'sweetalert2';
import axios from "axios";
import { var_api } from "../constant";
import ReactToPrint from 'react-to-print';
import found from "../../images/landing.jpg";
import date from "../../images/enrollment.svg";
import noOrders from "../../images/no-orders.jpg";
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import Bill from "../Foods/bill";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure()


const GorderBill  = React.forwardRef ((order) => {
  const[shopDetails,setShopDetails] = useState(null);

 useEffect(()=>{
  console.log("order parti",order)
  fetchShopDetails();
 },[])

  const fetchShopDetails = () => {
    const shopID = localStorage.getItem('shopId');
    // Make the API request
    axios
      .get(`${var_api}/api/shop/shopDetails/${shopID}`)
      .then((response) => {
        const data = response.data;
        setShopDetails(data)
        console.log("shop details:",shopDetails)
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }


 
    return (
        
      <div style={{backgroundColor:"rgba(63, 68, 63, 0.082)",padding:"30px",width:"300px"}}>
      <div>
      <p style={{textAlign:"center",fontWeight:"lighter",textTransform:"uppercase",color:"black",fontFamily:"sans-serif"}}>{localStorage.getItem('shopName')}</p>
     
      {shopDetails ? (
      <div className="m-0" style={{lineHeight: "15%",fontFamily:"sans-serif",margin:"0px"}}>
        <p  style={{textAlign:"center", fontWeight:"bold",fontSize:"12px"}}>#{shopDetails.address}</p>
        <p  style={{textAlign:"center", fontWeight:"normal",fontSize:"12px",marginBottom:"10px"}}>{shopDetails.state}, {shopDetails.country_name}</p>
      </div>
    ) : (
      <div>Loading...</div>
    )}
     
      
    </div>
    <hr  style={{border:"1px dashed black",padding:"0px",margin:"0px"}}/>
      <h6 style={{fontWeight:"lighter",color:"black", fontSize:"14px"}} >Order No:{" "}<b>{order.order_no}</b>
      <span style={{fontSize:"13px", float:"right",letterSpacing:"-1px"}}>{" "}{order.ordered_at}</span>
      </h6>
      {(() => {
  if (order.order_type === 0 && order.order_label === 0 && order.is_group_location_order === 0) {
      return <h6 style={{fontWeight:"lighter",color:"black", fontSize:"13px"}} >Normal Order{" "}
      <span style={{fontSize:"13px", float:"right",letterSpacing:"-1px"}}>{order.ordered_time}</span>
      </h6>;
  }
  else if (order.order_type === 1 && order.order_label === 0 && order.is_group_location_order === 0) {
    return <h6 style={{fontWeight:"lighter",color:"black", fontSize:"13px"}} >Normal Table Order{" "}
    <span style={{fontSize:"13px", float:"right",letterSpacing:"-1px"}}>{order.ordered_time}</span>
    </h6>;
}
else if (order.order_label === 1  &&order.is_group_location_order === 1 && order.order_type ===0 ) {
  return <h6 style={{fontWeight:"lighter",color:"black", fontSize:"13px"}} >Group Location Order {" "}
  <span style={{fontSize:"13px", float:"right",letterSpacing:"-1px"}}>{order.ordered_time}</span>
  </h6>;
}
else if (order.order_type === 0 && order.order_label === 1 && order.is_group_location_order === 0) {
  return <h6 style={{fontWeight:"lighter",color:"black", fontSize:"13px"}} >Credit Order{" "}
  <span style={{fontSize:"13px", float:"right",letterSpacing:"-1px"}}>{order.ordered_time}</span>
  </h6>;
}
else if (order.order_type === 1 && order.order_label === 1 && order.is_group_location_order === 0) {
return <h6 style={{fontWeight:"lighter",color:"black", fontSize:"13px"}} > Credit Table Order{" "}
<span style={{fontSize:"13px", float:"right",letterSpacing:"-1px"}}>{order.ordered_time}</span>
</h6>;
}
  })()}
<table style={{borderCollapse:"collapse",width:"100%",marginBottom:"10px"}}>
<thead style={{borderTop:"1px solid black",borderBottom:"1px solid black",maxWidth:"100%"}}>
 <th style={{padding:"2px",color:"black"}}>Item</th>
 <th style={{padding:"2px",color:"black"}}>Qty</th>
 <th style={{padding:"2px",color:"black"}}>Price</th>
 <th style={{padding:"2px",color:"black"}}>Total</th>
</thead>

<tbody>
 {order && order.ordered_by.map((item, itemIndex) => (
 <tr key={itemIndex}>
   <td style={{padding:"2px",color:"black",textAlign:"left",textTransform:"capitalize"}}>{item.food_name}</td>
   <td style={{padding:"2px",color:"black",textAlign:"center"}}>{item.quantity}</td>
   <td style={{padding:"2px",color:"black",textAlign:"center"}}>{item.unit_price}</td>
   <td style={{padding:"2px",color:"black",textAlign:"center"}}>{item.quantity * item.unit_price}</td>
 </tr>
 
 ))}
</tbody>
</table>
<hr style={{border:"1px dashed black",position:"relative",top:"-15px",padding:"0px",marginBottom:"10px"}}/>
      <h6 style={{padding:"1px",color:"black",textAlign:"center",fontWeight:"bolder",marginTop:"-20px",fontFamily:"monospace",marginBottom:"-2px"}}><b>Total Amount: {" "}
        {(Math.round(order.total * 100) / 100).toFixed(1)}</b> <span style={{fontSize:"17px"}}>{shopDetails ? shopDetails.shop_currency : "null"}</span></h6>
    
      <table style={{ borderCollapse: "collapse", border: "1px solid black", width: "100%" }}>
  <thead>
    <tr>
      <th style={{ border: "1px solid black", padding: "1px 4px 1px 4px" }}>Paymode</th>
      <th style={{ border: "1px solid black", padding: "1px 4px 1px 4px" }}>Amount</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td style={{ border: "1px solid black", padding: "1px 4px 1px 4px", textAlign: "center" }}>{order.pay_mode}</td>
      <td style={{ border: "1px solid black", padding: "1px 4px 1px 4px", textAlign: "center" }}>{(Math.round(order.total * 100) / 100).toFixed(1)}</td>
    </tr>
  </tbody>
</table>
    
      <h6 style={{margin:"2px",padding:"1px", border:"1px solid black", fontSize:"14px", fontWeight:"bolder"}}>Shop Ph : {shopDetails? shopDetails.user.mobile : null}</h6>
      <h6 style={{margin:"2px",padding:"1px",border:"1px solid black", fontSize:"13px", fontWeight:"bolder"}}>Thank you for Dine With Us.!</h6>      
      </div>
     
    );
  });



const GroupOrders = () => {
  
    const componentRef = useRef();
    const [modShow, setModShow] = useState(false);
    const [slotMod, setSlotMod] = useState(false);
    const [itemMod, setItemMod] = useState(false);
    const [locationTitle, setLocationTitle] = useState("");
    const [address, setAddress] = useState("");
    const [pinCode, setPinCode] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [locations, setLocations]= useState([]);
    const [content, setContent] = useState('image'); // Default content is 'image'
    const [switches, setSwitches] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedGPUser, setselectedGPUser] = useState({});
    const [locationID, setLocationID] = useState();
    const [SelectedlocationName, setSelectedLocationName] = useState("");
    const [SelectedSlotName, setSelectedSlotName] = useState("");
    const [gpUsers, setGPUSers] = useState([]);
    const [slotTitle, setSlotTitle] = useState("");
    const [orderOpenTime, setOrderOpenTime] = useState("");
    const [orderCloseTime, setOrderCloseTime] = useState("");
    const [gpSlots, setGpSlots] = useState([]);
    const [slotEditMode, setSlotEditMode] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [ordersDate, setOrdersDate] = useState([]);
    const [ordersSlot, setOrdersSlot] = useState([]);
    const [ordersNPackDetails, setordersNPackDetail] = useState([]);
    const [packedOrdersDetails, setPackedOrdersDetail] = useState([]);
    const [orderNo, setOrderNo] = useState([]);
    const [order_no, setOrder_no] = useState(null);
    const [loading, setLoading] = useState(false);
    const [slotLoading, setSlotLoading] = useState(false);
    const [ selectedOrder, setSelectedOrder] = useState();
    const [isAccept , setIsAccept] = useState(false);
    const [isOrderComplete, setIsOrderComplete] = useState(false);
    const [isOrderDeliver, setIsOrderDeliver] = useState(false);
    const [acceptedOrder , setAcceptedOrder] = useState();
    const [value, onChange] = useState('10:00');
    const [addLoLoading, setAddLoLoading] = useState(false);
    const [locationLoader, setLocationLoader] = useState(false);
    const [show, setShow] = useState(false);
    const [ordersArray, setOrdersArray] = useState([]);
    const [deletedLocation, setDeletedLocation] = useState({
      id:null,
      l_name:""
    })
    const [isDeleteUser, setIsDeleteUser] = useState(false);
    const [deletedUser, setDeleteduser] = useState({
      id:null,
      u_name:"",
      u_id:null
    })
    const [isDeleteSlot, setIsDeleteSlot] = useState(false);
    const [deletedSlot, setDeletedSlot] = useState({
      id:null,
      slot_name:""
    })
   const [ searchQuery,setSearchQuery] = useState('');
   const [selectAll, setSelectAll] = useState(false);
   const [orderCheckboxes, setOrderCheckboxes] = useState([]);
   const history = useHistory();
   const [isOrderBill, setIsOrderBill] = useState(false);
   const [shopDetails,setShopDetails] = useState(null);
   const [ deliverLoading, setDeliverLoading] = useState(false);
    

     //handle to add location for open the modal
     const handleAddLocation=()=>{
        setModShow(true);
      }


      const fetchShopDetails = () => {
        const shopID = localStorage.getItem('shopId');
        // Make the API request
        axios
          .get(`${var_api}/api/shop/shopDetails/${shopID}`)
          .then((response) => {
            const data = response.data;
            setShopDetails(data)
            console.log("shop details:",shopDetails)
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      }

       //navigate to another page
    const handleGoTOHome = () => {
      // Navigate to a specific route using history.push
      history.push('/home');
    };
      
     //post method for creating group location
      const handleCreateLocation = async() =>{
        const shopid = localStorage.getItem('shopId');
        if(!locationTitle || !address || !pinCode || !latitude || !longitude){
          Swal.fire("error","Failed to Add Location! Please fill all in the fields","error") 
        }else{
          setAddLoLoading(true);
        try {
          const response = await axios.post(`${var_api}/api/group-location-orders/post`, {
              shop_id:shopid,
              location_title:locationTitle,
              address:address,
              pin_code:pinCode,
              latitude:latitude,
              longitude:longitude,
              is_active:0
          
          });
          if(response.status===200){
          console.log('API Response:', response.data);
         
          setAddress("");
          setLatitude("");
          setLocationTitle("");
          setLongitude("");
          setPinCode("");
          fetchLocationData();
          setModShow(false);
          setAddLoLoading(false);
          Swal.fire("success"," Location Added Successfully!","success")
          }
        } catch (error) {
          console.error('API Error:', error);
          Swal.fire("error","Failed to Add Location!","error")
          setAddLoLoading(false);
          // Handle the error, e.g., show an error message to the user.
        }
      }
      }

      //toggle switch for is_active updation
      const handleToggle = async (index) => {
        const newSwitches = [...switches];
        console.log('newSwitches', newSwitches);
        console.log('newSwitches', !newSwitches[index]);
        newSwitches[index] = !newSwitches[index];
        console.log('newSwitches', newSwitches[index]);
       
        setSwitches(newSwitches);
        console.log('newSwitches', newSwitches);
         // Extract the location's ID (you need to replace 'id' with the actual ID property in your data)
  const locationId = locations[index].id;
  console.log('location Id', locationId);

  // Make an API request to update the location_status
  try {
    const response = await axios.put(`${var_api}/api/group-location-orders/put/${locationId}`, { is_active: newSwitches[index] ? 0 : 1 });
    if (response.status === 200) {
      console.log('Location status updated successfully');
      fetchLocationData();
    }
  } catch (error) {
    console.log('Error updating location status:', error);
    // Revert the switch state in case of an error
    newSwitches[index] = !newSwitches[index];
    setSwitches(newSwitches);
  }
      };

    //get location data
    const fetchLocationData = async () => {
      setLocationLoader(true);
      const shopid = localStorage.getItem('shopId');
      try {
        const response = await axios.get(`${var_api}/api/group-location-orders/getall/${shopid}` );
        const use = response.data.results;
        console.log("credit",use);
        
    // Create an array to track the state of each switch based on location_status
    const initialSwitches = use.map((location) => location.is_active === 0);
    console.log("initialSwitches",initialSwitches)
    setSwitches(initialSwitches);

        setLocations(use);
        setLocationLoader(false);
      }catch (error) {
        console.log('Error fetching data:', error);
        setLocations([]);
        setLocationLoader(false);
      }
    };


    //delete method for remove Location
    const handleDeleteOpenModal = (id,loName) => {
      setShow(true);
      setDeletedLocation({
        id:id,
        l_name:loName
      })
    }


    //delete method for remove Location
    const handleDeleteLocation = () => {
      axios
      .delete(`${var_api}/api/group-location-orders/delete/${deletedLocation.id}`)
      .then((response) => {
        if (response.status === 200) {
          fetchLocationData();
          setShow(false);
          Swal.fire("success"," Location Removed Successfully!","success")
          // Perform any other actions you need on success
        } else if (response.status === 404) {
          console.log('Location not found');
          Swal.fire("error","Location not found!","error")
          setShow(false);
        }
      })
      .catch((error) => {
        console.error('Error executing the request:', error);
        Swal.fire("error","Failed to Remove Location!","error")
        setShow(false);
        // Handle the error as needed
      });
    }


    //to open the form for location updation
    const handleEditUser = (user) => {
      setEditMode(true);
      setSelectedUser(user);
    };
  
    //update method for location
    const handleUpdateLocation = async (updatedUser) => {
      
      try {
        const response = await axios.put(`${var_api}/api/group-location-orders/put/${updatedUser.id}`, updatedUser);
        if (response.status === 200) {
          setEditMode(false);
          setSelectedUser(null);
          fetchLocationData();
          Swal.fire("success"," Location Detail Updated Successfully!","success")
          // Perform any other actions you need on success
        } else if (response.status === 404) {
          console.log('Location not found');
          Swal.fire("error","Location not found!","error")
        }
        // Perform any necessary action upon successful update
      } catch (error) {
        console.log(error);
        Swal.fire("error","Failed to Update Location!","error")
      }
    };

    //fetch the location data when page loads
    useEffect(() => {
      fetchLocationData();
      handleNormalOrderNo();
      getExitingOrderNo();
      fetchShopDetails();
    }, []);

    //show the create slot and users page part for corresponding location
     const handleCreateSlotUser = (id,l_name) => {
      console.log("location id is ", id)
      setLocationID(id)
      setContent('paragraph'); // Switch to paragraph content
      fetchLocationUsers(id);
      fetchLocationSlot(id);
      setSelectedLocationName(l_name)
     }

     //open the modal for create slot form
     const handleSlotMode = () => {
      setSlotMod(true);
     }

      //to close the modal
    const closeModal = () => {
        setModShow(false);
      }

      //to close the slot modal
    const closeSlotModal = () => {
      setSlotMod(false);
    }


    const closeItemModal = () => {
      setItemMod(false);
    }


     //show the create orders page part for corresponding location
      const handleGrpOrders = (id,loName) => {
        setContent('heading'); // Switch to heading content
        setLocationID(id)
        setSelectedLocationName(loName)
        fetchOrdersDateData(id);
        
      }

      //to close the location updation form
      const onClose = () => {
        setEditMode(false);
        setSelectedUser(null);
      }

        //handle to select the credit user
        const handleDropdownChange = (selectedOption) => {
          setselectedGPUser(selectedOption);
          console.log("selected user:",selectedGPUser)
        };

        //clear the value
        const clearSelectedValue = () => {
          setselectedGPUser(" ") // Clear the selected value
        };

         //Post method for add group location user
      const handleCreateGPUser= async ()=>{
        console.log("selected user add:",selectedGPUser)
        const shopid = localStorage.getItem('shopId');
        try {
          const response = await axios.post(`${var_api}/api/group_location_creditusers/post`, {
            shop_id:parseInt(shopid),
            user_id:selectedGPUser.iden,
            group_location_orders_id:locationID

          });
          if(response.status===200){
          console.log('API Response:', response.data);
          fetchLocationUsers(locationID);
          Swal.fire("success"," User Added Successfully!","success")
          clearSelectedValue();
          // You can handle the response as needed, such as displaying a success message to the user.
          }
         
        } catch (error) {
          console.error('API Error:', error);
          Swal.fire("error","Failed to Add User!","error")
          // Handle the error, e.g., show an error message to the user.
          if (error.response && error.response.data && error.response.data.error === "User with the provided user_id already exists") {
            Swal.fire("Error", "Customer is already added to the list", "error");
          } else {
            Swal.fire("Error", "Failed to Add User!", "error");
          }
        }

      }

       //get location data
    const fetchLocationUsers = async (id) => {
      const shopid = localStorage.getItem('shopId');
      try {
        const response = await axios.get(`${var_api}/api/group_location_creditusers/get/user/${shopid}/${id}` );
        const use = response.data.results;
        console.log(`location ${id} users`,use)
        setGPUSers(use);
        console.log(`location ${id} gp users`,gpUsers)
      }catch (error) {
        console.log('Error fetching data:', error);
        setGPUSers([]);
      }
    };


    const filteredGRPUsers = gpUsers.filter((user) => {
      const searchLower = searchQuery.toLowerCase();
      return (
        (user.name && user.name.toString().toLowerCase().includes(searchLower)) ||
        (user.phone_no && user.phone_no.toString().toLowerCase().includes(searchLower)) ||
        (user.email && user.email.toString().toLowerCase().includes(searchLower)) 
      );
    });


     //open the delete modal
      const handleDeleteGpUser = (id,u_name,u_id) => {
        setIsDeleteUser(true);
        setDeleteduser({
          id:id,
          u_name: u_name,
          u_id: u_id
        })
      }

       //delete method for remove gp user
      const handleToDeleteGpUser = () => {
         axios
        .delete(`${var_api}/api/group_location_creditusers/delete/${deletedUser.id}/${deletedUser.u_id}`)
        .then((response) => {
          if (response.status === 200) {
            console.log(deletedUser.id)
            fetchLocationUsers(locationID);
            setIsDeleteUser(false);
            Swal.fire("success"," Credit User Removed Successfully!","success")
            // Perform any other actions you need on success
          } else if (response.status === 404) {
            console.log('Credit user not found');
            Swal.fire("error","Credit user not found!","error")
            setIsDeleteUser(false);
          }
        })
        .catch((error) => {
          console.error('Error executing the request:', error);
          Swal.fire("error","Failed to Remove Credit User!","error")
          setIsDeleteUser(false);
          // Handle the error as needed
        });
      }


      //Post method for add slot
      const handleCreateGPSlot= async ()=>{
        const shopid = localStorage.getItem('shopId');
        if(!slotTitle || !orderOpenTime || !orderCloseTime){
          Swal.fire("error","Failed to Create Slot! Please fill all in the fields","error") 
        }else{
        try {
          const response = await axios.post(`${var_api}/api/group-location-slots/post`, {
              shop_id:shopid, 
              group_location_orders_id:locationID, 
              slot_title:slotTitle, 
              order_close_time: orderCloseTime, 
              order_open_time: orderOpenTime, 
              is_next_day_order:0
          });
          if(response.status===200){
          console.log('API Response:', response.data);
          Swal.fire("success"," Slot Added Successfully!","success")
          closeSlotModal();
          fetchLocationSlot(locationID);
          // You can handle the response as needed, such as displaying a success message to the user.
          }
        } catch (error) {
          console.error('API Error:', error);
          Swal.fire("error","Failed to Add Slot!","error")
          // Handle the error, e.g., show an error message to the user.
        }
      }
    }


     //get location data
     const fetchLocationSlot= async (id) => {
      const shopid = localStorage.getItem('shopId');
      try {
        const response = await axios.get(`${var_api}/api/group-location-slots/get/locationslot/${shopid}/${id}` );
        const use = response.data.results;
        setGpSlots(use);
      }catch (error) {
        console.log('Error fetching data:', error);
        setGpSlots([]);
      }
    };


    //to open the form for slot updation 
    const handleEditSlot = (user) => {
      setSlotEditMode(true);
      setSelectedSlot(user);
    };

    //to close the slot updation form
    const onCloseSlot = () => {
      setSlotEditMode(false);
      setSelectedSlot(null);
    }


     //update method for slot
     const handleUpdateSlot = async (updatedUser) => {
      try {
        const response = await axios.put(`${var_api}/api/group-location-slots/put/${updatedUser.id}`, updatedUser);
        if (response.status === 200) {
          setSlotEditMode(false);
          setSelectedSlot(null);
          fetchLocationSlot(locationID);
          Swal.fire("success"," Slot Detail Updated Successfully!","success")
          // Perform any other actions you need on success
        } else if (response.status === 404) {
          console.log('Slot not found');
          Swal.fire("error","Slot not found!","error")
        }
        // Perform any necessary action upon successful update
      } catch (error) {
        console.log(error);
        Swal.fire("Failed to Update Slot!","error")
      }
    };


     //d
     const handleDeleteGpSlot = (id,s_name) => {
      setIsDeleteSlot(true);
      setDeletedSlot({
        id:id,
        slot_name:s_name
      })
    }


    //delete method for remove Location
    const handleToDeleteSlots = () => {
      axios
      .delete(`${var_api}/api/group-location-slots/delete/${deletedSlot.id}`)
      .then((response) => {
        if (response.status === 200) {
          console.log(deletedSlot.id)
          fetchLocationSlot(locationID);
          Swal.fire("success"," Slot Removed Successfully!","success")
          setIsDeleteSlot(false);
          // Perform any other actions you need on success
        } else if (response.status === 404) {
          console.log('Slot not found');
          Swal.fire("error","Slot not found!","error")
          setIsDeleteSlot(false);
        }
      })
      .catch((error) => {
        console.error('Error executing the request:', error);
        Swal.fire("error","Failed to Remove Slot!","error")
        setIsDeleteSlot(false);
        // Handle the error as needed
      });
    }


    //get orders Date data
    const fetchOrdersDateData = async (id) => {
      const shopid = localStorage.getItem('shopId');
      setLoading(true);
      try {
        const response = await axios.get(`${var_api}/api/group-location-slot-orders/getdates/${shopid}/${id}` );
        const use = response.data.results;
        console.log("date",use);
        setOrdersDate(use);
        setLoading(false);
      }catch (error) {
        console.log('Error fetching data:', error);
        setOrdersDate([]);
        setLoading(false);
      }
    };

    //get the slots data
    const handleOrdersSlotData = async (date) => {
      const shopid = localStorage.getItem('shopId');
      setSlotLoading(true);
      try {
        const response = await axios.get(`${var_api}/api/group-location-slot-orders/getdates/getslots/${shopid}/${locationID}/${date}` );
        const use = response.data.results;
        console.log("slot",use);
        setOrdersSlot(use);
        setSlotLoading(false);
      }catch (error) {
        console.log('Error fetching data:', error);
        setOrdersSlot([]);
        setSlotLoading(false);
      }
    }

    //show the unpacked orders when click slot
    const handleListOrders = async(date,slot_id) =>{
      const shopid = localStorage.getItem('shopId');
      try {
        const response = await axios.get(`${var_api}/api/group-location-slot-orders/get/notpack/${shopid}/${locationID}/${date}/${slot_id}` );
        const use = response.data;
        console.log("order",use);
        setordersNPackDetail(use);
       handleListPackedOrders(slot_id,date)
      }catch (error) {
        console.log('Error fetching data:', error);
        setordersNPackDetail([]);
      }
    }

    //show the packed orders when click slot
    const handleListPackedOrders = async(slot_id,date) =>{
      setContent("order");
      const shopid = localStorage.getItem('shopId');
      try {
        const response = await axios.get(`${var_api}/api/group-location-slot-orders/get/packdone/${shopid}/${locationID}/${date}/${slot_id}` );
        const use = response.data.results;
        console.log("slot",use);
       setPackedOrdersDetail(use);
      }catch (error) {
        console.log('Error fetching data:', error);
       setPackedOrdersDetail([]);
      }
    }

    //change the status of payment 
    const handleOrderId = async (id) => {
      let maxOrderNo = 0;
      maxOrderNo = order_no.max_order_no + 1
      console.log("m",maxOrderNo,order_no)
      const updatedpayment = {
        order_no : maxOrderNo
      }
      try {
        const response = await axios.put(`${var_api}/api/group-location-slot-orders/put/${id}`, updatedpayment);
        if (response.status === 200) {
         
          toast('order no Updated Successfully!',{autoClose:2000})
          // Perform any other actions you need on success
        } else if (response.status === 404) {
          console.log('order not found');
          Swal.fire("error","order not found!","error")
        }
        // Perform any necessary action upon successful update
      } catch (error) {
        console.log(error);
        Swal.fire("Failed to Update order no!","error")
      }
    }

    //change the order status is ACCEPT
    const handleAcceptStatus = async (id,date,slot_id) => {
      const updatedData = {
        is_order_confirm_status : 1
      }
      try {
        const response = await axios.put(`${var_api}/api/group-location-slot-orders/put/${id}`, updatedData);
        if (response.status === 200) {
          handleListOrders (slot_id,date)
          Swal.fire("success"," Order Status Updated Successfully!","success")
          // Perform any other actions you need on success
        } else if (response.status === 404) {
          console.log('order not found');
          Swal.fire("error","order not found!","error")
        }
        // Perform any necessary action upon successful update
      } catch (error) {
        console.log(error);
        Swal.fire("Failed to Update order Status!","error")
      }
    }

    //to change the maybe status
    const handleChangeMaybe = async (id,date,slot_id) => {
      const updatedData = {
        is_order_confirm_status : 2
      }
      try {
        const response = await axios.put(`${var_api}/api/group-location-slot-orders/put/${id}`, updatedData);
        if (response.status === 200) {
          handleListOrders (date,slot_id)
          Swal.fire("success"," Order Status Updated Successfully!","success")
          // Perform any other actions you need on success
        } else if (response.status === 404) {
          console.log('order not found');
          Swal.fire("error","order not found!","error")
        }
        // Perform any necessary action upon successful update
      } catch (error) {
        console.log(error);
        Swal.fire("Failed to Update order Status!","error")
      }
    }


    //change the status for delivery status
    const handleToDeliveryStatus = async () => {
      setDeliverLoading(true);
     const shop_id = localStorage.getItem('shopId');
     const order= packedOrdersDetails[0]
      try {
        const response = await axios.put(`${var_api}/api/group-location-slot-orders/change/deliverystatus/${shop_id}`);
        if (response.status === 200) {
          handleListOrders (order.ordered_at,order.group_location_slot_id);
          setIsOrderDeliver(false);
          Swal.fire("success"," Delivery Status Updated Successfully!","success")
           // Trigger printing
           if(ordersArray.length > 0){
            setIsOrderBill(true);
           }else{
            toast('order not selected to print',{autoClose:2000})
           }
           setDeliverLoading(false);
           
          // Perform any other actions you need on success
        } else if (response.status === 404) {
          console.log('order not found');
          Swal.fire("error","order not found!","error")
          setIsOrderDeliver(false);
          setDeliverLoading(false);
        }
        // Perform any necessary action upon successful update
      } catch (error) {
        console.log(error);
        Swal.fire("Failed to Update Delivery Status!","error")
        setIsOrderDeliver(false);
        setDeliverLoading(false);
      }
      setOrderCheckboxes([]);
      
    }
    

     //get the order_no
     const handleNormalOrderNo = async() =>{
      const shopid = localStorage.getItem('shopId');
      try {
        const response = await axios.get(`${var_api}/api/food/normal/related-data/${shopid}` );
        const use = response.data;
        console.log("orderNo",use);
       setOrderNo(use);
      }catch (error) {
        console.log('Error fetching data:', error);
        setOrderNo([]);
      }
    }

    //change the packed status and insert order
    const handlePackedStatus = async (id,date,slot_id) => {
      const updatedData = {
        is_packing_done : 1
      }
      try {
        const response = await axios.put(`${var_api}/api/group-location-slot-orders/put/${id}`, updatedData);
        if (response.status === 200) {
          handleListOrders (date,slot_id)
          Swal.fire("success"," packed Status Updated Successfully!","success")
          // Perform any other actions you need on success
        } else if (response.status === 404) {
          console.log('order not found');
          Swal.fire("error","order not found!","error")
        }
        // Perform any necessary action upon successful update
      } catch (error) {
        console.log(error);
        Swal.fire("Failed to Update order Status!","error")
      }
    }


    //put method for cancel the orders
    const handleToCancelStatus = async () => {
      const idsArray = ordersNPackDetails.results.map(item => item.id);
      console.log("IDs Array:", idsArray);
      const order = ordersNPackDetails.results[0]
      const updatedData = {
        ids : idsArray
      }
      try {
        const response = await axios.put(`${var_api}/api/group-location-slot-orders/bulk-put`, updatedData);
        if (response.status === 200) {
          handleListOrders (order.ordered_at,order.group_location_slot_id)
          setIsOrderComplete(false);
          Swal.fire("success"," order Status Updated Successfully!","success")
          // Perform any other actions you need on success
        } else if (response.status === 404) {
          console.log('order not found');
          Swal.fire("error","order not found!","error")
          setIsOrderComplete(false);
        }
        // Perform any necessary action upon successful update
      } catch (error) {
        console.log(error);
        Swal.fire("Failed to Update order Status!","error")
        setIsOrderComplete(false);
      }
    }


    // const fetchSlotPackedData = async () => {
    //   const shopid = localStorage.getItem('shopId');
      
    //   try {
    //     const response = await axios.get(`${var_api}/api/group-location-slot-orders/get/packdone/${shopid}/38/02-04-2024/164`);
    //     const use = response.data.results;
    //     console.log("credit", use);
        
    //     // Create an array to track the state of each switch based on location_status
    //     const initialSwitches = use.map((location) => location.is_active === 0);
    //     console.log("initialSwitches", initialSwitches);
    //     setSwitches(initialSwitches);
        
    //     setLocations(use);
        
    //   } catch (error) {
    //     console.log('Error fetching data:', error);
    //     setLocations([]);
    //   }
    // };


    //To show the slot list
    const handleShowSoltList = (date) => {
      setContent('slotList'); // Switch to heading content
      handleOrdersSlotData(date)
    }

     //To show the slot list
    const handleShowOrders = (date,slotId,slot) => {
      setContent('order'); // Switch to heading content
      setSelectedSlotName(slot)
      handleListOrders(date,slotId)
    }


    const getExitingOrderNo= () => {
      const shop_id = localStorage.getItem('shopId');
      axios.get(`${var_api}/api/food/bigorderno/${shop_id}`)
        .then((response) => {
          const data = response.data.results;
          console.log("i",data)
          setOrder_no(data[0]);
    
        })
        .catch((error) => {
          console.error('Error fetching order no:', error);
          // Handle the error if needed
        });
    }


    //to open the accept modal
    const handleOpenAcceptModal= (order) => {
      setIsAccept(true);
      setAcceptedOrder(order)
    }


    const closeAcceptModal = () => {
      setIsAccept(false)
    }


    //To change the accept status and insert the order
     const handleChangeAccept = async (order) => {
      console.log("pring",order);
      let maxOrderNo = 0;
      maxOrderNo = order_no.max_order_no + 1
      console.log("m",maxOrderNo,order_no)
      var remark = document.querySelector('#remark') ? document.querySelector('#remark').value : "-";
     const orderItems = order.ordered_by.map(item => ({
      food_id:item.food_id,
      food_name: item.food_name,
      quantity: item.quantity,
      unit_price: item.unit_price,
      item_code: item.item_code, 
      category_code: item.category_code
    }));
      const updatedData = {
        creditUser_id: order.user_id,
        cust_name: order.name,
        phone_no: order.phone_no,
        order_no: maxOrderNo,
        total: order.total_price,
        total_items: order.no_of_items,
        shop_name: localStorage.getItem('shopName'),
        shop_id: order.shop_id,
        order_type:0,
        table_no: 0,
        items: orderItems,
        paid_status: 0,
        attender_name:"-",
        parcel_status: 0,
        print_status: 0,
        order_label: 1,
        ordered_at: order.ordered_at, 
        ordered_time: order.ordered_time,
        is_group_location_order: 1,
        group_location_order_id: order.id,
        pay_mode:"GROUP LOCATION CREDIT ORDER", 
        remarks:remark
      }
      try {
        const response = await axios.put(`${var_api}/api/group-location-slot-orders/accept/${order.id}`, updatedData);
        if (response.status === 200) {
          handleListOrders (order.ordered_at,order.group_location_slot_id);
          handleOrderId(order.id)
          setIsAccept(false);
          getExitingOrderNo();
          const customerID = response.data.idCust;
          const paymodesArray = [
            {
              order_id:customerID, 
              shop_id:order.shop_id, 
              id:11, 
              amount:order.total_price,
              pay_mode_name:"GROUP LOCATION CREDIT ORDER",
              ordered_at:order.ordered_at
            }
          ]

          try {
            const response = await axios.post(`${var_api}/api/order-paymode/post`, paymodesArray);
            console.log(response.data); // Log response from server
            console.log( "Succes", response.data.message );
            const entered_Paymodes = response.data.results;
            // const enteredPaymodesString = entered_Paymodes.map(paymode => paymode.pay_mode_name).join(', ');
            // this.setState({enteredPaymodes: enteredPaymodesString});
          } catch (error) {
            console.error('Error posting pay modes:', error);
          }
          toast('Status Updated Successfully!',{autoClose:2000})
          // Perform any other actions you need on success
        } else if (response.status === 404) {
          console.log('order not found');
          Swal.fire("error","order not found!","error")
        }
        // Perform any necessary action upon successful update
      } catch (error) {
        console.log(error);
        Swal.fire("Failed to Update order Status!","error")
      }
    }

    //to open the item details modal
    const handleOpenItemDetails = (order) => {
      setItemMod(true);
      setSelectedOrder(order);
    }

    //put method for order complete
    const handleToCompleteOrder = async() => {
      if (ordersNPackDetails && ordersNPackDetails.results) {
        
        // Check if any item satisfies the condition
        const isOrderToComplete = ordersNPackDetails.results.some(order => order.is_order_confirm_status === 1 );

           // Initialize a count variable
    let orderCount = 0;

    // Check if any item satisfies the condition and increment the count
    ordersNPackDetails.results.forEach(order => {
      if (order.is_order_confirm_status === 1) {
        orderCount++;
      }
    });
        
        if (isOrderToComplete && orderCount > 0) {
          console.log("yse",isOrderToComplete,orderCount)
          // If any order satisfies the condition, trigger the alert
          Swal.fire("error",`In Unpacked orders, Change packing status unpacked to packed when the order status is accepted, accepted ${orderCount} orders still not packed `,"error")
        } else {
          console.log("noe",isOrderToComplete,orderCount);
          // alert("No orders ready to complete.");
          setIsOrderComplete(true);
          // await handleToCancelStatus();
          // await handleToDeliveryStatus();
        }
      }else{
        // alert("No orders");
        // await handleToDeliveryStatus();
        setIsOrderDeliver(true);
      }

    }


    //close the modal
    const closeOrderModal = () => {
      setIsOrderComplete(false);
    }


     //close the modal
     const closeOrderDeliverModal = () => {
      setIsOrderDeliver(false);
    }

    const handleToCancelDelivery = async() => {
      await handleToCancelStatus();
      await handleToDeliveryStatus();
    }

    //calculate the count
    function getOrderCountByStatus(status) {
      if (!ordersNPackDetails || !ordersNPackDetails.results) return 0;
      
      return ordersNPackDetails.results.filter(order => order.is_order_confirm_status === status).length;
    }

    //get the value in search and set the setstate
    const handleSearchInput = (e) => {
      setSearchQuery(e.target.value)
      };


      // Function to handle the change in "Print All Orders" checkbox
  const handleSelectAll = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      // If "Print All Orders" is checked, check all individual order checkboxes
      const updatedCheckboxes = packedOrdersDetails.map((order, index) => index);
      const updatedorders = packedOrdersDetails.map((order, index) => order);
      setOrderCheckboxes(updatedCheckboxes);
      setOrdersArray(updatedorders);
      console.log("all orders", ordersArray)
    } else {
      // If "Print All Orders" is unchecked, uncheck all individual order checkboxes
      setOrderCheckboxes([]);
      setOrdersArray([]);
      console.log("all ordersccc", ordersArray)
    }
  };


  // Function to handle the change in individual order checkboxes
  const handleOrderCheckboxChange = (index, order) => {
    const updatedCheckboxes = [...orderCheckboxes];
    const orderIndex = ordersArray.findIndex(existingOrder => existingOrder.id === order.id);
  
    if (updatedCheckboxes.includes(index)) {
      // If order checkbox is already checked, uncheck it
      const checkboxIndex = updatedCheckboxes.indexOf(index);
      updatedCheckboxes.splice(checkboxIndex, 1);
      // Remove order from the array if it exists
      if (orderIndex !== -1) {
        setOrdersArray(prevOrders => prevOrders.filter((_, i) => i !== orderIndex));
      }
    } else {
      // If order checkbox is unchecked, check it
      updatedCheckboxes.push(index);
      // Add order to the array if it doesn't already exist
      if (orderIndex === -1) {
        setOrdersArray(prevOrders => [...prevOrders, order]);
      }
    }
    setOrderCheckboxes(updatedCheckboxes);
    console.log('ordersccc', ordersArray);
  };
  

   // Function to handle printing
   const handlePrint = () => {
    // Implement your printing logic here
    console.log("Printing orders:", orderCheckboxes);
  };


     
    return (
        <div>
           <div class="grpsplit grpleft">
           {editMode ? (
        <UserForm user={selectedUser} onSubmit={handleUpdateLocation} onClose={onClose} />
      ) : (
        <div>
          <i class="bi bi-arrow-left-circle" onClick={handleGoTOHome} style={{fontSize:"25px",padding:'20px',color:"green"}}></i>
          <h3 className='grpushe'>CREATE LOCATION</h3>
          <div style={{marginLeft:"12px",padding:"7px"}}>
            <button id='grpcusadd' onClick={handleAddLocation}  ><i class="bi bi-plus-circle" style={{border:"none"}}></i> {' '}
               Add Group Location
            </button>

            <br/> <br/> <br/>

            { locationLoader ? (
              <div class="text-center">
              <div class="spinner-border text-success" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            ) : (
            locations.map((user,index) => (
                           <div>
                             <div id='grpdiv'  key={index}>
                            <div style={{border:"none"}}>
                            <img src={Profile} alt='user' width='55px' height='55px' style={{marginTop:"20px"}} />
                            <div class="form-check form-switch" style={{fontSize:"16px",position:"relative",top:"-83px",left:"320px"}}>
                                <input class="form-check-input " type="checkbox" role="switch" id={`flexSwitchCheckChecked_${index}`} checked={switches[index]} onChange={() => handleToggle(index)} />
                                
                              </div>
                               <div style={{border:"none",margin:"-85px 0px 0px 80px"}}>
                                <h6 style={{textTransform:"capitalize"}}><span style={{fontWeight:"bold",fontSize:"14px"}}>Location Title: </span> {user.location_title} {' '}
                                <i class="bi bi-pencil-square" onClick={() => handleEditUser(user)} style={{fontSize:"15px",color:"green"}}></i>
                                 </h6>
                               <h6 style={{textTransform:"capitalize", fontSize:"13px"}}><span style={{fontWeight:"bold",fontSize:"14px"}}>Address: </span>  {user.address}</h6>
                                <h6 style={{fontSize:"14px"}}><span style={{fontWeight:"bold",fontSize:"14px"}}>Pin Code: </span> {user.pin_code}</h6>
                                <h6 style={{fontSize:"14px"}}><span style={{fontWeight:"bold",fontSize:"14px"}}>Latitude: </span> {user.latitude}  <span style={{fontWeight:"bold",fontSize:"14px",marginLeft:"20px"}}>Longitude:  </span> {user.longitude}</h6> 
                               </div>
                              </div>
                              <span>
                                {/* <i class="bi bi-trash" onClick={()=>handleDeleteOpenModal(user.id,user.location_title)} style={{float:"left",fontSize:"21px",color:"green",marginTop:"40px",marginRight:"-20px"}}></i> */}
                                </span>

                              <button className="btn btn-outline-success p-1"  onClick={() => handleGrpOrders(user.id, user.location_title)}
                            style={{fontSize:'13px',fontWeight:"bold",marginTop:"10px",float:"right"}}> View Orders </button>{' '}

                          {' '}  <button className="btn btn-outline-success p-1"  onClick={() => handleCreateSlotUser(user.id,user.location_title)}
                            style={{fontSize:'13px',fontWeight:"bold",marginTop:"10px",float:"right"}}>Create Users & Slots </button>
                        </div>
                        <br/>
                           </div>
                           )))}
</div>
                   
        </div>
      )}
      </div>
      <div class="grpsplit grpright">
        <div class="centered"style={{paddingLeft:"8px"}}>
        {content === 'paragraph' && (
          <>
          <h4 style={{fontFamily:"serif",fontWeight:"bolder",color:"green"}}>Location: <span style={{fontFamily:"serif",fontWeight:"normal",color:"black"}}>{SelectedlocationName}</span></h4>
          <Tabs
            defaultActiveKey="Credit Users"
            id="fill-tab-example"
            className="mb-1 ml-1 tabclrs"
            fill
          >
            <Tab eventKey="Credit Users" title={<span className="grpushe">CREDIT USERS</span>}>
              <div style={{margin:"30px auto 10px",padding:"15px",width:"420px"}}>
               
              <div style={{margin:"0px auto 10px",padding:"15px",width:"520px"}}>
                <h5 style={{color:"black",textAlign:"left",fontFamily:"serif"}}>Select Customer</h5>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="custom-searchable-dropdown">
                    <SearchableDropdown
                      onSelect={handleDropdownChange}  selectedValue={selectedGPUser} 
                    />
                  </div>
                  <button className="btn btn-success" onClick={handleCreateGPUser} style={{ marginLeft: "10px" }} >ADD</button>
                  <input class="form-control" type="Search" placeholder="Search" id="example-search-input" 
      style={{width:"230px",borderRadius:"15px",border:"1px solid black",float:"right", marginLeft:"10px"}} onChange={handleSearchInput}/>
                </div>
              </div>
              <div className="grid-container">

             {filteredGRPUsers && filteredGRPUsers.length === 0 ? (
              <div style={{ gridColumn: 'span 3', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',marginLeft: "200px" }}>
                <br/>
              <img src={found} alt="user" width="430px" height="230px" />
              <br/>
              <h6 style={{color:"black",fontWeight:"bolder",fontSize:"20px",textAlign:'center'}}>No Users Found</h6>
                </div>
             ) : (
              filteredGRPUsers && filteredGRPUsers.map((user,index) => (
                <div id='grcrediv' key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <img src={`${var_api}/${user.profile}`} alt="User" style={{ marginRight: '10px', width: '40px', height: '40px', borderRadius: '50%' }} />
                <div style={{ flex: 1, border: 'none', marginLeft: '10px' }}>
                  <div style={{ border: 'none', margin: '0px 0px 0px 0px' }}>
                    <h6 style={{ textTransform: 'capitalize', fontWeight: 'bolder', color: 'green' }}>
                      {user.name} {' '}
                      <span><i className="bi bi-trash" onClick={() => handleDeleteGpUser(user.id,user.name,user.user_id)} style={{ float: 'right', fontSize: '20px', color: 'red' }}></i></span>
                    </h6>
                    <h6 style={{ fontSize: '12px',color:"black" }}> <i className="bi bi-telephone" style={{color:"green"}}></i> {'  '} {user.phone_no}</h6>
                    <h6 style={{ fontSize: '12px',color:"black" }}><i className="bi bi-envelope" style={{color:"green"}}></i> {'  '} {user.email}</h6>
                  </div>
                </div>
              </div>
              
             ))
             )}
              </div>  
             
              </div>
             
           </Tab>
           <Tab eventKey="schedule" title={<span className="grpushe">SLOTS</span>}>
            <h5 style={{color:"green",padding:"10px", textAlign:"right",fontFamily:"serif"}}><b style={{color:"black"}}>No.Of.Slots:</b>{" "}{gpSlots.length}</h5>
            { slotEditMode ? (
              <SlotForm user={selectedSlot} onSubmit={handleUpdateSlot} onCloseSlot={onCloseSlot} />
            ) : (
              <div style={{backgroundColor: "#63a91f",backgroundColor: "#1b8b00",backgroundImage: "linear-gradient(314deg, #1b8b00 0%, #8ee895 74%)", opacity: "0.7",height:"auto"}}>
                
            <button type="button" class="btn btn-success" onClick={handleSlotMode} style={{margin:"30px"}}>Create Slot</button>
              <br/>
              <div class="slotdiv">
              { gpSlots.length === 0 ? (
                <div class="grid-item">
                   <div style={{border:"none",margin:"0px 0px 0px 0px"}}> 
                   <br/> <br/> <br/>  <br/>
                    <h4 style={{textTransform:"capitalize",fontWeight:"bolder",color:"white",fontFamily:'cursive',padding:"40px"}}>No Slots Available..!</h4>
                  </div>
                </div>
              ) : (
              gpSlots.map((user,index) => (
              <div id='slcrediv' class="grid-item" key={index}>
                <div style={{border:"none"}}>
                  <div style={{border:"none",margin:"0px 0px 0px 0px"}}> 
                  <i class={user.id === 3 ? "bi bi-moon-fill" : "bi bi-sun-fill"} style={{fontSize:"30px",display:"flex",justifyContent:"center",color:"black"}}></i>
                  <br/>
                    <h3 style={{textTransform:"capitalize",fontWeight:"bolder",color:"green",fontFamily:'cursive'}}>{user.slot_title} 
                    </h3>
                    <h6 style={{fontSize:"20px",textAlign:"center",fontFamily:"monospace",fontWeight:"bolder",color:"black"}}>{user.order_open_time}</h6>
                    <p style={{textAlign:"center",color:'black'}}>to</p>
                    <h6 style={{fontSize:"20px",textAlign:"center",fontFamily:"monospace",fontWeight:"bolder",color:"black"}}>{user.order_close_time}</h6>
                  </div>
                </div>
                <span>
                  {/* <i class="bi bi-trash" onClick={() => handleDeleteGpSlot(user.id,user.slot_title)}
                style={{float:"right",fontSize:"21px",color:"black",marginTop:"270px",marginRight:"-20px",color:"red"}}></i> */}
                <i class="bi bi-pencil-square" onClick={() => handleEditSlot(user)} style={{float:"right",fontSize:"21px",color:"black",marginTop:"270px",marginRight:"0px",color:"blue"}}></i></span>
              </div>
             ))
              )}
             <br/>
             
              </div>
              
              </div>
            )}
            </Tab>
         </Tabs>
          </>
        )}
        {content === 'image' && (
          <>
          <h3 style={{fontFamily:"cursive",position:"absolute",top:"120px",left:"310px"}}>Please Choose Location</h3>
          <div style={{display: "flex",justifyContent: "center",alignItems: "center",height: "100vh"}}>
          <img src={image} alt="location" width="700px" height="400px"  />
          </div>
  
          </>
        )}




         {content === 'heading' && (
        <div>
          <nav className="navbar" 
            style={{backgroundColor:"rgba(10, 241, 33, 0.133)",border:"none",padding:"10px",height:"60px",width:"1065px",position:"relative",top:"-18px"}} >
    <h4 style={{fontFamily:"cursive",fontWeight:"bolder",color:"green",padding:"10px"}}>Order List <span style={{fontSize:"15px", color:"red"}}>(Location Name:{SelectedlocationName})</span></h4>
      </nav>
      
      {loading ? (
        <div class="text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
  ) : (
      <div style={{border:"none",padding:"5px 10px 10px 10px",margin:"10px"}}>

<div style={{display: "flex", justifyContent: "space-between"}}>
  <h6 style={{fontFamily:"serif",fontWeight:"bolder",color:"blue", fontSize:"18px"}}>No.Of Orders Rec.:{ordersDate.length}</h6>
  <h6 style={{fontFamily:"serif",fontWeight:"bolder",color:"blue", fontSize:"18px"}}>Total Amount: ${
    ordersDate.reduce((accumulator, order) => {
      return accumulator + order.total_price;
    }, 0)
  }</h6>
</div>
     <div className="table-container" style={{ height:"auto", overflowX: 'auto', overflowY: 'scroll' }}>
     <table class="table table-hover small-first-col" style={{border:"none",width:"100%",margin:"auto"}}>
  <thead>
    <tr style={{fontWeight:"bolder"}}>
      <th scope="col" style={{color:"green",fontWeight:"bolder"}}>S.No</th>
      <th scope="col" style={{color:"green",fontWeight:"bolder"}}>Ordered Date</th>
      <th scope="col" style={{color:"green",fontWeight:"bolder"}}>No.of Orders Rec</th>
      <th scope="col" style={{color:"green",fontWeight:"bolder"}}>Total Amount</th>
      <th scope="col" style={{color:"green",fontWeight:"bolder"}}>Action</th>
    </tr>
  </thead>
  {ordersDate.length === 0 ? (
        <div style={{marginTop:"-210px"}}>
            <div style={{display: "flex",justifyContent: "center",alignItems: "center",height: "100vh"}}>
              <img src={date} alt="user" width="430px" height="230px" />
            </div>
          <h3 style={{color:"black",fontWeight:"bolder",fontSize:"25px",textAlign:'center',marginTop:"-210px",fontFamily:"monospace"}}>Ordered Dates Not Available</h3>
        </div>
      ):(
  <tbody class="body-half-screen">
    {ordersDate.map((date,index) => (

    <tr style={{padding:"10px",border:"none"}}>
      <th scope="row" style={{border:"none",padding:"15px"}}>{index+1}</th>
      <td style={{border:"none",padding:"15px"}}>{date.order_date}</td>
      <td style={{border:"none",padding:"15px"}}>{date.order_count}</td>
      <td style={{border:"none",padding:"15px"}}>{date.total_price}</td>
      <td style={{border:"none",padding:"15px"}}>
      <i class="bi bi-arrow-right-circle-fill" style={{fontSize:"22px",color:"green"}} onClick={() => handleShowSoltList(date.order_date)}></i>{' '}
      </td>
     
    </tr>
   ))}
 

   </tbody>
)}
</table>

      </div>
 
      </div>
      )}
        </div>
         )}






{content === 'slotList' && (
        <div>
          <nav className="navbar" 
            style={{backgroundColor:"rgba(10, 241, 33, 0.133)",border:"none",padding:"10px",height:"60px",width:"1065px",position:"relative",top:"-18px"}} >
    <h4 style={{fontFamily:"cursive",fontWeight:"bolder",color:"green",padding:"10px"}}>Slot List <span style={{fontSize:"15px", color:"red"}}>(Location Name:{SelectedlocationName})</span></h4>
      </nav>
      {slotLoading ? (
        <div class="text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
  ) : (
      
      <div style={{border:"none",padding:"5px 10px 10px 10px",margin:"10px"}}>

<div style={{display: "flex", justifyContent: "space-between"}}>
  <h6 style={{fontFamily:"serif",fontWeight:"bolder",color:"blue", fontSize:"18px"}}>No.Of Orders Rec.:<span>{ordersSlot.length}</span></h6>
  <h6 style={{fontFamily:"serif",fontWeight:"bolder",color:"blue", fontSize:"18px"}}>Total Amount: ${
    ordersSlot.reduce((accumulator, order) => {
      return accumulator + order.total_price;
    }, 0)
  }</h6>
</div>
     <div className="table-container" style={{ height:"auto", overflowX: 'auto', overflowY: 'scroll' }}>
     <table class="table table-hover small-first-col" style={{border:"none",width:"100%",margin:"auto"}}>
  <thead>
    <tr style={{fontWeight:"bolder"}}>
      <th scope="col" style={{color:"green",fontWeight:"bolder"}}>S.No</th>
      <th scope="col" style={{color:"green",fontWeight:"bolder"}}>Slot Title</th>
      <th scope="col" style={{color:"green",fontWeight:"bolder"}}>No.of Orders Rec</th>
      <th scope="col" style={{color:"green",fontWeight:"bolder"}}>Total Amount</th>
      <th scope="col" style={{color:"green",fontWeight:"bolder"}}>Action</th>
    </tr>
  </thead>
  {ordersSlot.length === 0 ? (
        <div style={{marginTop:"-110px"}}>
            <div style={{display: "flex",justifyContent: "center",alignItems: "center",height: "100vh"}}>
              <img src={date} alt="user" width="430px" height="230px" />
            </div>
          <h3 style={{color:"black",fontWeight:"bolder",fontSize:"25px",textAlign:'center',marginTop:"-210px",fontFamily:"monospace"}}>Slots Not Available</h3>
        </div>
      ):(
  <tbody class="body-half-screen">
    {ordersSlot.map((slot,index) => (

    <tr style={{padding:"10px",border:"none"}}>
      <th scope="row" style={{border:"none",padding:"15px"}}>{index+1}</th>
      <td style={{border:"none",padding:"15px"}}>{slot.slot_title}</td>
      <td style={{border:"none",padding:"15px"}}>{slot.order_count}</td>
      <td style={{border:"none",padding:"15px"}}>{slot.total_price}</td>
      <td style={{border:"none",padding:"15px"}}>
      <i class="bi bi-arrow-right-circle-fill" style={{fontSize:"22px",color:"green"}} onClick={()=>handleShowOrders(slot.order_date, slot.group_location_slot_id, slot.slot_title)}></i>{' '}
      </td>
     
    </tr>
   ))}
 

   </tbody>
)}
</table>

      </div>
 
      </div>
  )}
        </div>
         )}






         {content === "order" && (
         <>
       {
  (ordersNPackDetails && ordersNPackDetails.results && ordersNPackDetails.results.length > 0) ||
  ( packedOrdersDetails && packedOrdersDetails.length > 0) ?
  <div style={{display: "flex", justifyContent: "space-between", padding:"20px",width:"700px",margin:"auto"}}>
    <h6 style={{fontFamily:"serif",fontWeight:"bolder",color:"black", fontSize:"20px"}}>Shop Name: <span style={{fontWeight:"normal",color:"black"}}> {" "}{localStorage.getItem('shopName')}</span>
    <br/>Location: <span style={{color:"black",fontWeight:"normal"}}>{SelectedlocationName}</span>
    <br/>Slot Title: <span style={{color:"black",fontWeight:"normal"}}>{" "}{SelectedSlotName}</span>
    </h6>
    {!(ordersNPackDetails && ordersNPackDetails.results && ordersNPackDetails.results.some(order => order.is_order_status === 3) || packedOrdersDetails && packedOrdersDetails.some(order => order.delivery_status === 1)) &&
    <button className="btn btn-primary fw-bolder" style={{height:"40px"}} onClick={handleToCompleteOrder}>Order Complete</button> 
     } 
  </div> : null
}



          <div className="row" style={{padding:"0px",marginTop:"-25px"}} >
             <div className="col-lg-6">
            <div className="card" style={{borderRadius:"20px",width:"400px",border:"none",height:"650px"}} >
              <div class="card-header" style={{border:"none",color:"red",fontWeight:"bolder",fontSize:"18px"}}>Unpacked Orders{" "}
              <span class="badge rounded-circle bg-danger" style={{color:"white"}}>{ordersNPackDetails && ordersNPackDetails.results && ordersNPackDetails.results.length}</span>
              </div>
              <div className="card-body" id="scr" style={{marginTop:"-20px"}}>
              <div style={{display: "flex", justifyContent: "space-between", padding:"10px 20px 20px 0px",margin:"auto"}}>
                <h6 className="text-primary"><b>New: </b><b style={{color:"black"}}>{getOrderCountByStatus(0)}</b></h6>
                <h6 className="text-warning"><b>Maybe: </b><b style={{color:"black"}}>{getOrderCountByStatus(2)}</b> </h6>
                <h6 className="text-success"><b>Accept: </b><b style={{color:"black"}}>{getOrderCountByStatus(1)}</b></h6>
                <h6 className="text-danger"><b>Cancel:</b> <b style={{color:"black"}}>{getOrderCountByStatus(3)}</b></h6>
              </div>
                {/* <br/>
                <h6 style={{margin:"10px 10px 10px 5px"}}><span style={{float:"right"}}><i class="bi bi-calendar"></i>{" "}{formattedDate}</span></h6> */}
                <br/>
                
                {
  Array.isArray(ordersNPackDetails.results) ? (
    ordersNPackDetails.results.map((order, index) => (
                    <Card border="white" style={{backgroundColor:"rgba(172, 4, 4, 0.178)",borderRadius:"20px",width:"350px",marginBottom:"0px",marginTop:"5px",height:"auto"}} key={index}> 

 <Card.Body className="p-2">
         
 <svg xmlns="http://www.w3.org/2000/svg"  width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
 <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
</svg>
            <h6 style={{marginLeft:"23px",marginTop:"-18px"}}>{order.name} 
            <span><i class="bi bi-arrow-right-circle" style={{float:"right", fontSize:"20px", color:"blue"}} onClick={()=>handleOpenItemDetails(order)}></i></span></h6>
            <h6 style={{marginLeft:"380px",marginTop:"-35px"}}><Button style={{backgroundColor:"#cae8e6",borderColor:"#cae8e6"}}><svg xmlns="http://www.w3.org/2000/svg" style={{color:"blue"}} width="25" height="25" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
</svg></Button></h6>

<tr>

          <td  style={{marginTop:"10px",padding:"5px"}}> <h6 style={{marginRightposition:"absolute"}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
</svg></h6><h6 style={{marginLeft:"20px",marginTop:"-25px"}}>{order.phone_no} {" "}</h6></td>   
          <td style={{position:"absolute",padding:"5px",float:"right"}}><h6 style={{float:"right"}}><b>Ordered date:</b>{order.ordered_at}</h6></td>
          </tr>
          <tr>
          <td  style={{padding:"5px"}}><h6><b>No of Items:</b> {order.no_of_items}{" "}</h6></td>   
          <td  style={{position:"absolute",padding:"5px"}}><h6><b>Total Cost:</b>{order.total_price}</h6></td>
          </tr>
          <tr>
          <td><h6><b>Order Status:</b></h6></td>   
          <td ><h6>{
            order.is_order_confirm_status === 0 ? 
            <span class="badge rounded-pill bg-primary" style={{letterSpacing:"2px"}}>New</span> : order.is_order_confirm_status === 1 ?
            <span class="badge rounded-pill bg-warning" style={{letterSpacing:"2px"}}>Accepted</span> : order.is_order_confirm_status === 2 ?
            <span class="badge rounded-pill bg-warning" style={{letterSpacing:"2px"}}>Maybe</span> : order.is_order_confirm_status === 3 ?
            <span class="badge rounded-pill bg-danger" style={{letterSpacing:"2px"}}>Cancelled</span> : "-"
          }
            </h6></td>
          </tr>
          <tr>
          <td><h6><b>Packing Status:</b></h6></td>   
          <td ><h6 style={{color:"green",padding:"5px"}} >
            {
              order.is_packing_done === 1 ? <span className="text-success fw-bold">Packed</span> :  <span className="text-danger fw-bold" style={{ opacity: order.is_order_confirm_status === 3 ? 0.5 : 1 }} >Unpacked</span>
            }{" "}
            {order.is_order_confirm_status === 1 && <button className="btn btn-success" onClick={()=>handlePackedStatus(order.id,order.ordered_at, order.group_location_slot_id)}>Pack</button>}
            </h6></td>
          </tr>
         {order.is_order_confirm_status === 3 ? null : (
           <tr>
           <td><h6><b>Order Action:</b></h6></td>   
           <td><button className={order.is_order_confirm_status === 1 ? "btn btn-light" : "btn btn-success" } onClick={()=>handleOpenAcceptModal(order)} disabled={order.is_order_confirm_status === 1}>Accept</button>{" "}
           {order.is_order_confirm_status === 0 && <button className="btn btn-warning" onClick={()=>handleChangeMaybe(order.id, order.ordered_at, order.group_location_slot_id)}>Maybe</button>}
           
           </td>
           </tr>
         )}
         
    </Card.Body>
 
  </Card>

))
) : (
  <p style={{padding:"20px", textAlign:"center", fontWeight:"bolder"}}>No orders</p>
)
}
              </div>
            </div>
          </div>



          <div className="col-lg-6">
            <div className="card" style={{borderRadius:"20px",width:"400px",border:"none",height:"650px"}} >
              <div class="card-header" style={{border:"none",color:"green",fontWeight:"bolder",fontSize:"18px"}}>Packed Orders{" "}
              <span class="badge rounded-circle bg-dark" style={{color:"white"}}>{packedOrdersDetails && packedOrdersDetails.length}</span>
              </div>
              <div className="card-body" id="scr" style={{marginTop:"-40px"}}>
                {/* <br/>
                <h6 style={{margin:"10px 10px 10px 5px"}}><span style={{float:"right"}}><i class="bi bi-calendar"></i>{" "}{formattedDate}</span></h6> */}
                <br/>
                {packedOrdersDetails && packedOrdersDetails.some(order => order.delivery_status !== 1) &&
                <div class="form-check form-check-inline" style={{float:"right",margin:"5px"}}>
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" checked={selectAll}
          onChange={handleSelectAll}/>
                  <label class="form-check-label text-primary" for="inlineCheckbox1">Print All Orders</label>
                </div>}
                
                {
  Array.isArray(packedOrdersDetails) ? (
    packedOrdersDetails.map((order, index) => (
                    <Card border="white" style={{backgroundColor:"#cae8e6",borderRadius:"20px",width:"350px",marginBottom:"0px",marginTop:"12px",height:"auto"}} key={index}> 

 <Card.Body className="p-2">
         
 <svg xmlns="http://www.w3.org/2000/svg"  width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
 <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
</svg>
            <h6 style={{marginLeft:"23px",marginTop:"-18px"}}>{order.name}
            <span><i class="bi bi-arrow-right-circle-fill" style={{float:"right", fontSize:"20px", color:"blue"}} onClick={()=>handleOpenItemDetails(order)}></i></span>
            </h6>
            <h6 style={{marginLeft:"380px",marginTop:"-25px"}}><Button style={{backgroundColor:"#cae8e6",borderColor:"#cae8e6"}}><svg xmlns="http://www.w3.org/2000/svg" style={{color:"blue"}} width="25" height="25" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
</svg></Button></h6><tr>

          <td  style={{marginTop:"10px",padding:"5px"}}> <h6 style={{marginRightposition:"absolute"}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
</svg></h6><h6 style={{marginLeft:"20px",marginTop:"-25px"}}>{order.phone_no} {" "}</h6></td>   
          <td style={{position:"absolute",padding:"5px",float:"right"}}><h6 style={{float:"right"}}><b>Ordered date:</b>{" "}{order.ordered_at}</h6></td>
          </tr>
          <tr>
          <td  style={{padding:"5px"}}><h6><b>No of Items:</b> {order.no_of_items}{" "}</h6></td>   
          <td  style={{position:"absolute",padding:"5px"}}><h6><b>Total Cost:</b>{order.total_price}</h6></td>
          </tr>
          <tr>
          <td><h6><b>Order Status:</b></h6></td>   
          <td ><h6>{
            order.is_order_confirm_status === 0 ? 
            <span class="badge rounded-pill bg-primary" style={{letterSpacing:"2px"}}>New</span> : order.is_order_confirm_status === 1 && order.delivery_status === 0 ?
            <span class="badge rounded-pill bg-warning" style={{letterSpacing:"2px"}}>Accepted</span> : order.is_order_confirm_status === 2 ?
            <span class="badge rounded-pill bg-warning" style={{letterSpacing:"2px"}}>Maybe</span> : order.is_order_confirm_status === 3 ?
            <span class="badge rounded-pill bg-danger" style={{letterSpacing:"2px"}}>Cancelled</span> : order.is_order_confirm_status === 1 && order.delivery_status === 1 ?
            <span class="badge rounded-pill bg-success" style={{letterSpacing:"2px"}}>Delivered</span> : "-"
          }
            </h6></td>
          </tr>
          <tr>
          <td><h6><b>Packing Status:</b></h6></td>   
          <td ><h6 style={{color:"green",padding:"5px"}} >
            {
              order.is_packing_done === 1 ? <span className="text-success fw-bold">Packed</span> :  <span className="text-danger fw-bold">Unpacked</span>
            }
            </h6></td>
          </tr>
          {
            order.delivery_status ===0 &&
          
          <tr>
          <td><input type="checkbox" style={{position:"absolute",marginLeft:"95px",width:"20px",height:"20px",marginTop:"5px",color:"#cae8e6",border:"none"}} checked={orderCheckboxes.includes(index)}
                onChange={() => handleOrderCheckboxChange(index,order)}/>{" "}</td>
          <td >{" "}<h6 style={{padding:"5px"}} >Make Print Now</h6></td>
          </tr>
}
    </Card.Body>
 
  </Card>

))
) : (
  <p style={{padding:"20px", textAlign:"center", fontWeight:"bolder"}}>No orders</p>
)
}
               
              </div>
            </div>
          </div>


          </div>

         </>
         )}
        </div>
      </div>

      <Modal isOpen={modShow} toggle={closeModal}>
        <ModalHeader>CREATE LOCATION </ModalHeader>
        <ModalBody>
          <div className="form mb-3">
            <label htmlFor="floatingInput" style={{fontWeight:"bolder",color:"grey"}}>Location Title <b style={{color:"red"}}>*</b>
           </label>
            <input
             type="text"
             className="form-control"
             id="floatingInput"
             onChange={(e) => setLocationTitle(e.target.value)}
             required
            />
          </div>
          <div className="form mb-3">
            <label htmlFor="floatingAddress" style={{fontWeight:"bolder",color:"grey"}}>Address<b style={{color:"red"}}>*</b></label>
            <input
             type="text"
             className="form-control"
             id="floatingAddress"
             onChange={(e) => setAddress(e.target.value)}
             required
            />
          </div>
          <div className="form mb-3">
            <label htmlFor="floatingPincode" style={{fontWeight:"bolder",color:"grey"}}>Pin Code<b style={{color:"red"}}>*</b></label>
            <input
             type="number"
             className="form-control "
             id="floatingPincode"
             onChange={(e) => setPinCode(e.target.value)}
             required
            />
          </div>
          <div className="form mb-3">
            <label htmlFor="floatingLatitude" style={{fontWeight:"bolder",color:"grey"}}>Latitude<b style={{color:"red"}}>*</b></label>
            <input
              type="number"
              className="form-control"
              id="floatingLatitude"
              onChange={(e) => setLatitude(e.target.value)}
              required
            />
          </div>
          <div className="form mb-3">
            <label htmlFor="floatingLongtitude" style={{fontWeight:"bolder",color:"grey"}}>Longitude<b style={{color:"red"}}>*</b></label>
            <input
             type="number"
             className="form-control"
             id="floatingLongtitude"
             onChange={(e) => setLongitude(e.target.value)}
             required
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleCreateLocation}>
            {addLoLoading ? (
              <div class="text-center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            ) : " Submit" }
            </Button> 
          <Button color="danger" onClick={closeModal}>Close</Button>
        </ModalFooter>
      </Modal>  

      <Modal isOpen={slotMod} toggle={closeSlotModal}>
        <ModalHeader>CREATE SLOT </ModalHeader>
        <ModalBody>
        <div className="form mb-3">
            <label htmlFor="floatingSlotTitle" style={{fontWeight:"bolder",color:"grey"}}>Slot Title<b style={{color:"red"}}>*</b></label>
            <input
             type="text"
             className="form-control"
             id="floatingSlotTitle"
             onChange={(e) => setSlotTitle(e.target.value)}
             required
            />
          </div>
          <div className="form mb-3">
            <label htmlFor="floatingOpenTime" style={{fontWeight:"bolder",color:"grey"}}>Order Open Time<b style={{color:"red"}}>*</b></label>
            <input
             type="time"
             className="form-control"
             id="floatingOpenTime"
             onChange={(e) => setOrderOpenTime(e.target.value)}
             required
            />
            {/* <TimePicker 
              onChange={onChange} 
              value={value} /> */}
          </div>
          <div className="form mb-3">
            <label htmlFor="floatingCloseTime" style={{fontWeight:"bolder",color:"grey"}}>Order Close Time<b style={{color:"red"}}>*</b></label>
            <input
            type="time"
            className="form-control"
            id="floatingCloseTime"
            onChange={(e) => setOrderCloseTime(e.target.value)}
            required
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleCreateGPSlot}>Submit</Button> 
          <Button color="danger" onClick={closeSlotModal}>Close</Button>
        </ModalFooter>
      </Modal>  


      <Modal isOpen={itemMod} toggle={closeItemModal}>
        <ModalHeader toggle={closeItemModal} style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"12px"}}>Item Details </ModalHeader>
        <ModalBody>
          <div style={{display: "flex", justifyContent: "space-between", padding:"10px"}}>
            <h6 style={{fontFamily:"serif",fontWeight:"bolder",color:"green", fontSize:"18px"}}>Customer Name: <span style={{fontWeight:"normal",color:"black"}}>{" "}{selectedOrder && selectedOrder.name}</span></h6>
            <h6 style={{fontFamily:"serif",fontWeight:"bolder",color:"green", fontSize:"18px",float:"left"}}>Slot Title:<span style={{fontWeight:"normal",color:"black"}}> {" "}{selectedOrder && selectedOrder.slot_title}</span></h6>
          </div>
          <div style={{display: "flex", justifyContent: "space-between", padding:"1px 10px 1px 10px"}}>
            <h6 style={{fontFamily:"serif",fontWeight:"bolder",color:"green", fontSize:"18px"}}><i class="bi bi-telephone"></i> <span style={{fontWeight:"normal",color:"black"}}>{" "}{selectedOrder && selectedOrder.phone_no}</span></h6>
            <h6 style={{fontFamily:"serif",fontWeight:"bolder",color:"green", fontSize:"18px",float:"left"}}><i class="bi bi-calendar"></i><span style={{fontWeight:"normal",color:"black"}}> {" "}{selectedOrder &&selectedOrder.ordered_at}</span></h6>
          </div>

          <div className="table-container" style={{ height:"auto", overflowX: 'auto', overflowY: 'scroll' }}>
     <table class="table table-hover small-first-col" style={{border:"none",width:"100%",margin:"auto"}}>
  <thead>
    <tr style={{fontWeight:"bolder"}}>
      <th scope="col" style={{color:"green",fontWeight:"bolder"}}>S.No</th>
      <th scope="col" style={{color:"green",fontWeight:"bolder"}}>Items</th>
      <th scope="col" style={{color:"green",fontWeight:"bolder"}}>Qty</th>
      <th scope="col" style={{color:"green",fontWeight:"bolder"}}>Unit Price</th>
      <th scope="col" style={{color:"green",fontWeight:"bolder"}}>Amt.</th>
    </tr>
  </thead>
  
  <tbody class="body-half-screen">
    {selectedOrder && selectedOrder.ordered_by.map((item,index) => (

    <tr style={{padding:"10px",border:"none"}}>
      <th scope="row" style={{border:"none",padding:"15px"}}>{index+1}</th>
      <td style={{border:"none",padding:"15px"}}>{item.food_name}</td>
      <td style={{border:"none",padding:"15px"}}>{item.quantity}</td>
      <td style={{border:"none",padding:"15px"}}>{item.unit_price}</td>
      <td style={{border:"none",padding:"15px"}}>
      {item.quantity * item.unit_price}
      </td>
     
    </tr>
   ))}
   <tr style={{padding:"10px",border:"none"}}>
     
      <td style={{border:"none",padding:"15px", fontSize:"20px",color:"green",fontWeight:"bolder",textAlign:"center",borderTop:"1px solid black"}} colSpan={5}>
     Total Amount: {" "}{ selectedOrder && selectedOrder.total_price}
      </td>
     
    </tr>
 

   </tbody>

</table>

      </div>
        
        </ModalBody>
        {/* <ModalFooter>
          <Button color="success" onClick={handleCreateGPSlot}>Submit</Button> 
          <Button color="secondary" onClick={closeSlotModal}>Cancel</Button>
        </ModalFooter> */}
      </Modal>  

      <Modal isOpen={isAccept} toggle={closeAcceptModal} size="md">
       
        <ModalBody>
         {/* <h6 style={{fontWeight:"bolder"}}>If you want to Accept the order</h6> */}
         <div style={{margin:"auot",padding:"20px"}}>
         <Button color="success" onClick={()=>handleChangeAccept(acceptedOrder)} style={{float:"right"}}>Confirm</Button> 

         <h6 style={{fontWeight:"bolder", fontFamily:"serif"}}>Cust.Name: <span style={{fontWeight:"normal", fontFamily:"serif"}}>{acceptedOrder && acceptedOrder.name}</span></h6>
         <h6 style={{fontWeight:"bolder", fontFamily:"serif"}}>Mobile No: <span style={{fontWeight:"normal", fontFamily:"serif"}}>{acceptedOrder && acceptedOrder.phone_no}</span></h6>
         <h6 style={{fontFamily:"serif",fontWeight:"bolder", fontSize:"20px"}}>Bill Amount: <span style={{fontFamily:"monospace",fontWeight:"normal",color:"red"}}>{ acceptedOrder && acceptedOrder.total_price }</span><span style={{float:"right"}}>Paymode Total: <b style={{color: "red"}}>{ acceptedOrder && acceptedOrder.total_price }</b></span></h6>
         <br/>
       <div style={{marginTop:"-20px"}}>
       <table class="table table-hover">
  <thead>
    <tr>
      <th scope="col" style={{textAlign:"center"}}>Paymode</th>
      <th scope="col" style={{textAlign:"center"}}>Amount</th>
    </tr>
  </thead>
  <tbody>
  <tr>
      <td>
        <div class='card bg-danger text-light' style={{width:"auto", height:"auto", margin:"0px"}} >
          <div class="card-body" style={{textAlign:"center"}}>
          GROUP LOCATION CREDIT ORDER
          </div>
        </div>
      </td>
      <td>
       <Input  type="number" id="paymodeAmount" value={ acceptedOrder && acceptedOrder.total_price }  style={{width:"140px",margin:"auto"}} min="0" />    
      </td>
    </tr>
    </tbody>
        </table>
         </div>
         <div>
    <label style={{fontWeight:"bolder", fontFamily:"serif"}}>Remarks:</label>
       <Input  type="text" id="remark" placeholder="Remark" required  style={{width:"280px"}}/>    
    </div>
    
    
       </div>
       <br/>  
        </ModalBody>
        <ModalFooter>
         
          <Button color="danger" onClick={closeAcceptModal}>Cancel</Button>
        </ModalFooter>
      </Modal>



       <Modal isOpen={isOrderComplete} toggle={closeOrderModal} size="md">
       <ModalHeader toggle={closeOrderModal} style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"12px"}}>CONFIRM!</ModalHeader>
        <ModalBody>
         <h6 style={{fontWeight:"bolder",color:"green",fontFamily:"serif"}}>Total Orders: <span style={{fontWeight:"normal",color:"black",fontFamily:"monospace"}}>{ordersNPackDetails && ordersNPackDetails.results && ordersNPackDetails.results.length + packedOrdersDetails && packedOrdersDetails.length}</span></h6>
         <h6 style={{fontWeight:"bolder",color:"green",fontFamily:"serif"}}>Packed Orders: <span style={{fontWeight:"normal",color:"black",fontFamily:"monospace"}}>{packedOrdersDetails && packedOrdersDetails.length}</span></h6>
         <h6 style={{fontWeight:"bolder",color:"green",fontFamily:"serif"}}>Rejected Orders: <span style={{fontWeight:"normal",color:"black",fontFamily:"monospace"}}>{ordersNPackDetails && ordersNPackDetails.results && ordersNPackDetails.results.length}</span></h6>
         <p>Are you sure confirm the <b style={{color:"red"}}>{packedOrdersDetails && packedOrdersDetails.length} packed orders</b> to delivery and cancel the <b style={{color:"red"}}>{ordersNPackDetails && ordersNPackDetails.results && ordersNPackDetails.results.length} unpacked orders</b></p>
        </ModalBody>    
        <ModalFooter>
          <Button color="success" onClick={handleToCancelDelivery} >
          {
              deliverLoading ? (
                <div class="text-center">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              ) : "Confirm"
            }
            
            </Button> 
          <Button color="danger" onClick={closeOrderModal}>Cancel</Button>
        </ModalFooter>
      </Modal> 



      <Modal isOpen={isOrderDeliver} toggle={closeOrderDeliverModal} size="md">
       <ModalHeader toggle={closeOrderDeliverModal} style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"12px"}}>CONFIRM!</ModalHeader>
        <ModalBody>
         <p>Are you sure you want to confirm the delivery?</p>
        </ModalBody>    
        <ModalFooter>
          <Button color="success" onClick={handleToDeliveryStatus}>
            {
              deliverLoading ? (
                <div class="text-center">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              ) : "Confirm"
            }
            
          </Button> 
          <Button color="danger" onClick={closeOrderDeliverModal}>Cancel</Button>
        </ModalFooter>
      </Modal> 

      <Modal
        isOpen={show}
        toggle={()=>setShow(false)}
        size="md" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <ModalHeader closeButton style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"22px"}}>
          {/* <Modal.Title>confirmation</Modal.Title> */}
        </ModalHeader>
        <ModalBody>

         <div style={{padding:"20px",backgroundColor:"white"}}>
         <h4 style={{fontFamily:"monospace",fontWeight:"bolder",textAlign:"center"}}>CONFIRM!</h4>
          <h6 className='text-dark' style={{textAlign:"center",fontWeight:"bolder"}}>Are You Sure want to Delete Location{" "}
          <span style={{color:"red",textTransform:"uppercase",fontSize:"22px"}}>{deletedLocation.l_name}</span> ?</h6> 
         </div>
         {' '} 
          <button className="btn btn-danger" style={{float:"right"}} onClick={()=>setShow(false)}>
            Close
          </button>{' '} 
          <button className="btn btn-success" style={{float:"right",marginRight:"5px"}} onClick={handleDeleteLocation}>
            Confirm
          </button>
        </ModalBody>
      </Modal> 


      <Modal isOpen={isDeleteUser} toggle={()=>setIsDeleteUser(false)} size="md">
       <ModalHeader toggle={()=>setIsDeleteUser(false)} style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"22px"}}></ModalHeader>
        <ModalBody>
        <div style={{padding:"20px",backgroundColor:"white"}}>
         <h4 style={{fontFamily:"monospace",fontWeight:"bolder",textAlign:"center"}}>CONFIRM!</h4>
          <h6 className='text-dark' style={{textAlign:"center",fontWeight:"bolder"}}>Are You Sure want to Delete user{" "}
          <span style={{color:"red",textTransform:"uppercase",fontSize:"22px"}}>{deletedUser.u_name}</span> ?</h6> 
         </div>
        </ModalBody>    
        <ModalFooter>
          <Button color="success" onClick={handleToDeleteGpUser}>Confirm</Button> 
          <Button color="danger" onClick={()=>setIsDeleteUser(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>


      <Modal isOpen={isDeleteSlot} toggle={()=>setIsDeleteSlot(false)} size="md">
       <ModalHeader toggle={()=>setIsDeleteSlot(false)} style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"22px"}}></ModalHeader>
        <ModalBody>
        <div style={{padding:"20px",backgroundColor:"white"}}>
         <h4 style={{fontFamily:"monospace",fontWeight:"bolder",textAlign:"center"}}>CONFIRM!</h4>
          <h6 className='text-dark' style={{textAlign:"center",fontWeight:"bolder"}}>Are You Sure want to Delete Slot{" "}
          <span style={{color:"red",textTransform:"uppercase",fontSize:"22px"}}>{deletedSlot.slot_name}</span> ?</h6> 
         </div>
        </ModalBody>    
        <ModalFooter>
          <Button color="success" onClick={handleToDeleteSlots}>Confirm</Button> 
          <Button color="danger" onClick={()=>setIsDeleteSlot(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>


      <Modal isOpen={isOrderBill} toggle={()=>setIsOrderBill(false)} size="md">
       <ModalHeader toggle={()=>setIsOrderBill(false)} style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"22px"}}></ModalHeader>
        <ModalBody>
        <div ref={componentRef} style={{display:"block"}}>
        {/* Render content of ordersArray here */}
        {ordersArray.map((order, index) => (
          // Render each order item here
          <div key={index}>
              <div style={{backgroundColor:"rgba(63, 68, 63, 0.082)",padding:"30px",width:"300px"}}>
      <div>
      <p style={{textAlign:"center",fontWeight:"lighter",textTransform:"uppercase",color:"black",fontFamily:"sans-serif"}}>{localStorage.getItem('shopName')}</p>
     
      {shopDetails ? (
      <div className="m-0" style={{lineHeight: "15%",fontFamily:"sans-serif",margin:"0px"}}>
        <p  style={{textAlign:"center", fontWeight:"bold",fontSize:"12px"}}>#{shopDetails.address}</p>
        <p  style={{textAlign:"center", fontWeight:"normal",fontSize:"12px",marginBottom:"10px"}}>{shopDetails.state}, {shopDetails.country_name}</p>
      </div>
    ) : (
      <div>Loading...</div>
    )}
     
      
    </div>
    <hr  style={{border:"1px dashed black",padding:"0px",margin:"0px"}}/>
      <h6 style={{fontWeight:"lighter",color:"black", fontSize:"14px"}} >Order No:{" "}<b>{order.order_no}</b>
      <span style={{fontSize:"13px", float:"right",letterSpacing:"-1px"}}>{" "}{order.ordered_at}</span>
      </h6>
      <h6 style={{fontWeight:"lighter",color:"black", fontSize:"13px"}} >Group Location Order {" "}
  <span style={{fontSize:"13px", float:"right",letterSpacing:"-1px"}}>{order.ordered_time}</span>
  </h6>
<table style={{borderCollapse:"collapse",width:"100%",marginBottom:"10px"}}>
<thead style={{borderTop:"1px solid black",borderBottom:"1px solid black",maxWidth:"100%"}}>
 <th style={{padding:"2px",color:"black"}}>Item</th>
 <th style={{padding:"2px",color:"black"}}>Qty</th>
 <th style={{padding:"2px",color:"black"}}>Price</th>
 <th style={{padding:"2px",color:"black"}}>Total</th>
</thead>

<tbody>
 {order && order.ordered_by.map((item, itemIndex) => (
 <tr key={itemIndex}>
   <td style={{padding:"2px",color:"black",textAlign:"left",textTransform:"capitalize"}}>{item.food_name}</td>
   <td style={{padding:"2px",color:"black",textAlign:"center"}}>{item.quantity}</td>
   <td style={{padding:"2px",color:"black",textAlign:"center"}}>{item.unit_price}</td>
   <td style={{padding:"2px",color:"black",textAlign:"center"}}>{item.quantity * item.unit_price}</td>
 </tr>
 
 ))}
</tbody>
</table>
<hr style={{border:"1px dashed black",position:"relative",top:"-15px",padding:"0px",marginBottom:"10px"}}/>
      <h6 style={{padding:"1px",color:"black",textAlign:"center",fontWeight:"bolder",marginTop:"-20px",fontFamily:"monospace",marginBottom:"-2px"}}><b>Total Amount: {" "}
        {(Math.round(order.total_price * 100) / 100).toFixed(1)}</b> 
        <span style={{fontSize:"17px"}}>{shopDetails ? shopDetails.shop_currency : "null"}</span>
        </h6>
    
      <table style={{ borderCollapse: "collapse", border: "1px solid black", width: "100%" }}>
  <thead>
    <tr>
      <th style={{ border: "1px solid black", padding: "1px 4px 1px 4px" }}>Paymode</th>
      <th style={{ border: "1px solid black", padding: "1px 4px 1px 4px" }}>Amount</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td style={{ border: "1px solid black", padding: "1px 4px 1px 4px", textAlign: "center" }}>{order.pay_mode}</td>
      <td style={{ border: "1px solid black", padding: "1px 4px 1px 4px", textAlign: "center" }}>{(Math.round(order.total_price * 100) / 100).toFixed(1)}</td>
    </tr>
  </tbody>
</table>
    
      <h6 style={{margin:"2px",padding:"1px", border:"1px solid black", fontSize:"14px", fontWeight:"bolder"}}>Shop Ph : {shopDetails? shopDetails.user.mobile : null}</h6>
      <h6 style={{margin:"2px",padding:"1px",border:"1px solid black", fontSize:"13px", fontWeight:"bolder"}}>Thank you for Dine With Us.!</h6>      
      </div>
          </div>
        ))}
      </div>
        </ModalBody>    
        <ModalFooter>
        <ReactToPrint
                  trigger={() => (
                    <div style={{ textAlign: 'center' }}>
                       <Button color="success">Print</Button> 
                    </div>
                )}
                content={() => componentRef.current}
                />
          
          <Button color="danger" onClick={()=>setIsOrderBill(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>

     
        </div>
    )
}




const UserForm = ({ user, onSubmit, onClose }) => {
  const [edLocationTitle, setedLocationTitle] = useState(user.location_title);
  const [edAddress, setedAddress] = useState(user.address);
  const [edPincode, setedPincode] = useState(user.pin_code);
  const [edLatitude, setedLatitude] = useState(user.latitude);
  const [edLongitude, setedLongitude] = useState(user.longitude);
  // const [edIs_active, setedIs_active] = useState(user.is_active);
  // const shopid = localStorage.getItem('shopId');
        
              

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedUser = {
      id:user.id,
      location_title:edLocationTitle,
      address:edAddress,
      pin_code:edPincode,
      latitude:edLatitude,
      longitude:edLongitude,
      
    };

    onSubmit(updatedUser);
  };

  return (
    <div>
      <h3 className='grpushe'>UPDATE LOCATION</h3>
      <br/>
    <form onSubmit={handleSubmit} style={{margin:"20px",padding:"15px"}}>
      <div class="form-group">
        <label for="exampleInputLocation1" style={{fontWeight:"bolder",color:"green"}}>Location Title</label>
        <input type="text" class="form-control" id="exampleInputLocation1" aria-describedby="LocationHelp" placeholder="Enter Location" value={edLocationTitle} onChange={(e) => setedLocationTitle(e.target.value)} required/>
      </div>
      <br/>
      <div class="form-group">
        <label for="exampleInputLocation2" style={{fontWeight:"bolder",color:"green"}}>Address</label>
        <input type="text" class="form-control" id="exampleInputLocation2" aria-describedby="LocationHelp" placeholder="Enter Address" value={edAddress} onChange={(e) => setedAddress(e.target.value)} required />
      </div>
      <br/>
      <div class="form-group">
        <label for="exampleInputLocation3" style={{fontWeight:"bolder",color:"green"}}>Pincode</label>
        <input type="number" class="form-control" id="exampleInputLocation3" aria-describedby="LocationHelp" placeholder="Enter Pincode" value={edPincode} onChange={(e) => setedPincode(e.target.value)} required/>
      </div>
      <br/>
      <div class="form-group">
        <label for="exampleInputLocation4" style={{fontWeight:"bolder",color:"green"}}>Latitude</label>
        <input type="number" class="form-control" id="exampleInputLocation4" aria-describedby="LocationHelp" placeholder="Enter Latitude" value={edLatitude} onChange={(e) => setedLatitude(e.target.value)} required />
      </div>
      <br/>
      <div class="form-group">
        <label for="exampleInputLocation5" style={{fontWeight:"bolder",color:"green"}}>Longitude</label>
        <input type="number" class="form-control" id="exampleInputLocation5" aria-describedby="LocationHelp" placeholder="Enter Longitude" value={edLongitude} onChange={(e) => setedLongitude(e.target.value)} required/>
      </div>
      <br/>
      <button className="btn btn-success p-2" type="submit">Update</button>{' '}
      <button className="btn btn-danger p-2" onClick={onClose}>Close</button>
    </form>
    </div>
  );
};


const SlotForm = ({ user, onSubmit, onCloseSlot }) => {
  const [edSlotTitle, setedSlotTitle] = useState(user.slot_title);
  const [edOpenTime, setedOpenTime] = useState(user.order_open_time);
  const [edCloseTime, setedCloseTime] = useState(user.order_close_time);

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedUser = {
      id:user.id,
      slot_title:edSlotTitle,
      order_open_time:edOpenTime,
      order_close_time:edCloseTime,
      
    };

    onSubmit(updatedUser);
  };

  return (
    <div>
      <h3 className='grpushe'>UPDATE SLOT</h3>
      <br/>
    <form onSubmit={handleSubmit} style={{margin:"20px",padding:"15px"}}>
      <div class="form-group">
        <label for="exampleInputSlot1" style={{fontWeight:"bolder",color:"green"}}>Slot Title</label>
        <input type="text" class="form-control" id="exampleInputSlot1" aria-describedby="SlotHelp" placeholder="Enter Slot" value={edSlotTitle} onChange={(e) => setedSlotTitle(e.target.value)} required/>
      </div>
      <br/>
      <div class="form-group">
        <label for="exampleInputSlot2" style={{fontWeight:"bolder",color:"green"}}>Order Open Time</label>
        <input type="time" class="form-control" id="exampleInputSlot2" aria-describedby="SlotHelp" placeholder="Enter Time" value={edOpenTime} onChange={(e) => setedOpenTime(e.target.value)} required/>
      </div>
      <br/>
      <div class="form-group">
        <label for="exampleInputSlot3" style={{fontWeight:"bolder",color:"green"}}>Order Close Time</label>
        <input type="time" class="form-control" id="exampleInputSlot3" aria-describedby="SlotHelp" placeholder="Enter Time" value={edCloseTime} onChange={(e) => setedCloseTime(e.target.value)} required/>
      </div>
      <br/>
      <button className="btn btn-success p-2" type="submit">Update</button>{' '}
      <button className="btn btn-danger p-2" onClick={onCloseSlot}>Close</button>
    </form>
    </div>
  );
};



export default GroupOrders;