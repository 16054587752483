import React from 'react';
import './creditCustomer.css';
import profile from '../../images/splash/prof.png';
import { useState,useEffect } from 'react';
import axios from 'axios';
import { var_api } from '../constant';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { Label, Tooltip,Input } from 'reactstrap';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import swal from 'sweetalert';
import EmailTemplate from './emailTemp';
import { useRef } from 'react';


const CreditCustomer = () => {
   const [ users,setUsers ] =  useState([]);
   const fileInputRef = useRef(null);
   const [ rytUser,setRytUser ] =  useState([]);
   const [ shopId,setShopId ] = useState();
   const [ searchQuery,setSearchQuery] = useState('');
   const [ searchTrans,setSearchTrans] = useState('');
   const history = useHistory();
   const [showRight, setShowRight] = useState(true);
   const [creditId,setCreditId] = useState(' ');
   const [ transaction,setTransaction ] = useState([]);
   const [ orderDetails,setOrderDetails ] = useState();
   const [totalAmount, setTotalAmount] = useState(0);
   const [balance1, setBalance] = useState(0);
   const [ laBal,setLaBal ] = useState();
   const [ totalPaid,setTotalPaid ] = useState();
   const [ record,setRecord ] = useState([]);
   const [show, setShow] = useState(false);
   const [showOrder, setShowOrder] = useState(false);
   const [showPayment, setShowPayment] = useState(false);
   const [maxiShow, setMaxiShow] = useState(false);
   const [searchMonth, setSearchMonth] = useState('');
   const [ paidStatus, setPaidStatus] = useState('');
   const [startDate, setStartDate] = useState('');
   const [formattedStartDate, setFormattedStartDate] = useState('');
   const [formattedEndDate, setFormattedEndDate] = useState('');
   const [ payableAmount, setPayableAmount] = useState();
   const [isOpen, setIsOpen] = useState(false);
   const [ isEdit,setIsEdit ] = useState(false);
   const [puttooltipOpen, setPutToolTipOpen] = useState([]);
   const [actiontooltipOpen, setActionToolTipOpen] = useState([]);
   const [isOpenPaymode, setIsOpenPaymode] = useState(false);
   const [selectedAmounts, setSelectedAmounts] = useState([]);
   const [paymodes, setPaymodes] = useState([]);
   const [formData, setFormData] = useState({
    customer_name:'',
    email:'',
    country_code:'',
    phone_no:'',
    user_gov_id:'',
    user_profile:"",  
    maxCredit:''  
  });
  const [updateFormData, setUpdateFormData] = useState({
    id:null,
    name:'',
    email:'',
    country_code:'',
    phone_no:'',
    user_gov_id:'',
    user_profile:"",  
    maxCredit:''  
  });
  const [createLoading, setCreateLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);



    useEffect(() => {
        fetchData();
        handleListPayModes();
      }, []);


      // const fetchShopData = async () => {
      //   try {
      //      // const response = await axios.get('https://api.spbiller.com/api/shop/data');
      //      const create = localStorage.getItem('userId')
      //      const response = await axios.get(`${var_api}/api/shop/api/shops/${create}`);
      //     const user =response.data;
  
      //      if (Array.isArray(user)) {
      //       this.setState({ 
      //         users: user
      //        });
      //        localStorage.setItem('shopId',user.id);
      //        console.log("shop shop id",localStorage.getItem('shopId',user.id));
      //     } else {
      //       // Handle the case when responseData is not an array
      //       console.error('API response is not an array:', user);
      //       this.setState({ 
      //         users: [user]
      //        });; // Set users to an empty array or handle the error appropriately
      //     }
      //   } catch (error) {
      //     console.log(error);
      //   }
      // };
  

      //get credit user data
      const fetchData = async () => {
        const shopid = localStorage.getItem('shopId');
       try {
        const response = await axios.get(`${var_api}/api/credituser/data/${shopid}` );
        const use = response.data
        setShopId(use[0].shop_id);
        setRytUser(use[0]);
        setUsers(use);
      }catch (error) {
        console.log('Error fetching data:', error);
        setUsers([]);
        }
      };


       //get pending payment start date
       const fetchStartDate = async (Id) => {
        const shopid = localStorage.getItem('shopId');
       try {
        const response = await axios.get(`${var_api}/api/creditorder/get-start-date/${Id}/${shopid}` );
        const use = response.data
       setStartDate(use);
       console.log(use);
      }catch (error) {
        console.log('Error fetching data:', error);
       
        }
      };


      const toggleActionTooltip = (index) => {
        const actionTooltipOpen = [...actiontooltipOpen];
        actionTooltipOpen[index] = !actionTooltipOpen[index];
        setActionToolTipOpen(actionTooltipOpen);
      };

      const togglePutTooltip = (index) => {
        const putTooltipOpen = [...puttooltipOpen];
        putTooltipOpen[index] = !putTooltipOpen[index];
        setPutToolTipOpen(putTooltipOpen);
      };



 //calculate balance amount and total paid amount
        const fetchBal = async (id) => {
            
           try {
            const response = await axios.get(`${var_api}/api/payment/balancedata/${id}/${shopId}` );
            const data = response.data.balance;
            const totalpay = response.data.totalPaid;
            const finalAmount =  response.data.totalAmount
            setTotalPaid(totalpay)
            setLaBal(data);
            setTotalAmount(finalAmount);
            console.log("balance",laBal);
          }catch (error) {
            console.log('Error fetching data:', error);
            setUsers([]);
            }
          };


        //get all the data from payment record
          const fetchRecord = async (id) => {
            setShow(true)
            try {
             const response = await axios.get(`${var_api}/api/payment/fulldata/${id}/${shopId}` );
             const data = response.data.results;
 
             setRecord(data);
             console.log("balance",record);
             
           }catch (error) {
             console.log('Error fetching data:', error);
             setUsers([]);
             }
             fetchData();
           };


           //get order details for particular credit order
           const OrderDetails = async (id, status) => {
            setShowOrder(true)
            try {
             const response = await axios.get(`${var_api}/api/creditorder/orderitemshistory/${id}` );
             const data = response.data;
 
             setOrderDetails(data);
             setPaidStatus(status)
             console.log("order details",orderDetails);
             
           }catch (error) {
             console.log('Error fetching data:', error);
             setOrderDetails([]);
             }
             fetchData();
           };


           //get order details for particular group Location order
           const GLOrderDetails = async (id, status) => {
            setShowOrder(true)
            try {
             const response = await axios.get(`${var_api}/api/creditorder/grouplocationorder/${id}` );
             const data = response.data.results[0];
 
             setOrderDetails(data);
             setPaidStatus(status)
             console.log("order details",orderDetails);
             
           }catch (error) {
             console.log('Error fetching data:', error);
             setOrderDetails([]);
             }
           };
    
  
  

    // get the credit order history data
        const fetchTransData = async (id,user) => {
            const shopId = localStorage.getItem('shopId');
           try {
            const response = await axios.get(`${var_api}/api/creditorder/orderhistory/${shopId}/${id}` );
            const data = response.data
            console.log(data)
            if(data.results.length > 0){
              setTransaction(data.results);
            }else{
              setTransaction([]);
            }

           
             // Calculate the total amount from 'data' and store it in 'totalAmount'
            //  const calculatedTotal = data.reduce((accumulator, item) => accumulator + item.quantity * item.unit_price, 0);
             

            //  if(user.maxCredit<totalAmount){
            //   console.log("max",user.maxCredit);
            //   console.log("total",totalAmount);
            //   Swal.fire(`${user.name} meet the maximum limit`);
            // }
             
          }catch (error) {
            console.log('Error fetching data:', error);
            setTransaction([]);
            
            }
          };

      
          const handleEditClose = () =>{
            setIsEdit(false);
          }

//close the paymode modal
          const handlePaymodeModalClose = () =>{
            setIsOpenPaymode(false);
          }

          //input onchange for update
  const handleUpdateInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateFormData({ ...updateFormData, [name]: value });
  };

  
  //onchange function for file for update
  const handleImageChangeUpdate = (e) => {
    const file = e.target.files[0];
    setUpdateFormData({ ...updateFormData, user_profile: file || updateFormData.user_profile});
  };

  const handleUpdateSubmit = async (e) => {
    console.log("update data before put",updateFormData)
    e.preventDefault(); 
    setUpdateLoading(true);
    const capitalizedCustomerName = capitalize(updateFormData.name);
       

    const formDataToSend = new FormData();
    formDataToSend.append('name', capitalizedCustomerName);
    formDataToSend.append('country_code', updateFormData.country_code);
    formDataToSend.append('phone_no', updateFormData.phone_no);
    formDataToSend.append('user_gov_id', updateFormData.user_gov_id);
    formDataToSend.append('email', updateFormData.email);
    formDataToSend.append('maxCredit', parseInt(updateFormData.maxCredit));
    formDataToSend.append('user_profile', updateFormData.user_profile);
    
  
     try {
      const response = await fetch(`${var_api}/api/credituser/put/${updateFormData.id}/${shopId}`, {
        method: 'PUT',
      body: formDataToSend,
    });
      if(response.status == 200){
        setIsEdit(false);
        setUpdateLoading(false);
        Swal.fire({
          title: 'Success',
          text: 'Credit Customer Updated Successfully.',
          icon: 'success',
          confirmButtonColor: '#007500', // Set the custom color for the Confirm button
        });
        setUpdateFormData({
          name:'',
          email:'',
          country_code:'',
          phone_no:'',
          user_gov_id:'',
          user_profile:null,  
          maxCredit:''  
        });
         // Clear the value of the file input element
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    fetchData();
        const data = await response.json();
        console.log('Registration successful!',data);
     
      }else {
        console.error('Failed to update shop:', response.statusText);
        // Handle error, e.g., show an error message to the user
        Swal.fire({
          title: 'error',
          text: 'Credit Customer Already Exist',
          icon: 'error',
          confirmButtonColor: '#007500', // Set the custom color for the Confirm button
        });
      }
      
      // Handle success scenario as needed
    } catch (error) {
        console.error('Error:', error);
        // Handle error scenario as needed
      }
    }
       
   

//create the new credit customer
      const handleCredit = async()=>{
        setIsOpen(true);
      //   const { value: formValues } = await Swal.fire({
      //     title: 'Add Credit User',
      //     html:
      //       '<input type="text" id="swal-input1" class="swal2-input" placeholder="Enter Customer Name">' +
      //       '<input type="email" id="swal-input2" class="swal2-input" placeholder="Email">'+
      //       '<input type="text" id="swal-input3" class="swal2-input" placeholder="Gov ID">'+
      //       '<input type="number" id="swal-input4" class="swal2-input crein" placeholder="Phone No">'+
      //       '<input type="text" id="swal-input5" class="swal2-input" placeholder="Country Code">'+
      //       '<input type="number" id="swal-input6" class="swal2-input" placeholder="Maximum Credit">'+
      //       '<input type="file" id="swal-input7" class="swal2-input" placeholder="Select File">',
      //     focusConfirm: false,
      //     confirmButtonColor: '#007500',
      //     confirmButtonText: 'Submit',
      //     showCancelButton: true,
      //     preConfirm: () => {
      //       const input1 = document.getElementById('swal-input1').value;
      //       const input2 = document.getElementById('swal-input2').value;
      //       const input3 = document.getElementById('swal-input3').value;
      //       const input4 = document.getElementById('swal-input4').value;
      //       const input5 = document.getElementById('swal-input5').value;
      //       const input6 = document.getElementById('swal-input6').value;
      //       const input7 = document.getElementById('swal-input7').files[0];
            
      //       if (!input1 || !input2 || !input3 || !input4 || !input5 || !input6) {
      //         Swal.showValidationMessage('Please fill in all the required fields');
      //       } else {
      //         return [input1, input2, input3, input4, input5, input6, input7];
      //       }
      //     }
      //   })
      //   if (formValues) {
      //     const [ customer_name, email, gov_id, phone_no, country_code,maxi_credit,user_profile ] = formValues;
      //     const shop_id = localStorage.getItem('shopId');
      //     console.log("user_profile",user_profile.name);

      //      // Create a FormData object to send the file
      //  const formData = new FormData();
      //  formData.append('name', customer_name);
      //  formData.append('country_code', country_code);
      //  formData.append('phone_no', parseInt(phone_no));
      //  formData.append('user_gov_id', parseInt(gov_id));
      //  formData.append('email',email);
      //  formData.append('shop_id', shop_id);
      //  formData.append('shop_name', localStorage.getItem('shopName'));
      //  formData.append('maxCredit', parseFloat(maxi_credit));
      //  formData.append('user_profile',user_profile)

      //     try {
      //       const response = await fetch(`${var_api}/api/credituser/api/post`, {  
      //         method: 'POST',
      //         body: formData,
      //       });
      
      //       if (response.status === 200) {
      //         // Profile updated successfully
      //         showSuccessCreditAlert()
      //         fetchData();
      //       } else {
      //          showFailureCreditAlert ()
      //       }
      //     } catch (error) {
      //       console.error('Error updating profile:', error);
      //       Swal.fire('Error', 'Failed to create Credit User  profile. Please try again.', 'error');
      //     }
      //   }
      }


       //show the swal when status was 200
       const showSuccessCreditAlert = () => {
        Swal.fire({
          text: `Credit Customer Created Successfully`,
          icon: 'success',
          confirmButtonColor: '#007500', // Set the custom color for the Confirm button
        });
      };

      //show the swal  when getting error
      const showFailureCreditAlert = () => {
        Swal.fire({
          title: 'Success',
          text: 'Failed to create credit user!',
          icon: 'success',
          confirmButtonColor: '#007500', // Set the custom color for the Confirm button
        });
      };

      //update the email and government id
      const handleEdit = async(user)=>{
        console.log(user);
        setIsEdit(true);
        setUpdateFormData({
          id:user.id,
          name: user.name,
          email: user.email,
          country_code: user.country_code,
          phone_no: user.phone_no,
          user_gov_id: user.user_gov_id,
          user_profile: user.profile,
          maxCredit: user.maxCredit,  
        })
        console.log("updateFormData",updateFormData);
  //       const { value: formValues } = await Swal.fire({
  //         title: 'Update Credit User',
  //         html:`
  //         <label class="swalput">Name</label>
  //         <input type="text" id="swal-input1" class="swal2-input crein" value=${user.name}>
  //         <br/>
  //         <br/>
  //         <label class="swalput">Email</label>
  //         <input type="email" id="swal-input2" class="swal2-input crein" value=${user.email}>
  //         <br/>
  //         <br/>
  //         <label class="swalput">Gov.ID</label>
  //         <input type="text" id="swal-input3" class="swal2-input crein" value=${user.user_gov_id}>
  //         <br/>
  //         <br/>
  //         <label class="swalput">Phone</label>
  //         <input type="number" id="swal-input4" class="swal2-input crein" value=${user.phone_no}>
  //         <br/>
  //         <br/>
  //         <label class="swalput" id="countryC">Country Code</label>
  //         <input type="text" id="swal-input5" class="swal2-input crein" value=${user.country_code}>
  //         <br/>
  //         <br/>
  //         <label class="swalput" id="maxiC">Maximum Credit</label>
  //         <input type="number" id="swal-input6" class="swal2-input crein" value=${user.maxCredit}>
  //         <br/>
  //         <br/>
  //         <label class="swalput">Profile</label>
  //         <input type="file" id="swal-input7" class="swal2-input" accept="image/*">
  //         `

  //          ,
  //         focusConfirm: false,
  //         confirmButtonColor: '#007500',
  //         confirmButtonText: 'Submit',
  //         showCancelButton: true,
  //         preConfirm: () => {
  //           return new Promise(async (resolve) => {
  //           const input1 = document.getElementById('swal-input1').value;
  //           const input2 = document.getElementById('swal-input2').value;
  //           const input3 = document.getElementById('swal-input3').value;
  //           const input4 = document.getElementById('swal-input4').value;
  //           const input5 = document.getElementById('swal-input5').value;
  //           const input6 = document.getElementById('swal-input6').value;
  //           const input7 = document.getElementById('swal-input7').files[0];
            
  //           const emailPattern = /\S+@\S+\.\S+/;
  //       if (!emailPattern.test(input2)) {
  //           Swal.showValidationMessage('Please enter a valid email address.');
  //           return;
  //       }
  //           if (!input1 || !input2 || !input3 || !input4 || !input5 || !input6) {
  //             Swal.showValidationMessage('Please fill in all the required fields');
  //           } else {
             
           
      
  //         const shopId = localStorage.getItem('shopId');
  //         const id = user.id;

  //         // Create a FormData object to send the file
  //      const formData = new FormData();
  //      formData.append('name', input1 || user.name);
  //      formData.append('country_code', input5 || user.country_code);
  //      formData.append('phone_no', parseInt(input4) || user.phone_no);
  //      formData.append('user_gov_id', parseInt(input3) || user.user_gov_id);
  //      formData.append('email',input2 || user.email);
  //      formData.append('maxCredit', parseFloat(input6) || user.maxCredit);
  //      formData.append('user_profile',input7 || user.profile);
        
  //         try {
  //           const response = await axios.put(`${var_api}/api/credituser/put/${id}/${shopId}`, formData, {
  //             headers: {
  //               'Content-Type': 'multipart/form-data', // Set the content type for file upload
  //             },
  //           });

      
  //           if (response.status === 200) {
  //             // Profile updated successfully
  //             showSuccessEditAlert()
  //             fetchData();
  //           } else {
  //              showFailureEditAlert ()
  //           }
  //         } catch (error) {
  //           console.error('Error updating profile:', error);
  //           Swal.fire('Error', 'Failed to create Credit User  profile. Please try again.', 'error');
  //         }
          
  //         resolve(formData);
  //       }
  //     });
  //   },
  // });
 };

       //show the swal when status was 200
       const showSuccessEditAlert = () => {
        Swal.fire({
          text: `Data Updated Successfully `,
          icon: 'success',
          confirmButtonColor: '#007500', // Set the custom color for the Confirm button
        });
      };

      //show the swal  when getting error
      const showFailureEditAlert = () => {
        Swal.fire({
          title: 'Success',
          text: 'Failed to Update!',
          icon: 'success',
          confirmButtonColor: '#007500', // Set the custom color for the Confirm button
        });
      };


      //close the modal
      const handleClose = () => setShow(false);

       //close the order modal
       const handleOrderClose = () => setShowOrder(false);

       //close the payment modal
       const handlePayemntClose = () => setShowPayment(false);

      //show the swal when status was 200
      const showSuccessAlert = (paid,id) => {
        fetchBal(id)
        Swal.fire({
          // text: `Paid amount is ${paid} and the balance is ${laBal}`,
          text: `Amount paid successfully`,
          icon: 'success',
          confirmButtonColor: '#007500', // Set the custom color for the Confirm button
        });
      };

      //show the swal  when getting error
      const showFailureAlert = () => {
        Swal.fire({
          title: 'Success',
          text: 'Failed to Paid',
          icon: 'success',
          confirmButtonColor: '#007500', // Set the custom color for the Confirm button
        });
      };


      //get the value in search and set the setstate
      const handleSearchInput = (e) => {
       setSearchQuery(e.target.value)
       };

       const handleSearchTrans = (e) => {
        setSearchTrans(e.target.value)
        };

       //navigate to another page
       const handleButtonClick = () => {
        // Navigate to a specific route using history.push
        history.push('/home');
      };


      //when the arrow icon was clicked
      const handleTrans = (user) => {
        
     
        setRytUser(user);
        setMaxiShow(false);
        // setCreditId(user.id);
        // console.log("credit Id:",creditId)
        fetchTransData(user.id,user);
        setRytUser(user);
        fetchBal(user.id);
        setRytUser(user);
        // setShowRight(true);
        setRytUser(user);
        console.log("current user",user)
        fetchStartDate(user.id);
        if(user.maxCredit<totalAmount){
          setMaxiShow(true);
          console.log("max",user.maxCredit);
          console.log("total",totalAmount);
          // Swal.fire(`${user.name} meet the maximum limit`);
        }
        console.log("Total Amount",totalAmount)
       
      }


      const handleEmail = async (paid_amount,name,email,id) => {
        console.log("after entering the email function balance:",laBal);
        const mailID = localStorage.getItem('emailId');
        console.log('shop admin:',email);
        const shopName = localStorage.getItem('shopName');
        const response = await axios.get(`${var_api}/api/payment/balancedata/${id}/${shopId}` );
        const data = response.data.balance;

  // const billHtml = ReactDOMServer.renderToString(<Bill order={order} />);
  const emailData = {
    to:[email,mailID],
    subject: `${name} paid for ${shopName}..!`,
   
  }

  try {
    const response = await axios.post(`${var_api}/api/mailnotify/send-email/${shopId}/${id}`, emailData);
    console.log('Email sent:', response.data);
    console.log("after sent email function balance:",laBal);
  } catch (error) {
    console.error('Error sending email:', error);
  }
      }



         //get credit user data
         const fetchPayableAmount = async (id,start_date,end_date) => {
          const shopid = localStorage.getItem('shopId');
           // Format start date
         const formatted_Start_Date = formatSEDate(start_date);
         console.log("formatted_Start_Date",start_date);
          // Format end date
          const formatted_End_Date = formatSEDate(end_date);
          console.log("formatted_End_Date",formatted_End_Date);
 
         try {
          const response = await axios.get(`${var_api}/api/creditorder/get-payment-amount/${id}/${shopid}/${formatted_Start_Date}/${formatted_End_Date}` );
          const use = response.data
          setPayableAmount(use);
  
        }catch (error) {
          console.log('Error fetching data:', error);
          }
        };

        // Function to format date to dd-mm-yyyy format
const formatSEDate = (dateString) => {
  console.log(dateString);
  const dateObject = new Date(dateString);
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();

  // Pad single digit day/month with leading zero
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  return `${formattedDay}-${formattedMonth}-${year}`;
};


        function handleStartDateChange(event) {
          const startDateValue = event.target.value;
          setFormattedStartDate(startDateValue);
          fetchPayableAmount(rytUser.id,startDateValue,formattedEndDate);
          // Do something with the startDateValue, such as updating the state
        }
        
        function handleEndDateChange(event) {
          const endDateValue = event.target.value;
          setFormattedEndDate(endDateValue);
          fetchPayableAmount(rytUser.id,formattedStartDate,endDateValue);
          // Do something with the endDateValue, such as updating the state
        }

     
      //post the paid amount
      const handlePayment = async (id,name,email) => {
        if (!startDate || !startDate.startDate) {
          console.error("Start date is undefined or null");
          return;
        }
        console.log("daaaa",startDate.startDate)
        const formattedStartDate = startDate.startDate.replace(/-/g, '/');
  const parts = formattedStartDate.split('/');
  if (parts.length !== 3) {
    console.log("parts", parts);
    console.error("Invalid start date format");
    return;
  }
  const [month, day, year] = parts;
  console.log("parts", parts);
        const formattedDate = `${year}-${day.padStart(2, '0')}-${month.padStart(2, '0')}`;
        console.log("startDate",formattedDate);
        setFormattedStartDate(formattedDate);
        const EndDate = new Date().toISOString().split('T')[0]
        console.log("endDate",EndDate);
        setFormattedEndDate(EndDate);
        fetchPayableAmount(id,formattedDate,EndDate);
        setShowPayment(true);
      }


       //list the pay modes
     const handleListPayModes =async() => {
      const shop_id = localStorage.getItem('shopId');
      const user_id = localStorage.getItem('userId');
      try {
        const response = await axios.get(`${var_api}/api/shop-paymode/active-paymode-list/${shop_id}/${user_id}` );
        const data = response.data;
         // Filter paymodes data
    const filteredPaymode = data.filter(mode => mode.pay_mode_name !== "CREDIT ORDER" && mode.pay_mode_name !== "GROUP LOCATION CREDIT ORDER");
        setPaymodes(filteredPaymode);
        const newSelectedAmounts = Array(paymodes.length).fill(0);
        setSelectedAmounts(newSelectedAmounts);
       console.log("payyyyy",data);
      }catch (error) {
        console.log('Error fetching data:', error);
        }
      }



      //post the payment
      const handlePaymentPost = async () => {
        setIsOpenPaymode(true);
        setSelectedAmounts([]);
      }

      
//search the credit customer
      const filteredOrders = users.filter((order) => {
        const searchLower = searchQuery.toLowerCase();
        return (
          (order.name && order.name.toLowerCase().includes(searchLower)) ||
          (order.phone_no && order.phone_no.includes(searchQuery))
        );
      });
      
      
          // Check if there are no matching results
      const noResults = filteredOrders.length === 0;

      //remove the time 
      function formatDate(dateString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString(undefined, options);
      }

      //search the transaction data
      const filteredtrans = transaction.filter((order) => {
        const searchLower = searchTrans.toLowerCase();
        return (
          (order.total && order.total.toString().includes(searchLower)) ||
          (order.ordered_at && typeof order.ordered_at === 'string' && order.ordered_at.toString().includes(searchLower)) ||
          (order.paid_status === 0 && searchLower === 'not paid') || // Include searching for "Not Paid"
          (order.paid_status === 1 && searchLower === 'paid') || // Include searching for "Paid"
          (order.is_group_location_order === 0 && searchLower === 'credit order') || // Include searching for "Credit Order"
          (order.is_group_location_order === 1 && searchLower === 'group location order') // Include searching for "Group Location Order"
        );
      });
      
      
      //close the modal
      const handleCloseCreate = () => {
        setIsOpen(false);
      }


      //input onchange
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  //onchange function for file
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, user_profile: file });
  };

    // Function to capitalize the first letter of a string
const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
 };



  //post method for creating the shop
  const handleSubmit = async (e) => {
    console.log("update data before post",formData);
    const shop_id = localStorage.getItem('shopId');
    e.preventDefault();  
    setCreateLoading(true);
    
    const created_by = localStorage.getItem('userId') || "";
    
        const capitalizedCountryName = capitalize(formData.customer_name);
       

    const formDataToSend = new FormData();
    formDataToSend.append('name', capitalizedCountryName);
    formDataToSend.append('country_code', formData.country_code);
    formDataToSend.append('phone_no', formData.phone_no);
    formDataToSend.append('user_gov_id', formData.user_gov_id);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('shop_id', shop_id); 
    formDataToSend.append('shop_name', localStorage.getItem('shopName'));
    formDataToSend.append('maxCredit', parseInt(formData.maxCredit));
    formDataToSend.append('user_profile', formData.user_profile);
    
  
     try {
      const response = await fetch(`${var_api}/api/credituser/api/post`, {
        method: 'POST',
      body: formDataToSend,
    });
      if(response.status == 200){
        setIsOpen(false);
        Swal.fire({
          title: 'Success',
          text: 'Credit Customer Created Successfully.',
          icon: 'success',
          confirmButtonColor: '#007500', // Set the custom color for the Confirm button
        });
        setFormData({
          customer_name:'',
    email:'',
    country_code:'',
    phone_no:'',
    user_gov_id:'',
    user_profile:"",  
    maxCredit:'' 
        });
         // Clear the value of the file input element
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setCreateLoading(false);
    fetchData();
        const data = await response.json();
        console.log('Registration successful!',data);
     
      }else {
        console.error('Failed to create shop:', response);
        if(response.status === 400){
          Swal.fire({
            icon:"error",
            text:`This Email Id Already Exist in your Credit Customer List`,
            confirmButtonColor:"#007500"
          })
        }else{
          Swal.fire({
            icon:"error",
            text:`Failed to Create cusotmer`,
            confirmButtonColor:"#007500"
          })
        }
        
      }
      
      // Handle success scenario as needed
    } catch (error) {
        console.error('Error:', error);
        // Handle error scenario as needed
      }
    };


    //onclick method paymode card
const handleCardClick = (id) => {
  // Find the index of the pay mode with the given ID
  const index = paymodes.findIndex(mode => mode.id === id);
  console.log("inddx", index,paymodes);
  


  const total = (Math.round(payableAmount.totalAmount * 100) / 100);


    // Create an array to hold the new amounts, initialized with zeros
    const newSelectedAmounts = Array(paymodes.length).fill(0);
 
  // Set the amount for the clicked pay mode
  newSelectedAmounts[index] = total; // Set the initial value here
  setSelectedAmounts(newSelectedAmounts);

  console.log("after setting", newSelectedAmounts);
}



const handleAmountChange = (index, value) => {

 const newSelectedAmounts = [...selectedAmounts];

   // Convert the value to a number
  const newValue = value !== '' ? parseInt(value) : 0;

  // Check if the value is 0 and the user wants to clear it
  if (newValue === 0) {
    newSelectedAmounts[index] = null; // Set the value to an empty string
  } else {
    // Ensure that the value is non-negative
  const nonNegativeValue = newValue >= 0 ? newValue : 0;
    // Update the quantity at the specified index
    newSelectedAmounts[index] = nonNegativeValue;
  }
  console.log("after setting textfield", newSelectedAmounts);
  // Clear the card clicked index
  setSelectedAmounts(newSelectedAmounts);
}


const handleToGeneratePayment = async () => {
  setIsComplete(true);
  const PaymentTotal = payableAmount.totalAmount.toFixed(1);
  const paymodeTotal  = selectedAmounts.reduce((accumulator, amount) => {
    return accumulator + amount;
}, 0).toFixed(1)
   if (paymodeTotal > 0){
    if(paymodeTotal === PaymentTotal){

      const shop_id = parseInt(localStorage.getItem('shopId'));
      const currDate = new Date().toLocaleDateString();
      const currTime = new Date().toLocaleTimeString();

      try {
                  const response = await axios.post(`${var_api}/api/payment/api/post`, {
                    shop_id:parseInt(shop_id),
                    credit_id:rytUser.id,
                    name:rytUser.name,
                    email:rytUser.email,
                    ordered_at: currDate,
                    ordered_time: currTime,
                    paid_amount: payableAmount.totalAmount,
                    start_date:formatSEDate(formattedStartDate),
                    end_date:formatSEDate(formattedEndDate),
                  }, {
                    headers: {
                      'Accept': 'application/json',
                    }
                  });
            
                  if (response.status === 200) {
                    console.log("response function :",response,response.data.insertedId);
                    const currDate = new Date();
                    const dd = String(currDate.getDate()).padStart(2, '0');
                    const mm = String(currDate.getMonth() + 1).padStart(2, '0'); // January is 0!
                    const yyyy = currDate.getFullYear();
          
                    const formattedDate = `${dd}-${mm}-${yyyy}`;
                    const insertid = response.data.insertedId;

                       // Create a new array with amounts inserted into matched pay modes
                    const paymodesArray = paymodes.map((mode, index) => {
                      const amount = selectedAmounts[index] || 0; // Get amount from selectedAmounts array
                      return { ...mode, amount, shop_id:shop_id, payment_id: insertid, paid_at:formattedDate, cust_id:rytUser.id  }; // Create a new object with amount inserted
                    });

                    console.log("amountwitharray",paymodesArray);
                    try {
                      const modeResponse = await axios.post(`${var_api}/api/payment-paymode/post`, paymodesArray);
                      console.log(modeResponse.data); // Log response from server
                      console.log( "Succes", modeResponse.data.message );
                      setSelectedAmounts([]);
                      setIsComplete(false);
                      // const entered_Paymodes = modeResponse.data.results;
                      // const enteredPaymodesString = entered_Paymodes.map(paymode => paymode.pay_mode_name).join(', ');
                      // this.setState({selectedAmounts:[], enteredPaymodes: enteredPaymodesString});
                    } catch (error) {
                      console.error('Error posting pay modes:', error);
                      setIsComplete(false);
                    }
                    fetchBal(rytUser.id);
                    fetchTransData(rytUser.id);
                    setShowPayment(false);
                    setIsOpenPaymode(false);
                    fetchStartDate(rytUser.id);
                    console.log(response);
                    // // Profile updated successfully
                    showSuccessAlert(payableAmount.totalAmount,rytUser.id)
                    
                    // console.log("befored entering the email function balance:",laBal);
                    
                    handleEmail(payableAmount.totalAmount,rytUser.name,rytUser.email,rytUser.id)
                    // console.log("last function balance:",laBal);
                    
                    
                    
                  } else {
                     showFailureAlert ()
                     setIsComplete(false);
                  }
                  
                } catch (error) {
                  console.error('Error updating profile:', error);
                  Swal.fire('Error', 'Failed to paid amount . Please try again.', 'error');
                  setIsComplete(false);
                }
    }else{
      Swal.fire({
        icon:"error",
        title:"Oops...",
        text:`pay mode amount (${paymodeTotal}) is not equal to total amount (${PaymentTotal})`,
        confirmButtonColor:"#007500"
      })  
      setIsComplete(false);    
    }

   }else{
    Swal.fire({
      icon:"error",
      title:"Oops...",
      text:"Enter the pay mode amount",
      confirmButtonColor:"#007500"
    })
    setIsComplete(false);
   }
}

        


    return(
        <div className='whole' style={{backgroundColor:"white"}}>
           <div className="row">
          <div className="col-md-6 col-sm-12">
            <div class="split left">
                <div class="centered">
                    <h3 className='ushe'>CREDIT USERS</h3>
                    <div style={{marginLeft:"12px",padding:"7px"}}>
                        <button id='cusadd' onClick={handleCredit}><i class="bi bi-plus-circle" style={{border:"none"}}></i> {' '}
                            Add Customer
                        </button>
                        <div class="input-group" 
                        style={{border:"none",borderRadius:"10px",padding:"10px",margin:"15px auto",width:'400px',backgroundColor:"white",boxShadow: "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"}}>
                            <input class="form-control border-end-0 border" type="Search" placeholder="Search" id="example-search-input" onChange={handleSearchInput}/>
                        </div>
                        <h6 style={{fontFamily:"serif",fontWeight:"bolder",color:"green",textAlign:"right", marginTop:"20px"}}>No.Of Credit Customers: <span style={{fontFamily:"monospace",color:"black"}}>{users.length}</span></h6>
                        {noResults ? (
                            <p>No Data </p>
                           ) : (
                            filteredOrders.map((user,index) => (
                           <div>
                             <div id='nediv' key={index}>
                            <div style={{border:"none"}}>
                            <Tooltip
                            isOpen={actiontooltipOpen[index]} // Use tooltip state based on index
                            target={`actionButton${index}`} // Specify the target ID of the button
                            toggle={() => toggleActionTooltip(index)}
                            placement='bottom'>View Details </Tooltip>
                               <img src={`${var_api}/${user.profile}`} alt='user' width='65px' height='65px'  /><i class="bi bi-arrow-right-circle" id={`actionButton${index}`} onClick={() => {handleTrans(user); toggleActionTooltip(index);}} style={{fontSize:"23px",position:"relative",top:"-25px",left:"230px"}}></i>
                               <div style={{border:"none",margin:"-65px 0px 0px 80px"}}>
                               <Tooltip
                                  isOpen={puttooltipOpen[index]} // Use tooltip state based on index
                                  target={`putButton${index}`} // Specify the target ID of the button
                                  toggle={() => togglePutTooltip(index)}
                                  placement='right'>Edit Details</Tooltip>
                                <h5 style={{textTransform:"capitalize"}}>{user.name} {' '} <i class="bi bi-pencil-square" id={`putButton${index}`} onClick={() => {handleEdit(user); togglePutTooltip(index);}} style={{fontSize:"15px"}}></i></h5>
                               <h6 style={{fontSize:"12px"}}> <i class="bi bi-telephone"></i> {'  '} {user.phone_no}</h6>
                                <h6 style={{fontSize:"12px"}}><i class="bi bi-envelope"></i> {'  '} {user.email}</h6>
                                
                               </div>
                            </div>
                        </div>
                        <br/>
                           </div>
                           )))
                        }
                        
                
                        
                    </div>
                   
                </div>
                </div>
            </div>

            <div class="split right">
              <div className="col-md-6 col-sm-12">
            <nav className="navbar" 
            style={{boxShadow: "0px 0px 1px 0px black",backgroundColor:"green",border:"none",padding:"5px",height:"50px",width:"1065px",position:"relative",top:"-10px"}} >
        <h3 style={{ fontFamily: "cursive", fontWeight: "bolder",marginLeft: "380px", color: "white" }}>{localStorage.getItem('shopName')}</h3>
        <button className="btn" onClick={handleButtonClick} style={{marginLeft:"700px",backgroundColor:"white",color:"green",marginTop:"-40px"}}
         ><i class="bi bi-house"></i> Go to Home</button>
      </nav>
      <div style={{border:"none",padding:"20px",width:"1020px",height:"850px",margin:"auto",borderRadius:"15px",backgroundColor:"white",boxShadow:" rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px"}}>
      { showRight && 
      <div>
        <div style={{padding:"20px",margin:"10px",border:"none"}}>
      <img src={`${var_api}/${rytUser.profile}`} alt='user' width='165px' height='165px'/>
      { rytUser.maxCredit<totalAmount ?
       ( <span class="badge badge-pill badge-danger p-2" style={{color:"white",float:"right",textTransform:"capitalize"}}><i class="bi bi-person-fill-x" style={{fontSize:"20px"}}>{' '}</i>{rytUser.name} met the maximum credit limit</span> ) : null
    }
      <div id='indiv'>
        <h6 id='dahe' >Customer Name: <span style={{fontWeight:"normal"}}>{rytUser.name}</span></h6>
        <h6 id='dahe'>Mobile No: <span style={{fontWeight:"normal"}}>{rytUser.phone_no}</span></h6>
        <h6 id='dahe'>Email: <span style={{fontWeight:"normal"}}>{rytUser.email}</span></h6>
        <h6 id='dahe'>Gov Id: <span style={{fontWeight:"normal"}}>{rytUser.user_gov_id}</span></h6>
        <h6 id='dahe'>Maximum Credit Limit: <span style={{fontWeight:"normal"}}>{rytUser.maxCredit}</span></h6>
      </div>
      {/* <div>
        <br/>
        <br/>
        <button className='btn btn-success' onClick={()=>handlePayment(rytUser.id,rytUser.name,rytUser.email)}>Get Payment</button>{' '}
        <button className='btn btn-success' onClick={()=>fetchRecord(rytUser.id)}>Payment History</button>
        
        <h4 style={{float:"right",fontWeight:"lighter",fontFamily:"fantasy",color:"green",marginTop:"-20px"}}>Total Amount:{' '} <span style={{fontWeight:"bolder",fontFamily:"cursive",color:"green"}}> ${(Math.round(totalAmount * 100) / 100).toFixed(1)}</span></h4><br/>
        <h4 style={{float:"right",fontWeight:"lighter",fontFamily:"fantasy",color:"red",marginTop:"-20px"}}>Balance to Pay:{' '} <span style={{fontWeight:"bolder",fontFamily:"cursive",color:"red"}}>  ${(Math.round(laBal * 100) / 100).toFixed(1)}</span></h4>
      </div> */}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px' }}>
  <div>
    <button className="btn btn-success" onClick={() => handlePayment(rytUser.id, rytUser.name, rytUser.email)}>Get Payment</button>{' '}
    <button className="btn btn-success" onClick={() => fetchRecord(rytUser.id)}>Payment History</button>
  </div>

  <div style={{ marginLeft: '10px', paddingRight: '100px' }}>
    <h4 style={{ fontWeight:"lighter",fontFamily:"fantasy",color:"green", margin: 0 }}>
      Total Amount: {' '}
      <span style={{ fontWeight: "bolder", fontFamily: "cursive", color: "green" }}>
        ${(Math.round(totalAmount * 100) / 100).toFixed(1)}
      </span>
    </h4>
    <h4 style={{ fontWeight:"lighter",fontFamily:"fantasy",color: "red", marginTop: "-5px" }}>
      Balance to Pay: {' '}
      <span style={{ fontWeight: "bolder", fontFamily: "cursive", color: "red" }}>
        ${(Math.round(laBal * 100) / 100).toFixed(1)}
      </span>
    </h4>
  </div>
</div>
      </div>
      <br/>
      <input class="form-control" type="Search" placeholder="Search" id="example-search-input" 
      style={{width:"230px",borderRadius:"25px",border:"1px solid black",float:"right", marginRight: "130px"}} onChange={handleSearchTrans}/>
      <h3 style={{textAlign:"center",fontFamily:"cursive",fontWeight:"bolder"}}>Order History</h3>
      <br/>
     <div>
     <table class="table table-success table-hover table-scroll small-first-col">
  <thead>
    <tr>
      <th scope="col">S.No</th>
      <th scope="col">Ordered At</th>
      <th scope="col">order Price</th>
      <th scope="col">Paid Status</th>
      <th scope="col">Order Type</th>
    </tr>
  </thead>
  <tbody class="body-half-screen">
   {filteredtrans.length > 0 ? (
   filteredtrans.map((data,index) =>(

    <tr>
      <th scope="row">{index+1}</th>
      <td>{data.ordered_at}</td>
      <td>{data.total}</td>
      <td>{data.paid_status === 0 ? (
        <span class="text-danger" style={{color:"white",fontWeight:"bolder"}}><i class="bi bi-x-circle-fill" style={{color:"red",fontSize:"16px"}}>{' '}</i>Not Paid</span>
      ) : (
        <span class="text-success" style={{color:"white",fontWeight:"bolder"}}><i class="bi bi-check-circle-fill" style={{color:"green",fontSize:"16px"}}>{' '}</i>Paid</span>
      ) }</td>
      <td>{data.is_group_location_order === 0 ? (
        <button className='btn btn-danger' onClick={()=>OrderDetails(data.id, data.paid_status)}>Credit Order</button>
      ) : (
        <button className='btn btn-success' onClick={()=>GLOrderDetails(data.group_location_order_id, data.paid_status)}>Group Location Order</button>
      )}</td>
    </tr>
   ))
   ) : (
    <tr>
      <td colSpan="5" style={{ textAlign: "center" }}>
        No Data Available
      </td>
    </tr>
   )}
 

   </tbody>

</table>

      </div>
<br/>
<br/>

<Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title> PAYMENT HISTORY </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <caption className="hefo" style={{captionSide:"top",textAlign:"left",fontSize:"20px",color:"green",backgroundColor:"white"}}>Total Paid Amount: <span style={{color:"black"}}>{totalPaid} </span>{' '}
            <input type='text' placeholder="search"  value={searchMonth}
  onChange={(e) => setSearchMonth(e.target.value)}
            style={{marginLeft:"270px",height:"40px",width:"150px",borderRadius:"15px",padding:"10px"}}
            /> 
            </caption>
        
        <table class="table table-success table-hover table-scroll small-first-col" >
           
  <thead>

    
    <tr>
      <th scope="col">S.No</th>
      <th scope="col">Ordered At</th>
      <th scope="col">Paid Amount</th>
      {/* <th scope="col">Balance</th> */}
    </tr>
  </thead>
  <tbody class="body-half-screen">
   {record.length > 0 ? (
  record.filter((data) => {
    // Check if the selected month matches the month in the "ordered_at" date
    if (searchMonth) {
      const selectedMonth = new Date(searchMonth + '-01'); // Assuming searchMonth is in format 'YYYY-MM'
      const recordMonth = new Date(data.ordered_at).getMonth() + 1;
      return selectedMonth.getMonth() + 1 === recordMonth;
    }
    // If no search month is specified, show all records
    return true;
  })
   .map((data,index) =>(

    <tr>
      <th scope="row">{index+1}</th>
      <td>{formatDate(data.ordered_at)}</td>
      <td>{data.paid_amount}</td>
      {/* <td>{data.balance}</td> */}
    </tr>
   ))) : (
    <tr>
      <td colSpan="3" style={{ textAlign: "center" }}>
        No Data Available
      </td> 
    </tr>
   )}
 

   </tbody>

</table>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>



      <Modal
        show={showOrder}
        onHide={handleOrderClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title> Order Details </Modal.Title>
        </Modal.Header>
        {orderDetails && ( 
        <Modal.Body>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <h6 style={{color:"green",fontWeight:"bolder"}}>Ordered Date: <span style={{color:"black",fontWeight:"bolder"}}>{orderDetails.ordered_at}</span></h6>
              <h6 style={{color:"green",fontWeight:"bolder"}}>Total Amount: <span style={{color:"black",fontWeight:"bolder"}}>{orderDetails.total || orderDetails.total_price}</span></h6>
            </div>
            <div style={{ flex: 1 }}>
              <h6 style={{color:"green",fontWeight:"bolder"}}>No Of Items: <span style={{color:"black",fontWeight:"bolder"}}>{orderDetails.items && orderDetails.items.length || orderDetails.ordered_by && orderDetails.ordered_by.length}</span></h6>
              <h6 style={{color:"green",fontWeight:"bolder"}}>Paid Status: <span style={{color:"black",fontWeight:"bolder"}}>{paidStatus === 1 ? "Paid" : "Not Paid"}</span></h6>
            </div>
          </div>
       
       <br/>
        
        <table class="table table-success table-hover table-scroll small-first-col" >
           
  <thead>

    
    <tr>
      <th scope="col">S.No</th>
      <th scope="col">Food Name</th>
      <th scope="col">Quantity</th>
      <th scope="col">Unit Price</th>
    </tr>
  </thead>
  <tbody class="body-half-screen">
   {((orderDetails.items || orderDetails.ordered_by) && orderDetails.items ? orderDetails.items : orderDetails.ordered_by).map((data,index) =>(

    <tr>
      <th scope="row">{index+1}</th>
      <td>{data.food_name}</td>
      <td>{data.quantity}</td>
      <td>{data.unit_price}</td>
    </tr>
   ))}
 

   </tbody>

</table>
        </Modal.Body>
        )}
      </Modal>


      <Modal
  show={showPayment}
  onHide={handlePayemntClose}
  backdrop="static"
  keyboard={false}
>
  <Modal.Header closeButton>
    <Modal.Title>Get Payment Details</Modal.Title>
  </Modal.Header>

  <Modal.Body>
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1 }}>
        <label className="swalLabel"  style={{ marginRight: '10px', color: 'green' }}>Start Date</label>
        <input type='date' name='date' id='swal-input-startDate' value={formattedStartDate} onChange={handleStartDateChange}  style={{ border: '2px solid green', padding: '10px', borderRadius: '15px' }} placeholder='choose date' min={formattedStartDate} />
      </div>
      <div style={{ flex: 1 }}>
        <label className="swalLabel" style={{ marginLeft: '20px', marginRight: '10px', color: 'green' }}>End Date</label>
        <input type='date' name='date' id='swal-input-endDate' value={formattedEndDate} onChange={handleEndDateChange} style={{ border: '2px solid green', padding: '10px', borderRadius: '15px' }} placeholder='choose date' />
      </div>
    </div>
    <br/>
    {
      payableAmount && (
        <h5 style={{ textAlign: 'center', fontFamily: 'sans-serif', fontWeight: 'bolder', color: 'green' }}>
      <span style={{ color: 'black' }}>Payable Amount:</span> {payableAmount.totalAmount.toFixed(2)}
    </h5>
      )
    }
    <br/>
    <button className='btn btn-success' style={{borderRadius:"15px"}} onClick={handlePaymentPost} disabled = {payableAmount && payableAmount.totalAmount<=0}>Pay</button>{' '}<button className='btn btn-danger' style={{borderRadius:"15px"}} onClick={handlePayemntClose}>Close</button>
  </Modal.Body>
</Modal>



<Modal
        show={isOpen}
        onHide={handleCloseCreate}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title> Add Credit Customer </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form  className="py-2" onSubmit={handleSubmit}>
          <div class="row">
            <div class="col">
               <label for="input1" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Customer Name</label>
              <input type="text" class="form-control" placeholder="enter customer name" id="input1"  name="customer_name"  value={formData.customer_name}  onChange={handleInputChange} style={{borderRadius:"12px"}} required/>
            </div>
            <div class="col">
              <label for="input2" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Email</label>
              <input type="email" class="form-control" placeholder="enter email" id="input2" name="email" value={formData.email} onChange={handleInputChange} style={{borderRadius:"12px"}} required/>
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col">
               <label for="input3" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Gov ID</label>
              <input type="text" class="form-control" placeholder="enter gov.Id" id="input3" name="user_gov_id" value={formData.user_gov_id} onChange={handleInputChange} style={{borderRadius:"12px"}} required/>
            </div>
            <div class="col">
              <label for="input4" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Phone No</label>
              <input type="number" class="form-control" placeholder="enter phone no" id="input4" name="phone_no" value={formData.phone_no} onChange={handleInputChange} style={{borderRadius:"12px"}} required/>
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col">
               <label for="input5" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Country Code</label>
              <input type="number" class="form-control" placeholder="enter country code" id="input5" name="country_code" value={formData.country_code} onChange={handleInputChange} style={{borderRadius:"12px"}} required/>
            </div>
            <div class="col">
            <label for="input6" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Maximum Credit</label>
              <input type="number" class="form-control" placeholder="enter maximum credit" id="input6" name="maxCredit" value={formData.maxCredit} onChange={handleInputChange} style={{borderRadius:"12px"}} required/>
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col">
            <label for="formFile" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Customer Profile</label>
              <input ref={fileInputRef} class="form-control" type="file" id="formFile" onChange={handleImageChange} style={{borderRadius:"12px"}} value={formData.shop_image}/>
            </div>
            <div class="col">
              
            </div>
           
          </div>
          <br/>
         
          {createLoading ? (
                  <div class="text-center">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                ) : <button className="btn btn-success" style={{float:"right",margin:"5px",borderRadius:"15px"}}>Submit</button>}
             
          </form>
          <button className="btn btn-danger" onClick={handleCloseCreate} style={{borderRadius:"15px",float:"right",marginTop:"-1px"}}>Close</button>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>


      <Modal
        show={isEdit}
        onHide={handleEditClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title> Update Credit Customer </Modal.Title>
        </Modal.Header>
        <Modal.Body>
       {
       updateFormData && 
       <form  className="py-2" 
       onSubmit={handleUpdateSubmit}>
       <div class="row">
         <div class="col">
            <label for="input1" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Customer Name</label>
           <input type="text" class="form-control" placeholder="Enter Customer Name" id="input1"  name="name"  value={updateFormData.name}  onChange={handleUpdateInputChange} style={{borderRadius:"12px"}} required/>
         </div>
         <div class="col">
           <label for="input2" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Email </label>
           <input type="email" class="form-control" placeholder="Enter Email" id="input2" name="email" value={updateFormData.email} onChange={handleUpdateInputChange} style={{borderRadius:"12px"}} required/>
         </div>
       </div>
       <br/>
       <div class="row">
         <div class="col">
            <label for="input3" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Gov ID</label>
           <input type="text" class="form-control" placeholder="Enter Gov.ID" id="input3" name="user_gov_id" value={updateFormData.user_gov_id} onChange={handleUpdateInputChange} style={{borderRadius:"12px"}} required/>
         </div>
         <div class="col">
           <label for="input4" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Phone No</label>
           <input type="text" class="form-control" placeholder="Enter state" id="input4" name="phone_no" value={updateFormData.phone_no} onChange={handleUpdateInputChange} style={{borderRadius:"12px"}} required/>
         </div>
       </div>
       <br/>
       <div class="row">
         <div class="col">
            <label for="input5" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Country Code</label>
           <input type="text" class="form-control" placeholder="Enter Country Code" id="input5" name="country_code" value={updateFormData.country_code} onChange={handleUpdateInputChange} style={{borderRadius:"12px"}} required/>
         </div>
         <div class="col">
            <label for="input5" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Maximum Credit Amount</label>
           <input type="text" class="form-control" placeholder="Enter Maximum Credit" id="input5" name="maxCredit" value={updateFormData.maxCredit} onChange={handleUpdateInputChange} style={{borderRadius:"12px"}} required/>
         </div>
       </div>
       <br/>
       <div class="row">
         <div class="col">
         <label for="formFile" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Customer Profile</label>
           <input ref={fileInputRef} class="form-control" type="file" id="formFile" onChange={handleImageChangeUpdate} style={{borderRadius:"12px"}} />
         </div>
         <div class="col">
           
         </div>
        
       </div>
       <br/>
       {updateLoading ? (
                  <div class="text-right" style={{float: "right",}}>
                  <div class="spinner-border" role="status" >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                ) :  <button className="btn btn-success" style={{ float: "right", margin: "5px", borderRadius: "15px" }}>Update</button>}
    

       </form> 
       }
          <button className="btn btn-danger" onClick={handleEditClose} style={{borderRadius:"15px",float:"right",marginTop:"-1px"}}>Close</button>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal
        show={isOpenPaymode}
        onHide={handlePaymodeModalClose}
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title> Payment Preview </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div style={{margin:"auto",padding:"10px 20px 10px 20px"}}>
       
       {
         isComplete ? (
           <div>
       <div class="spinner-border" role="status">
         <span class="visually-hidden">Loading...</span>
       </div>
     </div>
         ) :  <Button variant="success" onClick={handleToGeneratePayment}   style={{float:"right"}}>Complete</Button>
       }
         
       
      <h6 style={{fontWeight:"bolder", fontFamily:"serif"}}>Cust.Name:  <span style={{fontWeight:"normal", fontFamily:"serif",color:"green"}}>{rytUser.name}</span></h6>
      <h6 style={{fontWeight:"bolder", fontFamily:"serif"}}>Mobile No: <span style={{fontWeight:"normal", fontFamily:"serif",color:"green"}}>{rytUser.phone_no}</span></h6>
      <h6 style={{fontFamily:"serif",fontWeight:"bolder", fontSize:"20px"}}>Credit Amount: <span style={{fontFamily:"monospace",fontWeight:"normal",color:"green"}}> {payableAmount && payableAmount.totalAmount ? payableAmount.totalAmount.toFixed(1) : 0}</span> <span style={{float:"right"}}> Paymode Total: 
        <b style={{color: (payableAmount && payableAmount.totalAmount ? payableAmount.totalAmount.toFixed(1) : 0) === selectedAmounts.reduce((accumulator, amount) => {
            return accumulator + amount;
        }, 0).toFixed(1) ? "green" : "red"}}>
            {selectedAmounts.reduce((accumulator, amount) => {
                return accumulator + amount;
            }, 0).toFixed(1)}
        </b></span></h6>
      <br/>
    <div style={{marginTop:"-20px"}}>
    
    <table class="table table-hover">
<thead>
 <tr>
   <th scope="col" style={{textAlign:"center"}}>Paymode</th>
   <th scope="col" style={{textAlign:"center"}}>Amount</th>
 </tr>
</thead>
<tbody>
 {
   paymodes
   .map((mode,index)=>(
 <tr key={index}>
   <td>
     <div class={`card ${selectedAmounts[index] > 0 ? 'bg-success' : ''} ${selectedAmounts[index] > 0 ? 'text-light' : ''}`} style={{width:"auto", height:"auto", margin:"0px"}} onClick={() => handleCardClick(mode.id)}>
       <div class="card-body" style={{textAlign:"center"}}>
         {mode.pay_mode_name}
       </div>
     </div>
   </td>
   <td>
    <Input  type="number" id={`paymodeAmount${mode.id}`} placeholder="0" value={selectedAmounts[index]} onChange={(e) => handleAmountChange(index, e.target.value)} style={{width:"140px",margin:"auto"}} min="0" />    
   </td>
 </tr>
   ))
 }
 </tbody>
     </table>
  
    </div>
  
      <div style={{textAlign:"center", marginTop:"5px"}}>
     {" "}
      <Button variant="danger"  onClick={handlePaymodeModalClose}>
         Close
       </Button>{' '} 
      
      </div>
       
     </div>
        </Modal.Body>
      </Modal>



        </div>
      }
      </div>
      </div>
            </div>
            </div>
        </div>

        
    )
}

export default CreditCustomer;