import React, { Component } from "react";
import './Foods.css';
// import { db } from '././../../firebase.js';
// import { collection, onSnapshot, query, addDoc, getDocs, serverTimestamp, updateDoc, doc } from "firebase/firestore";
import breakfast from "../../fakeData/breakfast";
import burger from '../../fakeData/burger';
import dinner from '../../fakeData/dinner';
import  drinks from '../../fakeData/drinks';
import icecream from '../../fakeData/icecream';
import lunch from '../../fakeData/lunch';
import pizza from '../../fakeData/pizza';
import sandwich from '../../fakeData/sandwich';
import shawarma from '../../fakeData/shawarma';
import { TabContent, TabPane, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { OrderContext } from "../kitchen/orderContext";
import {  ModalHeader, ModalBody,Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, Tooltip } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import ReactToPrint from 'react-to-print';
import Bill from './bill';
import { var_api } from "../constant";
import SearchableDropdown from "../creditCustomers/search";
import ReactDOMServer from 'react-dom/server';
import { data } from "jquery";
import Select from 'react-select';
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { Display } from "react-bootstrap-icons";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Card} from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import Offcanvas from 'react-bootstrap/Offcanvas';
import qz, { printers } from 'qz-tray';
import sha256 from 'js-sha256';
import forge from 'node-forge';
import html2pdf from 'html2pdf.js';
import downloadPrintServer from "../../images/splash/downloadserver.jpg";
import extractPrintServer from "../../images/splash/extractfloder.jpg";
import runServer from "../../images/splash/runserver.jpg";
import dependencies from "../../images/splash/installdependencies.jpg";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

toast.configure()


// Import a custom CSS file for styling
// const Foods = () => {
//   const [foods, setFoods] = useState([]);
//   const [selectedFoodType, setSelectedFoodType] = useState("lunch");
//   const [selectedFastFoodType, setSelectedFastFoodType] = useState("pizza");

//   useEffect(() => {
//     setFoods(allFoods);
//   }, []);

//   const selectedFoods = foods.filter(
//     (food) => food.category === selectedFoodType
//   );
//   const selectedFastFoods = foods.filter(
//     (food) => food.category === selectedFastFoodType
//   );


  class Foods extends React.Component {
   
  

    constructor(props) {
      
      super(props);
      this.routeChange = this.routeChange.bind(this);
      this.routeChance=this.routeChance.bind(this);
      this.routeDay=this.routeDay.bind(this);
      this.routeShop=this.routeShop.bind(this);
      this.routeDaily=this.routeDaily.bind(this);
      this.routeVoid=this.routeVoid.bind(this);
      this.routePurchaseOrders =this.routePurchaseOrders.bind(this);
      this.routeStoreGrn =this.routeStoreGrn.bind(this);
      this.routeDayClosing =this.routeDayClosing.bind(this);
      this.routeStockAdjustments =this.routeStockAdjustments.bind(this);
      this.routeTable =  this.routeTable.bind(this);
      this.routeCredit =  this.routeCredit.bind(this);
      this.routeSupplier = this.routeSupplier.bind(this);
      this.routeUom = this.routeUom.bind(this);
      this.toggle = this.toggle.bind(this);
      this.toggleNav = this.toggleNav.bind(this);
      this.toggleChange = this.toggleChange.bind(this);
      this.handleOnclick = this.handleOnclick.bind(this);
      this.routeReport = this.routeReport.bind(this);
      this.routeContract = this.routeContract.bind(this);
      this.routeGroupOrders =  this.routeGroupOrders.bind(this);
      this.routeEmployee = this.routeEmployee.bind(this);
      this.routeRawMaterial = this.routeRawMaterial.bind(this);
      this.routeTablemaster = this.routeTablemaster.bind(this);
      this.routeOutstandingpayment = this.routeOutstandingpayment.bind(this);
      this.routeItemSummary = this.routeItemSummary.bind(this);
      this.routeOrderhistory= this.routeOrderhistory.bind(this);
      this.routeMysubscription =  this.routeMysubscription.bind(this);
      this.handleModLogout = this.handleModLogout.bind(this);
      this.handleCloseConfirmation = this.handleCloseConfirmation.bind(this);
      this.routeSettlements = this.routeSettlements.bind(this);
      this.routeParcelStatus = this.routeParcelStatus.bind(this);
      this.routenNewSettlements = this.routenNewSettlements.bind(this);
      this.handleSwitchToggle = this.handleSwitchToggle.bind(this);
      this.handleInstandFoodChange = this.handleInstandFoodChange.bind(this);
      this.handleInstandQuantityChange = this.handleInstandQuantityChange.bind(this);
      this.handleInstandPriceChange =  this.handleInstandPriceChange.bind(this);
      this.routeSubKitchenmaster =  this.routeSubKitchenmaster.bind(this);
      this.routeSubChance = this.routeSubChance.bind(this);
      this.handleOpenNotifications = this.handleOpenNotifications.bind(this);
      this.handleOpenSettingPrintServer = this.handleOpenSettingPrintServer.bind(this);
      this.routePaymodeStatusMaster = this.routePaymodeStatusMaster.bind(this);
      this.handleShowServices = this.handleShowServices.bind(this);
      this.handleCloseServices = this.handleCloseServices.bind(this);
      this.handleOpenPremiumAlert = this.handleOpenPremiumAlert.bind(this);
      this.routeStockTransaction =this.routeStockTransaction.bind(this);
      this.routeStockTake =this.routeStockTake.bind(this);
      this.routeGLAccounts =this.routeGLAccounts.bind(this);
      this.routeGLFinanceSummary =this.routeGLFinanceSummary.bind(this);
      this.routeStockBalanceReport =this.routeStockBalanceReport.bind(this);
      this.routepandLReport = this.routepandLReport.bind(this);
      
      this.state = {
        dropdownOpen: false
      };
      this.state = {
        shadropdownOpen: false,
        tooltipOpen: false // State to manage tooltip visibility
      };
      const shopNameFromLocalStorage = localStorage.getItem('shop_name');
      this.state = {
        activeTab: '1'
      };
      
      // Get today's date
      const today = new Date();

     // Format the date as dd-mm-yyyy
      const formattedDate = `${String(today.getDate()).padStart(2, '0')}-${String(today.getMonth() + 1).padStart(2, '0')}-${today.getFullYear()}`;
      this.state = {
        isChecked:true,
        activeTab: 2,
        selectedList: 'Breakfast',
        customers:[], // Set the default selected list to 'Breakfast'
        orderNo: 100,
        showTable: false,
        shopName: '',
        users:[],
        categories: [],
        foods: [],
        cartItems: [],
        orders: [],
        mobileInput:[],
        searchQuery:'',
        foodSearchQuery: '',
        checkedCategories:false,
        loading:false,
        selectedCreditCustomer: null,
        creditName:'',
        creditMobile:'',
        selectedData: '',
        firstCategoryId:0,
        isPrinted:false,
        ItemCodeOptions:[],
        selectedItemCode:null,
        show:false,
        orderHistoryItems:[],
        selectedOrderLabel: { value: '7', label: 'Pending' },
        selectedPayMode: null,
        editpaymode:null,
        updateRemark:" ",
        labelCodeOptions: [
            { value: '7', label: 'Pending' },
            { value: '1', label: 'Normal Order' },
            { value: '2', label: 'Credit Order' },
            { value: '3', label: 'Group Location Order' },
            { value: '4', label: 'Normal Table Order' },
            { value: '5', label: 'Credit Table Order' },
            { value: '0', label: 'All' },
        ], 
        payModeOptions: [
          
      ], 
      payModes:[],
        startDate: formattedDate,
        endDate: formattedDate,
        isOpenSelectDate:false,
        orderSummary:{},
        isOpenOrderSummary:false,
        order_no:null,
        isLogin:false,
        iscredit:false,
        orderLoading: false,
        amountDetail:{},
        isNormalModal: false,
        isNormalCreditModal:false,
        isCreditModal:false,
        isParcelChecked: false,
        orderResponse:{},
        isOrderConfirmation:false,
        imageStatus: false,
        isPayModeEdit:false,
        editPaymodeOrder:null,
        isPayModeEditLoading:false, 
        selectedAmounts: [],
        givenAmounts: [],
        isAmountSetByCardClick: false, // Initially set to false 
        enteredPaymodes:null,
        isOpenAddInstant: false,
        instantFoodName:null,
        instantQuantity:null,
        instantPrice:null,
        editSelectedAmounts:[],
        isMastersMenuOpen: false,
        showNotify:false,
        isNotify:false,
        notifications:[],
        currentPage:1,
        totalItems:0,
        startIndex:0,
        notifySearchQuery:'',
        itemsPerPage : 25,
        notifytooltipOpen:false,
        printTooltipOpen:[],
        puttooltipOpen:[],
        showService:false,
        selectedCategory: 0,
        addedItems: [], // Track items that have been added to the cart
        subscriptions : null, 
        isPremium: false,
        connected: false,
        connectionAttempts: 0,
        disconnecting: false, 
        isVoid: false,
        voidOrder: null,
        settingTooltipOpen:false,
        settingModalOpen: false,
        printLoader:{},
        isPrintNowChecked: false, // State variable to track checkbox status
        shopDetails: null,
        printingType : localStorage.getItem("printingType") || 'defaultPrint',
        printers:[],
        isOpenSelectPrint:false,
        selectedPrinter:'',
        editBillAmounts:[]
      };

      
      // this.contextType = OrderContext; 
    }
  
//  handleCheckboxChange =()=>{
//    this.setState((prevState)=>({
//      isChecked:!prevState.isChecked,
//    }));
//  };
    // handleGenerateNo = async () => {
    //   const { orderNo } = this.state;
  
    //   // Increment the order number by 1
    //   const newOrderNo = orderNo + 1;
  
    //   // Get the reference to the specific document in Firestore
    //   const orderNoRef = doc(db, 'jaya', 'twenty');
  
    //   try {
    //     // Update the 'order_no' field with the new order number
    //     await updateDoc(orderNoRef, { order_no: newOrderNo });
  
    //     // Set the new order number in the state
    //     this.setState({
    //       orderNo: newOrderNo,
    //       showTable: true,
    //     });
    //   } catch (error) {
    //     console.error('Error updating order number:', error);
    //   }
    // };

   
    // handleGenerateNo = async () => {

    //   try {
    //     const { orderNo, customers } = this.state;
  
    //   // Increment the order number by 1
    //    const newOrderNo = orderNo + 1;
  
    //     // Assuming the new order data is fetched from Firestore and stored in newOrderData
    //     // Replace the following line with your actual data retrieval from Firestore
       

    //     this.setState({
    //             orderNo: newOrderNo,
    //             showTable: true,
    //           });

    //   localStorage.setItem('customers', JSON.stringify(customers));
    //   console.log(customers)
  
    //   } catch (error) {
    //     console.error('Error generating order:', error);
    //   }
    // };


    // componentDidUpdate(prevProps) {
    //   const { customers } = this.props;
  
    //   // Calculate the total amount and total number of items
    //   let totalAmount = 0;
    //   let totalItems = 0;
  
    //   customers.forEach((customer) => {
    //     Object.values(customer.data).forEach((fieldValue) => {
    //       if (Array.isArray(fieldValue)) {
    //         fieldValue.forEach((item) => {
    //           totalAmount += item.price * item.quantity;
    //           totalItems += 1;
    //         });
    //       }
    //     });
    //   });
  
    //   // Update the 'total' and 'item_no' fields in Firestore
    //   const customerRef = doc(db, 'jaya', 'twenty');
    //   updateDoc(customerRef, { total: totalAmount, item_no: totalItems });
    // }
    
    // componentDidMount() {
    //   // Fetch data from Firestore and set it to the 'movies' state
    //   const colRef = collection(db, 'jaya');
    //   const q = query(colRef);
    //   this.unsubscribe = onSnapshot(q, (snapshot) => {
    //     const customers = snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }));
    //     this.setState({ 
    //       customers
    //      });
    //     });

    //   this.fetchData()
    // }

    componentWillUnmount() {
      // Unsubscribe from the snapshot when the component is about to unmount
      // if (this.unsubscribe) {
      //   this.unsubscribe();
      // }
    }


    componentDidMount() {

      const userId = localStorage.getItem('userId');
      console.log("userId",userId);
      console.log("Before checking userID",localStorage.getItem('shopName'));
      console.log("Before checking userID",localStorage.getItem('shopId'));
      // this.getShopName(userId);
      
      if (userId) {
       
        const shopName = localStorage.getItem('shopName');
        const shopID = localStorage.getItem('shopId');
        this.fetchData();
        console.log(" chencking",localStorage.getItem('shopType'))
        // Retrieve the shop name from localStorage
        if (shopName && shopID ) {
          console.log("shop there");
          if(localStorage.getItem('shopType') == 2){
            this.props.history.push('/sb-home');
            console.log("shop chencking",localStorage.getItem('shopType'))
          }

          this.setState({ shopName }, () => {
            // this.fetchCategoriesAndFirstCategoryFoodItems();
          });
          console.log("after checking shop_name",localStorage.getItem('shopName'));
          console.log("after checking shop_name",localStorage.getItem('shopId'));
          this.handleListClick(); //this reason
          this.fetchCreditUser();
          this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);
          this.fetchAllOrdersSummary(this.state.selectedOrderLabel.value);
          // this.getExitingOrderNo();
          this.fetchItemCode();

          // localStorage.removeItem('shopName');
        } else {
          console.log("no shop")
          // If shop name is not available in localStorage, fetch it from the server
          this.getShopName(userId);
          if (localStorage.getItem('image_status') === null || '0') {
            localStorage.setItem('image_status', '1');
            console.log("b imagestatus",localStorage.getItem('image_status'));
      
          }
          console.log("gett",localStorage.getItem('shopType'))
          if(localStorage.getItem('shopType') == 2){
            this.props.history.push('/sb-home');
            console.log("no shop chencking",localStorage.getItem('shopType'))
          }
          
         
        }
      } else {
        console.error('User ID not found in local storage.');
        this.props.history.push("/signin");
        // Handle the case where the user ID is not available in local storage
      }
      // Get the user_id from local storage
      // const userId = localStorage.getItem('userId');
      // if (userId) {
      //   // Fetch the shop name for the provided user ID
      //   this.getShopName(userId);
      // } else {
      //   console.error('User ID not found in local storage.');
      //   // Handle the case where the user ID is not available in local storage
      // }

     
      // this.getShopName(userId);
      // this.fetchCategories();
      // this.handleListClick(this.state.firstCategoryId)
      // this.handleListClick(); //this reason
      // this.fetchOrderHistory();
     
      
     
      // this.handleListClick(this.defaultCategoryId)


      // Simulate a 3-second delay before checking categories (replace with your data fetching logic)
    setTimeout(() => {
      this.fetchData();
      // this.fetchCategories();
  this.setState({ checkedCategories: true})
    }, 11000); // Delay for 3 seconds

    this.handleListPayModes();
    this.subscriptionDetail();
    this.fetchPrinters();
    // this.initializeQZTray();
    this.setState({imageStatus: localStorage.getItem('image_status') === '1' ? true : false})
    console.log("imagestatus",localStorage.getItem('image_status'),this.state.imageStatus);

    }

    componentWillUnmount() {
      if (this.unsubscribe) {
        this.unsubscribe();
      }
      this.disconnectFromQZTray();
    }
 
    

    // fetchCategoriesAndFirstCategoryFoodItems() {
    //   const { shopName } = this.state;
    //   const categoriesRef = collection(db, `shops/${shopName}/categories`);
    
    //   getDocs(categoriesRef)
    //     .then((querySnapshot) => {
    //       const categories = querySnapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }));
    //       console.log('Categories:', categories);
    
    //       if (categories.length > 0) {
    //         const firstCategoryId = categories[0].id;
    //         console.log(firstCategoryId )
    //         // this.handleListClick(firstCategoryId);
    //       }
    //     })
    //     .catch((error) => {
    //       console.error('Error fetching categories:', error);
    //     });
    // }
    getShopDetails(){
      const shopID = localStorage.getItem('shopId');
      // Make the API request
      axios
        .get(`${var_api}/api/shop/shopDetails/${shopID}`)
        .then((response) => {
          this.setState({
            shopDetails: response.data
          });
          console.log("shop details:",this.state.shopDetails)
          localStorage.setItem('shopCurrency', response.data.shop_currency);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }

   
  //show the shop name in center
    getShopName(userId) {
      axios.get(`${var_api}/api/shop/shopData/${userId}`)
        .then((response) => {
          const data = response.data;
          this.setState({ shopName:data.shopName });
          localStorage.setItem('shopName', data.shopName);
          localStorage.setItem('shopId', data.shopID);
          localStorage.setItem('shopType',data.shop_type);
          if(data.shop_type == 2){
            this.props.history.push('/sb-home');
          }
          this.handleListClick();
          this.fetchCreditUser();
          this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);
          this.fetchAllOrdersSummary(this.state.selectedOrderLabel.value);
          this.getExitingOrderNo();
          
        })
        .catch((error) => {
          console.error('Error fetching shop name:', error);
          // Handle the error if needed
        });
}

    
fetchCategories = async () => {
          
         const shopId = localStorage.getItem('shopId')
         try {
          const response = await axios.get(`${var_api}/api/categories/api/categories/${shopId}` );
          const data = response.data;
          if (data.length > 0) {
            // If there are categories in the response, set the ID of the first category in the state
            this.setState({ categories: data, firstCategoryId: data[0].id });
            // this.handleListClick(this.state.firstCategoryId)
          } else {
            // Handle the case where there are no categories
            console.log('No categories found.');
          }
        }catch (error) {
          console.log('Error fetching data:', error);
          
          }
        };



        handleShowServices() {
          this. getShopDetails();
          this.setState({ showService: true });
        }


        //Fetch the order history
        fetchAllOrdersHistory = async (order_label) => {
          const shopId = localStorage.getItem('shopId')
          this.setState({orderLoading: true});
          try {
           const response = await axios.get(`${var_api}/api/food/normaldate/related-data/${shopId}/${order_label}/${this.state.startDate}/${this.state.endDate}` );
           const data = response.data;
           if (data.length > 0) {
             // If there are categories in the response, set the ID of the first category in the state
             this.setState({ orderHistoryItems: data });
             this.setState({orderLoading: false});
             // this.handleListClick(this.state.firstCategoryId)
           } else {
             // Handle the case where there are no categories
             console.log('No categories found.');
             this.setState({ orderHistoryItems: [] });
             this.setState({orderLoading: false});
           }
         }catch (error) {
           console.log('Error fetching data:', error);
           
           }
         };


        subscriptionDetail = async () => {
          const userid = localStorage.getItem('userId');
          try {
            const response = await axios.get(`${var_api}/api/user/user-subscription-detail/${userid}`);
            const subData = response.data;
            console.log("sub data", subData);
            this.setState({subscriptions: subData});
          } catch (error) {
            console.log('Error fetching data:', error);
            this.setState({subscriptions: []});
          }
        };

        
      // // console.log("shop category:",shopName)
      // const colRef = collection(db, `shops/${shopName}/categories`);
      // const q = query(colRef);
      // const unsub = onSnapshot(q, (snapshot) => {
      //   // if (snapshot.empty) {
      //   //   // No categories available, show an alert
      //   //   Swal.fire({
      //   //     icon:"error",
      //   //     title:"Oops...",
      //   //     text:"No Categories Available",
      //   //     confirmButtonColor:"#007500"
      //   //   })
      //   //   this.setState({ categories: [] });
      //   //   this.setState({ foods: [] });
      //   // } else {
      //   const categoryNames = snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }));
      //   this.setState({ categories: categoryNames });
        
      //   });
      



    // fetchOrderHistory = () => {
    //   const shopName = localStorage.getItem('shopName');
    //   console.log("shop category:", shopName);
    //   const ordersRef = collection(db, `shops/${shopName}/orders`);
    
    //   const unsubscribe = onSnapshot(ordersRef, (querySnapshot) => {
    //     const orders = querySnapshot.docs.map((doc) => ({
    //       id: doc.id, // Get the document ID
    //       ...doc.data(), // Include the document data
    //     }));
    
    //     // Sort the orders array based on the order_no field
    //     orders.sort((a, b) => b.order_no - a.order_no);
    
    //     this.setState({ orders:orders });
    //     this.fetchData();
    //   }, (error) => {
    //     console.error('Error fetching orders:', error);
    //   });
    
    //   // Store the unsubscribe function in a class property
    //   this.unsubscribe = unsubscribe;
    // }


    handleCloseServices() {
      this.setState({ showService: false });
    }
  


    //Fetch the order history
    fetchAllOrdersSummary = async (order_label) => {
      const shopId = localStorage.getItem('shopId')
      try {
       const response = await axios.get(`${var_api}/api/food/summary/related-data/${shopId}/${order_label}/${this.state.startDate}/${this.state.endDate}` );
       const data = response.data;
       console.log("oooo", response.data);
       
         // If there are categories in the response, set the ID of the first category in the state
         this.setState({ orderSummary: data });
         console.log("oooo", response);
       
     }catch (error) {
       console.log('Error fetching data:', error);
       this.setState({ orderSummary: [] });
       }
     };


     //list the pay modes
     handleListPayModes =async() => {
      const shop_id = localStorage.getItem('shopId');
      const user_id = localStorage.getItem('userId');
      try {
        const response = await axios.get(`${var_api}/api/shop-paymode/active-paymode-list/${shop_id}/${user_id}` );
        const data = response.data
        const options = data.map(modes => ({
          value: modes.pay_mode_name,
          label:  modes.pay_mode_name
        }));
        const filteredPaymode = data.filter(mode => mode.pay_mode_name !== "CREDIT ORDER" && mode.pay_mode_name !== "GROUP LOCATION CREDIT ORDER");
        this.setState({payModeOptions: options, payModes:filteredPaymode})
        const newSelectedAmounts = Array(this.state.payModes.length).fill(0);
        this.setState({ selectedAmounts: newSelectedAmounts});
       console.log("payyyyy",data);
      }catch (error) {
        console.log('Error fetching data:', error);
        }
      }


   
    handleListClick =async() => {
      const id = localStorage.getItem('shopId')
      this.setState({ loading : true });
      try {
        const response = await axios.get(`${var_api}/api/categories/cate/subfood/${id}` );
        const data = response.data
        this.setState({ foods: data.categories});
        this.setState({ loading : false });
        // this.fetchOrderHistory();
      }catch (error) {
        console.log('Error fetching data:', error);
        
        }
//       this.setState({ loading : true });
// const shopName = localStorage.getItem('shopName');
//       console.log("shop category:",shopName)
//       const colRef = collection(db, `shops/${shopName}/categories/${id}/foodMaster`);
//       const q = query(colRef);
//         const unsub = onSnapshot(q, (snapshot) => {
//           const foodNames = snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }));
//           this.setState({ foods: foodNames, defaultCategoryId: id });
//           this.setState({ loading : false });
//         });
      };


  
    toggle(tab) {
     
      const { activeTab } = this.state;
      // this.fetchCategories();
  
      if (activeTab === tab) {
    
        // Close the active tab if it's clicked again
        this.setState({ activeTab: null });
      } else {
      
        this.setState({ activeTab: tab });
        
      }
    }

    // routeChange() {
    //   const { shopName } = this.state;
    //   let path = `/cate/${encodeURIComponent(shopName)}`;
    //   this.props.history.push(path);
    // }

    routeChange() {
      let path = `/cate`;
      this.props.history.push(path);
    }

    routeChance() {
      let path = `/kitchen`;
      this.props.history.push(path);
    }


    routeSubChance() {
      let path = `/sub-kitchen`;
      this.props.history.push(path);
    }

    routeShop() {
      let path = `/shopmas`;
      this.props.history.push(path);
    }

    routeDay() {
      let path = `/deliver`;
      this.props.history.push(path);
    }

    routeDaily() {
      let path = `/daily`;
      this.props.history.push(path);
    }


    routeVoid() {
      let path = `/void-transaction`;
      this.props.history.push(path);
    }

    routePurchaseOrders() {
      let path = `/purchase-orders`;
      this.props.history.push(path);
    }

    routeStoreGrn() {
      let path = `/store-grn`;
      this.props.history.push(path);
    }


    routeDayClosing() {
      let path = `/day-closing`;
      this.props.history.push(path);
    }


    routeStockAdjustments() {
      let path = `/stock-adjustments`;
      this.props.history.push(path);
    }


    routeTable() {
      let path = `/tableOrders`;
      this.props.history.push(path);
    }

    routeCredit() {
      let path = `/creditUser`;
      this.props.history.push(path);
    }

    routeContract() {
      let path = `/contract`;
      this.props.history.push(path);
    }

    routeReport() {
      let path = `/report`;
      this.props.history.push(path);
    }

    routeUom() {
      let path = `/uom-master`;
      this.props.history.push(path);
    }

    routeGroupOrders() {
      let path = `/groupOrders`;
      this.props.history.push(path);
    }


    routeSupplier() {
      let path = `/supplier-master`;
      this.props.history.push(path);
    }

    routeEmployee() {
      let path = `/employee-master`;
      this.props.history.push(path);
    }

    routeRawMaterial() {
      let path = `/RawMaterial-master`;
      this.props.history.push(path);
    }

    routeTablemaster() {
      let path = `/table-master`;
      this.props.history.push(path);
    }

    routeSubKitchenmaster() {
      let path = `/sub-kitchen-master`;
      this.props.history.push(path);
    }


    routeMysubscription() {
      let path =`/Mysubscriptionhistory`;
      this.props.history.push(path);
    }

     routeOutstandingpayment() {
        let path = `/Outstandingpayment`;
        this.props.history.push(path);
    }


    routeItemSummary() {
      let path = `/item-summary`;
      this.props.history.push(path);
  }

  routeOrderhistory() {
    let path = `/order-history`;
    this.props.history.push(path);
}

routeSettlements() {
  let path = `/expense-log`;
  this.props.history.push(path);
}

routenNewSettlements() {
  let path = `/new-settlement`;
  this.props.history.push(path);
}

routeParcelStatus() {
  let path = `/parcel-kitchen`;
  this.props.history.push(path);
}


routePaymodeStatusMaster() {
  let path = `/paymode-status-master`;
  this.props.history.push(path);
}

routeStockTransaction() {
  let path = `/stock-transaction`;
  this.props.history.push(path);
}

routeStoreGrn() {
  let path = `/store-grn`;
  this.props.history.push(path);
}

routeStockTake() {
  let path = `/stock-take`;
  this.props.history.push(path);
}

routeGLAccounts() {
  let path = `/glaccounts-Master`;
  this.props.history.push(path);
}

routeGLFinanceSummary() {
  let path = `/glfinance-Summary`;
  this.props.history.push(path);
}

routeStockBalanceReport() {
  let path = `/stock-balance-report`;
  this.props.history.push(path);
}

routepandLReport() {
  let path = `/profit-and-loss-report`;
  this.props.history.push(path);
}

    toggleNav() {
      
      this.setState(prevState => ({
        dropdownOpen: !prevState.dropdownOpen

      }));
     
    }

      toggleChange() {
        this.setState(prevState => ({
          shadropdownOpen: !prevState.shadropdownOpen
        }));

    }


    toggleTooltip = () => {
      this.setState(prevState => ({
        tooltipOpen: !prevState.tooltipOpen
      }));
    }


    toggleNotifyTooltip = () => {
      this.setState({
        notifytooltipOpen: !this.state.notifytooltipOpen
      });
    };


    toggleSettingsTooltip = () => {
      this.setState({
        settingTooltipOpen: !this.state.settingTooltipOpen
      });
    };


    togglePrintTooltip = (index) => {
      this.setState(prevState => {
        const newTooltipOpen = [...prevState.printTooltipOpen];
        newTooltipOpen[index] = !newTooltipOpen[index];
        return { printTooltipOpen: newTooltipOpen };
    });
      // const printTooltipOpen = [...this.state.printTooltipOpen];
      // printTooltipOpen[index] = !printTooltipOpen[index];
      // this.setState({ printTooltipOpen });
    };



    togglePutTooltip = (index) => {
      const puttooltipOpen = [...this.state.puttooltipOpen];
      puttooltipOpen[index] = !puttooltipOpen[index];
      this.setState({ puttooltipOpen });
    };


    handleOpenPremiumAlert = () => {
      this.setState({ isPremium: true});
    }

    handleClosePremiumAlert = () => {
      this.setState({ isPremium: false});
    }



    toggleMastersMenu = () => {
      this.setState(prevState => ({
        isMastersMenuOpen: !prevState.isMastersMenuOpen,
        dropdownOpen: !prevState.dropdownOpen
      }));
    }
  

    // handleAddToCart = (foodItem) => {
    //   // Add logic to handle adding the selected food item to the cart
    //   // For demonstration, let's just redirect to the cart page
  
    //   // Redirect to the cart page
    //   this.props.history.push({
    //     pathname: '/cart',
    //     state: { foodItems: [foodItem] },
    //   });
    // };


    
    handleNameSelection = (name) => {
      console.log("ini")
      if(name.shop_type === 2){
        console.log("ini1")
        localStorage.removeItem('shopName')
        const shopName=localStorage.setItem('shopName',name.shop_name)
        localStorage.setItem('shopId',name.id)
        this.props.history.push('/sb-home');
        console.log("type",name.shop_type,localStorage.getItem("shopType"));
        localStorage.setItem('shopType',name.shop_type);
        console.log("localStorage type",localStorage.getItem("shopType"));
      }else{
        console.log("ini2")
        this.setState({
          shopName: name.shop_name,
        });
        localStorage.removeItem('shopName')
        const shopName=localStorage.setItem('shopName',name.shop_name)
        localStorage.setItem('shopId',name.id)
        // this.fetchCategories();
        this.handleListClick();
        this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);
        this.fetchAllOrdersSummary(this.state.selectedOrderLabel.value);
        this.getExitingOrderNo();
        this.handleListPayModes();
        this.setState({foods:[]})
        this.setState({orders:[], cartItems:[]})
        console.log("type",name.shop_type,localStorage.getItem("shopType"));
        localStorage.setItem('shopType',name.shop_type);
        console.log("localStorage type",localStorage.getItem("shopType"));
      }
      
     
    };

    fetchData = async () => {
      try {
         // const response = await axios.get('https://api.spbiller.com/api/shop/data');
         const create = localStorage.getItem('userId')
         const response = await axios.get(`${var_api}/api/shop/api/shops/${create}`);
        const user =response.data;

         if (Array.isArray(user)) {
          this.setState({ 
            users: user
           });
 
          //  localStorage.setItem('shopId',user.id);
          //  console.log("shop shop id",localStorage.getItem('shopId',user.id));
        } else {
          // Handle the case when responseData is not an array
          console.error('API response is not an array:', user);
          this.setState({ 
            users: [user]
           });; // Set users to an empty array or handle the error appropriately
        }
      } catch (error) {
        console.log(error);
      }
    };

    handleOnclick(){
      this.setState({show:true})
    }

    handleOpenNotifications(){
      this.setState({showNotify:true})
      this.fetchNotifications();
    }


    handleOpenSettingPrintServer(){
      this.setState({settingModalOpen:true})
    }


     //fetch notifications
     fetchNotifications = async () => {
      const { currentPage, itemsPerPage } = this.state;
      const startIndexCalc = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndexCalc + itemsPerPage;
      this.setState({isNotify:true});
       const shopId = localStorage.getItem('shopId')
       try {
        const response = await axios.get(`${var_api}/api/orderNotification/getall/ordernotification/${shopId}` );
        const data = response.data.results;
        if (data.length > 0) {
          const slicedData = data.slice(startIndexCalc, endIndex);
          // If there are categories in the response, set the ID of the first category in the state
          this.setState({ notifications:slicedData, isNotify:false, startIndex:startIndexCalc, totalItems:data.length });
          
        } else {
          // Handle the case where there are no categories
          console.log('No tables found.');
          this.setState({isNotify:false});
          this.setState({totalItems:0,startIndex:0,notifications:[]})
        }
      }catch (error) {
        console.log('Error fetching data:', error);
        this.setState({isNotify:false,notifications:[]});
        }
    }

    //close the summary modal
handleCloseNotifyConfirmation = () => {
  this.setState({showNotify:false});
}

//get the value in search and set the setstate
handleSearchNotifyInput = (e) => {
  this.setState({notifySearchQuery:e.target.value});
  };


  //handle to change the pagination page
handlePageChange = (pageNumber) => {
  this.setState({ currentPage: pageNumber }, () => {
    this.fetchNotifications(); // Fetch data for the new page
  });
};


handleCloseConfirmation(){
      this.setState({show: false})
    }


     //function for logout
  handleModLogout(){
   const logout= localStorage.removeItem('userId');
   const shop= localStorage.removeItem('shopId');
      const place = localStorage.getItem('userId');
      const printera= localStorage.removeItem('selectedPrinter');
      localStorage.getItem('printingType');
      localStorage.removeItem('e_role');
      console.log("id",place)
      if(place==null){
        this.props.history.push('/signin')
      }
      localStorage.removeItem('shopName');
      localStorage.removeItem('shopType');
      }
    

    //fetch all the item code
    fetchItemCode= async()=> {
      const shopId = localStorage.getItem('shopId')
        try {
          const response = await axios.get(`${var_api}/api/foodMaster/api/allFood/${shopId}`);
          const data = response.data.result;
    
          // Transform the data from the API into the format expected by react-select
          const formattedOptions = data.map(item => ({
            label: item.item_code, // Change this to the actual property name from your API response
          }));
    
          this.setState({ItemCodeOptions:formattedOptions});
        } catch (error) {
          console.log('Error fetching data:', error);
          
        }
}

   //fetch the customer balance
   fetchCustomerBalance= async(id)=> {
    const shopId = localStorage.getItem('shopId')
      try {
        const response = await axios.get(`${var_api}/api/payment/balancedata/${id}/${shopId}`);
        const data = response.data;
       console.log("de",data)
        this.setState({amountDetail:data});
      } catch (error) {
        console.log('Error fetching data:', error);
        
      }
}



  //set the selected item code
   handleCodeSelectChange = (data) => {
    this.fetchItemCode();
 
    this.setState({selectedItemCode:data}, () => {
      console.log('selected item code:', this.state.selectedItemCode);
    });
   }


    handleSearchInput = (e) => {
     this.setState({
      searchQuery : e.target.value,
     });
    };


    //onchange for food search text field
    handleFoodSearchInput = (e) => {
      this.setState({
        foodSearchQuery : e.target.value,
      });
     };
 


    //fetch the item details filter by item code
    handleAddByItemCode = async () => {
      const { selectedItemCode } = this.state
      if(selectedItemCode && selectedItemCode.label){
        const item_code = selectedItemCode.label
      const shopId = localStorage.getItem('shopId')
      try {
        const response = await axios.get(`${var_api}/api/foodMaster/api/filterItem/${shopId}/${item_code}`);
        const data = response.data.result;

        const food = data[0];
       // Add the selected food to the cart
        this.addToCart(food);
        console.log("selected food details",food)
  
       
      } catch (error) {
        console.log('Error fetching data:', error);
        
      }
        
      }else{
        Swal.fire({
          text:"Select Item Code Before Add",
          confirmButtonColor: '#007500',
        })
      }
      
    }


    incrementQuantity = (itemId) => {
      const { cartItems } = this.state;
      console.log("itemid", itemId)
      const updatedCartItems = cartItems.map((item) => {
        if (item.id === itemId) {
          console.log("q",item.quantity, parseInt(item.quantity)+1)
          return { ...item, quantity: parseInt(item.quantity) + 1 };
        }
        return item;
      });
      this.setState({ cartItems: updatedCartItems });
    };
    
    // decrementQuantity = (itemId) => {
    //   const { cartItems } = this.state;
    //   const updatedCartItems = cartItems.map((item) => {
    //     if (item.id === itemId && item.quantity > 0) {
    //       return { ...item, quantity: item.quantity - 1 };
    //     }
    //     return item;
    //   });
    //   this.setState({ cartItems: updatedCartItems });
    // };


    // decrementQuantity = (itemId) => {
    //   const { cartItems } = this.state;
    //   const updatedCartItems = cartItems
    //     .map((item) => {
    //       if (item.id === itemId && item.quantity > 0) {
    //         return { ...item, quantity: item.quantity - 1 };
    //       }
    //       return item;
    //     })
    //     .filter(item => item.quantity > 0); // Remove items with quantity 0
    
    //   this.setState({ cartItems: updatedCartItems });
    // };
    
    decrementQuantity = (itemId) => {
      const { cartItems, addedItems } = this.state;
      const updatedCartItems = cartItems
        .map((item) => {
          if (item.id === itemId && item.quantity > 0) {
            return { ...item, quantity: item.quantity - 1 };
          }
          return item;
        })
        .filter(item => item.quantity > 0); // Remove items with quantity 0
  
      // Remove the item from addedItems if its quantity is 0
      const updatedAddedItems = updatedCartItems.length === cartItems.length
        ? addedItems
        : addedItems.filter(item => item !== cartItems.find(ci => ci.id === itemId).food.id);
  
      this.setState({ cartItems: updatedCartItems, addedItems: updatedAddedItems });
    };


    addToCart = (food) => {
      const { cartItems } = this.state;
  const existingItemIndex = cartItems.findIndex((item) => item.food.id === food.id);

  if (existingItemIndex !== -1) {
    const updatedCartItems = [...cartItems];
    updatedCartItems[existingItemIndex].quantity += 1;
    this.setState({ cartItems: updatedCartItems });
  } else {
    const cartItemId = Date.now();
      this.setState((prevState) => ({
        cartItems: [...prevState.cartItems, { id: cartItemId, food: food, quantity: 1 }],
        addedItems: [...prevState.addedItems, food.id], // Mark item as added
      }));
  }
  this.setState({selectedItemCode:null})
  console.log("cc",cartItems);
    };

   


    removeFromCart = (cartItemId) => {
      const { cartItems } = this.state;
      const updatedCartItems = cartItems.filter((item) => item.id !== cartItemId);
      this.setState({ cartItems: updatedCartItems });
    };

    fetchCreditUser = async () => {
      const shopId = localStorage.getItem('shopId');
     try {
      const response = await axios.get(`${var_api}/api/credituser/data/${shopId}` );
      const data = response.data;
      this.setState({ mobileInput: data });
    }catch (error) {
      console.log('Error fetching data:', error);
      this.setState({ mobileInput: [] });
      }
    };


//     //find the big order number
// getExitingOrderNo() {
//   const shop_id = localStorage.getItem('shopId');
//   axios.get(`${var_api}/api/food/bigorderno/${shop_id}`)
//     .then((response) => {
//       const data = response.data.results;
//       console.log("i",data)
//       this.setState({ order_no:data[0] });

//     })
//     .catch((error) => {
//       console.error('Error fetching order no:', error);
//       // Handle the error if needed
//     });
// }


clearSelectedValue = () => {
  this.setState({ selectedData: ""}); // Clear the selected value
};


    generateCreditOrder = async () => {
      const { cartItems, order_no, isParcelChecked } = this.state;

  
      //check cart is empty
      if(cartItems.length === 0){
        Swal.fire({
          icon:"error",
          title:"Oops...",
          text:"Your cart is empty. Please add items to your cart before placing an order.",
          confirmButtonColor:"#007500"
        })
      }
      else{
        if( this.state.selectedData.value && this.state.selectedData.label && this.state.selectedData.iden){
          

          const user_balance = this.state.amountDetail.balance;
          const maxLimit = this.state.selectedData.maxCredit;
          const itemTotal = this.state.cartItems.reduce((accumulator, cartItem) => {
            return accumulator + cartItem.quantity * cartItem.food.price;
          }, 0);
          const totalAmount = user_balance + itemTotal;
          console.log("uuu",user_balance,maxLimit,itemTotal,totalAmount)
        if(maxLimit>=totalAmount){
          this.setState({iscredit:true});
        const shopName = localStorage.getItem('shopName');
      // console.log("shop category:",shopName)
      // const orderRef = collection(db, `shops/${shopName}/orders`);
    
      const orderItems = this.state.cartItems.map((cartItem) => {
        return {
          food_id:cartItem.food.id,
          food_name: cartItem.food.food_name,
          quantity: cartItem.quantity,
          unit_price: cartItem.food.price,
          item_code: cartItem.food.item_code,
          category_code:cartItem.food.category_code,
          // Add more properties as needed
        };
      });

      const total = this.state.cartItems.reduce((accumulator, cartItem) => {
        return accumulator + cartItem.quantity * cartItem.food.price;
      }, 0);

      const totalWithServiceCharge = total.toFixed(2); // Add a service charge of 5 to the total

console.log('Total with Service Charge:', totalWithServiceCharge);
    
      // const totalItems = this.state.cartItems.reduce((accumulator, cartItem) => {
      //   return accumulator + cartItem.quantity;
      // }, 0);

      const totalItems = this.state.cartItems.length;
    
      try {
        // const timestamp = serverTimestamp();
        // const querySnapshot = await getDocs(orderRef);
        // const orderNumber = querySnapshot.size + 1;
        const currDate = new Date();
        const dd = String(currDate.getDate()).padStart(2, '0');
        const mm = String(currDate.getMonth() + 1).padStart(2, '0'); // January is 0!
        const yyyy = currDate.getFullYear();
        
        const formattedDate = `${dd}-${mm}-${yyyy}`;
        const currTime = new Date().toLocaleTimeString();
        let maxOrderNo = 0;
        // if (order_no > maxOrderNo) {
        //   console.log("orderno",order_no)
        //    maxOrderNo = order_no.max_order_no;
        //   }
          // console.log("m",order_no)
          // maxOrderNo = order_no.max_order_no + 1
          // console.log("m",maxOrderNo)

          var remark = document.querySelector('#remark') ? document.querySelector('#remark').value : "-";


    //     const customerName = document.querySelector('#name').value;
    //     const customerPhone = document.querySelector('#mobile').value;


    //     // Validate name (only strings)
    // if (!/^[A-Za-z\s]+$/.test(customerName)) {
    //   alert('Invalid name. Please enter a valid name.');
    //   return;
    // }

    // // Validate mobile number (only 10 digits)
    // if (!/^\d{10}$/.test(customerPhone)) {
    //   alert('Invalid mobile number. Please enter a 10-digit mobile number.');
    //   return;
    // }
// debugger;
console.log("name and phone:",this.state.selectedData.label,this.state.selectedData.value)
const userID = localStorage.getItem('userId');
const shopiD = localStorage.getItem('shopId');
const empID = localStorage.getItem("admin_employee")
const userEmail = localStorage.getItem("emailId");
   // const selectedCreditCustomer = JSON.parse( document.querySelector('#selectedCreditCustomer').value);
   const paymodesArray = [
    {
      // order_id:customerID, 
      // shop_id:parseInt(shopiD), 
      id:10, 
      amount:total,
      pay_mode_name:"CREDIT ORDER",
      // ordered_at:formattedDate
    }
  ]
       

        // Prepare the data to be sent to the Node.js API
        const orderData = {
          // admin_id: parseInt(userID),
          // admin_email: userEmail,
          credit_user_id:this.state.selectedData.iden,
          // shop_name:shopName,
          shop_id:parseInt(shopiD),
          cust_name: this.state.selectedData.label,
          phone_no: this.state.selectedData.value,
          // total_items:totalItems,
          // order_no:maxOrderNo,
          // total: totalWithServiceCharge,
          // order_type:0,
          // table_no:0,
          // paid_status: 0,
          // attender_name: "-",
          // captain:"Admin",
          // parcel_status:0,
          // print_status:0,
          // order_label:1,
          items: orderItems,
          paymodesArray: paymodesArray,
          // ordered_at:formattedDate,
          // ordered_time:currTime,
          // is_group_location_order:0,
          // group_location_order_id:0,
          // pay_mode: "CREDIT ORDER",
          remarks:remark,
          is_parcel: isParcelChecked ? 1 : 0,
          user_id:parseInt(userID),
          emp_id:parseInt(empID),
          // kitchen_parcel_status:0,
          // is_local: 0,
          // local_date: "-"
        };

         // Send the order data to the Node.js API using axios
    const response = await axios.post(`${var_api}/api/creditorder/api/new-post`, orderData);
    if (response.status === 200) {

      console.log("customer id:",response.data.idCust)
      const customerID = response.data.idCust;
      const creditUserId =  response.data.creditId;
      const traData = response.data.insertedTransItesIds;
      const entered_Paymodes = response.data.results;
      const enteredPaymodesString = entered_Paymodes.map(paymode => paymode.pay_mode_name).join(', ');
      this.setState({enteredPaymodes: enteredPaymodesString});
     

      // try {
      //   const response = await axios.post(`${var_api}/api/order-paymode/post`, paymodesArray);
      //   console.log(response.data); // Log response from server
      //   console.log( "Succes", response.data.message );
      //   const entered_Paymodes = response.data.results;
      //   const enteredPaymodesString = entered_Paymodes.map(paymode => paymode.pay_mode_name).join(', ');
      //   this.setState({enteredPaymodes: enteredPaymodesString});
      // } catch (error) {
      //   console.error('Error posting pay modes:', error);
      // }

      document.querySelector('#remark').value = '';
      this.setState({ selectedPayMode: null});
      this.setState({orderResponse:response.data})
     
      console.log("credit user ids",creditUserId,traData);
      
      
      await this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);


      // const creditorderItems = this.state.cartItems.map((cartItem,index) => {
      //   const tradata = response.data.insertedTransItesIds[index]; 
      //   return {
      //     food_name: cartItem.food.food_name,
      //     quantity: cartItem.quantity,
      //     unit_price: cartItem.food.price,
      //     item_code: cartItem.food.item_code,
      //     category_code:cartItem.food.category_code,
      //     trans_id: tradata,
      //     // Add more properties as needed
      //   };
      // });
      // console.log("cccc",creditorderItems,cartItems)

      // await addDoc(orderRef, {
      //   // customer_name: selectedCreditCustomer.name,
      //   // customer_phone: selectedCreditCustomer.phone_no,
      //   customer_name: this.state.selectedData.label,
      //   customer_phone: this.state.selectedData.value,
      //   order_items: creditorderItems,
      //   total: totalWithServiceCharge,
      //   total_items: totalItems,
      //   order_no:orderNumber,
      //   ordered_at: timestamp,
      //   sub_total:total,
      //   process_code:0,
      //   print_status:0,
      //   paid_status:0,
      //   parcel_status:0,
      //   customer_id :customerID,
      //   creditUser_id:creditUserId
      //   // Add more properties as needed
      // });
      
      // Handle successful order submission to both databases
      console.log('Order submitted successfully to Firestore and Node.js');

      const reversedOrders = this.state.orderHistoryItems.slice().reverse();
 // Find the index of the new order in orderHistoryItems array
 const newOrderIndex = reversedOrders.findIndex(order => order.id === customerID );

 // After order completion, check if the checkbox is checked
 if (this.state.isPrintNowChecked && newOrderIndex !== -1) {
  this.setState({ showService: true });
   await this.handlePrints(newOrderIndex, customerID);
   console.log("print check box checked",reversedOrders[newOrderIndex])
   this.setState({ showService: false });
 }
    }

       
       
    
        console.log('Order posted to Firestore successfully!');
        const email = localStorage.getItem('emailId');
        console.log('shop admin:',email);
        const orderItemsString = JSON.stringify(orderItems);
        

        // const billHtml = ReactDOMServer.renderToString(<Bill order={order} />);
        const emailData = {
          to:[this.state.selectedData.mail,email],
          subject: `${this.state.selectedData.label} ordered new order from ${shopName}..!`,
        }

        const username = this.state.selectedData.label
        const shopID = localStorage.getItem('shopId')
        try {
          const response = await axios.post(`${var_api}/api/mailnotify/send-orderemail/${this.state.selectedData.mail}/${shopID}`, emailData);
          console.log('Email sent:', response.data);
          
        } catch (error) {
          console.error('Error sending email:', error);
          this.setState({iscredit:false});
        }

        this.setState({isCreditModal:false, isParcelChecked:false});
        this.setState({isOrderConfirmation:true});
    
        this.fetchAllOrdersSummary(this.state.selectedOrderLabel.value);
         // Remove the items from the cartItems array
    this.setState({ cartItems: [], addedItems: []});
    
    this.clearSelectedValue();
    // const audio = new Audio('https://spbiller.com/audio/place-order.mp3');
    // audio.play();

    
       
        this.setState({iscredit:false});
    console.log('Cart items removed from state!');
    
  // this.getExitingOrderNo();
      } catch (error) {
        console.error('Error posting order:', error);
        this.setState({iscredit:false});
      }
        // Fetch and display order history
        // this.fetchOrderHistory();
        
      
      }else{

        Swal.fire({
          icon:"error",
          title:"Oops...",
          text:"The order reached the credit limit",
          confirmButtonColor:"#007500"
        })
        }


      }else{
        Swal.fire({
          icon:"error",
          title:"Oops...",
          text:"Select Credit User",
          confirmButtonColor:"#007500"
        })
      }
    }
  }
  


  handleGenerateOrderModal = () => {
     //check cart is empty
     if(this.state.cartItems.length === 0){
      Swal.fire({
        icon:"error",
        title:"Oops...",
        text:"Your cart is empty. Please add items to your cart before placing an order.",
        confirmButtonColor:"#007500"
      })
    }else{
      this.setState({ isNormalModal: true,selectedAmounts:[]});
    }
    
  }


  handleGenerateNormalOrderModal = () => {
    //check cart is empty
    if(this.state.cartItems.length === 0){
     Swal.fire({
       icon:"error",
       title:"Oops...",
       text:"Your cart is empty. Please add items to your cart before placing an order.",
       confirmButtonColor:"#007500"
     })
   }else{

    if( this.state.selectedData.value && this.state.selectedData.label && this.state.selectedData.iden){
     this.setState({ isNormalCreditModal: true, selectedAmounts:[]});
   }  else{
      Swal.fire({
        icon:"error",
        title:"Oops...",
        text:"Select Credit User",
        confirmButtonColor:"#007500"
      })
    }
   
 }
}



handleGeneratecreditOrderModal = () => {
  //check cart is empty
  if(this.state.cartItems.length === 0){
   Swal.fire({
     icon:"error",
     title:"Oops...",
     text:"Your cart is empty. Please add items to your cart before placing an order.",
     confirmButtonColor:"#007500"
   })
 }else{

  if( this.state.selectedData.value && this.state.selectedData.label && this.state.selectedData.iden){
    const user_balance = this.state.amountDetail.balance;
    const maxLimit = this.state.selectedData.maxCredit;
    const itemTotal = this.state.cartItems.reduce((accumulator, cartItem) => {
      return accumulator + cartItem.quantity * cartItem.food.price;
    }, 0);
    const totalAmount = user_balance + itemTotal;
    console.log("uuu",user_balance,maxLimit,itemTotal,totalAmount)

    if(maxLimit>=totalAmount){
   this.setState({ isCreditModal: true});
    }else{

      Swal.fire({
        icon:"error",
        title:"Oops...",
        text:"The order reached the credit limit",
        confirmButtonColor:"#007500"
      })
      }


 }  else{
    Swal.fire({
      icon:"error",
      title:"Oops...",
      text:"Select Credit User",
      confirmButtonColor:"#007500"
    })
  }
 
}
}



  generateOrder = async () => {
    const { cartItems, order_no, isParcelChecked, selectedAmounts, givenAmounts, payModes } = this.state;


    //check cart is empty
    if(cartItems.length === 0){
      Swal.fire({
        icon:"error",
        title:"Oops...",
        text:"Your cart is empty. Please add items to your cart before placing an order.",
        confirmButtonColor:"#007500"
      })
    }
    else{

       // Calculate the total amount from cartItems
  const cartTotal = this.state.cartItems.reduce((accumulator, cartItem) => {
    return accumulator + cartItem.quantity * cartItem.food.price;
  }, 0);

  // Calculate the total amount from selectedAmounts
  const selectedTotal = this.state.selectedAmounts.reduce((accumulator, amount) => {
    return accumulator + amount;
  }, 0);

  
  console.log("amounts",cartTotal,selectedTotal)
      if(selectedTotal > 0){

        if(selectedTotal === cartTotal){
          

          for (let index = 0; index < payModes.length; index++) {
            const payMode = payModes[index];
            const selectedAmount = selectedAmounts[index];
            const givenAmount = givenAmounts[index];
           
        
            // if (payMode.pay_mode_name === "CASH") {
              console.log("sssssss",givenAmount,selectedAmount)
              // Check if givenAmount is provided and greater than or equal to selectedAmount
              if (givenAmount < selectedAmount) {
                Swal.fire({
                  icon: "error",
                  title: "Invalid Entry",
                  text: "Given amount must be greater than or equal to the selected amount for Cash pay mode.",
                  confirmButtonColor: "#007500"
                });
                return;
              }
            // }
          }
          

      const shopName = localStorage.getItem('shopName');
      this.setState({isLogin:true});
    // console.log("shop category:",shopName)
    // const orderRef = collection(db, `shops/${shopName}/orders`);
  
    const orderItems = this.state.cartItems.map((cartItem) => {
      return {
        food_id:cartItem.food.id,
        food_name: cartItem.food.food_name,
        quantity: cartItem.quantity,
        unit_price: cartItem.food.price,
        item_code: cartItem.food.item_code,
        category_code:cartItem.food.category_code,
        // Add more properties as needed
      };
    });

    const total = this.state.cartItems.reduce((accumulator, cartItem) => {
      return accumulator + cartItem.quantity * cartItem.food.price;
    }, 0);

    const totalWithServiceCharge = total.toFixed(2); // Add a service charge of 5 to the total
  
    // const totalItems = this.state.cartItems.reduce((accumulator, cartItem) => {
    //   return accumulator + cartItem.quantity;
    // }, 0);

    const totalItems = this.state.cartItems.length;

    
  
    try {
      // const timestamp = serverTimestamp();
      // const querySnapshot = await getDocs(orderRef);
      // const orderNumber = querySnapshot.size + 1;
      const currDate = new Date();
      const dd = String(currDate.getDate()).padStart(2, '0');
      const mm = String(currDate.getMonth() + 1).padStart(2, '0'); // January is 0!
      const yyyy = currDate.getFullYear();
      
      const formattedDate = `${dd}-${mm}-${yyyy}`;
      const currTime = new Date().toLocaleTimeString();

      // let maxOrderNo = 0;
      // if (order_no > maxOrderNo) {
      //   console.log("orderno",order_no)
      //    maxOrderNo = order_no;
      //   }
      // console.log("m",order_no)
      // maxOrderNo = order_no.max_order_no + 1
      // console.log("m",maxOrderNo)
      

      // var customerName = document.querySelector('#name').value;
      // var customerPhone = document.querySelector('#mobile').value;

      var customerName = document.querySelector('#name') ? document.querySelector('#name').value : "-";
      var customerPhone = document.querySelector('#mobile') ? document.querySelector('#mobile').value : "-";
      var remark = document.querySelector('#remark') ? document.querySelector('#remark').value : "-";

  //     if(!this.state.isChecked){
        
  // const selectedCreditCustomer = JSON.parse( document.querySelector('#selectedCreditCustomer').value);
  //        customerName =selectedCreditCustomer.name;
  //      customerPhone = selectedCreditCustomer.phone_no;
  //     }

      // Validate name (only strings)
  // if (!/^[A-Za-z\s]+$/.test(customerName)) {
  //   alert('Invalid name. Please enter a valid name.');
  //   return;
  // }

  // Validate mobile number (only 10 digits)
  // if (!/^\d{5}$/.test(customerPhone)) {
  //   alert('Invalid mobile number. Please enter a 10-digit mobile number.');
  //   return;
  // }

const userID = localStorage.getItem('userId');
const empID = localStorage.getItem("admin_employee");
const userEmail = localStorage.getItem("emailId");
 const shop_id = localStorage.getItem('shopId');

   // Create a new array with amounts inserted into matched pay modes
   const paymodesArray = this.state.payModes.map((mode, index) => {
    const amount = this.state.selectedAmounts[index] || 0; // Get amount from selectedAmounts array
    return { pay_mode_name: mode.pay_mode_name, id: mode.id, amount }; // Create a new object with amount inserted
  });

     

      // Prepare the data to be sent to the Node.js API
      const orderData = {
        // admin_id: parseInt(userID),
        // admin_email: userEmail,
        // shop_name:shopName,
        cust_name: customerName,
        phone_no: customerPhone,
        // total_items:totalItems,
        // order_no:maxOrderNo,
        // total: totalWithServiceCharge,
        // order_type:0,
        // table_no:0,
        // paid_status: 0,
        // order_label:0,
        // is_group_location_order:0,
        // group_location_order_id:0,
        // ordered_at:formattedDate,
        // ordered_time:currTime,
        shop_id:parseInt(shop_id),
        credit_user_id:0,
        items: orderItems,
        // pay_mode: "-",
        remarks:remark,
        is_parcel: isParcelChecked ? 1 : 0,
        user_id:parseInt(userID),
        emp_id:parseInt(empID),
        paymodesArray: paymodesArray
        // kitchen_parcel_status:0,
        // is_local: 0,
        // local_date: "-"
      };
      

       // Send the order data to the Node.js API using axios
  const response = await axios.post(`${var_api}/api/food/new-post`, orderData);
  if (response.status === 200) {
    console.log("customer id:", response.data.idUser);
      // Wait for 5 seconds
      // await new Promise(resolve => setTimeout(resolve, 10000));


    const customerId = response.data.idUser;
    const entered_Paymodes = response.data.results;
    const enteredPaymodesString = entered_Paymodes.map(paymode => paymode.pay_mode_name).join(', ');
    this.setState({selectedAmounts:[], enteredPaymodes: enteredPaymodesString});




    
    // console.log("amountwitharray",paymodesArray);

    // try {
    //   const response = await axios.post(`${var_api}/api/order-paymode/post`, paymodesArray);
    //   console.log(response.data); // Log response from server
    //   console.log( "Succes", response.data.message );
    //   const entered_Paymodes = response.data.results;
    //   const enteredPaymodesString = entered_Paymodes.map(paymode => paymode.pay_mode_name).join(', ');
    //   this.setState({selectedAmounts:[], enteredPaymodes: enteredPaymodesString});
    // } catch (error) {
    //   console.error('Error posting pay modes:', error);
    // }



    const data = response.data.insertedTransIds;
    console.log("o.r",response.data)
    this.setState({orderResponse:response.data})

    // Extract the insertedTransIds array from the response
    console.log("transaction id", data);
    console.log('Order submitted successfully to Firestore and Node.js');

    // Clear the text fields by setting their values to an empty string
    document.querySelector('#name').value = '';
    document.querySelector('#mobile').value = '';
    document.querySelector('#remark').value = '';
    this.setState({isNormalModal:false, isParcelChecked:false});
    this.setState({isOrderConfirmation:true});
    
    await this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);
    
  
    // Play the notification sound
   // Play the notification sound
// const audio = new Audio('https://spbiller.com/audio/place-order.mp3');
// audio.play();
const reversedOrders = this.state.orderHistoryItems.slice().reverse();
 // Find the index of the new order in orderHistoryItems array
 const newOrderIndex = reversedOrders.findIndex(order => order.id === customerId );
 console.log("print check box in funtion", newOrderIndex, this.state.isPrintNowChecked, customerId, this.state.orderHistoryItems)

 // After order completion, check if the checkbox is checked
 if (this.state.isPrintNowChecked && newOrderIndex !== -1) {
  this.setState({ showService: true });
   await this.handlePrints(newOrderIndex, customerId);
   console.log("print check box checked",reversedOrders[newOrderIndex])
   this.setState({ showService: false });
 }

}


     
      this.setState({isLogin:false});
  
      console.log('Order posted to Firestore successfully!');

       // Remove the items from the cartItems array
  this.setState({ cartItems: [], addedItems: []});
  this.setState({ selectedItemCode: null});
  this.setState({ selectedPayMode: null});
  this.setState({ givenAmounts: [], selectedAmounts:[]});
  this.fetchAllOrdersSummary(this.state.selectedOrderLabel.value);
  
// this.getExitingOrderNo();


  console.log('Cart items removed from state!');
    } catch (error) {
      console.error('Error posting order:', error);
      this.setState({isLogin:false});
    }
      // Fetch and display order history
      // this.fetchOrderHistory();
  }
  else{
    Swal.fire({
      icon:"error",
      title:"Oops...",
      text:"pay mode amount is not equal to total amount",
      confirmButtonColor:"#007500"
    })
  }

}
  
  else{
        Swal.fire({
          icon:"error",
          title:"Oops...",
          text:"Enter the pay mode amount",
          confirmButtonColor:"#007500"
        })
      }
    }
  };


  generateNormalOrder = async () => {
    const { cartItems, order_no, isParcelChecked } = this.state;


    //check cart is empty
    if(cartItems.length === 0){
      Swal.fire({
        icon:"error",
        title:"Oops...",
        text:"Your cart is empty. Please add items to your cart before placing an order.",
        confirmButtonColor:"#007500"
      })
    }
    else{
      if( this.state.selectedData.value && this.state.selectedData.label && this.state.selectedData.iden){

         // Calculate the total amount from cartItems
  const cartTotal = this.state.cartItems.reduce((accumulator, cartItem) => {
    return accumulator + cartItem.quantity * cartItem.food.price;
  }, 0);

  // Calculate the total amount from selectedAmounts
  const selectedTotal = this.state.selectedAmounts.reduce((accumulator, amount) => {
    return accumulator + amount;
  }, 0);


        if(selectedTotal > 0){

          if(selectedTotal === cartTotal){

          const shopName = localStorage.getItem('shopName');
          this.setState({isLogin:true});
       
        const orderItems = this.state.cartItems.map((cartItem) => {
          return {
            food_id:cartItem.food.id,
            food_name: cartItem.food.food_name,
            quantity: cartItem.quantity,
            unit_price: cartItem.food.price,
            item_code: cartItem.food.item_code,
            category_code:cartItem.food.category_code,
            // Add more properties as needed
          };
        });
    
        const total = this.state.cartItems.reduce((accumulator, cartItem) => {
          return accumulator + cartItem.quantity * cartItem.food.price;
        }, 0);
    
        const totalWithServiceCharge = total.toFixed(2); // Add a service charge of 5 to the total
      
        
    
        const totalItems = this.state.cartItems.length;
    
        
      
        try {
          
          const currDate = new Date();
          const dd = String(currDate.getDate()).padStart(2, '0');
          const mm = String(currDate.getMonth() + 1).padStart(2, '0'); // January is 0!
          const yyyy = currDate.getFullYear();
          
          const formattedDate = `${dd}-${mm}-${yyyy}`;
          const currTime = new Date().toLocaleTimeString();
    
          let maxOrderNo = 0;
          // if (order_no > maxOrderNo) {
          //   console.log("orderno",order_no)
          //    maxOrderNo = order_no;
          //   }
          // console.log("m",order_no)
          // maxOrderNo = order_no.max_order_no + 1
          // console.log("m",maxOrderNo)
          var remark = document.querySelector('#remark') ? document.querySelector('#remark').value : "-";
          const userID = localStorage.getItem('userId');
          const empID = localStorage.getItem("admin_employee")
          const userEmail = localStorage.getItem("emailId");

            // Create a new array with amounts inserted into matched pay modes
    const paymodesArray = this.state.payModes.map((mode, index) => {
      const amount = this.state.selectedAmounts[index] || 0; // Get amount from selectedAmounts array
      return { pay_mode_name: mode.pay_mode_name, id: mode.id, amount }; // Create a new object with amount inserted
    });

    console.log("amountwitharray",paymodesArray);
    
          // Prepare the data to be sent to the Node.js API
          const orderData = {
            // admin_id: parseInt(userID),
            // admin_email: userEmail,
            // shop_name:shopName,
            cust_name:  this.state.selectedData.label,
            phone_no: this.state.selectedData.value,
            // total_items:totalItems,
            // order_no:maxOrderNo,
            // total: totalWithServiceCharge,
            // order_type:0,
            // table_no:0,
            // paid_status: 0,
            // order_label:0,
            // is_group_location_order:0,
            // group_location_order_id:0,
            // ordered_at:formattedDate,
            // ordered_time:currTime,
            shop_id:localStorage.getItem('shopId'),
            credit_user_id:this.state.selectedData.iden,
            items: orderItems,
            user_id:localStorage.getItem('userId'),
            emp_id: localStorage.getItem('admin_employee'),
            // pay_mode: "-",
            remarks:remark,
            is_parcel: isParcelChecked ? 1 : 0,
            paymodesArray: paymodesArray,
            // kitchen_parcel_status:0,
            // is_local: 0,
            // local_date: "-"
          };
          
    
           // Send the order data to the Node.js API using axios
      const response = await axios.post(`${var_api}/api/food/new-post`, orderData);
      if (response.status === 200) {
    
        console.log("customer id:",response.data.idUser)
        const customerid = response.data.idUser;

        const entered_Paymodes = response.data.results;
      const enteredPaymodesString = entered_Paymodes.map(paymode => paymode.pay_mode_name).join(', ');
      this.setState({selectedAmounts:[], enteredPaymodes: enteredPaymodesString});
    
        const data = response.data.insertedTransIds; 
        this.setState({orderResponse:response.data})
    
        // Extract the insertedTransIds array from the response
       console.log("transaction id",data);
       

      


    // try {
    //   const response = await axios.post(`${var_api}/api/order-paymode/post`, paymodesArray);
    //   console.log(response.data); // Log response from server
    //   console.log( "Succes", response.data.message );
    //   const entered_Paymodes = response.data.results;
    //   const enteredPaymodesString = entered_Paymodes.map(paymode => paymode.pay_mode_name).join(', ');
    //   this.setState({selectedAmounts:[], enteredPaymodes: enteredPaymodesString});
    // } catch (error) {
    //   console.error('Error posting pay modes:', error);
    // }

    
       document.querySelector('#remark').value = '';
       this.setState({ selectedPayMode: null});
       this.setState({isNormalCreditModal:false, isParcelChecked:false});
        this.setState({isOrderConfirmation:true});
        await this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);
    
        // Handle successful order submission to both databases
        console.log('Order submitted successfully to Firestore and Node.js');
        // const audio = new Audio('https://spbiller.com/audio/place-order.mp3');
        // audio.play();

        const reversedOrders = this.state.orderHistoryItems.slice().reverse();
 // Find the index of the new order in orderHistoryItems array
 const newOrderIndex = reversedOrders.findIndex(order => order.id === customerid );
 // After order completion, check if the checkbox is checked
 if (this.state.isPrintNowChecked && newOrderIndex !== -1) {
  this.setState({ showService: true });
   await this.handlePrints(newOrderIndex, customerid);
   console.log("print check box checked",reversedOrders[newOrderIndex])
   this.setState({ showService: false });
 }


    
      }
    
        
          this.setState({isLogin:false});
      
          console.log('Order posted to Firestore successfully!');
    
           // Remove the items from the cartItems array
      this.setState({ cartItems: [], addedItems: [] });
      this.setState({ selectedItemCode: null});
      
      this.fetchAllOrdersSummary(this.state.selectedOrderLabel.value);
      
    // this.getExitingOrderNo();
    this.clearSelectedValue();
    
      console.log('Cart items removed from state!');
        } catch (error) {
          console.error('Error posting order:', error);
          this.setState({isLogin:false});
        }
          // Fetch and display order history
          // this.fetchOrderHistory();

        }
        else{
          Swal.fire({
            icon:"error",
            title:"Oops...",
            text:"pay mode amount is not equal to total amount",
            confirmButtonColor:"#007500"
          })
        }
      }else{
          Swal.fire({
            icon:"error",
            title:"Oops...",
            text:"Enter the pay mode Amount!",
            confirmButtonColor:"#007500"
          })
        }
        
    }
    else{
      Swal.fire({
        icon:"error",
        title:"Oops...",
        text:"Select Credit User",
        confirmButtonColor:"#007500"
      })
    }
  };
}

    handleAddCard = () => {
      // Navigate to a specific route using history.push
      this.props.history.push('/cate');
    };

    handleDropdownChange = (selectedOption) => {
      this.setState({ selectedData: selectedOption });
      console.log("credit user",selectedOption)
       // Perform validation checks here
    this.fetchCustomerBalance(selectedOption.iden)
    
    };

     // Function to update parcel status
  updateParcelInfo = (custId,id) => {

    const shopID = localStorage.getItem('shopId');
    const shopName = localStorage.getItem('shopName');

    const updatedData = {
     parcel_status:1,
    }


      // const orderRef = doc(db, `shops/${shopName}/orders`, id);
    
      // // Update the process_code field to 1
      // updateDoc(orderRef, {
      //   parcel_status:1,
      // })
      // .then(() => {
      //   console.log('process_code updated successfully');
      // })
      // .catch(error => {
      //   console.error('Error updating process_code:', error);
      // });

    axios
    .put(`${var_api}/api/food/parcelput/${shopID}/${custId}`, updatedData)
    .then((response) => {
      toast('Order added to parcel order',{autoClose:2000})
      console.log('Data updated successfully:', response.data.message);
      // Handle success, e.g., show a success message or update the UI
    })
    .catch((error) => {
      console.error('Error updating data:', error);
      // Handle error, e.g., show an error message or update the UI accordingly
    });
};


//update the print status
handlePrint = (orderId) => {
  const shopID = localStorage.getItem('shopId');
  console.log("ids", orderId);
   
  const updatedStatus = {
    print_status:1,
   }

   axios
   .put(`${var_api}/api/food/put/print/${shopID}/${orderId}`, updatedStatus)
   .then((response) => {
    //  Swal.fire({
    //    text:"print status updated",
    //    confirmButtonColor:"#007500"
    //  })
    this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);
    toast('print status updated',{autoClose:2000})
     console.log('Data updated successfully:', response.data.message);
   })
   .catch((error) => {
     console.error('Error updating data:', error);
     // Handle error, e.g., show an error message or update the UI accordingly
   });
  // this.setState({ isPrinted: true }); // Set the state to indicate that the button has been clicked
  // // Call the ReactToPrint logic here if needed
};


//update the print status
handleVoidPut = () => {
  const shopID = localStorage.getItem('shopId');
  const orderId = this.state.voidOrder.id;
   
  const updatedStatus = {
    is_void:1,
   }

   axios
   .put(`${var_api}/api/food/put/is-void/${shopID}/${orderId}`, updatedStatus)
   .then((response) => {
    //  Swal.fire({
    //    text:"print status updated",
    //    confirmButtonColor:"#007500"
    //  })
    this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);
    toast('void status updated',{autoClose:2000})
     console.log('Data updated successfully:', response.data.message);
     this.setState({isVoid:false});
   })
   .catch((error) => {
     console.error('Error updating data:', error);
     // Handle error, e.g., show an error message or update the UI accordingly
   });
  // this.setState({ isPrinted: true }); // Set the state to indicate that the button has been clicked
  // // Call the ReactToPrint logic here if needed
};


 signData = (toSign, privateKeyPem) => {
  const privateKey = forge.pki.privateKeyFromPem(privateKeyPem);
  const md = forge.md.sha512.create();
  md.update(toSign, 'utf8');
  const signature = privateKey.sign(md);
  return forge.util.encode64(signature);
};



initializeQZTray = async () => {
  try {

    qz.security.setCertificatePromise(function(resolve, reject) {
      //Preferred method - from server
//        fetch("assets/signing/digital-certificate.txt", {cache: 'no-store', headers: {'Content-Type': 'text/plain'}})
//          .then(function(data) { data.ok ? resolve(data.text()) : reject(data.text()); });

      //Alternate method 1 - anonymous
//        resolve();  // remove this line in live environment

      //Alternate method 2 - direct
      resolve("-----BEGIN CERTIFICATE-----\n" +
"MIIECzCCAvOgAwIBAgIGAZBtKKX2MA0GCSqGSIb3DQEBCwUAMIGiMQswCQYDVQQG\n" +
"EwJVUzELMAkGA1UECAwCTlkxEjAQBgNVBAcMCUNhbmFzdG90YTEbMBkGA1UECgwS\n" +
"UVogSW5kdXN0cmllcywgTExDMRswGQYDVQQLDBJRWiBJbmR1c3RyaWVzLCBMTEMx\n" +
"HDAaBgkqhkiG9w0BCQEWDXN1cHBvcnRAcXouaW8xGjAYBgNVBAMMEVFaIFRyYXkg\n" +
"RGVtbyBDZXJ0MB4XDTI0MDYzMDA3MTgxOFoXDTQ0MDYzMDA3MTgxOFowgaIxCzAJ\n" +
"BgNVBAYTAlVTMQswCQYDVQQIDAJOWTESMBAGA1UEBwwJQ2FuYXN0b3RhMRswGQYD\n" +
"VQQKDBJRWiBJbmR1c3RyaWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMs\n" +
"IExMQzEcMBoGCSqGSIb3DQEJARYNc3VwcG9ydEBxei5pbzEaMBgGA1UEAwwRUVog\n" +
"VHJheSBEZW1vIENlcnQwggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQDd\n" +
"snZmXeIgZFbb/TgULomwgto15aNCLZJBy86Jsx9DF05IhEQLOYJ8x8QgNc7bTFfc\n" +
"ywnZvtsv2VdYip197R+g2LVL4f0FUTY3zTkV4RMWjgHt8ohuxww/MLKhy5R3Fknp\n" +
"Xn5ovDUZOyIbs9gVCZepR7MvoCOcE/KP9R7gGF2l3zIpCI1EEntr13CIrnAZrhs+\n" +
"JA5DHzlDEV0rZpCEQnysA4exgGpmNGpstDRy2Kdj3RtKXyTaH3dD/I2NQ5BUOBeJ\n" +
"wVOWHgLxP9kE0s1i0XgDFSjt9Di8gk1SEK9BRHGComonX2vFW+c0a39W6zkhpPhv\n" +
"wCQyUEJ4X359VrjCQU4TAgMBAAGjRTBDMBIGA1UdEwEB/wQIMAYBAf8CAQEwDgYD\n" +
"VR0PAQH/BAQDAgEGMB0GA1UdDgQWBBTJ4yKVPVX6NPawD8kSkOSlpg1PcjANBgkq\n" +
"hkiG9w0BAQsFAAOCAQEAzbyjcQ90HaxQKyazZUuB128q4dsVDXyIam6NMnT5Lkv9\n" +
"AROiVnef2XityBucBJnC9Pf4HW98msTSeyPjSDPDLX6k3Ib8/f76gU6uu6WgApHI\n" +
"EYBkIVpoeKq4ch35yg9SBZZNwv/xJeW6EjA9zLQ1Poje75AYOouXI2j6I0eqk6RD\n" +
"MCtThm8CS6JlDcArMBUZwFk7otsWS90PQTIfaFkXbXoyFcSZmna1gOW0ZZ4AHgKY\n" +
"rXVmWlaSLstc5jUFCKDDfTfIay9bqLjbc4EaTmayU8qdmJrhcvULFMmGCvu1FGgA\n" +
"T+/fhNSt4qhYlDrqkTIAV1ICXEIOWnh/vHx3fb/5SA==\n" +
"-----END CERTIFICATE-----");
  });

  qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
  qz.security.setSignaturePromise((toSign) => {
    return (resolve, reject) => {
      try {
        const privateKey = "-----BEGIN PRIVATE KEY-----\n" +
"MIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQDdsnZmXeIgZFbb\n" +
"/TgULomwgto15aNCLZJBy86Jsx9DF05IhEQLOYJ8x8QgNc7bTFfcywnZvtsv2VdY\n" +
"ip197R+g2LVL4f0FUTY3zTkV4RMWjgHt8ohuxww/MLKhy5R3FknpXn5ovDUZOyIb\n" +
"s9gVCZepR7MvoCOcE/KP9R7gGF2l3zIpCI1EEntr13CIrnAZrhs+JA5DHzlDEV0r\n" +
"ZpCEQnysA4exgGpmNGpstDRy2Kdj3RtKXyTaH3dD/I2NQ5BUOBeJwVOWHgLxP9kE\n" +
"0s1i0XgDFSjt9Di8gk1SEK9BRHGComonX2vFW+c0a39W6zkhpPhvwCQyUEJ4X359\n" +
"VrjCQU4TAgMBAAECggEADFngx8jg4WNwWrbg9MWM9+T/tDNUJPU1/0K+rHmVZcml\n" +
"cQZ8EKYGaN5A78x09vm/XAvXGNGjE3zdJWUqQLWWZqx8GTtWEIGa7taY9hOWhSdU\n" +
"izBREomLW0qNa9R+3nhC649PT+rLCISnXKS92++EtV0gaf2sI4oRoEc3OPk9NRGU\n" +
"zj3IEFLyx3E2saYI129w29evDMplo6C3NRtaAFEp+cWSG5DI+sCc+Juzbu1CblSl\n" +
"9+8W3vV4TPK4lwbe1XmUauUFam0n+2j/X9Ipr+yZy92O1eP5isVRW9/wBnLunSz3\n" +
"kNYD50DNgEkvWDrd6061JEzhSpnx4q+c48n4kdYcGQKBgQD9YHjrVAH1mMl6EZWH\n" +
"kNy/QWnZ+OkB5okPDLLh4SzmDAiJ8DKqhO5nALtMkv3mBYceVGKMySmfTBMAuwh5\n" +
"ogWMoyipoS3YHslzVMpJXzZtSTNXBY4SAoCtyYz9yKjcAg/Ykv7jMaiXelwfTkMq\n" +
"wWQTTEe0ghDIFLOl2tpFK1iVOQKBgQDf/gds4IKLkd8yzvhLSAAd533I40mMp3LH\n" +
"pBQ76vOt3FiIU7Xg0cuH6T5H/hhuW7N3BkvEXRSzABo98sRKDd3aCd5HEFkQqivh\n" +
"auLRHFL+0hfyLQH8UzprJ+YtGk6AEx41UpeddsaBR6ezmsuj+gGA3MseSWiK81kM\n" +
"Gw7TWyupqwKBgQCWuPGVQ2ba+uU3CQjDGOhHmRjnggrRh5qWaj5q4bD8s8HMlCb/\n" +
"io3qlePU/EeusjEbWKU7DFsHGJDhSWEUQc49R71jkq6btkytn42pscrFndq9dF97\n" +
"1SNIvfFx8hCIt21HrsGS0rJwUWhFcx+/akUmuaSZ+xkSfEYVbieTJ+LM+QKBgQCg\n" +
"XrZeQ5fz6arOK9UlwRUkU/uA+wpLxA+v1FAQXjW9gwd4A8cygcKhOmO3E7vWN52U\n" +
"WFOi8anCRw6Cz++tvaPbWjlk09WQ0uW77NUcNpVNkIpIf+TuDYDD+7sXC8CsfgOg\n" +
"1dNrkACryuvM8cVfT74Hzw02UASjh3t36V+B/8KRJQKBgQCEq+FX7l6VrZIdvyAK\n" +
"f+A4Vxr+S+cRvqXphilnjcuPEMTdRzTD8ROHVeQ+GajptVyC+ODOq1roBqp9VXRI\n" +
"+zxhjo2vBbWjXKWD0fFssCPam9C5OzavJ6ZAI/FtujwjPnxoDOhrGzBOhF0nzAA3\n" +
"5/7Etv6n5dzVZ9Px1dM0Vn1KSQ==\n" +
"-----END PRIVATE KEY-----";
        const signedData = this.signData(toSign, privateKey);
        resolve(signedData);
      } catch (error) {
        reject(error);
      }
    };
  });



    qz.api.setSha256Type((data) => sha256(data));
    qz.api.setPromiseType((resolver) => new Promise(resolver));

    await this.connectToQZTray();
} catch (error) {
    console.error('Failed to connect to QZ Tray', error);
    alert('Failed to connect to QZ Tray. Please ensure QZ Tray is running.');
}
};


fetchPrinters = async () => {
  try {
    const response = await axios.get('http://localhost:3001/api/printers');
    const printers = response.data.printers;
    console.log("oooo", data);
    
      // If there are categories in the response, set the ID of the first category in the state
      this.setState({ printers,  selectedPrinter: printers[0]});
      localStorage.setItem('selectedPrinter', printers[0]);
  }catch (error) {
    console.log('Error fetching data:', error);
    this.setState({ printers: [] });
    }
};

 
connectToQZTray = async () => {
  try {
    await qz.websocket.connect({ retries: 5, delay: 1000 });
    this.setState({ connected: true });
    console.log('Connected to QZ Tray');
    const selectedPrinter = localStorage.getItem('selectedPrinter');
    console.log("local storage printer", selectedPrinter);
    if(!selectedPrinter){
      this.fetchPrinters();
      console.log("calling fetchPrinters");
    }
} catch (error) {
    this.setState(prevState => ({ connectionAttempts: prevState.connectionAttempts + 1 }));
    console.error('Failed to connect to QZ Tray:', error);
    if (this.state.connectionAttempts < 5) {
        setTimeout(this.connectToQZTray, 2000); // Retry connection after 2 seconds
    } else {
        alert('Failed to connect to QZ Tray after multiple attempts. Please ensure QZ Tray is running.');
    }
}
};


disconnectFromQZTray = async () => {
  if (this.state.connected && !this.state.disconnecting) {
      this.setState({ disconnecting: true });
      try {
          await qz.websocket.disconnect();
          console.log('Disconnected from QZ Tray');
      } catch (error) {
          console.error('Error disconnecting from QZ Tray:', error);
      } finally {
          this.setState({ connected: false, disconnecting: false });
      }
  }
};


handlePrintss = async(index,id) => {
  console.log('Print button clicked');
  // const content = '<div>hi</div>';
  // if (window.electron) {
  //   window.electron.printContent(content);
  //   console.log('Called window.electron.printContent');
  // } else {
  //   console.error('Electron not available');
  // }
  

  if (!this.state.connected) {
    console.error('Not connected to QZ Tray');
    alert('Not connected to QZ Tray. Please ensure QZ Tray is running.');
    return;
}
const billContent = document.getElementById(`billContent${index}`).innerHTML;
// const printers = await qz.printers.find();
//   const selectedPrinter = printers[0];
// console.log("printer name", printers[0]);
const selectedPrinter = localStorage.getItem('selectedPrinter');
console.log("local storage printer", selectedPrinter);

try {
    const config = qz.configs.create(selectedPrinter); // Replace 'Printer_Name' with your printer name
    const data = [
        { type: 'html', format: 'plain', data: billContent },
    ];

    await qz.print(config, data);
    console.log('Print command executed successfully');
    this.handlePrint(id)
} catch (error) {
    console.error('Error printing', error);
}
};



  //Method to handle the print button click
//   handlePrints= async (index, orderId) =>{
//     try {
//     const element = document.getElementById(`billContent${index}`);

      
//     if (!element) {
//         console.error('Element to print does not exist.');
//         return;
//     }
//     this.setState({printLoader:true});

//    // Convert the div to a PDF
//     const options = {
//       margin: 0, // Ensure all margins are set to zero
//       filename: `bill_${orderId}.pdf`,
//       image: { type: 'jpeg', quality: 0.98 },
//       html2canvas: {
//         scale: 2, // You can adjust the scale for better quality
//         useCORS: true,
//         backgroundColor: null // Ensure no background color
//       },
//       jsPDF: {
//         unit: 'in',
//         format: [2.28, 1.90],
//         orientation: 'portrait'
//       }
//     };
    

//     const pdf = html2pdf().from(element).set(options);
//     const pdfData = await pdf.outputPdf('datauristring');

//     // Convert PDF to base64
//     const base64Data = pdfData.split(',')[1];

//     // Send the base64 PDF data to the backend
//     await axios.post('http://localhost:3001/print', {
//       data: base64Data,
//     });
//     toast('Print successfully');
//     this.setState({printLoader:false});
//     this.handlePrint(orderId)
//     //need to change print status 0 to 1 so call the update api
// } catch (err) {
//     console.error('Error printing:', err);
//     this.setState({printLoader:false});
//     toast('Please run the print server!')
// } 
// };


handlePrints = async (index, orderId) => {
  try {
      const element = document.getElementById(`billContent${index}`);

      if (!element) {
          console.error('Element to print does not exist.');
          return;
      }
      this.setState(prevState => ({
        printLoader: {
          ...prevState.printLoader,
          [index]: true,
        },
      }));
  

      // Capture the element as an image
      const canvas = await html2canvas(element, {
          scale: 2, // Adjust the scale for better quality
          useCORS: true
      });

      // Convert the canvas to an image
      const imgData = canvas.toDataURL('image/png');

      // Get natural dimensions of the canvas to maintain aspect ratio
      const imgWidth = 1.98; // Width in inches
      const imgHeight = (canvas.height / canvas.width) * imgWidth; // Height maintaining aspect ratio
      
      // Create a PDF and add the image to it
      const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'in',
          format: [imgWidth, imgHeight] // Set the dimensions of the PDF
      });

      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

      // Convert the PDF to base64
      const pdfData = pdf.output('datauristring');

      const printerName = localStorage.getItem("selectedPrinter");
      // Send the base64 PDF data to the backend
      await axios.post('http://localhost:3001/print', {
          data: pdfData.split(',')[1],
          printerName: printerName
      });

      toast('Print successfully');
      this.handlePrint(orderId);
  } catch (err) {
      console.error('Error printing:', err);
      toast('Please run the print server!');
  }finally {
    this.setState(prevState => ({
      printLoader: {
        ...prevState.printLoader,
        [index]: false,
      },
    }));
  }
};


 

//to update paid status to 1
handlePaidStatus = (id,orderId) => {

  const shopName = localStorage.getItem('shopName');
  // const orderRef = doc(db, `shops/${shopName}/orders`, orderId);

  // // Update the process_code field to 1
  // updateDoc(orderRef, {
  //   paid_status: 1
  // })
  // .then(() => {
  //   console.log('process_code updated successfully');
  // })
  // .catch(error => {
  //   console.error('Error updating process_code:', error);
  // });
  
  const shopID = localStorage.getItem('shopId');
   
  const updatedStatus = {
    paid_status:1,
   }

   axios
   .put(`${var_api}/api/food/put/paidstatus/${shopID}/${id}`, updatedStatus)
   .then((response) => {
    //  Swal.fire({
    //    text:"paid status updated",
    //    confirmButtonColor:"#007500"
    //  })
     toast('paid status updated',{autoClose:2000})
     console.log('Data updated successfully:', response.data.message);
   })
   .catch((error) => {
     console.error('Error updating data:', error);
     // Handle error, e.g., show an error message or update the UI accordingly
   });
  // this.setState({ isPrinted: true }); // Set the state to indicate that the button has been clicked
  // // Call the ReactToPrint logic here if needed
};



//to update paid status to 1
handleCancelStatus = (id,orderId) => {

  const shopName = localStorage.getItem('shopName');
  // const orderRef = doc(db, `shops/${shopName}/orders`, orderId);

  // // Update the process_code field to 1
  // updateDoc(orderRef, {
  //   paid_status: 2
  // })
  // .then(() => {
  //   console.log('process_code updated successfully');
  // })
  // .catch(error => {
  //   console.error('Error updating process_code:', error);
  // });
  
  const shopID = localStorage.getItem('shopId');
   
  const updatedStatus = {
    paid_status:2,
   }

   axios
   .put(`${var_api}/api/food/put/paidstatus/${shopID}/${id}`, updatedStatus)
   .then((response) => {
    toast('order cancelled',{autoClose:2000})
     console.log('Data updated successfully:', response.data.message);
   })
   .catch((error) => {
     console.error('Error updating data:', error);
     // Handle error, e.g., show an error message or update the UI accordingly
   });
  // this.setState({ isPrinted: true }); // Set the state to indicate that the button has been clicked
  // // Call the ReactToPrint logic here if needed
};


handleOrderLabelSelectChange = (selectedOption) => {
  this.setState({ selectedOrderLabel: selectedOption });
  this.fetchAllOrdersHistory(selectedOption.value);
  this.fetchAllOrdersSummary(selectedOption.value)
}


//onchange function for payment mode
handlePayModeSelectChange = (selectedOption) => {
  this.setState({ selectedPayMode: selectedOption });
  console.log(selectedOption)
}

handleStartDateChange = (event) => {
  const startDate = event.target.value;
  // Split the date string into year, month, and day
 const [year, month, day] = startDate.split('-');
 // Create a new date string with the desired format
 const formattedDate = `${day}-${month}-${year}`;
 // Do whatever you want with the formatted date
 console.log('Formatted date:', formattedDate);
  // Do something with the start date, e.g., store it in state
  this.setState({ startDate:formattedDate });
  
}

handleEndDateChange = (event) => {
  const endDate = event.target.value;
  // Split the date string into year, month, and day
 const [year, month, day] = endDate.split('-');
 // Create a new date string with the desired format
 const formattedDate = `${day}-${month}-${year}`;
 // Do whatever you want with the formatted date
 console.log('Formatted date:', formattedDate);
  // Do something with the end date, e.g., store it in state
  this.setState({ endDate:formattedDate });
  
}


handleOpenSelectDate = () => {
  this.setState ({isOpenSelectDate:true});
}


handleCloseDateConfirmation = () => {
  this.setState ({isOpenSelectDate:false});
}

handleSelectDateRange = () => {
  this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);
  this.setState ({isOpenSelectDate:false});
  this.fetchAllOrdersSummary(this.state.selectedOrderLabel.value)
  
}

formatDate(dateString) {
  const [day, month, year] = dateString.split('-');
  return `${year}-${month}-${day}`;
}

//open the summary modal
handleOpenOrderSumModal = () => {
  this.setState({isOpenOrderSummary:true});
}


//close the summary modal
handleCloseSummaryConfirmation = () => {
  this.setState({isOpenOrderSummary:false});
}


//close the setting printer server modal
handleCloseSettingPrintServer = () => {
  this.setState({settingModalOpen:false});
}


//close the summary modal
handleCloseNormalConfirmation = () => {
  this.setState({isNormalModal:false});
}


//close the summary modal
handleCloseNormalCreditConfirmation = () => {
  this.setState({isNormalCreditModal:false});
}


//close the summary modal
handleCloseCreditConfirmation = () => {
  this.setState({isCreditModal:false});
}


//close the summary modal
handleCloseOrderConfirmation = () => {
  this.setState({isOrderConfirmation:false});
}


//close the summary modal
handleCloseVoidConfirmation = () => {
  this.setState({isVoid:false});
}

//close the summary modal
handleCloseInstantAdding = () => {
  this.setState({isOpenAddInstant:false});
}


//close the summary modal
handleCloseaPayModeEdit = () => {
  this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);
  this.setState({isPayModeEdit:false});
}



// Function to handle checkbox change
handleCheckboxChange = () => {
  // Update state to toggle isChecked value
  this.setState(prevState => ({
    isParcelChecked: !prevState.isParcelChecked
  }));
};

//switch for image status onchange
handleSwitchToggle() {
  // Toggle the switch state
  const newStatus = !this.state.imageStatus;
  this.setState({ imageStatus: newStatus });
  // Update localStorage
  localStorage.setItem('image_status', newStatus ? '1' : '0');
  console.log("imagestatus",localStorage.getItem('image_status'));
}

//to open the  edit modal
handleOpenEditPayModal = (order) => {
  // Sort pay_modes in ascending order based on pay_mode_id
  const sortedPayModes = order.pay_modes.sort((a, b) => a.paymode_id - b.paymode_id);
  const filteredPaymode = sortedPayModes.filter(mode => mode.pay_mode_name !== "CREDIT ORDER" && mode.pay_mode_name !== "GROUP LOCATION CREDIT ORDER");

  this.setState({isPayModeEdit:true, editPaymodeOrder:order, editSelectedAmounts:filteredPaymode, editpaymode: {label:order.pay_mode, value: order.pay_mode}, updateRemark:order.remarks});
  console.log("paymodd order",order);
  console.log("editSelectedAmounts",this.state.editSelectedAmounts, order.pay_modes);
  console.log("editSelectedAmounts", sortedPayModes);
}


handleVoidStatus = (order) => {
  this.setState({ isVoid:true, voidOrder: order});
}


//onchange the edit modal
handlePayModeEditChange = (selectedOption) => {
  this.setState({ editpaymode: selectedOption });
  console.log(selectedOption)
}

// Update the remark value in state when input changes
handleRemarkChange = (event) => {
  this.setState({ updateRemark: event.target.value });
};


//update method for paymode
handleUpdatePaymode = () => { 
const ModeTotal = this.state.editSelectedAmounts && this.state.editSelectedAmounts.reduce((sum, currentItem) => sum + currentItem.amount, 0)
  if(this.state.editPaymodeOrder.total !== ModeTotal){
    Swal.fire({
            icon:"error",
            title:"Oops...",
            text:"Paymode Total is not Equal to Total Amount",
            confirmButtonColor:"#007500"
          })

  }
  else{
  this.setState({isPayModeEditLoading:true});
  const shopID = localStorage.getItem('shopId');

   console.log("update",this.state.editSelectedAmounts)
   const paymodesArray = this.state.editSelectedAmounts.map((mode, index) => ({
    amount:  mode.amount ? mode.amount : 0,
    id: mode.id,
    shop_id: parseInt(shopID),
    remarks : this.state.updateRemark,
    order_id:mode.order_id
}));


   axios
   .put(`${var_api}/api/order-paymode/edit-order-paymodes`, paymodesArray)
   .then((response) => {
  
     toast('Updated Successfully',{autoClose:2000});
     this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);
     this.setState({isPayModeEdit:false,isPayModeEditLoading:false});
     console.log('Data updated successfully:', response.data.message);
   })
   .catch((error) => {
     console.error('Error updating data:', error);
     this.setState({isPayModeEditLoading:false});
     // Handle error, e.g., show an error message or update the UI accordingly
   });
  }
};





//onclick method paymode card
handleCardClick = (id) => {
  const { cartItems } = this.state;
  // Find the index of the pay mode with the given ID
  const index = this.state.payModes.findIndex(mode => mode.id === id);
  console.log("inddx", index,this.state.payModes);
  

  const total= cartItems.reduce((accumulator, cartItem) => {
    return accumulator + cartItem.quantity * cartItem.food.price;
  }, 0);

    // Create an array to hold the new amounts, initialized with zeros
    const newSelectedAmounts = Array(this.state.payModes.length).fill(0);
 
  // Set the amount for the clicked pay mode
  newSelectedAmounts[index] = total; // Set the initial value here
  let givenAmounts = [...this.state.givenAmounts];
   givenAmounts = Array(this.state.payModes.length).fill(0);
  givenAmounts[index] = total;
  this.setState({ givenAmounts });
  console.log("giv",givenAmounts);
 
  // Update the state
  this.setState({ selectedAmounts: newSelectedAmounts, isAmountSetByCardClick: true });

  console.log("after setting", newSelectedAmounts, this.state.isAmountSetByCardClick);
}



handleEditCardClick = (id, total) => {
  const { editSelectedAmounts } = this.state;
  // Find the index of the pay mode with the given ID
  const index = this.state.editSelectedAmounts.findIndex(mode => mode.id === id);
  console.log("inddx", index,this.state.editSelectedAmounts);
  
  const updatedEditSelectedAmounts = editSelectedAmounts.map(item => ({
    ...item,
    amount: 0
}));

if (index !== -1) {
  updatedEditSelectedAmounts[index].amount = total;
}

// Update the component state with the new array
this.setState({ editSelectedAmounts: updatedEditSelectedAmounts });
 
 
  console.log("after setting", updatedEditSelectedAmounts);
}


handleEditAmountChange = (index, value) => {
 const newSelectedAmounts = [...this.state.editSelectedAmounts];

  // Convert the value to a number
  const newValue = value !== '' ? parseFloat(value) : 0;
  // Check if the value is 0 and the user wants to clear it
  if (newValue === 0) {
    newSelectedAmounts[index].amount  = null; // Set the value to an empty string
  } else {
    // Ensure that the value is non-negative
  const nonNegativeValue = newValue >= 0 ? newValue : 0;
    // Update the quantity at the specified index
    newSelectedAmounts[index].amount = nonNegativeValue;
  }
  // Set the amount for the corresponding index
  // newSelectedAmounts[index].amount = parseFloat(value) || 0;
  console.log("after setting textfield", newSelectedAmounts);
  // Clear the card clicked index
  this.setState({ editSelectedAmounts: newSelectedAmounts});
}


handleAmountChange = (index, value) => {

  // Check if the amount is being set due to card clicking
  // if (this.state.isAmountSetByCardClick) {
  //    // Create an array to hold the new amounts, initialized with zeros
  //    const newSelectedAmounts = Array(this.state.payModes.length).fill(0);
  //    console.log("if", newSelectedAmounts);
  //   // Reset the flag
  //   this.setState({ selectedAmounts: newSelectedAmounts, isAmountSetByCardClick: false });
  // }
 // Create a copy of the current selectedAmounts array
 const newSelectedAmounts = [...this.state.selectedAmounts];

   // Convert the value to a number
  const newValue = value !== '' ? parseFloat(value) : 0;

  // Check if the value is 0 and the user wants to clear it
  if (newValue === 0) {
    newSelectedAmounts[index] = null; // Set the value to an empty string
  } else {
    // Ensure that the value is non-negative
  const nonNegativeValue = newValue >= 0 ? newValue : 0;
    // Update the quantity at the specified index
    newSelectedAmounts[index] = nonNegativeValue;

    const givenAmounts = [...this.state.givenAmounts];
    givenAmounts[index] = nonNegativeValue;
    this.setState({ givenAmounts });
  }
  console.log("after setting textfield", newSelectedAmounts);
  // Clear the card clicked index
  this.setState({ selectedAmounts: newSelectedAmounts});
}


handleGivenAmountChange = (index, value) => {
  const givenAmounts = [...this.state.givenAmounts];
  const newValue = parseFloat(value) || 0;
  givenAmounts[index] = newValue;
  this.setState({ givenAmounts });
};


handleEditClick = (index) => {
  const editBillAmounts = [...this.state.editBillAmounts];
  editBillAmounts[index] = !editBillAmounts[index];
  this.setState({ editBillAmounts });
};




//to open the instant food modal
handletoOpenInstantFood = () => {
  this.setState({ isOpenAddInstant: true});
}


//onchange function for instant food name
handleInstandFoodChange(event) {
  this.setState({
    instantFoodName : event.target.value
  });
}


//onchange function for instant quantity
handleInstandQuantityChange(event) {
  this.setState({
    instantQuantity : event.target.value
  });
}


//onchange function for instant price
handleInstandPriceChange(event) {
  this.setState({
    instantPrice : event.target.value
  });
}


//to adding instant food in cart items
handleToInstantAddingFood = () => {
  const { cartItems, instantFoodName, instantQuantity, instantPrice } = this.state;
  if(instantFoodName && instantQuantity && instantPrice && instantPrice > 0 && instantQuantity > 0){
const existingItemIndex = cartItems.findIndex((item) => item.food.food_name === instantFoodName);

if (existingItemIndex !== -1) {
const updatedCartItems = [...cartItems];
updatedCartItems[existingItemIndex].quantity += instantQuantity;
this.setState({ cartItems: updatedCartItems });
this.setState({ isOpenAddInstant: false, instantFoodName:null, instantQuantity:null, instantPrice:null});
} else {

const food = {
   food_name: instantFoodName,
   price: instantPrice || 1,
   category_code:" ",
   category_id:0,
   cost:0,
   currency:" ",
   id:0,
   image:" ",
   item_code:`instant+${instantFoodName}`,
   uom:" "
}
const cartItemId = Date.now();
this.setState((prevState) => ({
  cartItems: [...prevState.cartItems, {  id: cartItemId,food: food, quantity: instantQuantity }],
}));
this.setState({ isOpenAddInstant: false, instantFoodName:null, instantQuantity:null, instantPrice:null});
 }
console.log("cc",cartItems);
  }else{
    toast('Please Check the values',{autoClose:2000})
  }
};


onCategoryClick = (idx) => {
  this.setState({ selectedCategory: idx });
};


handlePrintCheckboxChange = () => {
  this.setState(prevState => ({
    isPrintNowChecked: !prevState.isPrintNowChecked,
  }));
  console.log("checkbox", this.state.isPrintNowChecked);
};
 


handleOnchangePrintType  = (e) => {
  const selectedValue = e.target.value;
  localStorage.setItem('printingType', selectedValue);
  this.setState({ printingType: selectedValue });
  console.log("print type", localStorage.getItem('printingType'));
};


handleToOpenPrinters = () =>{
  this.setState({ isOpenSelectPrint: true, settingModalOpen: false});
}


handleCloseToPrinters = () =>{
  this.setState({ isOpenSelectPrint: false});
}


handlePrinterChange = (event) => {
  const selectedPrinter = event.target.value;
  this.setState({ selectedPrinter });
  localStorage.setItem('selectedPrinter', selectedPrinter); // Save the selected printer to local storage
};

    // fetchData = async () => {
    //   const data = localStorage.getItem('userId')
    //  try {
    //   const response = await axios.get('http://localhost:4300/api/shop/shopData/'+data );
    //   // Assuming setUsers is a function to update your state with the response data.
    //   const use = response.data
    //   // const use = [response.data.id,response.data.name,response.data.email,response.data.password,response.data.country,response.data.mobile]
    //   console.log(use)
    //   this.users(use);
    // }catch (error) {
    //   console.log('Error fetching data:', error);
      
    //   }
    // };
    
    

    render() {
      const { activeTab,isLogin,iscredit } = this.state;
      const { customers } = this.state;
      const { selectedList } = this.state;
      const { orderNo,showTable } = this.state;
      const { shopName } = this.state;
      const { users } = this.state;
      const { categories } = this.state;
      const { foods } = this.state;
      const { orders } = this.state;
      const { searchQuery } = this.state;
      const { checkedCategories } = this.state;
      const { loading,editPaymodeOrder } = this.state;
      const { isPrinted,ItemCodeOptions,selectedItemCode,orderHistoryItems, selectedCategory } = this.state;

      // console.log("shop Id:",localStorage.getItem('shopId')); 
      // console.log("email Id:",this.state.selectedData.mail); 
      
     // Filter orders based on the search query
    const filteredOrders = orders.filter((order) => {
      const searchLower = searchQuery.toLowerCase();
      return (
        order.customer_name.toLowerCase().includes(searchLower) ||
        order.customer_phone.includes(searchQuery) ||
       ( order.order_no + '').includes(searchQuery) ||
        order.order_items.some((item) =>
          item.food_name.toLowerCase().includes(searchLower)
        )
      );
    });

    // Check if there are no matching results
const noResults = orderHistoryItems.length === 0;

const CartTotal = this.state.cartItems.reduce((accumulator, cartItem) => {
  return accumulator + cartItem.quantity * cartItem.food.price;
}, 0);


// Calculate the total amount from selectedAmounts
const modeTotal = this.state.selectedAmounts.reduce((accumulator, amount) => {
  return accumulator + amount;
}, 0);


const editModeTotal = this.state.editSelectedAmounts && this.state.editSelectedAmounts.reduce((sum, currentItem) => sum + currentItem.amount, 0)
     
const yourData = {
      Breakfast: breakfast,
      Chicken: lunch,
      Starters: shawarma,
      Pizza: pizza,
      Burgers: burger,
      HotBeverage: drinks,
      ColdBeverage: drinks,
      Dinner: dinner,
      Desserts: icecream,
      sandwich: sandwich,
    };

    const lists = yourData;

    const filteredNotifys = Array.isArray(this.state.notifications) && this.state.notifications.length > 0
    ? this.state.notifications.filter((order)=> {
      const searchLower = this.state.notifySearchQuery.toLowerCase();
      return(
          (order.triggered_by_username.name && order.triggered_by_username.name.toString().toLowerCase().includes(searchLower)) ||
          (order.triggered_by_username.phone_no && order.triggered_by_username.phone_no.toString().toLowerCase().includes(searchLower)) || 
          (order.triggered_by_username.email && order.triggered_by_username.email.toString().toLowerCase().includes(searchLower)) ||
          (order.ordered_at && order.ordered_at.toString().toLowerCase().includes(searchLower)) ||
          (order.ordered_time && order.ordered_time.toString().toLowerCase().includes(searchLower))
      )
    })
    : [];


    const filteredFoods = foods && foods.map((food) => {
      const searchLower = this.state.foodSearchQuery.toLowerCase();
      const matchedFoods = food.sub_food.filter((item) => {
        // Check if food name or category name matches the search query
        const foodNameMatch = item.food_name.toLowerCase().includes(searchLower);
        const categoryNameMatch = food.category_name.toLowerCase().includes(searchLower);
        return foodNameMatch || categoryNameMatch;
      });
      return { ...food, sub_food: matchedFoods };
    });

    const hasMatchedItems = filteredFoods.some(food => food.sub_food.length > 0); // Check if any category has matched items
    
    
    // console.log("Filtered Foods:", filteredFoods);
    // console.log("Search Query:", this.state.foodSearchQuery);

   

    
//     const collectionName = 'jaya';
// const documentName = 'twenty'; // The document name you want to find

// // Create a query to search for the document by its name
// const q = query(collection(db, collectionName));

// // Execute the query and get the matching documents
// getDocs(q)
//   .then((querySnapshot) => {
//     let foundDocumentId = null;
//     querySnapshot.forEach((docSnapshot) => {
//       const documentData = docSnapshot.data();
//       if (documentData && docSnapshot.id === documentName) {
//         // Document with the given name found
//         foundDocumentId = docSnapshot.id;
//         console.log('Document ID:', foundDocumentId);
//         console.log('Document Data:', documentData);
//       }
//     });

//     if (foundDocumentId === null) {
//       console.log('No document found with the given name.');
//     }
//   })
//   .catch((error) => {
//     console.error('Error fetching documents:', error);
//   });




  return (
    
    <>
     <Row>
    
     <Col  xs="3" md="4" lg="4" xl="4">
     <div style={{display: 'flex', alignItems: 'center', padding: '5px', marginLeft: '5px', marginTop: '5px', borderRadius: '5px', position: 'fixed', zIndex: '2'}}>
     {
    this.state.subscriptions  && this.state.subscriptions.is_tableorder === 0 ? (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleNav} className="custom-dropdown">
  <DropdownToggle className='wine'> <i class="bi bi-list-stars" style={{fontSize:"23px"}}></i> </DropdownToggle>
  <DropdownMenu  className="scrollable-menu">
         <DropdownItem className="dropItem" onClick={this.routeMysubscription}>
           MY SUBSCRIPTION
          </DropdownItem>
          <DropdownItem className="dropItem" onClick={this.toggleMastersMenu}>MASTERS
          {this.state.isMastersMenuOpen ? <i class="bi bi-caret-up-fill" style={{marginLeft:"7px"}}></i> : <i class="bi bi-caret-down-fill" style={{marginLeft:"7px"}}></i>} </DropdownItem> 
          
          {this.state.isMastersMenuOpen && (
           
             <>
              {/* <DropdownItem className="dropItem" onClick={this.routeShop}>
                SHOP MASTER
              </DropdownItem> */}
              <DropdownItem className="dropItem" onClick={this.routeUom}>
                UOM MASTER
              </DropdownItem>
              <DropdownItem className="dropItem" onClick={this.routeChange}>
                CATEGORY MASTER
              </DropdownItem>
              <DropdownItem className="dropItem" onClick={this.routeTablemaster}>
                TABLE MASTER
              </DropdownItem>
              <DropdownItem className="dropItem" onClick={this.routeSubKitchenmaster}>
                SUB KITCHEN MASTER
              </DropdownItem>
              <DropdownItem className="dropItem" onClick={this.routeEmployee}>
                EMPLOYEE MASTER
              </DropdownItem>
              <DropdownItem className="dropItem" onClick={this.routeSupplier}>
                SUPPLIER MASTER
              </DropdownItem>
              <DropdownItem className="dropItem" onClick={this.routeRawMaterial}>
                RAW MATERIAL MASTER
              </DropdownItem>
              <DropdownItem className="dropItem" onClick={this.routePaymodeStatusMaster}>
                PAYMODE STATUS MASTER
              </DropdownItem>
              <DropdownItem className="dropItem" onClick={this.routeGLAccounts}>
                GL ACCOUNTS MASTER
              </DropdownItem>
             </>
          )}

          
          <DropdownItem divider style={{ borderColor: 'white' }} />
          
          
          {/* <DropdownItem className="dropItem" onClick={this.routeTable}>
          TABLE ORDERS
          </DropdownItem> */}
          <DropdownItem className="dropItem" onClick={this.routeChance}>
          KITCHEN
          </DropdownItem>
          <DropdownItem className="dropItem" onClick={this.routeSubChance}>
          SUB KITCHEN
          </DropdownItem>
          <DropdownItem className="dropItem" onClick={this.routeParcelStatus}>
          PARCEL STATUS
          </DropdownItem>
            <DropdownItem className="dropItem" onClick={this.routeOrderhistory}>
                ORDER HISTORY
            </DropdownItem>
          <DropdownItem className="dropItem" onClick={this.routeCredit}>
          CREDIT CUSTOMERS
          </DropdownItem>
          <DropdownItem className="dropItem" onClick={this.routeOutstandingpayment}>
                OUTSTANDING PAYMENTS
            </DropdownItem>
            <DropdownItem className="dropItem" onClick={this.routeItemSummary}>
                ITEM & PAYMODE SUMMARY
            </DropdownItem>
            <DropdownItem className="dropItem" onClick={this.routeSettlements}>
                EXPENSE LOG
            </DropdownItem>
            <DropdownItem className="dropItem" onClick={this.routenNewSettlements}>
                SETTLEMENTS
            </DropdownItem>
            <DropdownItem className="dropItem" onClick={this.routeGroupOrders}>
          GROUP LOCATION ORDERS
          </DropdownItem>
          <DropdownItem className="dropItem" onClick={this.routeDaily}>
          DAILY INVENTORY
          </DropdownItem>
          <DropdownItem className="dropItem" onClick={this.routeVoid}>
          VOID TRANSACTION
          </DropdownItem>
          <DropdownItem className="dropItem" onClick={this.routePurchaseOrders}>
            PURCHASE ORDERS
          </DropdownItem>
          <DropdownItem className="dropItem" onClick={this.routeStoreGrn}>
            STORE GRN
          </DropdownItem>
          <DropdownItem className="dropItem" onClick={this.routeStockTake}>
            STOCK TAKE
          </DropdownItem>
          <DropdownItem className="dropItem" onClick={this.routeStockAdjustments}>
            STOCK ADJUSTMENTS
          </DropdownItem>
          <DropdownItem className="dropItem" onClick={this.routeStockTransaction}>
            STOCK TRANSACTION
          </DropdownItem>
          <DropdownItem className="dropItem" onClick={this.routeDayClosing}>
            DAY CLOSING
          </DropdownItem>
          <DropdownItem className="dropItem" onClick={this.routeGLFinanceSummary}>
            GL FINANCE SUMMARY
          </DropdownItem>
          <DropdownItem className="dropItem" onClick={this.routeStockBalanceReport}>
            STOCK BALANCE REPORT
          </DropdownItem>
          <DropdownItem className="dropItem" onClick={this.routepandLReport}>
            P & L REPORT
          </DropdownItem>
         
          
          {/* <DropdownItem className="dropItem" onClick={this.routeContract}>
          CONTRACT FOODS
          </DropdownItem> */}
          

         
            
          
         
        </DropdownMenu>
      </Dropdown>
    ) : (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleNav} className="custom-dropdown">
  <DropdownToggle className='wine'> <i class="bi bi-list-stars" style={{fontSize:"23px"}}></i> </DropdownToggle>
  <DropdownMenu>
         <DropdownItem className="dropItem" onClick={this.routeMysubscription}>
           MY SUBSCRIPTION
          </DropdownItem>
          <DropdownItem className="dropItem" onClick={this.toggleMastersMenu}>MASTERS
          {this.state.isMastersMenuOpen ? <i class="bi bi-caret-up-fill" style={{marginLeft:"7px"}}></i> : <i class="bi bi-caret-down-fill" style={{marginLeft:"7px"}}></i>} </DropdownItem> 
          
          {this.state.isMastersMenuOpen && (
           
             <>
              <DropdownItem className="dropItem" onClick={this.routeUom}>
                UOM MASTER
              </DropdownItem>
              <DropdownItem className="dropItem" onClick={this.routeChange}>
                CATEGORY MASTER
              </DropdownItem>
              <DropdownItem className="dropItem" onClick={this.routeTablemaster}>
                TABLE MASTER
              </DropdownItem>
              <DropdownItem className="dropItem" onClick={this.routeEmployee}>
                EMPLOYEE MASTER
              </DropdownItem>
              <DropdownItem className="dropItem" onClick={this.routePaymodeStatusMaster}>
                PAYMODE STATUS MASTER
              </DropdownItem>
             </>
          )}

            <DropdownItem className="dropItem" onClick={this.routeOrderhistory}>
                ORDER HISTORY
            </DropdownItem>
            <DropdownItem className="dropItem" onClick={this.routeItemSummary}>
                ITEM & PAYMODE SUMMARY
            </DropdownItem>

          
          <DropdownItem divider style={{ borderColor: 'white' }} />
          <DropdownItem header style={{ backgroundColor:"white", color: 'red', fontSize: '16px', fontWeight: 'bold', paddingLeft: '15px' }}>
          <i class="bi bi-lightning-fill" style={{color:"yellow"}}></i>Premium Features
    </DropdownItem>
          <DropdownItem style={{ color: 'green', backgroundColor:"white", fontSize: '15px', paddingLeft: '15px' }} onClick={this.handleOpenPremiumAlert}>
          KITCHEN
          </DropdownItem>
          <DropdownItem style={{ color: 'green', backgroundColor:"white", fontSize: '15px', paddingLeft: '15px' }} onClick={this.handleOpenPremiumAlert}>
          SUB KITCHEN
          </DropdownItem>
          <DropdownItem style={{ color: 'green', backgroundColor:"white", fontSize: '15px', paddingLeft: '15px' }} onClick={this.handleOpenPremiumAlert}>
          PARCEL STATUS
          </DropdownItem>
          <DropdownItem style={{ color: 'green', backgroundColor:"white", fontSize: '15px', paddingLeft: '15px' }} onClick={this.handleOpenPremiumAlert}>
          CREDIT CUSTOMERS
          </DropdownItem>
          <DropdownItem style={{ color: 'green', backgroundColor:"white", fontSize: '15px', paddingLeft: '15px' }} onClick={this.handleOpenPremiumAlert}>
          GROUP LOCATION ORDERS
          </DropdownItem>
          <DropdownItem style={{ color: 'green', backgroundColor:"white", fontSize: '15px', paddingLeft: '15px' }} onClick={this.handleOpenPremiumAlert}>
                OUTSTANDING PAYMENTS
            </DropdownItem>
            <DropdownItem style={{ color: 'green', backgroundColor:"white", fontSize: '15px', paddingLeft: '15px' }} onClick={this.handleOpenPremiumAlert}>
                EXPENSE LOG
            </DropdownItem>
            <DropdownItem style={{ color: 'green', backgroundColor:"white", fontSize: '15px', paddingLeft: '15px' }} onClick={this.handleOpenPremiumAlert}>
                SETTLEMENTS
            </DropdownItem>

          <DropdownItem style={{ color: 'green', backgroundColor:"white", fontSize: '15px', paddingLeft: '15px' }} onClick={this.handleOpenPremiumAlert}>
          DAILY INVENTORY
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    )
  }
   </div>
     </Col>

     <Col  xs="3" md="4" lg="4" xl="4" className="d-flex justify-content-center" >
     {/* <div id="fix" style={{position:"fixed", left: "43%", transform: "translateX(-50%)" }}> */}
     <div id="fix">
    <h1 class="banner" style={{textTransform:"uppercase", fontSize: "clamp(6px, 4vw, 40px)" }}><div class="glow">&nbsp;</div>{ shopName }</h1>
   </div>
     </Col>

     <Col  xs="3" md="4" lg="4" xl="4" className="d-flex justify-content-end" >
     <div style={{display: 'flex', alignItems: 'center', padding: '10px 25px', gap:"10px"}}>
    
  <Tooltip
          isOpen={this.state.notifytooltipOpen}
          target="notificationButton" // Specify the target ID of the button
          toggle={this.toggleNotifyTooltip}
        >
          View Notifications
        </Tooltip>
        <Tooltip
          isOpen={this.state.settingTooltipOpen}
          target="SettingButton" // Specify the target ID of the button
          toggle={this.toggleSettingsTooltip}
        >
          setting print server
        </Tooltip>
        <button  className="btn btn-outline-success"  onClick={this.routeTable}><i class="bi bi-ui-radios-grid"></i></button>

  <button id="notificationButton" className="btn btn-outline-success" onClick={() => {this.handleOpenNotifications(); this.toggleNotifyTooltip(); }}><i class="bi bi-bell-fill"></i></button>
  <button id="SettingButton" className="btn btn-outline-success" onClick={() => {this.handleOpenSettingPrintServer(); this.toggleSettingsTooltip(); }}><i class="bi bi-gear"></i></button>
  <button className="btn btn-outline-success"  onClick={this.handleOnclick}>Logout</button>
  </div>
     </Col>
     </Row>

      
 
    
 
      {/* <div className="form-check form-switch" style={{ marginLeft: "30px" }}>
  <label className="form-check-label" htmlFor="flexSwitchCheckDefault" style={{ float: 'left', marginRight: '40px', textTransform:"uppercase",fontWeight:"bolder",color:"green" }}>Image Status</label>
  <input 
   className="form-check-input"
   type="checkbox"
   role="switch"
   id="flexSwitchCheckDefault"
   style={{ backgroundColor: 'green', borderColor: 'green', float: 'right' }}
   checked={this.state.imageStatus}
   onChange={this.handleSwitchToggle} />
</div> */}
 
 

  {/* <Tooltip
          isOpen={this.state.tooltipOpen}
          target="dropdownToggle" // Specify the target ID of the toggle
          toggle={this.toggleTooltip}
        >
           Shop List
        </Tooltip> */}

  {/* <Dropdown isOpen={this.state.shadropdownOpen} toggle={this.toggleChange} className="custom-dropdown">
  <DropdownToggle className='wine'  id="dropdownToggle" style={{marginLeft:"1290px",backgroundColor:"white",border:"none",position:"fixed"}} onClick={this.toggleTooltip}><i class="bi bi-shop-window" style={{fontSize:"35px",color:"green"}} ></i></DropdownToggle>
  
  <DropdownMenu >
  {users.map(user=>(
    <DropdownItem key={user.id} className="dropItem" onClick={() => this.handleNameSelection(user)}>{user.shop_name}</DropdownItem>
    ))}
  </DropdownMenu>
 
  </Dropdown> */}

 

    {/* <div id='header' style={{backgroundColor:"rgba(10, 241, 33, 0.133)"}}>
      <nav>
        <ul id="capt">
          <li id="first">BILLS</li>
          <li id="second">
          <button style={{border:"none",backgroundColor:"rgba(10, 241, 33, 0.133)",textShadow: '2px 2px 2px black'}}
              
              onClick={this.routeChance}
            >
             KITCHEN
              </button>
          </li>
          <li id='third'>
          <button style={{border:"none",backgroundColor:"green",textShadow: '2px 2px 2px black'}}
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}
            >
             CATEGORIES
              </button>
          </li>
          <li id="four">
          <button style={{border:"none",backgroundColor:"green",textShadow: '2px 2px 2px black'}}
             onClick={this.routeChange}>
             MASTER
              </button>
          </li>
          <li id="five">
          <button style={{border:"none",backgroundColor:"green",textShadow: '2px 2px 2px black'}}
             onClick={this.routeDay}>
             DELIVERY
              </button>
          </li>
        </ul>
      </nav>
    </div> */}



  
    <div id='left-side' style={{zIndex:"1"}}>

    <br/>
   <h4 id="hefo"><b>{this.state.isChecked ? "Normal Order Cart" : "Credit Order Cart"}</b></h4>
 
    <button className={!this.state.isChecked ? "btn btn-secondary" : "btn btn-success"} onClick={() => this.setState({ isChecked: true})}>Normal Order</button> 
         <button className={this.state.isChecked ? "btn btn-secondary" : "btn btn-success" } onClick={() => this.setState({ isChecked: false})}>Credit Order</button>

       <div id="leftcont">
         <div style={{backgroundColor:"green",padding:"10px"}}>
          {/* <select hidden={this.state.isChecked}  name="selectedCreditCustomer" id="selectedCreditCustomer">
         {this.state.mobileInput.map((user)=>(
          
          <option value={JSON.stringify(user)} >{user.name} {user.phone_no}</option>

        
         ))}
         </select> 
          
          <Input hidden={!this.state.isChecked} type="text" id="name" placeholder="Name" required />
        <Input hidden={!this.state.isChecked} type="number" id="mobile" placeholder="Mobile No" maxLength="10" style={{marginTop:"2px"}} required/>
         */}

{this.state.isChecked ? (
  <div style={{ display: 'flex', alignItems: 'center', gap:"4px" }}>
  <Input hidden={!this.state.isChecked} type="text" id="name" placeholder="Name" required />
   <Input hidden={!this.state.isChecked} type="number" id="mobile" placeholder="Mobile No" maxLength="10" style={{marginTop:"2px"}} required/>
 </div>
 
) : (
  <SearchableDropdown onSelect={this.handleDropdownChange} selectedValue={this.state.selectedData}/>
  
)}
   <Select
    options={ItemCodeOptions}
    placeholder="Select Item Code"
    value={selectedItemCode}
    onChange={this.handleCodeSelectChange}
    isSearchable={true}
    className="customSelect"
  />  <span><button className="btn btn-primary" onClick={this.handleAddByItemCode} style={{float:"right",marginTop:"-38px"}}>Add</button></span> 

{this.state.selectedData.iden  && !this.state.isChecked &&
  <h6 style={{paddingTop:"5px", color:"white"}}>maximum Credit limit: {" "}{this.state.selectedData && this.state.selectedData.maxCredit && this.state.selectedData.maxCredit.toFixed(3)} <br/><span>Outstanding Payment : {this.state.amountDetail && this.state.amountDetail.balance && this.state.amountDetail.balance.toFixed(3)} 
  </span></h6>}
  <h6 style={{paddingTop:"5px", color:"white"}}>No.of Items: {" "}{this.state.cartItems.length} 
  <span>
  <div class="form-check form-check-inline" style={{float:"right",margin:"0px 5px 0px 5px"}}>
  <input
          className="form-check-input"
          type="checkbox"
          id="inlineCheckbox1"
          value="option1"
          // Set checked attribute based on state
          checked={this.state.isParcelChecked}
          // Call handleCheckboxChange function on change event
          onChange={this.handleCheckboxChange}
        />
                  <label class="form-check-label text-light" for="inlineCheckbox1">Parcel</label>
                </div>
  </span>
  {/* <span style={{marginLeft:"5px"}} ><button className="btn btn-warning" style={{borderRadius:"15px",padding:"1px 5px 1px 5px",fontSize:"15px"}} onClick={this.handletoOpenInstantFood}><i class="bi bi-plus-circle-fill"></i>{" "}Instant Food</button></span> */}
  <span style={{ marginLeft: "5px" }}>
  <button 
    className="btn" 
    style={{ 
      borderRadius: "15px", 
      padding: "1px 5px", 
      fontSize: "15px", 
      backgroundColor: "orange", // Set background color to orange
      color: "white", // Set text color (optional)
      border: "none" // Remove default border if needed
    }} 
    onClick={this.handletoOpenInstantFood}
  >
    <i className="bi bi-plus-circle-fill"></i>{" "}Instant Food
  </button>
</span>

  </h6>
  
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
  <span style={{fontSize:"22px",color:"white"}}>Total:{CartTotal.toFixed(1)}</span>
  {this.state.isChecked}
  
  {isLogin ? (
    <div className="text-center">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : this.state.isChecked ? (
    <button onClick={this.handleGenerateOrderModal} className="btn btn-primary btn-sm" style={{ marginRight: '10px', fontSize: '14px' }}>Place Order</button>
  ) : (
    <button onClick={this.handleGenerateNormalOrderModal} className="btn btn-primary btn-sm"  // Added btn-sm class
    style={{ padding: '5px 10px', fontSize: '14px' }}>Normal Order</button>
  )}

  {!this.state.isChecked && (
    iscredit ? (
      <div className="text-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    ) : (
      <button disabled={this.state.isChecked} className="btn btn-danger btn-sm"  // Added btn-sm class
      style={{ padding: '5px 10px', fontSize: '14px' }} onClick={this.handleGeneratecreditOrderModal}>Credit Order</button>
    )
  )}
</div>

  
{/* {this.state.selectedData && (
          <div style={{color:"white"}}>
            Mobile.No: {this.state.selectedData.value}
          </div>
        )} */}
           
         </div>
         <div id="kbox">
         
         {this.state.cartItems.map((cartItem) => (
           <div id="box1" >
           
             <table>
             
              <tbody key={cartItem.id}>

               <tr>
             
                 <td style={{color:"green",textShadow:"1px 1px 1px black",fontSize:"17px"}}>
                 {cartItem.food && cartItem.food.food_name ? cartItem.food.food_name : ''}
                 </td>  
                 {/* <i class="bi bi-x-circle-fill" onClick={() => this.removeFromCart(cartItem.id)} style={{marginLeft:"100px",float:"right"}}></i> */}

                 <td style={{fontSize:"15px",textAlign:"right",width:"70%",marginLeft:"80px",float:"right",marginTop:"15px"}}>
                 {/* {
                  cartItem.quantity === 1 ? <span style={{color:"grey",fontSize:"18px",padding:"5px"}}><i class="bi bi-dash-circle-fill"></i></span> : <span onClick={() => this.decrementQuantity(cartItem.id)} style={{color:"red",fontSize:"18px",padding:"5px"}}><i class="bi bi-dash-circle-fill"></i></span>
                 } */}

                <span onClick={() => this.decrementQuantity(cartItem.id)} style={{color:"red",fontSize:"18px",padding:"5px"}}><i class="bi bi-dash-circle-fill"></i></span>
                 
                 {cartItem.quantity}
                 <span onClick={() => this.incrementQuantity(cartItem.id)} style={{color:"green",fontSize:"18px",padding:"5px"}}><i class="bi bi-plus-circle-fill"></i></span>
                 </td>
                
               </tr>
               <tr>
                 <td colSpan='2'>price: <span style={{color:"green",fontSize:"15px"}}>
                 {cartItem.food ? ` ${cartItem.food.price}  ${cartItem.food.currency} ` : ''} 
                  </span> <span>{" "}Total Price: <b>{(cartItem.quantity * cartItem.food.price).toFixed(3)} {cartItem.food.currency}</b> </span></td>
               </tr>
                
              </tbody>
            
             </table>
               
           </div>
            ))}
           <br/>
          

         </div>
         {/* {this.state.isChecked}
         <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: !this.state.isChecked ? '10px' : '0'  }}>
   
       
        {isLogin ? (
          <div class="text-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        ) :  this.state.isChecked ? <button onClick={this.handleGenerateOrderModal} className="btn btn-success" style={{ marginRight: '10px' }}>Place Order</button> : (<button onClick={this.handleGenerateNormalOrderModal} className="btn btn-success" style={{ marginRight: '10px' }}>Normal Order</button>) }
        
    
    {!this.state.isChecked && (
        
          iscredit ? (
          <div class="text-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        ) : <button disabled={this.state.isChecked} className="btn btn-danger" onClick={this.handleGeneratecreditOrderModal}>Credit Order</button>
            
        
    )}
</div> */}

       
          
       </div>
       {/* <table style={{marginTop:"-60px"}}>
         <tr >
           <td>Item No</td>
           <td style={{fontSize:"20px",color:"green",position:"absolute",left:"230px"}}></td>
         </tr>
         <tr >
           <td>Total</td>
           <td style={{fontSize:"19px",color:"green",position:"absolute",left:"200px"}}></td>
         </tr>
       </table>
       <br/><br/> */}
      
    
    {/* <br/>
   
      <h5>Orders</h5>
     {customers.map((customer) =>(
        <React.Fragment key={customer.id}>
          <div id="leftcont">
            <div style={{backgroundColor:"green",padding:"5px"}}>
              <h3 style={{color:"white",textAlign:"left",textShadow:"2px 2px 2px black",height:"50px"}}>{customer.data.name}<span style={{fontSize:"11px"}}>{customer.data.phone}</span></h3>
            </div>
            {Object.values(customer.data).map((fieldValue) => {
               if (Array.isArray(fieldValue)) {
                return (
            <div id="kbox" key={fieldValue}>
              {fieldValue.map((item, index) => (
              <div id="box1" >
               
                <table>
                  <tr key={index}>
                    <td style={{color:"green",textShadow:"1px 1px 1px black",fontSize:"17px"}}>{item.item_name} {" "} </td>
                    <td style={{fontSize:"15px"}}>{" "} - {item.quantity}</td>
                  </tr>
                  <tr>
                    <td>unit price: <span style={{color:"green",fontSize:"15px"}}>{' '}INR. {item.price}</span></td>
                  </tr>
                </table>
                
              </div>
               ))}
              <br/>
            </div>
              );
            }
            return null;
           })}
          </div>
          <table style={{marginTop:"-60px"}}>
            <tr >
              <td>Item No</td>
              <td style={{fontSize:"20px",color:"green",position:"absolute",left:"230px"}}>{customer.data.item_no}</td>
            </tr>
            <tr >
              <td>Total</td>
              <td style={{fontSize:"19px",color:"green",position:"absolute",left:"200px"}}>INR. {customer.data.total}</td>
            </tr>
          </table>
          <br/><br/>
          <button id="press" onClick={this.handleGenerateNo}>Generate no</button>
        </React.Fragment>
      ))
     } */}
     </div>







  
    <div id='main-side'>
    <TabContent activeTab={this.state.activeTab}>
    <TabPane tabId="2">
            <Row>
              <Col md="12">
                <div style={{width:"50%",margin:"auto"}}>
                <ul style={{textAlign:"center"}}>
                {categories.map((category) => (
                  <li key={category.id} className="listing" style={{border:"1px solid green",padding:"5px",marginTop:"5px",fontWeight:"bold",listStyleType:"none",textTransform:"capitalize"}}>
                    <a href='#' className="linking" onClick={() => this.handleListClick(category.id)}>{category.category_name}
                    <span style={{fontSize:"8px",letterSpacing:"1px"}}>({category.category_code})</span>
                    </a></li>
                  ))}
                </ul>
                </div>
              </Col>
            </Row>
          </TabPane>
      </TabContent>
      {/* <input class="form-control" type="Search" placeholder="Quick Food Search" id="foodhomesearch" onChange={this.handleFoodSearchInput}/> */}
    <section className="food-area my-0">
      <div className="container" style={{maxWidth:"1100px",padding:"10px"}}>
      {loading ? (
        <div className="fooder-container">
      	  <div className="fooddinner"></div>
        </div>
      ) : (
      
        hasMatchedItems ? (
          // filteredFoods.map((food) => (
            <>
           {/* {food.sub_food.length > 0 && ( // Render category name only if it has matched items
        <h5 style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>{food.category_name}</h5>
            )} */}

<div className="d-flex justify-content-center">
          <Row xs={1} md={2} lg={2} xl={3} xxl={5} className={`g-5 ${
      filteredFoods.length <= 3 ? "few-cards" : ""
    }`}>
            {filteredFoods.map((category, idx) => (
              <Col key={category.id}  className={`custom-col ${filteredFoods.length <= 3 ? "few-cards-col" : ""} custom-margin`}>
                <Card
                  style={{
                    maxWidth: "12rem",
                    height: "auto",
                    border: "2px solid black",
                    backgroundColor:
                      selectedCategory === idx ? "green" : "white", // Check if the category is selected
                    padding: "0px",
                    borderRadius: "0px",
                    cursor: "pointer"
                  }}
                  onClick={() => this.onCategoryClick(idx)} // Set selected category index
                >
                  <Card.Body>
                    <Card.Title
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                        padding: "0px",
                        margin: "0px",
                        color: selectedCategory === idx ? "white" : "black" // Check if the category is selected
                      }}
                    >
                      {category.category_name.toUpperCase()}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
        <br/>
        <div className="row" id="mafix" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', gap: '25px' }}>
        {/* {food.sub_food.map((item, index) => ( */}
        {(this.state.selectedCategory !== null &&
    filteredFoods[this.state.selectedCategory] &&
    filteredFoods[this.state.selectedCategory].sub_food &&
    filteredFoods[this.state.selectedCategory].sub_food.map((item, index) => (
        
        <div className="col mb-1 mt-1 ftco-animate" key={index} style={{ flex: '0 0 auto'}}>
            {/* <div className="card" id="effe" style={{ width: '12rem', height:"auto", padding:"10px", marginTop:"6px",
             backgroundColor: this.state.addedItems.includes(item.id) ? 'rgba(10, 241, 33, 0.133)' : 'white'
            }} onClick={() => !this.state.addedItems.includes(item.id) && this.addToCart(item)}> */}
             <div className="card" id="effe" style={{ width: '12rem', height:"auto", padding:"10px", marginTop:"6px",
             backgroundColor: this.state.addedItems.includes(item.id) ? 'rgba(10, 241, 33, 0.133)' : 'white'
            }} onClick={() => this.addToCart(item)}>
              {/* {this.state.imageStatus ? <img src={`${var_api}/${item.image}`} alt='image' height="8rem" style={{ height:"8rem", marginTop:"1px" }} /> : null} */}
                <div style={{textAlign:"center", padding:"10px"}}>
                    <h6 className="card-title" style={{color:"green", textTransform:"capitalize", fontSize:"18px"}}>{item.food_name}</h6>
                    {/* <p style={{textAlign:"center", fontSize:"13px", margin:"3px 8px"}}>UOM: <b> {item.uom}</b> </p> */}
                    <h6  style={{color:"green",textAlign:"center",fontSize:"20px",fontWeight:"bolder"}}> {item.price} {" "} {item.currency} </h6>
                    {/* {
                      item.is_active === 1 ?  <button className="btn" style={{backgroundColor:"green", color:"white",padding:"5px"}} onClick={() => this.addToCart(item)}><i className="bi bi-cart3"></i> Add to Cart</button> : 
                      <span className="badge rounded-pill bg-danger p-2" style={{fontWeight:"normal",fontSize:"15px"}}><i class="bi bi-exclamation-triangle"></i>{" "}Not Available</span>
                    } */}
                   
                </div>
            </div>
        </div>
           )))}
         {/* ))} */}
   
</div>
</>
//  ))
) : (
  <div className="text-center" style={{color:"red",fontWeight:"bolder"}}>
  <i class="bi bi-exclamation-triangle"></i> No Foods Available
</div>
 ))}
       </div>
      
    </section>

       {foods.length === 0 && checkedCategories && (
        
    <div className="container" style={{ textAlign: "center", marginTop: "20px" }}>
      <div className="col mb-1 mt-1 ftco-animate" >
        <div className="card" style={{ width: "12rem",height:"20rem",padding:"10px",marginBottom:"-27px",float:"left",boxShadow:"inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3)"}}>
          <h5 onClick={this.handleAddCard} style={{color:"green",padding:"15px",fontFamily:" 'DM Serif Display', serif",marginTop:"93px"}}> <i className="bi bi-plus-circle"></i> Add Categories</h5>
        </div>
      </div>
    </div>
  )}
    </div>


    
    <div
    //  id='right-side'
     >

<div >
    
<Button 
  variant="light" 
  style={{
    border: "2px solid green",
    borderRadius: "50px",
    position: "fixed", // Set position to fixed
    right: "20px", // Adjust right position as needed
    bottom: "20px", // Adjust bottom position as needed
    zIndex: "999", // Set a high z-index to ensure the button is above other content
    margin: "10px", // Add margin as needed
  }} 
  onClick={this.handleShowServices}
>
  <i className="bi bi-stack fs-4" style={{ color: "green" }}></i>
  <span className="badge rounded-circle bg-dark" style={{ position: "absolute", top: "-5px", right: "-5px" }}>{orderHistoryItems.length}</span>
</Button>

      
      <Offcanvas show={this.state.showService} onHide={this.handleCloseServices} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{textAlign:"center"}}>TODAY ORDERS
          <div 
        // id="hinFood"
        >
   <h3 style={{color:"green",textShadow:"2px 2px 2px black",fontWeight:"bolder",letterSpacing:"2px"}} id="hefo" ></h3>
   {/* <div style={{ display: 'flex', alignItems: 'center', gap:"5px" }}>
   
</div> */}
   
   <div style={{ display: 'flex', alignItems: 'center', gap:"10px" }}>
    {/* <label style={{fontFamily:"serif",fontWeight:"bolder"}}>Type: 
    </label> */}
    <Select
        options={this.state.labelCodeOptions}
        placeholder="Select Order"
        value={this.state.selectedOrderLabel}
        onChange={this.handleOrderLabelSelectChange}
        isSearchable={true}
        menuPlacement="top"
        className="customSelectFood"
        styles={{ menu: (provided, state) => ({
          ...provided,
          zIndex: 9999
      }) }}
       
    />
     <button className="btn btn-danger text-white p-1" style={{fontSize:"15px",margin:"auto"}}  onClick={this.handleOpenOrderSumModal}>Summary</button>
   
</div>
   </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        
   <br/>


        <div id="sss">
      {
        this.state.orderLoading ? (
          <div class="text-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        ) : (
          noResults ? (
            <p style={{textAlign:"center",fontWeight:"bolder"}}>Today No Orders</p>
          ) : (
            orderHistoryItems.slice().reverse().map((order, index) => (
              <div key={index} style={{ marginTop: "-10px" }}>
                  <div id={`billContent${index}`}>
                  <Bill order={order} shopDetails={this.state.shopDetails} ref={(el) => (this[`componentRef${index}`] = el)} />
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                      {
                          this.state.subscriptions && this.state.subscriptions.is_tableorder === 0 &&
                          <>
                              {/* <Tooltip
                                  isOpen={this.state.printTooltipOpen[index]} // Use tooltip state based on index
                                  target={`printButton${index}`} // Specify the target ID of the button
                                  toggle={() => this.togglePrintTooltip(index)}
                              >
                                 Default Print
                              </Tooltip> */}
                              <div style={{ textAlign: 'center' }}>
                              
                              {/* <button
          className='btn-md btn-primary'
          id='printButton'
          style={{ borderRadius: '5px', border: 'none', padding: '7px', margin: '5px', padding: '0px auto' }}
          onClick={this.handlePrints}
        >
          <i className='bi bi-printer-fill'></i> Print
        </button> */}
        {
         this.state.printLoader[index] ? (
            <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          ) : this.state.printingType == "directPrint" ?   
          <button className='btn-md btn-warning'  style={{ borderRadius: '5px', border: 'none', padding: '7px', margin: '5px', padding: '0px auto' }} onClick={()=>this.handlePrints(index,order.id)}><i className='bi bi-printer-fill'></i></button> : (
            <ReactToPrint
                  trigger={() => (
                    <div style={{ textAlign: 'center' }}>
                        <button className='btn-md btn-primary' id={`printButton${index}`} style={{ borderRadius: "5px", border: "none", padding: "7px", margin:"5px", padding:"0px auto" }} onClick={() => this.togglePrintTooltip(index)}><i className="bi bi-printer-fill"></i></button>
                    </div>
                )}
                  content={() => this[`componentRef${index}`]}
                  onAfterPrint={() => this.handlePrint(order.id)}
                />
          )
        }
        
        
                              </div>
                          
                {/* <ReactToPrint
                  trigger={() => (
                    <div style={{ textAlign: 'center' }}>
                        <button className='btn-md btn-primary' id={`printButton${index}`} style={{ borderRadius: "5px", border: "none", padding: "7px", margin:"5px", padding:"0px auto" }} onClick={() => this.togglePrintTooltip(index)}><i className="bi bi-printer-fill"></i></button>
                    </div>
                )}
                  content={() => this[`componentRef${index}`]}
                  onAfterPrint={() => this.handlePrint(order.id)}
                /> */}
                    </>
               }
                
                {/* <hr style={{border:"1px dashed grey",position:"relative",bottom:"110px"}}/> */}
                {/* <button className="btn-md btn-success" style={{marginRight:"4px",borderRadius:"5px",border:"none",padding:"7px"}} 
                onClick={() => this.handlePaidStatus(order.customer_id,order.id)} disabled={order.paid_status === 1}
                >{ order.paid_status === 1 ? "Paid" : "Pay" }</button>
                <button className={`btn-md btn-danger ${order.paid_status === 2 ? 'smallText' : '' }`} style={{marginRight:"4px",borderRadius:"5px",border:"none",padding:"7px"}}
                onClick={() => this.handleCancelStatus(order.customer_id,order.id)} disabled={order.paid_status === 2}
                >{ order.paid_status === 2 ? "Cancelled" : "Cancel" }</button> */}
    
               {/* { order.parcel_status === 0 &&
                <button className="btn-md btn-warning" onClick={()=> this.updateParcelInfo(order.customer_id,order.id)} style={{marginTop:"10px",borderRadius:"5px",border:"none",padding:"7px"}}>Parcel</button>
               } */}
              {
               (order.pay_modes.some(mode => mode.amount > 0 && (mode.pay_mode_name === "CREDIT ORDER" || mode.pay_mode_name === "GROUP LOCATION CREDIT ORDER"))) ? null :
                <div style={{ textAlign: 'center' }}>
                   <Tooltip
        isOpen={this.state.puttooltipOpen[index]} // Use tooltip state based on index
        target={`putButton${index}`} // Specify the target ID of the button
        toggle={() => this.togglePutTooltip(index)}
      >
          Edit Paymode
        </Tooltip>
                <button className="btn btn-primary" id={`putButton${index}`} style={{borderRadius: "5px", border: "none", margin:"5px", padding:"0px 6px" }} onClick={() => {this.handleOpenEditPayModal(order); this.togglePutTooltip(index); }}><i class="bi bi-pencil-square" style={{fontSize:"14px"}}></i></button>
                <button className='btn-md btn-danger'  style={{ borderRadius: '5px', border: 'none', padding: '7px', margin: '5px', padding: '0px auto' }} onClick={()=>this.handleVoidStatus(order)}><i class="bi bi-trash"></i></button>
                </div>
              }
               </div>
               <br/>
               
                </div>
            
            ))
            )

        )

      }
    
        
       
    </div>
        </Offcanvas.Body>
      </Offcanvas>
   
{/* </div> */}
   </div>
   
   
      
    {/* {showTable && ( */}
    {/* <div id="sss">
    {orders.map((order, index) => (
      <div key={index} style={{backgroundColor:"rgba(63, 68, 63, 0.082)",padding:"0px 10px"}}>
      <hr style={{border:"1px dashed grey",position:"relative",top:"123px"}}/>
        <h4 style={{textAlign:"center"}}>order no: <span style={{color:"green",fontSize:"20px"}}>{order.order_no}</span></h4>
 
        <table>
     
      <caption style={{captionSide:"top",fontSize:"13px",fontWeight:"bolder"}}>Name: <span style={{color:"green",fontSize:"16px",textTransform:"capitalize"}}>{order.customer_name}</span><br/>Phone No: <span style={{color:"green",fontSize:"16px"}}>{order.customer_phone}</span>
      </caption>
        <thead>
          <th style={{padding:"5px",color:"black"}}>item</th>
          <th style={{padding:"5px",color:"black"}}>quantity</th>
          <th style={{padding:"5px",color:"black"}}>price(INR)</th>
        </thead>

        <tbody>
          {order.order_items.map((item, itemIndex) => (
          <tr key={itemIndex}>
            <td style={{padding:"5px",color:"grey",textAlign:"center"}}>{item.food_name}</td>
            <td style={{padding:"5px",color:"grey",textAlign:"center"}}>{item.quantity}</td>
            <td style={{padding:"5px",color:"grey",textAlign:"center"}}>{item.unit_price}</td>
          </tr>
          
          ))}
        </tbody>
        <tr>
        <td colspan="3" style={{padding:"5px",color:"black",textAlign:"right",fontWeight:"bolder"}}>Total: {order.total}</td>
        </tr>
      </table>
      <button className="btn btn-success">Print</button>
      <hr style={{border:"1px dashed grey",position:"relative",bottom:"90px"}}/>
    
      </div>
    ))}
      
      </div>

   */}

<Modal
        show={this.state.show}
        onHide={this.handleCloseConfirmation}
        backdrop="static"
        keyboard={false}
        size="md" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <Modal.Header closeButton style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"12px"}}>
          <Modal.Title  style={{fontFamily:"serif",fontWeight:"bolder",marginLeft:"190px"}}>Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>

         <div style={{padding:"20px",backgroundColor:"white"}}>
          <h6 className='text-dark' style={{textAlign:"center",fontWeight:"bolder"}}>Are You Sure You want to Logout ?</h6> 
         </div>
         {' '} 
          <Button variant="danger" style={{float:"right"}} onClick={this.handleCloseConfirmation}>
            Close
          </Button>{' '} 
          <Button variant="success" style={{float:"right",marginRight:"5px"}} onClick={this.handleModLogout}>
            Confirm
          </Button>
        </Modal.Body>
      </Modal>



      <Modal
        show={this.state.isOpenSelectPrint}
        onHide={this.handleCloseToPrinters}
        backdrop="static"
        keyboard={false}
        size="md" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <Modal.Header closeButton>
          <Modal.Title  style={{fontFamily:"serif",fontWeight:"bolder"}}>Change Printer</Modal.Title>
        </Modal.Header>
        <Modal.Body>

         <div style={{padding:"20px",backgroundColor:"white"}}>
          {
          this.state.printers.length == 0 ? (
            <h6 style={{color:"red", textAlign:"center"}}>Ensure you runned the print server!</h6>
          ) : (
            this.state.printers.map((printer, index) => (
              <div key={index} className="form-check" style={{ display: "inline-block", marginLeft: "70px" }}>
              <input
                className="form-check-input"
                type="radio"
                name="printer"
                id={`printer-${index}`}
                value={printer}
                checked={this.state.selectedPrinter === printer}
                onChange={this.handlePrinterChange}
                required
              />
              <label className="form-check-label" htmlFor={`printer-${index}`} style={{ fontWeight: "bolder" }}>
                {printer}
              </label>
            </div>
            ))
          )
           
            }
         </div>
         {' '} 
          <Button variant="danger" style={{float:"right"}} onClick={this.handleCloseToPrinters}>
            Close
          </Button>
        </Modal.Body>
      </Modal>


      <Modal
        show={this.state.settingModalOpen}
        onHide={this.handleCloseSettingPrintServer}
        backdrop="static"
        keyboard={false}
        size="lg" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <Modal.Header closeButton style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"12px"}}>
        <Modal.Title style={{fontSize:"19px", fontWeight:"bold"}}>Setting The Print Server</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div class="col">
            <label for="formFile" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Printing Type</label>
            <br/>
              <div class="form-check" style={{display:"inline-block"}}>
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="defaultPrint"  checked={this.state.printingType === 'defaultPrint'} onChange={this.handleOnchangePrintType} required/>
                <label class="form-check-label" for="flexRadioDefault1">
                  Default Print
                </label>
              </div>
              <div class="form-check" style={{display:"inline-block", marginLeft:"70px"}}>
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"  value="directPrint"   checked={this.state.printingType === 'directPrint'} onChange={this.handleOnchangePrintType} required/>
                <label class="form-check-label" for="flexRadioDefault2">
                  Direct Print
                </label>
              </div>
            </div>

            {
              localStorage.getItem('printingType') === "defaultPrint" ? (
                <h6 style={{fontWeight:"bold", color:"red", marginTop:"20px"}}>Default Printing Using Google Chrome Popup</h6>
              ) : (
                <>
                  <div className="card" style={{ width: 'auto', height: 'auto', margin: '10px' }}>
  <div className="card-body">

  <div style={{display:"flex",alignItems:"center", gap:"50px"}}>
          <a href="/printserver.zip" download>
            <button className="btn btn-primary" >Download Print Server</button>
          </a> 
          <h6>Active Printer: <b style={{color:"red"}}>{localStorage.getItem('selectedPrinter')}</b></h6>
          <button className="btn btn-success" onClick={this.handleToOpenPrinters}>Change Printer</button>
        </div>
<br/>
    <h5 style={{ textAlign: 'center', color: 'green', textDecoration:'underline' }}>INSTRUCTIONS</h5>
    <ol style={{ paddingLeft: '20px', lineHeight: '1.6', marginTop:"10px" }}>
      <li><strong>Step 1:
        {/* Download the Print Server ZIP File: */}
        </strong> Click the button below to download <code>printserver.zip</code> to your computer.</li>
      <img src={downloadPrintServer} alt="downloadPrinterServer" width="680px" height="350px" style={{margin:"auto"}}/>
      <br/>
      <br/>
      <li><strong>Step 2:
        {/* Extract the ZIP File: */}
        </strong> Locate the <code>printserver.zip</code> file and extract it to a folder of your choice. Right-click the ZIP file and select <code>Extract All…</code>, then follow the prompts to extract the files.</li>
      <img src={extractPrintServer} alt="extractPrintServer"width="680px" height="350px" style={{margin:"auto"}}/>
      <br/>
      <br/>
      <li><strong>Step 3:
        {/* Run the <code>install_dependencies.bat</code> Script: */}
        </strong> Open the folder where you extracted the ZIP file. Double-click on <code>install_dependencies.bat</code> to run the script. This will install all the necessary dependencies.</li>
      <img src={dependencies} alt="dependencies" width="680px" height="350px" style={{margin:"auto"}}/>
      <br/>
      <br/>
      <li><strong>Step 4:
        {/* Run the <code>run_server.bat</code> Script: */}
        </strong> After installing the dependencies, double-click on <code>run_server.bat</code> to start the Node.js server.</li>
      <img src={runServer} alt="runServer" width="680px" height="350px" style={{margin:"auto"}}/>
      <br/>
      <br/>
      <li><strong>Verify the Server is Running:</strong> After running <code>run_server.bat</code>,Check the node_server.log file in the print-server folder.
       {/* open a web browser and go to <a href="http://localhost:3001" target="_blank" rel="noopener noreferrer">http://localhost:3001</a>.  */}
       You should see a message indicating that the server is running.</li>
      <br/>
      <li><strong>Troubleshooting:</strong> If you encounter issues:
        <ul>
          <li>Ensure that port 3001 is not being used by another application.</li>
          <li>Check the <code>node_server.log</code> file in the <code>print-server</code> folder for any errors.</li>
          <li>Manually run the server by navigating to the <code>print-server</code> directory in a command prompt and executing <code>node index.js</code>.</li>
          <li>For help, contact support at [Your Support Contact Information].</li>
        </ul>
      </li>
      <br/>
    </ol>
  </div>
</div>

         
      
                </>
              )
            }
         
         {/* <div style={{textAlign:"center"}}>
         <Button variant="danger"  onClick={this.handleCloseSettingPrintServer}>
            Close
          </Button>{' '} 
         
         </div> */}
        </Modal.Body>
      </Modal>




      <Modal
        show={this.state.isPremium}
        onHide={this.handleClosePremiumAlert}
        backdrop="static"
        keyboard={false}
        size="md" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <Modal.Header>
        <h6><i class="bi bi-award-fill" style={{color:"red", fontSize:"20px"}}></i> Premium Features</h6>
        </Modal.Header>
        <Modal.Body>

         <div style={{padding:"20px",backgroundColor:"white"}}>
          <h6 className='text-dark' style={{textAlign:"center",fontWeight:"bolder"}}>To activate premium features, please subscribe now!</h6> 
         </div>
         {' '} 
          <Button variant="danger" style={{float:"right"}} onClick={this.handleClosePremiumAlert}>
            Close
          </Button>{' '} 
          <Button variant="success" style={{float:"right",marginRight:"5px"}} onClick={this.routeMysubscription}>
            Activate
          </Button>
        </Modal.Body>
      </Modal>




      
      
      <Modal
        show={this.state.isOpenSelectDate}
        onHide={this.handleCloseDateConfirmation}
        backdrop="static"
        keyboard={false}
        size="md" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <Modal.Header closeButton style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"12px"}}>
        <Modal.Title style={{fontSize:"19px", fontWeight:"bold"}}>Select Date Range</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            
            <div className="col">
              <label style={{color:"green", fontFamily:"serif", fontWeight:"bolder"}}>Start Date:</label>
              <input type='date' name='date'  style={{border:"2px ridge green",borderRadius:"15px",height:"35px",padding:"10px"}}
              placeholder='choose date' onChange={this.handleStartDateChange} value={this.formatDate(this.state.startDate)} />{" "}
            </div>
            <div className="col">
              <label style={{color:"green", fontFamily:"serif", fontWeight:"bolder"}}>End Date:</label>
              <input type="date" name='date'  placeholder="choose date" onChange={this.handleEndDateChange} 
                 style={{border:"2px ridge green",borderRadius:"15px",height:"35px",padding:"10px"}} value={this.formatDate(this.state.endDate)}
              />
            </div>
          </div>
     <br/>
         <div style={{textAlign:"center"}}>
         <Button variant="danger"  onClick={this.handleCloseDateConfirmation}>
            Close
          </Button>{' '} 
          <Button variant="success" style={{marginRight:"5px"}} onClick={this.handleSelectDateRange}>
            Ok
          </Button>
         </div>
        </Modal.Body>
      </Modal>


      <Modal
        show={this.state.isOpenOrderSummary}
        onHide={this.handleCloseSummaryConfirmation}
        backdrop="static"
        keyboard={false}
        size="md" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <Modal.Header closeButton style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"12px"}}>
        <Modal.Title style={{fontSize:"19px", fontWeight:"bold"}}> Today Order Summary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
            <h5 style={{fontFamily:"serif",fontWeight:"bolder",color:"green"}}>Order Type: {" "} 
            <span style={{textAlign:"center",color:"black",fontSize:"18px"}}>{this.state.selectedOrderLabel.label}</span></h5>
          
              <h5 style={{fontFamily:"serif",fontWeight:"bolder",color:"green"}}>Count: {" "} 
              <span style={{textAlign:"center",color:"black",fontSize:"18px"}}>{this.state.orderSummary.order_count ? this.state.orderSummary.order_count: 0 }</span></h5>
           
              <h5 style={{fontFamily:"serif",fontWeight:"bolder",color:"green"}}>Price:{" "} 
              <span style={{textAlign:"center",color:"black",fontSize:"18px"}}>{this.state.orderSummary.total_sum ? (this.state.orderSummary.total_sum).toFixed(2) : 0}</span></h5>
           
     <br/>
         <div style={{textAlign:"center"}}>
         <Button variant="danger"  onClick={this.handleCloseSummaryConfirmation}>
            Close
          </Button>{' '} 
         
         </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={this.state.isNormalModal}
        onHide={this.handleCloseNormalConfirmation}
        backdrop="static"
        keyboard={false}
        size="md" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <Modal.Header closeButton style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"12px"}}>
        <Modal.Title style={{fontSize:"19px", fontWeight:"bold"}}> Normal Order Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div style={{margin:"auto",padding:"10px 20px 10px 20px"}}>
       
          {
            isLogin ? (
              <div>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
            ) :  <Button variant="success"  onClick={this.generateOrder} style={{float:"right",padding:"0px 7px"}}>Complete Order</Button>
          }
            
          
         <h6 style={{fontWeight:"bolder", fontFamily:"serif"}}>Cust.Name: <span style={{fontWeight:"normal", fontFamily:"serif"}}>{document.querySelector('#name') ? document.querySelector('#name').value : "-"}</span></h6>
         <h6 style={{fontWeight:"bolder", fontFamily:"serif"}}>Mobile No: <span style={{fontWeight:"normal", fontFamily:"serif"}}>{document.querySelector('#mobile') ? document.querySelector('#mobile').value : "-"}</span>
         <span style={{float:"right"}}>
         <div class="form-check form-check-inline" style={{float:"right",margin:"0px 5px 0px 5px"}}>
  <input
          className="form-check-input"
          type="checkbox"
          id="inlineCheckbox1"
          value="option1"
          checked={this.state.isPrintNowChecked}
          onChange={this.handlePrintCheckboxChange}
        />
         <label class="form-check-label text-dark" for="inlineCheckbox1" style={{fontSize:"15px"}}>Make Print Now</label>
      </div>
         </span>
         </h6>
         <h6 style={{fontFamily:"serif",fontWeight:"bolder", fontSize:"20px"}}>Bill Amount: <span style={{fontFamily:"monospace",fontWeight:"normal",color:"green"}}>
          {this.state.cartItems.reduce((accumulator, cartItem) => 
      accumulator + cartItem.quantity * cartItem.food.price,0).toFixed(1)}
      </span> <span style={{float:"right"}}>Paymode Total: <b style={{color: CartTotal === modeTotal ? "green" : "red"}}>
        {this.state.selectedAmounts.reduce((accumulator, amount) => 
      accumulator + amount, 0).toFixed(1)}</b></span></h6>
         <br/>
       <div style={{marginTop:"-20px"}}>
       
       <table class="table table-hover">
  <thead>
    <tr>
      <th scope="col" style={{textAlign:"center"}}>Paymode</th>
      <th scope="col" style={{textAlign:"center"}}>Bill</th>
      <th scope="col" style={{textAlign:"center"}}>Rec.</th>
      <th scope="col" style={{textAlign:"center"}}>Bal.</th>
    </tr>
  </thead>
  <tbody>
    {
      this.state.payModes
      .map((mode,index)=>(

      mode.show_balance === 1 ? (
        <tr key={index}>
        <td>
          <div class={`card ${this.state.selectedAmounts[index] > 0 ? 'bg-success' : ''} ${this.state.selectedAmounts[index] > 0 ? 'text-light' : ''}`} style={{width:"auto", height:"auto", margin:"0px"}} onClick={() => this.handleCardClick(mode.id)}>
            <div class="card-body" style={{textAlign:"center"}}>
              {mode.pay_mode_name}
            </div>
          </div>
        </td>
      <td>
        <div style={{display:"flex", alignItems:"center"}}>
        <i class="bi bi-pencil" style={{color:"blue"}} onClick={() => this.handleEditClick(index)}></i>
        <Input type="number" id={`paymodeAmount${mode.id}`} placeholder="0" value={this.state.selectedAmounts[index] != null ? this.state.selectedAmounts[index].toFixed(1) : ""} onChange={(e) => this.handleAmountChange(index, e.target.value)} step="0.01" style={{width: "70px", margin: "auto"}} min="0" readOnly={!this.state.editBillAmounts[index]}/>
        </div>
      </td>
     <td>
      <Input type="number" id={`paymodeGivenAmount${mode.id}`} placeholder="0" value={this.state.givenAmounts[index]} onChange={(e) => this.handleGivenAmountChange(index, e.target.value)} step="0.01" style={{width: "70px", margin: "auto"}} min="0" />
       </td>
     <td>
        <b style={{color:"red"}}>{this.state.givenAmounts[index] == undefined ? 0 : this.state.givenAmounts[index] !=0 ?( this.state.givenAmounts[index] - this.state.selectedAmounts[index]).toFixed(2) : 0}</b>
     </td>
      </tr>
    ) : (

      <tr key={index}>
         <td>
          <div class={`card ${this.state.selectedAmounts[index] > 0 ? 'bg-success' : ''} ${this.state.selectedAmounts[index] > 0 ? 'text-light' : ''}`} style={{width:"auto", height:"auto", margin:"0px"}} onClick={() => this.handleCardClick(mode.id)}>
            <div class="card-body" style={{textAlign:"center"}}>
              {mode.pay_mode_name}
            </div>
          </div>
        </td>
      <td> <Input  type="number" id={`paymodeAmount${mode.id}`} placeholder="0" value={this.state.selectedAmounts[index] != null ? this.state.selectedAmounts[index].toFixed(1) : ""} onChange={(e) => this.handleAmountChange(index, e.target.value)}  step="0.01" style={{width:"140px",margin:"auto"}} min="0" />
       </td>  
         </tr>
    ) 
     
  
      ))
    }
    </tbody>
        </table>
       {/* <label style={{fontWeight:"bolder", fontFamily:"serif"}}>Pay Mode:</label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
           <Select
        options={this.state.payModeOptions}
        placeholder="Select mode"
        value={this.state.selectedPayMode}
        onChange={this.handlePayModeSelectChange}
        isSearchable={true}
        className="customSelectpaymode"
        required
    />
       </div> */}
    <div style={{ display: 'flex', alignItems: 'center' }}>
    <label style={{fontWeight:"bolder", fontFamily:"serif"}}>Remarks:</label>{" "}
       <Input  type="text" id="remark" placeholder="Remark" required  style={{width:"280px"}}/>    
    </div>
    
    
       </div>
     
         <div style={{textAlign:"center", marginTop:"5px"}}>
        {" "}
         <Button variant="danger"  onClick={this.handleCloseNormalConfirmation}>
            Close
          </Button>{' '} 
         
         </div>
          
        </div>
        </Modal.Body>
      </Modal>


      <Modal
        show={this.state.isNormalCreditModal}
        onHide={this.handleCloseNormalCreditConfirmation}
        backdrop="static"
        keyboard={false}
        size="md" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <Modal.Header closeButton style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"12px"}}>
        <Modal.Title style={{fontSize:"19px", fontWeight:"bold"}}> Normal Credit Order Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div style={{margin:"auot",padding:"20px"}}>
         {
            isLogin ? (
              <div>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
            ) : <Button variant="success"  onClick={this.generateNormalOrder} style={{float:"right",padding:"0px 7px"}}>Complete Order</Button>
          }
            
         {" "}
       
         <h6 style={{fontWeight:"bolder", fontFamily:"serif"}}>Cust.Name: <span style={{fontWeight:"normal", fontFamily:"serif"}}>{this.state.selectedData.label}</span></h6>
         <h6 style={{fontWeight:"bolder", fontFamily:"serif"}}>Mobile No: <span style={{fontWeight:"normal", fontFamily:"serif"}}>{this.state.selectedData.value}</span>
         <span style={{float:"right"}}>
         <div class="form-check form-check-inline" style={{float:"right",margin:"0px 5px 0px 5px"}}>
  <input
          className="form-check-input"
          type="checkbox"
          id="inlineCheckbox1"
          value="option1"
          checked={this.state.isPrintNowChecked}
          onChange={this.handlePrintCheckboxChange}
        />
         <label class="form-check-label text-dark" for="inlineCheckbox1" style={{fontSize:"15px"}}>Make Print Now</label>
      </div>
         </span>
         </h6>
         <h6 style={{fontFamily:"serif",fontWeight:"bolder", fontSize:"20px"}}>Bill Amount: <span style={{fontFamily:"monospace",fontWeight:"normal",color:"green"}}>{this.state.cartItems.reduce((accumulator, cartItem) => 
      accumulator + cartItem.quantity * cartItem.food.price,0).toFixed(1)}</span> 
      <span style={{float:"right"}}>Paymode Total: <b style={{color: CartTotal === modeTotal ? "green" : "red"}}>{this.state.selectedAmounts.reduce((accumulator, amount) => 
      accumulator + amount, 0).toFixed(1)
    }</b></span></h6>
         <br/>
       <div style={{marginTop:"-20px"}}>
       <table class="table table-hover">
  <thead>
    <tr>
      <th scope="col" style={{textAlign:"center"}}>Paymode</th>
      <th scope="col" style={{textAlign:"center"}}>Amount</th>
    </tr>
  </thead>
  <tbody>
    {
      this.state.payModes
      .map((mode,index)=>(
    <tr key={index}>
      <td>
        <div class={`card ${this.state.selectedAmounts[index] > 0 ? 'bg-success' : ''} ${this.state.selectedAmounts[index] > 0 ? 'text-light' : ''}`} style={{width:"auto", height:"auto", margin:"0px"}} onClick={() => this.handleCardClick(mode.id)}>
          <div class="card-body" style={{textAlign:"center"}}>
            {mode.pay_mode_name}
          </div>
        </div>
      </td>
      <td>
       <Input  type="number" id={`paymodeAmount${mode.id}`} placeholder="0" value={this.state.selectedAmounts[index]} onChange={(e) => this.handleAmountChange(index, e.target.value)}  step="0.01" style={{width:"140px",margin:"auto"}} min="0" />    
      </td>
    </tr>
      ))
    }
    </tbody>
        </table>

       {/* <label style={{fontWeight:"bolder", fontFamily:"serif"}}>Pay Mode:</label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
           <Select
        options={this.state.payModeOptions}
        placeholder="Select mode"
        value={this.state.selectedPayMode}
        onChange={this.handlePayModeSelectChange}
        isSearchable={true}
        className="customSelectpaymode"
    />
       </div> */}
    <br/>
    <div>
    <label style={{fontWeight:"bolder", fontFamily:"serif"}}>Remarks:</label>
       <Input  type="text" id="remark" placeholder="Remark" required  style={{width:"280px"}}/>    
    </div>
    
    
       </div>
       <br/>  

         <div style={{textAlign:"center"}}>
        
         <Button variant="danger"  onClick={this.handleCloseNormalCreditConfirmation}>
            Close
          </Button>{' '} 
         
         </div>
          
        </div>
        </Modal.Body>
      </Modal>


      <Modal
        show={this.state.isCreditModal}
        onHide={this.handleCloseCreditConfirmation}
        backdrop="static"
        keyboard={false}
        size="md" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <Modal.Header closeButton style={{backgroundColor:"rgb(239, 156, 156)",padding:"12px"}}>
        <Modal.Title style={{fontSize:"19px", fontWeight:"bold"}}> Credit Order Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div style={{margin:"auot",padding:"20px"}}>
       
         {
            iscredit ? (
              <div>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
            ) :  <Button variant="success"  onClick={this.generateCreditOrder} style={{float:"right",padding:"0px 7px"}}>Complete Order </Button>
          }
            
         <h6 style={{fontWeight:"bolder", fontFamily:"serif"}}>Cust.Name: <span style={{fontWeight:"normal", fontFamily:"serif"}}>{this.state.selectedData.label}</span></h6>
         <h6 style={{fontWeight:"bolder", fontFamily:"serif"}}>Mobile No: <span style={{fontWeight:"normal", fontFamily:"serif"}}>{this.state.selectedData.value}</span>
         <span style={{float:"right"}}>
         <div class="form-check form-check-inline" style={{float:"right",margin:"0px 5px 0px 5px"}}>
  <input
          className="form-check-input"
          type="checkbox"
          id="inlineCheckbox1"
          value="option1"
          checked={this.state.isPrintNowChecked}
          onChange={this.handlePrintCheckboxChange}
        />
         <label class="form-check-label text-dark" for="inlineCheckbox1" style={{fontSize:"15px"}}>Make Print Now</label>
      </div>
         </span>
         </h6>
         <h6 style={{fontFamily:"serif",fontWeight:"bolder", fontSize:"20px"}}>Bill Amount: <span style={{fontFamily:"monospace",fontWeight:"normal",color:"red"}}>{this.state.cartItems.reduce((accumulator, cartItem) => 
      accumulator + cartItem.quantity * cartItem.food.price,0).toFixed(1)
    }</span><span style={{float:"right"}}>Paymode Total: <b style={{color: "red"}}>{CartTotal}</b></span></h6>
         <br/>
       <div style={{marginTop:"-20px"}}>

       <table class="table table-hover">
  <thead>
    <tr>
      <th scope="col" style={{textAlign:"center"}}>Paymode</th>
      <th scope="col" style={{textAlign:"center"}}>Amount</th>
    </tr>
  </thead>
  <tbody>
  <tr>
      <td>
        <div class='card bg-danger text-light' style={{width:"auto", height:"auto", margin:"0px"}} >
          <div class="card-body" style={{textAlign:"center"}}>
          CREDIT ORDER
          </div>
        </div>
      </td>
      <td>
       <Input  type="number" id="paymodeAmount" value={CartTotal}  style={{width:"140px",margin:"auto"}} min="0" />    
      </td>
    </tr>
    </tbody>
        </table>
       {/* <label style={{fontWeight:"bolder", fontFamily:"serif"}}>Pay Mode: CREDIT ORDER</label> */}
       
    <br/>
    <div>
    <label style={{fontWeight:"bolder", fontFamily:"serif"}}>Remarks:</label>
       <Input  type="text" id="remark" placeholder="Remark" required  style={{width:"280px"}}/>    
    </div>
    
    
       </div>
       <br/>  

         <div style={{textAlign:"center"}}>
         {" "}
         <Button variant="danger"  onClick={this.handleCloseCreditConfirmation}>
            Close
          </Button>{' '} 
         
         </div>
          
        </div>
        </Modal.Body>
      </Modal>



      <Modal
        show={this.state.isOrderConfirmation}
        onHide={this.handleCloseOrderConfirmation}
        backdrop="static"
        keyboard={false}
        size="md" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <Modal.Header closeButton style={{padding:"12px",textAlign:"center"}}>
        <Modal.Title style={{fontSize:"19px", fontWeight:"bold",textAlign:"center"}}> Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div style={{margin:"auto",padding:"20px"}}>
          <h6 style={{textAlign:"center"}}>Thank you for the payment</h6>
          <h6 style={{textAlign:"center"}}>Pay Mode: <span style={{fontWeight:"bolder",fontFamily:"monospace",fontSize:"18px"}}>{this.state.enteredPaymodes}</span></h6>
          <h6 style={{textAlign:"center"}}>Bill Amount: <span style={{fontWeight:"bolder",fontFamily:"monospace",fontSize:"18px"}}>{this.state.orderResponse.totalAmount}</span></h6>
        </div>
        <div style={{textAlign:"center"}}>
         <Button variant="danger"  onClick={this.handleCloseOrderConfirmation}>
            Close
          </Button>{' '} 
         
         </div>
        </Modal.Body>
      </Modal>



      <Modal
        show={this.state.isVoid}
        onHide={this.handleCloseVoidConfirmation}
        backdrop="static"
        keyboard={false}
        size="md" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <Modal.Header closeButton style={{padding:"12px",textAlign:"center"}}>
        <Modal.Title style={{fontSize:"19px", fontWeight:"bold",textAlign:"center"}}> Confirm!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div style={{margin:"auto",padding:"20px"}}>
          <h6 style={{textAlign:"center"}}>Are you sure you want to push <span style={{color:"red", fontWeight:"bold", fontSize:"20px"}}> Order No: {this.state.voidOrder && this.state.voidOrder.order_no} </span>  to the void collection?</h6>
        </div>
        <div style={{textAlign:"center"}}>
         <Button variant="danger"  onClick={this.handleCloseVoidConfirmation}>
            Cancel
          </Button>{' '} 
         
          <Button variant="success"  onClick={this.handleVoidPut}>
            Confirm
          </Button>{' '} 
         </div>
        </Modal.Body>
      </Modal>


      <Modal
        show={this.state.isOpenAddInstant}
        onHide={this.handleCloseInstantAdding}
        backdrop="static"
        keyboard={false}
        size="lg" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <Modal.Header closeButton style={{padding:"12px",textAlign:"center"}}>
        <Modal.Title style={{fontSize:"19px", fontWeight:"bold",textAlign:"center"}}> Instant Food Adding</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div style={{margin:"auto",padding:"20px"}}>
        <div class="row">
         <div class="col">
            <label for="input1" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Food Name</label>
           <input type="text" class="form-control" placeholder="Enter food name" id="input1" name="food_name" style={{borderRadius:"12px"}} value={this.state.instantFoodName} onChange={this.handleInstandFoodChange} />
         </div>
         <div class="col">
           <label for="input3" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Unit Price</label>
           <input type="number" class="form-control" placeholder="Enter price" id="input3" name="quantity" style={{borderRadius:"12px"}} value={this.state.instantPrice} onChange={this.handleInstandPriceChange} required min="0.1"/>
         </div>
         <div class="col">
           <label for="input2" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Qty</label>
           <input type="number" class="form-control" placeholder="Enter quantity" id="input2" name="quantity" style={{borderRadius:"12px"}} value={this.state.instantQuantity} onChange={this.handleInstandQuantityChange} required min="1" />
         </div>
        </div>
        <br/>
        {
          this.state.instantQuantity && this.state.instantPrice &&
        <h6 style={{fontFamily:"monospace", fontWeight:"bolder", color:"blue"}}>Food Total: {this.state.instantQuantity * this.state.instantPrice}</h6>
    }
        </div>
        <div style={{textAlign:"center"}}>
        <Button variant="success"  onClick={this.handleToInstantAddingFood}>
            Add
          </Button>{' '} 
         
         <Button variant="danger"  onClick={this.handleCloseInstantAdding}>
            Close
          </Button>{' '} 
         
         </div>
        </Modal.Body>
      </Modal>



      <Modal
        show={this.state.isPayModeEdit}
        onHide={this.handleCloseaPayModeEdit}
        backdrop="static"
        keyboard={false}
        size="md" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <Modal.Header closeButton style={{padding:"12px",textAlign:"center"}}>
        <Modal.Title style={{fontSize:"19px", fontWeight:"bold",textAlign:"center"}}>Update Paymode</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        <div className="p-3">
           
        {
  this.state.isPayModeEditLoading ? (
    <div className="text-center">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <Button variant="success"  onClick={this.handleUpdatePaymode} style={{float:"right"}}>
      <i className="bi bi-vector-pen"></i> Update
      </Button>
  )
}
<br/>
          <h6 style={{color:"blue", textAlign:"left"}}>Order No: <b>{editPaymodeOrder ? editPaymodeOrder.order_no: " "}</b></h6>
          <h6 style={{color:"green"}}>Total Amount: <b>{editPaymodeOrder ? editPaymodeOrder.total : ""}</b> <span style={{float:"right"}}>Paymode Total: 
          <b style={{color: editPaymodeOrder && editPaymodeOrder.total == editModeTotal ? "green" : "red"}}>{this.state.editSelectedAmounts ? this.state.editSelectedAmounts.reduce((sum, currentItem) => sum + currentItem.amount, 0) : ""}</b></span></h6>
          
          <div className="row">
            <div className="col">
            <table class="table table-hover">
  <thead>
    <tr>
      <th scope="col" style={{textAlign:"center"}}>Paymode</th>
      <th scope="col" style={{textAlign:"center"}}>Amount</th>
    </tr>
  </thead>
  <tbody>
    {
     this.state.editSelectedAmounts && this.state.editSelectedAmounts
      .map((mode,index)=>(
    <tr key={index}>
      <td>
        <div class={`card ${this.state.editSelectedAmounts[index].amount > 0 ? 'bg-success' : ''} ${this.state.editSelectedAmounts[index].amount > 0 ? 'text-light' : ''}`} style={{width:"auto", height:"auto", margin:"0px"}} onClick={() => this.handleEditCardClick(mode.id, editPaymodeOrder && editPaymodeOrder.total)}>
          <div class="card-body" style={{textAlign:"center"}}>
            {mode.pay_mode_name}
          </div>
        </div>
      </td>
      <td>
       <Input  type="number" id={`paymodeEditAmount${mode.id}`}  value={mode.amount} onChange={(e) => this.handleEditAmountChange(index, e.target.value)} style={{width:"140px",margin:"auto"}} min="0" />    
      </td>
    </tr>
      ))
    }
    </tbody>
        </table>
            {/* <label class="form-label text-success fw-bold m-0" style={{fontFamily:"serif"}}>Paymode</label>
            <Select
        options={this.state.payModeOptions}
        placeholder="Select mode"
        value={this.state.editpaymode}
        onChange={this.handlePayModeEditChange}
        isSearchable={true}
        className="customSelectpaymode"
    /> */}
            </div>
            <div className="col">
            <label class="form-label text-success fw-bold m-0" style={{fontFamily:"serif"}}>Remark</label>
            <Input  type="text" id="editremark" placeholder="Remark" required  style={{width:"280px"}} value={this.state.updateRemark} onChange={this.handleRemarkChange} />    
            </div>
          </div>
        </div>
        <div style={{textAlign:"center"}}>
      
          {" "}
         <Button variant="danger"  onClick={this.handleCloseaPayModeEdit}>
            Close
          </Button>{' '} 
         
         </div>
        </Modal.Body>
      </Modal>


      <Modal
      show={this.state.showNotify}
      onHide={this.handleCloseNotifyConfirmation}
      backdrop="static"
      keyboard={false}
      size="xl" // Set size to large
      style={{ borderRadius: '20px' }} // Add border radius style
      >
        <Modal.Header closeButton style={{padding:"10px"}}>
        <Modal.Title style={{fontSize:"19px", fontWeight:"bold",textAlign:"center"}}> <i class="bi bi-bell"></i>Notifications</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <input class="form-control" type="Search" placeholder="Search" id="example-search-input" 
      style={{width:"230px",borderRadius:"15px",border:"1px solid black",float:"right",marginRight:"30px"}} onChange={this.handleSearchNotifyInput}/>
      <br/>
        <div style={{margin:"auto",padding:"10px"}}>
        
        <Card  border="success" style={{borderRadius:"20px",width:"auto",height:"auto",margin:"10px"}}> 
        <Card.Body className="p-2">

        <Card className="m-0 border hover" style={{width:"auto",height:"auto"}}> 
          <Card.Body>
            <div style={{display:"flex", alignItems:"center", gap:"70px",padding:"0px 50px"}}>
              <h5 style={{fontFamily:"serif",fontWeight:"bolder",color:"green"}}>S.No</h5>
              <h5 style={{fontFamily:"serif",fontWeight:"bolder",color:"green"}}>Cust Name</h5>
              <h5 style={{fontFamily:"serif",fontWeight:"bolder",color:"green"}}>Mobile No</h5>
              <h5 style={{fontFamily:"serif",fontWeight:"bolder",color:"green"}}>Email Id</h5>
              <h5 style={{fontFamily:"serif",fontWeight:"bolder",color:"green"}}>Ordered Date</h5>
              <h5 style={{fontFamily:"serif",fontWeight:"bolder",color:"green"}}>Ordered Time</h5>
            </div>
          </Card.Body>
          </Card>

     <div className="notifydiv" style={{height:"450px",overflow:"scroll"}}>
     {
      this.state.isNotify ? (
        <div class="text-center" style={{marginTop:"20px"}}>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      ):(
        filteredNotifys.length === 0 ? (
          <div class="text-center" style={{marginTop:"20px",color:"red"}}>
         No Data Available
        </div>
        ) : (
        filteredNotifys.map((notify,index)=>(
          <Card className="m-0 hoverable-card" style={{width:"auto",height:"auto"}} key={index}> 
          <Card.Body>
            <div style={{display:"flex", alignItems:"center", gap:"70px",padding:"0px 50px"}}>
            <h5 style={{fontFamily:"monospace"}}>{this.state.startIndex +index+1}</h5>
              <h5 style={{fontFamily:"serif"}}>{notify.triggered_by_username.name}</h5>
              <h5 style={{fontFamily:"serif"}}>{notify.triggered_by_username.phone_no}</h5>
              <h5 style={{fontFamily:"serif"}}>{notify.triggered_by_username.email}</h5>
              <h5 style={{fontFamily:"serif"}}>{notify.ordered_at}</h5>
              <h5 style={{fontFamily:"serif"}}>{notify.ordered_time}</h5>
            </div>
          </Card.Body>
          </Card>
        ))
      ))}
     </div>
       <br/>

<Pagination
    activePage={this.state.currentPage}
    itemsCountPerPage={this.state.itemsPerPage}
    totalItemsCount={this.state.totalItems} // Update to reflect total items for pagination
    pageRangeDisplayed={5}
    onChange={this.handlePageChange}
    itemClass="page-item"
    linkClass="page-link"
    innerClass="pagination justify-content-end"
    activeClass="active-pagination" // Custom class for the active pagination item
/>

        </Card.Body>
        </Card>
        </div>
        
        </Modal.Body>
      </Modal>

    </div>

   
    </>
    );
  }
}

// Set the contextType property to access the OrderContext
Foods.contextType = OrderContext;


export default withRouter(Foods);

